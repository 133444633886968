import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import BreadcrumbsStyle from "./BreadcrumbsStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Breadcrumbs = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = BreadcrumbsStyle(theTheme);
  const { separator, breadcrumbs, color, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, separator, breadcrumbs, color });

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.breadcrumbs],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  return (
    <ol ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
      <ErrorBoundary>
        {breadcrumbs && breadcrumbs.length > 0
          ? breadcrumbs.map((item, index) => {
              return (
                <li className={`${classes?.[classesName?.breadcrumbs_item]}`} key={index} onClick={item?.onClick ? item.onClick : () => {}}>
                  <span
                    className={`${item.onClick ? classes?.[classesName?.breadcrumbs_item_hover] : null} ${index === breadcrumbs.length - 1 ? classes?.[classesName?.breadcrumbs_item_last] : null}`}
                  >
                    {!item?.icon ? null : item?.icon}
                    {!item?.label ? null : item?.label}
                  </span>
                  {item?.blnSeparator === false ? null : <span className={classes?.[classesName?.breadcrumbs_separator]}>{separator ? separator : "/"}</span>}
                </li>
              );
            })
          : ""}
      </ErrorBoundary>
    </ol>
  );
});

export default Breadcrumbs;
