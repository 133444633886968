"use client";
import { StyleBuilder } from "..";

export default function ClassesBuilder(objStyles, objOption) {
  const classesClient = StyleBuilder({
    blnRandomPrefix: typeof objOption?.blnRandomPrefix !== "boolean" ? true : objOption?.blnRandomPrefix,
    blnIsDefaultStyle: false,
    blnCbmisStyle: false,
    objStyles: { ...objStyles },
  });

  return classesClient();
}

export function makeStyle(rules) {
  return rules;
}
