import { StyleBuilder, styleVariables } from "@cbmisorg/styles";

export const classesName = {
  drawer: `${styleVariables?.prefixDefaultClassName}_drawer`,
  drawerOpen: `${styleVariables?.prefixDefaultClassName}_drawer_open`,

  drawerContainer: `${styleVariables?.prefixDefaultClassName}_drawerContainer`,
  sx: `${styleVariables?.prefixDefaultClassName}_drawer_sx`,

  buttonSwipe: `${styleVariables?.prefixDefaultClassName}_buttonSwipe`,

  groupBar: `${styleVariables?.prefixDefaultClassName}_group_bar`,
  nav: `${styleVariables?.prefixDefaultClassName}_nav_item`,
  nav_item_item_content: `${styleVariables?.prefixDefaultClassName}_nav_item_item_content`,

  menu_container: `${styleVariables?.prefixDefaultClassName}_menu_container`,
  menu: `${styleVariables?.prefixDefaultClassName}_menu`,
  menu_bg_dialog_open: `${styleVariables?.prefixDefaultClassName}_menu_bg_dialog_open`,

};
export const style = StyleBuilder({
  blnRandomPrefix: false,
  blnIsDefaultStyle: true,
  blnCbmisStyle: true,
  objStyles: {
    [classesName?.buttonSwipe]: {
      height: "20px",
      width: "25px",
      position: "absolute",
      zIndex: "1000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "2px 2px 1px 0 #ababab4d",
      cursor: "pointer",
      xs: {
        marginInlineStart: "35vw",
      },
      sm: {
        marginInlineStart: "70vw",
      },
      md: {
        marginInlineStart: "80vw",
      },
      lg: {
        marginInlineStart: "85vw",
      },
      xl: {
        marginInlineStart: "90vw",
      },
    },
    [classesName?.drawer]: {
      background: "#f8fafc",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 1ms",
      boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05)",
      display: "none",
      flexDirection: "column",
      flex: "1 0 auto",
      bottom: "auto",
      outline: "0px",
      left: "0px",
      right: "auto",
      minWidth: "0px",
      opacity: 0,
      zIndex: "1000",
      overflow: "auto",
      [`.${classesName?.groupBar}`]: {
        display: "block !important",
      },
      [`.${classesName?.nav}`]: {
        minWidth: "100%",
        [`.${classesName?.nav_item_item_content}`]: {
          margin: "0px",
          width: "90%",
        },
      },
      [`.${classesName?.menu_container}`]: {
        minWidth: "100%",
      },
      [`.${classesName?.menu}`]: {
        position: "relative  !important",
        width: "100%  !important",
        boxShadow: "none  !important",
        borderRadius: "0  !important",
        padding: "0  !important",
        top: "initial !important",
        left: "initial  !important",
        right: "initial  !important",
      },
      [`.${classesName?.menu_bg_dialog_open}`]:{
          position: "relative",
          display: "none",
          width: "0",
          top: 0,
          left: 0,
          height: "0",
          zIndex: "-1 !important",
      },
    },

  },
});

export const styleSx = StyleBuilder({
  blnRandomPrefix: true,
  blnIsDefaultStyle: false,
  blnCbmisStyle: true,
  objStyles: {
    [classesName?.drawer]: (props) => {
      return {
        ...open(props?.open),
        ...mode(props?.mode),
      };
    },
    [classesName?.sx]: (props) => {
      return {
        ...props?.sx,
      };
    },
  },
});

const mode = (mode) => {
  const getMode = String(mode)?.toLowerCase();
  switch (getMode) {
    case "dashboard":
      return {
        position: "sticky",
        top: "70px",
        height: "calc(100% - 200px)",
      };
    default:
      return {
        position: "fixed",
      };
  }
};

const open = (open) => {
  return {
    display: "block",
    opacity: 1,
    overflow: "auto",
    minHeight: "calc(-68px + 100vh)",
    maxHeight: "calc(-58px + 100vh)",
    padding: "0.5rem",
    xs: {
      minWidth: "65vw",
      maxWidth: "65vw",
    },
    sm: {
      minWidth: "30vw",
      maxWidth: "30vw",
    },
    md: {
      minWidth: "20vw",
      maxWidth: "20vw",
    },
    lg: {
      minWidth: "15vw",
      maxWidth: "15vw",
    },
    xl: {
      minWidth: "10vw",
      maxWidth: "10vw",
    },
  };
};


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;
  const classesName = {
    drawer: `${styleVariables?.prefixDefaultClassName}_drawer`,
    drawerOpen: `${styleVariables?.prefixDefaultClassName}_drawer_open`,
  
    drawerContainer: `${styleVariables?.prefixDefaultClassName}_drawerContainer`,
    sx: `${styleVariables?.prefixDefaultClassName}_drawer_sx`,
  
    buttonSwipe: `${styleVariables?.prefixDefaultClassName}_buttonSwipe`,
  
    groupBar: `${styleVariables?.prefixDefaultClassName}_group_bar`,
    nav: `${styleVariables?.prefixDefaultClassName}_nav_item`,
    nav_item_item_content: `${styleVariables?.prefixDefaultClassName}_nav_item_item_content`,
  
    menu_container: `${styleVariables?.prefixDefaultClassName}_menu_container`,
    menu: `${styleVariables?.prefixDefaultClassName}_menu`,
    menu_bg_dialog_open: `${styleVariables?.prefixDefaultClassName}_menu_bg_dialog_open`,
  
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.buttonSwipe]: {
        height: "20px",
        width: "25px",
        position: "absolute",
        zIndex: "1000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "2px 2px 1px 0 #ababab4d",
        cursor: "pointer",
        xs: {
          marginInlineStart: "35vw",
        },
        sm: {
          marginInlineStart: "70vw",
        },
        md: {
          marginInlineStart: "80vw",
        },
        lg: {
          marginInlineStart: "85vw",
        },
        xl: {
          marginInlineStart: "90vw",
        },
      },
      [classesName?.drawer]: {
        background: "#f8fafc",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 1ms",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05)",
        display: "none",
        flexDirection: "column",
        flex: "1 0 auto",
        bottom: "auto",
        outline: "0px",
        left: "0px",
        right: "auto",
        minWidth: "0px",
        opacity: 0,
        zIndex: "1000",
        overflow: "auto",
        [`.${classesName?.groupBar}`]: {
          display: "block !important",
        },
        [`.${classesName?.nav}`]: {
          minWidth: "100%",
          // fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
          [`.${classesName?.nav_item_item_content}`]: {
            margin: "0px",
            width: "90%",
          },
        },
        [`.${classesName?.menu_container}`]: {
          minWidth: "100%",
        },
        [`.${classesName?.menu}`]: {
          position: "relative  !important",
          width: "100%  !important",
          boxShadow: "none  !important",
          borderRadius: "0  !important",
          padding: "0  !important",
          top: "initial !important",
          left: "initial  !important",
          right: "initial  !important",
        },
        [`.${classesName?.menu_bg_dialog_open}`]:{
            position: "relative",
            display: "none",
            width: "0",
            top: 0,
            left: 0,
            height: "0",
            zIndex: "-1 !important",
        },
      },
  
    },
  });
  
  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.drawer]: (props) => {
        return {
          ...open(props?.open),
          ...mode(props?.mode),
        };
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  return{
    classesName,
    style,
    styleSx
  }
}