import React from "react";
import { Avatar, Grid, Paper, Typography } from "@cbmisorg/material";
import profileImage from "../../../../util/assets/images/team1.jpg";
import { dictionary } from "../../../../util/appLanguage/dictionary";

const labels = dictionary?.components?.public?.sheikProfile;
function MemberInfo({ classes, lang }) {
  return (
    <React.Fragment>
      <Paper className={classes?.bgBox2}>
        <Grid container justifyContent="center">
          <Grid item className={classes?.profileImgContainer}>
            <Avatar src={profileImage} alt="" className={classes?.profileImgAvatar} />
          </Grid>
          <Grid item xs="12" justifyContent="center">
            <Typography as="subtitle1">{labels?.name?.[lang]}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default MemberInfo;
