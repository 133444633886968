import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { Button, Container, Grid, Line, Typography, Modal, ModalHeader, ModalBody } from "@cbmisorg/material";

import { useApp } from "../../../../../../configuration/contextapi/context";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";

import { CtrlRegActivityGuest } from "./RegActivityGuest.controller";
import { objStyle } from "./RegActivityGuest.style";

import { App_info_Color, App_Second_Color } from "../../../../../../util/appStyle";
import { workSectorID } from "../../../../../../configuration/appVariables";
import WorkSectorOption from "./component/WorkSectorOption.view";
import SubjectOption from "./component/SubjectOption.view";


const labels = dictionary?.components?.stakeholders?.member?.account?.regActivityGuest;

function RegActivityGuestView({ isLoading, setIsLoading, activityInfo, closeModal, parentState }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle(lang));

  const [state, setState] = useState({
    blnIsInitDone: false,
    objActivityInfo: {},
    lstCurentSubject: [],
    subjectSelected: null,
    blnOpenModal: false,
  });

  const fields = FormData({
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    lstSubject: {
      control: "radio",
      blnLanguage: false,
      validations: {},
    },
    lstTargeted: {
      control: "list",
      blnLanguage: false,
      validations: {
        required: true,
      },
    },
  });

  const handlers = CtrlRegActivityGuest({ appState, appDispatch, isLoading, setIsLoading });
  const handelWorkSectorChange =  handlers.handelWorkSectorChange({ fields, state, setState, WorkSectorOptionComponent: WorkSectorOption, SubjectOptionComponent: SubjectOption });
  
  function handleSubmit() {
    handlers.addGuest({ state, setState, fields, closeModal, activityInfo });
  }

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      handlers.initData({ activityInfo, state, setState, parentState, fields, WorkSectorOptionComponent: WorkSectorOption, SubjectOptionComponent: SubjectOption, closeModal });
    }
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Grid container justifyContent="center" spacing={1}>
          {activityInfo?.objActivity?.overview?.value?.[lang] && (
            <Grid item xs="12">
              <Typography as="body2" className={classes?.labelsLight}>
                {activityInfo?.objActivity?.overview?.value?.[lang]}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Line className={classes?.line} />

        <Grid container spacing={2}>
          <Grid item className={classes?.subtitle} p-3>
            <Typography as="subtitle1" className={classes?.subtitleLabel}>
              {labels?.registrationInfo?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} p-0 />
          <Grid item xs="12" md={fields.bigWorkSector?.getValue() ? 6 : 12}>
            <FormField
              objHandler={fields.bigWorkSector}
              icon="Work"
              mode={"classic"}
              iconColor={App_info_Color}
              color={App_Second_Color}
              className={classes?.field}
              onChange={handelWorkSectorChange}
            />
          </Grid>
          <Grid item xs={fields.bigWorkSector?.getValue() ? 12 : "0"} md={fields.bigWorkSector?.getValue() ? 6 : "0"}>
            <FormField objHandler={fields.lstTargeted} icon="people" mode={"classic"} iconColor={App_info_Color} color={App_Second_Color} className={classes?.field} />
          </Grid>
          <Grid item xs={12}>
            <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
          </Grid>
          {state.lstCurentSubject?.length && fields.lstTargeted?.getValue()?.key && (
            <Grid item xs="12">
              {state?.objActivityInfo?.type?.value?.["eng"] !== "none" ? (
                <FormField objHandler={fields.lstSubject} color={App_Second_Color} />
              ) : (
                Object?.keys(state.lstCurentSubject)?.map((element, index) => {
                  return <SubjectOption key={index} element={state.lstCurentSubject?.[element]?.subjectValue} blnLst={true} lang={lang} state={state} setState={setState} />;
                })
              )}
            </Grid>
          )}
        </Grid>

        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs="12" pt-5 pb-10>
            <Button
              py-0
              px-10
              label={labels?.btn?.[lang]}
              mode="outlined"
              color={!fields?.lstSubject?.getValue() ? "#999" : App_info_Color}
              onClick={handleSubmit}
              className={classes?.btn}
              disabled={!fields?.lstSubject?.getValue()}
            />
          </Grid>
        </Grid>
      </Container>

      <Modal open={state?.blnOpenModal} eventClose={() => setState({ ...state, blnOpenModal: false, subjectSelected: null })} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}></ModalHeader>
        <ModalBody className={classes?.modalBody}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            {/* <Grid item xs="11">
              <img src={state?.subjectSelected?.detail?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid> */}

            <Grid item xs="12" md="8">
              <img src={state?.subjectSelected?.detail?.table?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid>
            <Grid item xs="12" md="4">
              <img src={state?.subjectSelected?.detail?.speakers?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RegActivityGuestView;
