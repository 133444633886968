// export const getBaseSize=()=>{
//     let size=0.25
//     if(typeof document !== 'undefined' && typeof  localStorage !== "undefined"){
//         const local =  localStorage?.getItem("app-themes-base-size")
//         if(!isNaN(Number(local))){
//             size = Number(local)
//         }
//     }
//     return size
// }

export const defaultBaseSize = 0.25;

export const baseSizeXS = 0.15;
export const baseSizeSM = 0.25;
export const baseSizeMD = 0.35;
export const baseSizeLG = 0.4;
export const baseSizeXL = 0.5;

export const globalValues = {
  inherit: "inherit",
  initial: "initial",
  revert: "revert",
  revertLayer: "revert-layer",
  unset: "unset",
  none: "none",
};

export const cssKeys = {
  //#region color
  blue: "blue",
  indigo: "indigo",
  purple: "purple",
  pink: "pink",
  red: "red",
  orange: "orange",
  yellow: "yellow",
  green: "green",
  teal: "teal",
  cyan: "cyan",
  primary: "primary",
  secondary: "secondary",
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
  white: "white",
  black: "black",
  disabled: "disabled",

  gray: "gray",
  light: "light",
  dark: "dark",

  bodyColor: "bodyColor",
  bodyColorLight: "bodyColorLight",
  bodyColorDark: "bodyColorDark",

  bodyColorBg: "bodyColorBg",
  bodyColorBgLight: "bodyColorBgLight",
  bodyColorBgDark: "bodyColorBgDark",

  borderColor: "borderColor",
  borderColor2: "borderColor2",

  currentColor: "currentColor",

  inputColor: "inputColor",
  hover: "hover",

  transparent: "transparent",
  //#endregion

  //#region  size
  width: "w",
  height: "h",
  min: "min",
  max: "max",

  px: "px",
  auto: "auto",
  full: "full",
  half: "half",
  screen: "screen",

  "3xs": "3xs",
  "2xs": "2xs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",

  item_2xs: "item_2xs",
  item_xs: "item_xs",
  item_sm: "item_sm",
  item_md: "item_md",
  item_lg: "item_lg",
  item_xl: "item_xl",
  item_2xl: "item_2xl",

  border_2xs: "border_2xs",
  border_xs: "border_xs",
  border_sm: "border_sm",
  border_md: "border_md",
  border_lg: "border_lg",
  border_xl: "border_xl",

  "1/2": "1/2",
  "1/3": "1/3",
  "2/3": "2/3",
  "1/4": "1/4",
  "2/4": "2/4",
  "3/4": "3/4",
  "1/5": "1/5",
  "2/5": "2/5",
  "3/5": "3/5",
  "4/5": "4/5",

  "1/6": "1/6",
  "2/6": "2/6",
  "3/6": "3/6",
  "4/6": "4/6",
  "5/6": "5/6",

  "1/12": "1/12",
  "2/12": "2/12",
  "3/12": "3/12",
  "4/12": "4/12",
  "5/12": "5/12",
  "6/12": "6/12",
  "7/12": "7/12",
  "8/12": "8/12",
  "9/12": "9/12",
  "10/12": "10/12",
  "11/12": "11/12",
  //#endregion

  //#region  spacing
  mrg: "m",
  mrgTop: "mt",
  mrgBottom: "mb",
  mrgLeft: "ml",
  mrgRight: "mr",
  mrgY: "my",
  mrgX: "mx",

  pdn: "p",
  pdnTop: "pt",
  pdnBottom: "pb",
  pdnLeft: "pl",
  pdnRight: "pr",
  pdnY: "py",
  pdnX: "px",
  //#endregion

  //#region side
  x: "x",
  y: "y",
  t: "t",
  b: "b",
  r: "r",
  l: "l",
  start: "start",
  end: "end",
  //#endregion

  //#region bg
  size: "size",
  position: "position",
  attachment: "attachment",
  repeat: "repeat",
  clip: "clip",
  opacity: "opacity", //bg-opacity-value (%)
  none: "none", //bg-none
  //#endregion

  //#region border
  border: "border",
  divide: "divide",
  rounded: "rounded",
  borderSides: "borderSides",
  //#endregion

  //#region  position
  relative: "relative",
  absolute: "absolute",
  fixed: "fixed",
  sticky: "sticky",
  //#endregion

  //#region text
  text: "text",
  font: "font",
  break: "break",
  truncate: "truncate",
  transform: "transform",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "subtitle1",
  subtitle2: "subtitle2",
  subtitle3: "subtitle3",
  body1: "body1",
  body2: "body2",
  body3: "body3",
  caption: "caption",
  overline: "overline",

  fontWeight: "fontWeight",
  //#endregion

  //#region display
  block: "block",
  flex: "flex",
  //#endregion

  //#region
  contentBox: "contentBox",
  borderBox: "borderBox",
  //#endregion

  //#region flex
  center: "center",
  baseline: "baseline",
  //#endregion

  //#region  fontWeight
  fontWeight_2xs: "fontWeight_2xs",
  fontWeight_xs: "fontWeight_xs",
  fontWeight_sm: "fontWeight_sm",
  fontWeight_md: "fontWeight_md",
  fontWeight_lg: "fontWeight_lg",
  fontWeight_xl: "fontWeight_xl",
  //#endregion

  //#region
  breakSpaces: "breakSpaces",
  //#endregion

  //#region shadow
  shadow: "shadow",
  xl2: "2xl",
  inner: "inner",
  outline: "outline",
  //#endregion

  baseSize:"baseSize",
};

export function objCss(baseSize = defaultBaseSize) {
  return {
    background: {
      [cssKeys?.size]: {//bg-value
        cover: "cover",
        contain: "contain",
        auto: "auto",
      },
      [cssKeys?.position]: {// bg-v-value-h-value
        cover: "top",
        contain: "bottom",
        center: "center",
        left: "left",
        right: "right",
      },
      [cssKeys?.attachment]: {//bg-value
        fixed: "fixed",
        local: "local",
        scroll: "scroll",
      },
      [cssKeys?.repeat]: {//bg-repeat || bg-repeat-value || bg-no-repeat
        repeat: "repeat",
        no: "no-repeat",
        x: "repeat-x",
        y: "repeat-y",
        round: "round",
        space: "space",
      },
      [cssKeys?.clip]: {//bg-clip-value
        border: "border-box",
        content: "content-box",
        padding: "padding-box",
        text: "text",
      },
    },
    border: {
      [cssKeys?.border]: {
        solid: "solid",
        dashed: "dashed",
        dotted: "dotted",
        none: "none",
      },
      [cssKeys?.divide]: {},
      [cssKeys?.rounded]: {
        xs: "0.25rem",
        sm: "0.5rem",
        md: "0.75rem",
        lg: "1rem",
        xl: "1.5rem",
        pill: "100%",
        px: "1px",
      },
      [cssKeys?.borderSides]: {
        X: "x",
        y: "y",
        t: "t",
        b: "b",
        r: "r",
        l: "l",
        xs: "xs",
        ys: "ys",
        xe: "xe",
        ye: "ye",
        tr: "tr",
        tl: "tl",
        br: "br",
        bl: "bl",
      },
      [cssKeys?.size]: {
        none: "0px",
        xs: "1px",
        sm: "2px",
        md: "3px",
        lg: "4px",
        xl: "5px",
      },
    },
    color: {
      [cssKeys?.blue]: "#1769aa",
      [cssKeys?.indigo]: "#6610f2",
      [cssKeys?.purple]: "#6f42c1",
      [cssKeys?.pink]: "#e91e63",
      [cssKeys?.red]: "#dc3545",
      [cssKeys?.orange]: "#fd7e14",
      [cssKeys?.yellow]: "#ffcc00",
      [cssKeys?.green]: "#198754",
      [cssKeys?.teal]: "#20c997",
      [cssKeys?.cyan]: "#0dcaf0",
      [cssKeys?.primary]: "#1769aa",
      [cssKeys?.secondary]: "#e91e63",
      [cssKeys?.success]: "#198754",
      [cssKeys?.info]: "#0dcaf0",
      [cssKeys?.warning]: "#ffcc00",
      [cssKeys?.error]: "#dc3545",
      [cssKeys?.white]: "#ffffff",
      [cssKeys?.black]: "#000000",
      [cssKeys?.disabled]: "#bcbcbc",
      [cssKeys?.hover]: "#a2c3dd",

      [cssKeys?.gray]: "#374151",
      [cssKeys?.light]: "#e5e7eb",
      [cssKeys?.dark]: "#111827",

      [cssKeys?.bodyColor]: "#5a6472",
      [cssKeys?.bodyColorLight]: "#e5e7eb",
      [cssKeys?.bodyColorDark]: "#030712",

      [cssKeys?.transparent]: "transparent",
      [cssKeys?.bodyColorBg]: "#f6f6f6",
      [cssKeys?.bodyColorBgLight]: "#f1f1f1",
      [cssKeys?.bodyColorBgDark]: "#1f2937",

      [cssKeys?.borderColor]: "#9BA4B5",
      [cssKeys?.borderColor2]: "#212121",

      [cssKeys?.currentColor]: "currentColor",
      [cssKeys?.inputColor]: "#55585c",

      [`${cssKeys?.primary}_1`]: "#b9d2e6",
      [`${cssKeys?.primary}_2`]: "#5d96c4",
      [`${cssKeys?.primary}_3`]: "#4587bb",
      [`${cssKeys?.primary}_4`]: "#2e78b3",
      [`${cssKeys?.primary}_5`]: "#1769aa",
      [`${cssKeys?.primary}_6`]: "#155f99",
      [`${cssKeys?.primary}_7`]: "#125488",
      [`${cssKeys?.primary}_8`]: "#104a77",
      [`${cssKeys?.primary}_9`]: "#0e3f66",
    },
    size: {
      [cssKeys?.px]: "1px",
      [cssKeys?.auto]: "auto",
      [cssKeys?.full]: "100%",
      [cssKeys?.half]: "50%",
      [cssKeys?.screen]: "100v",

      [cssKeys?.xs]: "25%",
      [cssKeys?.sm]: "41.666667%",
      [cssKeys?.md]: "58.333333%",
      [cssKeys?.lg]: "75%",
      [cssKeys?.xl]: "91.666667%",

      [cssKeys?.item_2xs]: `${baseSize * 4}rem`,
      [cssKeys?.item_xs]: `${baseSize * 6}rem`,
      [cssKeys?.item_sm]: `${baseSize * 8}rem`,
      [cssKeys?.item_md]: `${baseSize * 10}rem`,
      [cssKeys?.item_lg]: `${baseSize * 12}rem`,
      [cssKeys?.item_xl]: `${baseSize * 15}rem`,
      [cssKeys?.item_2xl]: `${baseSize * 18}rem`,

      [cssKeys?.border_2xs]: `${baseSize / 3}rem`,
      [cssKeys?.border_xs]: `${baseSize / 2.5}rem`,
      [cssKeys?.border_sm]: `${baseSize / 1.5}rem`,
      [cssKeys?.border_md]: `${baseSize * 1}rem`,
      [cssKeys?.border_lg]: `${baseSize * 2}rem`,
      [cssKeys?.border_xl]: `${baseSize * 3}rem`,

      [cssKeys?.["1/2"]]: "50%",
      [cssKeys?.["1/3"]]: "33.333333%",
      [cssKeys?.["2/3"]]: "66.666667%",
      [cssKeys?.["1/4"]]: "25%",
      [cssKeys?.["2/4"]]: "50%",
      [cssKeys?.["3/4"]]: "75%",
      [cssKeys?.["1/5"]]: "20%",
      [cssKeys?.["2/5"]]: "40%",
      [cssKeys?.["3/5"]]: "60%",
      [cssKeys?.["4/5"]]: "80%",
      [cssKeys?.["1/6"]]: "16.666667%",
      [cssKeys?.["2/6"]]: "33.333333%",
      [cssKeys?.["3/6"]]: "50%",
      [cssKeys?.["4/6"]]: "66.666667%",
      [cssKeys?.["5/6"]]: "83.333333%",

      [cssKeys?.["1/12"]]: "8.333333%",
      [cssKeys?.["2/12"]]: "16.666667%",
      [cssKeys?.["3/12"]]: "25%",
      [cssKeys?.["4/12"]]: "33.333333%",
      [cssKeys?.["5/12"]]: "41.666667%",
      [cssKeys?.["6/12"]]: "50%",
      [cssKeys?.["7/12"]]: "58.333333%",
      [cssKeys?.["8/12"]]: "66.666667%",
      [cssKeys?.["9/12"]]: "75%",
      [cssKeys?.["10/12"]]: "83.333333%",
      [cssKeys?.["11/12"]]: "91.666667%",

      baseSize: `${baseSize}rem`,
    },
    spacing: {
      [cssKeys?.px]: "1px",
      [cssKeys?.auto]: "auto",

      [cssKeys?.["3xs"]]: `${baseSize / 3}rem`,
      [cssKeys?.["2xs"]]: `${baseSize / 2}rem`,
      [cssKeys?.xs]: `${baseSize * 1}rem`,
      [cssKeys?.sm]: `${baseSize * 2}rem`,
      [cssKeys?.md]: `${baseSize * 3}rem`,
      [cssKeys?.lg]: `${baseSize * 4}rem`,
      [cssKeys?.xl]: `${baseSize * 5}rem`,
    },
    text: {
      [cssKeys?.break]: {
        normal: "normal",
        words: "break-word",
        all: "break-all",
      },
      [cssKeys?.transform]: {
        uppercase: "uppercase",
        lowercase: "lowercase",
        capitalize: "capitalize",
        normalcase: "none",
      },
      [cssKeys?.size]: {
        h1: `${baseSize * 13}rem`,
        h2: `${baseSize * 10}rem`,
        h3: `${baseSize * 7.5}rem`,
        h4: `${baseSize * 6.5}rem`,
        h5: `${baseSize * 6}rem`,
        h6: `${baseSize * 5.25}rem`,
        subtitle1: `${baseSize * 5}rem`,
        subtitle2: `${baseSize * 4}rem`,
        subtitle3: `${baseSize * 3.5}rem`,
        body1: `${baseSize * 4.5}rem`,
        body2: `${baseSize * 3.8}rem`,
        body3: `${baseSize * 3.3}rem`,
        caption: `${baseSize * 2.8}rem`,
        overline: `${baseSize * 2}rem`,
      },
      [cssKeys?.fontWeight]: {
        fontWeight_2xs: 400,
        fontWeight_xs: 500,
        fontWeight_sm: 600,
        fontWeight_md: 700,
        fontWeight_lg: 800,
        fontWeight_xl: 900,
      },
    },
    position: {
      [cssKeys?.relative]: "relative",
      [cssKeys?.absolute]: "absolute",
      [cssKeys?.fixed]: "fixed",
      [cssKeys?.sticky]: "sticky",
    },
    display: {
      [cssKeys?.block]: "block",
      [cssKeys?.flex]: "flex",
    },
    boxSizing: {
      [cssKeys?.contentBox]: "content-box",
      [cssKeys?.borderBox]: "border-box",
      ...globalValues,
    },
    flex: {
      [cssKeys?.center]: "center",
      [cssKeys?.baseline]: "baseline",
    },
    whiteSpace: {
      [cssKeys?.breakSpaces]: "break-spaces",
    },
    side: {
      [cssKeys?.start]: "start",
    },
    boxShadow: {
      [cssKeys?.shadow]: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      [cssKeys?.inner]: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      [cssKeys?.outline]: "1px 1px 1px rgba(0, 0, 0, 0.2)",
      [cssKeys?.none]: "none",

      [cssKeys?.xl2]: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",// "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      [cssKeys?.xl]: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",// "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      [cssKeys?.lg]: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",// "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      [cssKeys?.md]: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",//"0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.05),",
      [cssKeys?.sm]: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",// "0 2px 3px -1px rgba(0, 0, 0, 0.05),  0 2px 3px -1px rgba(0, 0, 0, 0.05), 0 2px 3px -1px rgba(0, 0, 0, 0.05), 0 2px 3px -1px rgba(0, 0, 0, 0.05)",

      [cssKeys?.xs]: "0 0 0 .5px rgba(0, 0, 0, 0.05),  0 0px 0px .5px rgba(0, 0, 0, 0.05)",
    },
  };
}

export const mainTheme = {
  themes: {},
  currentTheme: null,
  currentBaseSize: 0.25,
  baseSize: 0.25,
  cssObject: objCss(0.25),
};

// --`${styleVariables?.prefixDefaultClassName}`-color-gray
// --`${styleVariables?.prefixDefaultClassName}`-color-dark
// --`${styleVariables?.prefixDefaultClassName}`-color-primary
// --`${styleVariables?.prefixDefaultClassName}`-color-secondary
// --`${styleVariables?.prefixDefaultClassName}`-color-success
// --`${styleVariables?.prefixDefaultClassName}`-color-info
// --`${styleVariables?.prefixDefaultClassName}`-color-warning
// --`${styleVariables?.prefixDefaultClassName}`-color-error
// --`${styleVariables?.prefixDefaultClassName}`-color-light

// --`${styleVariables?.prefixDefaultClassName}`-color-body
// --`${styleVariables?.prefixDefaultClassName}`-color-light-body
// `--${styleVariables?.prefixDefaultClassName}-color-dark-body`

// --`${styleVariables?.prefixDefaultClassName}`-color-bg-body
// --`${styleVariables?.prefixDefaultClassName}`-color-bg-light-body
// --`${styleVariables?.prefixDefaultClassName}`-color-bg-dark-body

// `--${styleVariables?.prefixDefaultClassName}-color-border`:

// `--${styleVariables?.prefixDefaultClassName}-color-hover`:

// `--${styleVariables?.prefixDefaultClassName}-h1-font-size
// --`${styleVariables?.prefixDefaultClassName}`-h2-font-size
// --`${styleVariables?.prefixDefaultClassName}`-h3-font-size
// --`${styleVariables?.prefixDefaultClassName}`-h4-font-size
// --`${styleVariables?.prefixDefaultClassName}`-h5-font-size
// --`${styleVariables?.prefixDefaultClassName}`-h6-font-size
// --`${styleVariables?.prefixDefaultClassName}`-subtitle1-font-size
// `--${styleVariables?.prefixDefaultClassName}-subtitle2-font-size`
// --`${styleVariables?.prefixDefaultClassName}`-body1-font-size
// --`${styleVariables?.prefixDefaultClassName}`-body2-font-size
// --`${styleVariables?.prefixDefaultClassName}`-caption-font-size
// --`${styleVariables?.prefixDefaultClassName}`-overline-font-size

// --`${styleVariables?.prefixDefaultClassName}`-item-size-xs
// --`${styleVariables?.prefixDefaultClassName}`-item-size-sm
// --`${styleVariables?.prefixDefaultClassName}`-item-size-md
// --`${styleVariables?.prefixDefaultClassName}`-item-size-lg
// --`${styleVariables?.prefixDefaultClassName}`-item-size-xl
