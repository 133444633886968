"use client";

function getPkgConfigIcon() {
    const initConfig = {
      blnIsTestingMode: true,
    };
    return initConfig;
  }

  export var pkgConfigIcon = getPkgConfigIcon();

  export function setPkgConfigIcon({ blnIsTestingMode = false,}) {
    pkgConfigIcon.blnIsTestingMode = blnIsTestingMode;
  }


export { default as Icon } from './Icon';
export {default as objIconsCategories} from "./iconsCategories"