import React, { forwardRef, useEffect, useRef, useState } from "react";
import { styleFunction } from "@cbmisorg/styles";
import {Icon} from "@cbmisorg/icons";
import Text from "../Text/Text";


const Number = forwardRef((props, ref) => {
    const {defaultValue, max=100,min=0,step=1,setValue,...rest} = props

    const [theValue, setTheValue] = useState(defaultValue || "");


    const handleAdd = (event) => {
      let newValue = theValue
      if(!newValue){
        newValue = parseInt(max) || 0;
      }
      const theStep =parseInt(step);
      if(newValue < max){
          newValue = parseInt(newValue) + theStep;
      }else if(!max && parseInt(min) !== 0){
        newValue = parseInt(newValue) + theStep;
      }
      if(newValue > max){
        newValue = max
      }
        setTheValue(newValue);
    };

    const handleMinus = (event) => {
      let newValue = theValue

      if(!newValue){
        newValue = parseInt(min) || 0;
      }
      const theStep =parseInt(step);
      if(newValue > min){
          newValue = parseInt(newValue) - theStep;
      }else if(!min && parseInt(min) !== 0){
        newValue = parseInt(newValue) - theStep;
      }
      if(newValue < min){
        newValue = min
      }
        setTheValue(newValue);
    };

    useEffect(()=>{
      if(setValue && theValue){
        setValue(theValue);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[theValue])


  return (
          <Text
          ref={ref}
          type="number"
          defaultValue={theValue}
          endComponent={
            <div className="cbmis-input-icon-end-container cbmis-input-number-buttons-container">
                  <Icon icon="add" onClick={handleAdd} p-0 m-0  />
                  <Icon icon="minimize" onClick={handleMinus} p-0 m-0/>
              </div>
          }
          {...rest}
          />
    )
});

export default Number