import { StyleBuilder } from "@cbmisorg/styles";

export const style = StyleBuilder({
  blnRandomPrefix: false,
  blnIsDefaultStyle: true,
  blnCbmisStyle: true,
  objStyles: {
    cbmis_counter: {
      font: "700 50px 'Source Code Pro', monospace",
      display: "block",
      textAlign: "center",
    },
  },
});

export const styleSx = StyleBuilder({
  blnRandomPrefix: true,
  blnIsDefaultStyle: false,
  blnCbmisStyle: true,
  objStyles: {
    cbmis_counter_sx: (props) => {
      return props?.sx;
    },
  },
});
