import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    tabs: `${styleVariables?.prefixDefaultClassName}_tabs`,
    tab_page: `${styleVariables?.prefixDefaultClassName}_tab_page`,
    tab_page_contents: `${styleVariables?.prefixDefaultClassName}_tab_page_contents`,
    tab: `${styleVariables?.prefixDefaultClassName}_tab`,
    tab_input: `${styleVariables?.prefixDefaultClassName}_tab_input`,
    sx: `${styleVariables?.prefixDefaultClassName}_tabs_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.tabs]: {
        margin: "auto",
        position: "relative",
        display: "flex",
        // height: "100%",
        width: "100%",
        overflowY: "hidden",
        overflowX: "auto",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
      },
      [classesName?.tab_page]: {
        margin: "auto",
        position: "relative",
        display: "block",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
      },
      [classesName?.tab_page_contents]: {
        maxWidth: cssObject?.size?.[cssKeys?.full],
        width: cssObject?.size?.[cssKeys?.full],
        margin: "0 auto",
        "&*":{
          opacity: 0,
          transition: "opacity 0.2s, transform 0.2s",
        }
      },
      [classesName?.tab]: {
        margin: "auto",
        position: "relative",
        display: "flex",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        userSelect: "none",
        transition: "background 0.4s, padding-left 0.2s",
        width: "100%",

        padding: `0`,
        marginInlineEnd: `${cssObject?.spacing?.[cssKeys?.md]}`


      },
      [classesName?.tab_input]:{
        display: "none",
        "&+":{
          [`.${classesName?.tab_page}`]:{
            transition: "transform 0.2s",
            transform: "translateX(100%)",
          }
        },
        "&checked":{
          "&+":{
            [`.${classesName?.tab_page}`]:{
              transform: "translateX(0%)",
              [`.${classesName?.tab_page_contents}`]:{
                opacity: 1,
                transform: "translateY(0px)"
              }
            }
          }
        }
      }
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.tab_page]: {
        gridColumnStart: 1,
        gridRowStart: 2,
        gridColumnEnd: "span 3",
        padding: `0px ${cssObject?.spacing?.[cssKeys?.lg]}`,
        gridTemplateRows: `${cssObject?.size?.[cssKeys?.item_xl]} 1fr`,
        display: "flex",
        alignItems: "center",
      },
      [classesName?.tab_page_contents]: {
        "&*":{
          transform: `translateY(200px)`,
        }
      },
      [classesName?.tabs]:(props)=> {
        return{
          border:String(props?.mode)?.toLowerCase() ==="nav"?"none": `${cssObject?.size?.[cssKeys?.px]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`,
          borderBottomColor: `${styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary])}`,
          background: styleFunction?.checkColorReturnValue([cssKeys?.bodyColorBgLight]),
          borderRadius:String(props?.mode)?.toLowerCase() !=="pill"?"none": `${cssObject?.border?.[cssKeys?.rounded]?.[cssKeys?.xl]}`,
          gridTemplateRows: `${cssObject?.size?.[cssKeys?.item_md]} 1fr`,
        }
      },
      [classesName?.tab]:(props)=> {
        return{
          minHeight: `${cssObject?.size?.[cssKeys?.item_sm]}`,
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle2],


        }
      },
      [classesName?.tab_input]:(props)=>{
        return{
          "&checked":{
            "&+":{
              [`.${classesName?.tab}`]:{
                background:String(props?.mode)?.toLowerCase() ==="nav"?"none": styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary]),
                borderBottom:String(props?.mode)?.toLowerCase() !=="nav"?"none": `${cssObject?.border?.[cssKeys?.size]?.sm} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`,
                borderBottomColor: `${styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary])}`,
                color: String(props?.mode)?.toLowerCase() !=="nav"? styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.light]): styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary]),
                borderRadius:String(props?.mode)?.toLowerCase() !=="pill"?"none": `${cssObject?.border?.[cssKeys?.rounded]?.[cssKeys?.xl]}`,
                fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
              }
            }
          }
        }
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });



  return{
    classesName,
    style,
    styleSx,
  }
}