import React, { useContext } from "react";
import { useApp } from "../../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import AccountMember from "../../member/account";
import { objUserRole } from "../../../../configuration/appVariables";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import Header from "../../../shared/shredHeader";

//old
import AdminAccount from "../../../../../old/components/stakeholders/admin/account/Account";
import ShredHeader from "../../../../../old/components/sharedUi/header/ShredHeader";
import { ClientDirection } from "@cbmisorg/helper";
import { AppContext } from "../../../../../old/configuration/contextapi/AppContext";

const labels = dictionary?.components?.stakeholders?.shared?.account;

function Account() {
  useCheckHooksGlb();
  const oldAppState = useContext(AppContext);
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;

  const handelChangeLang = () => {
    if (lang === "arb") {
      ClientDirection.setDirection("ltr");
      oldAppState.appState.clientInfo.strLanguage = "eng";
      oldAppState.appState.clientInfo.strDataLanguage = "eng"
      appState.clientInfo.strLanguage = "eng";
      appState.clientInfo.strDataLanguage = "eng";
    } else {
      ClientDirection.setDirection("rtl");
      appState.clientInfo.strLanguage = "arb";
      appState.clientInfo.strDataLanguage = "arb";
       oldAppState.appState.clientInfo.strLanguage = "arb";
      oldAppState.appState.clientInfo.strDataLanguage = "arb"
    }
    oldAppState.appDispatch()
    appDispatch();
  };

  return (
    <React.Fragment>
      {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Member ? (
        <React.Fragment>
          <Header title={labels?.title?.[lang]} lang={lang} blnHome={true} blnBack={false} blnLang={true} changLang={handelChangeLang} />
          <AccountMember />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ShredHeader title={dictionary?.components?.public?.footer?.account?.[lang]} lang={lang} blnHome={true} blnLang={true} blnBack={false} changLang={handelChangeLang} />
          <AdminAccount />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Account;
