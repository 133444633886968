
function getPkgConfigFileIcons() {
    const initConfig = {
      blnIsTestingMode: true,
    };
    return initConfig;
  }

  export var pkgConfigFileIcons = getPkgConfigFileIcons();

  export function setPkgConfigFileIcons({ blnIsTestingMode = false,}) {
    pkgConfigFileIcons.blnIsTestingMode = blnIsTestingMode;
  }

  export {getIconForFolder} from "./IconGenerate";
  export {getIconForFile} from "./IconGenerate";
  export {getIconForOpenFolder} from "./IconGenerate"

  export {default as ShowIcon} from "./ShowIcon";