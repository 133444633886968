export const keysBG={
    size:"size",
    position:"position",
    attachment:"attachment",
    repeat:"repeat",
    clip:"clip",
    opacity:"opacity", //bg-opacity-value (%)
    none:"none" //bg-none
}

export default{
    [keysBG?.size]:{ //bg-value
        cover:"cover",
        contain:"contain",
        auto:"auto",
    },
    [keysBG?.position]:{ // bg-v-value-h-value
        cover:"top",
        contain:"bottom",
        center:"center",
        left: "left",
        "right":"right"
    },
    [keysBG?.attachment]:{ //bg-value
        fixed:"fixed",
        local:"local",
        scroll:"scroll"
    },
    [keysBG?.repeat]:{//bg-repeat || bg-repeat-value || bg-no-repeat
        repeat:"repeat",
        no:"no-repeat",
        x:"repeat-x",
        y:"repeat-y",
        round:"round",
        space:"space",
    },
    [keysBG?.clip]:{//bg-clip-value
        border:"border-box",
        content:"content-box",
        padding:"padding-box",
        text:"text",
    }
}