import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import CardStyle from "./CardStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const CardFront = forwardRef((/**@type {any}*/props, ref) => {
  const { sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {
    classesName,
    // style,
    styleCardFrontSx} = CardStyle(theTheme);
  // const classes = style();
  const classesSx = styleCardFrontSx({sx});
  const itemClasses = styleFunction.getClassName(classesName?.card_front, classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  const comID = id ||  String(useId()).slice(1,-1)

  return (
    <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)} key={comID}>
      <div className={classesName?.card_content}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
    </div>
  );
});

export default CardFront;
