import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";
export const objStyle = (lang) =>
  makeStyle({
  secDark: {
    position: "relative",
    display: "block",
    width: "100%",
    // minHeight: "100vh",
    margin: "auto",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
  },
  mainContainer: {
    width: `${(11 / 12) * 100}%`,
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs: {
      width: `${(11 / 12) * 100}%`,
    },
  },
  bgBox: {
    height: "100%",
    padding: "20px",
    zIndex: 10,
    boxShadow: "none",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../util/assets/images/box1-h.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  },
  bgBox2: {
    height: "100%",
    padding: "20px",
    zIndex: 10,
    boxShadow: "none",
    overflow: "hidden",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../util/assets/images/box3.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  },
  profileImgContainer: {
    position: "relative",
    display: "block",
    height: "150px",
    width: "150px",
    margin: "0",
    padding: "0",
    background: `url(${require("../../../util/assets/images/cir2.png")})`,
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    backgroundSize: "contain !important",
    overflow: "hidden",
    backdropFilter: "blur(100px)",
  },
  profileImgAvatar:{
    width:"120px",
    height:"120px",
    marginTop:"10px"
  }
});
