import *  as ValuesHelper  from "./formFunctions/ValuesFunctions"
import *  as ValidateHelper  from "./formFunctions/ValidateFunctions"
import *  as ErrorsHelper  from "./formFunctions/ErrorsFunctions"


export const {getFormValues,
              getValueByFieldName,
              updateValue,
              addToCheckboxArray,
              deleteFromCheckboxArray,
              getOptions
              } = ValuesHelper

export const {getError,updateError} = ErrorsHelper

export const {
              checkFormValues,
              isFormValid,
              checkFormValueByState,
              performValidation
              } = ValidateHelper
