import { objCssProperties } from "packages/style/src/helper/cssProperties";
import { returnColor } from "../color/color";
import keys, { keysBorder } from "./keys";
import colorGradientName from "../color/colorGradientName";

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    if(arrClasses?.[0] === keysBorder?.border  && keys?.[keysBorder?.border]?.[arrClasses?.[1]]){
        return {[objCssProperties?.border] : `1px ${keys?.[keysBorder?.border]?.[arrClasses?.[1]]} ${colorGradientName?.gray?.[500]}`}
    }
    else if(arrClasses?.[0] === keysBorder?.border  && !isNaN(Number(arrClasses?.[1]))){
        return {[objCssProperties?.borderWidth] : `${Number(arrClasses?.[1])}px`}
    }
    else if(arrClasses?.[0] ===  keysBorder?.rounded && keys?.[keysBorder?.rounded]?.[arrClasses?.[1]]){
        return {[objCssProperties?.borderRadius] : keys?.[keysBorder?.rounded]?.[arrClasses?.[1]] }
    }
    else{
        return {[objCssProperties?.borderColor] : returnColor(arrClasses)}
    }
}

// else if(arrClasses?.[0] === keysBorder?.border  && keys?.[keysBorder?.borderSides]?.[arrClasses?.[1]] && keys?.[keysBorder?.border]?.[arrClasses?.[2]]){
    //     return {[cssKeys?.border] : `1px ${keys?.[keysBorder?.border]?.[arrClasses?.[1]]} #222`}
    // }