import React, { useEffect } from "react";
import { Avatar, Container, Grid, Icon, Paper, Slideshow, Typography } from "@cbmisorg/client-app";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import associationImg from "../../../../../assets/images/bg-img.png";
import curvyLines from "../../../../../assets/images/curvyLines.png";
import { appStyle } from "../../../../../appHelper/appStyle";
import { App_Beige_Color4, App_Primary_Color, App_orang_color } from "../../../../../appHelper/appColor";
import slide1 from "../../../../../assets/images/hero.jpg";
import slide2 from "../../../../../assets/images/slide1.jpg";
import slide3 from "../../../../../assets/images/slide3.jpg";



const ContentSlide = ({ lang, slide }) => {
  return (
    <Container sx={{ height: "100%" }} dir={lang === "arb" ? "rtl" : "ltr"}>
      <Grid container sx={{ height: "100%", alignItems: "center" }}>
        <Grid item xs="12" container spacing={2}>
          <Grid item xs="12">
            <Typography as="subtitle1" className="lineUp">
              {slide?.title?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography as="body2" className="lineUp">
              {slide?.body?.[lang]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

function Aboutus({ lang, appState }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.aboutus;
  const labelsHero = dictionary?.components?.public?.landingPage?.home?.hero;

  const slides = [
    { content: <ContentSlide lang={lang} slide={labelsHero?.slide1} />, image: slide1 },
    { content: <ContentSlide lang={lang} slide={labelsHero?.slide2} />, image: slide2 },
    { content: <ContentSlide lang={lang} slide={labelsHero?.slide3} />, image: slide3 },
  ];
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Container pb-5 id="aboutus" sx={{marginTop:"250px !important", sm:{marginTop:"-10px !important"}}}>
        <Grid container spacing="3" pt-0>
          <Grid item xs="0" md="5" alignSelf="flex-start" p-0 m-0 container spacing={3}>
            <Grid item xs="12">
              <img src={associationImg} alt="" width="90%" loading="lazy" />
            </Grid>
          </Grid>
          <Grid item xs="12" md="7" container spacing="2">
            <Grid item xs="12">
              <Typography as="h4" color="primary" sx={appStyle?.title1}>
                {labels?.title?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12" pt-0>
              <Typography as="subtitle1">{labels?.subtitle?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="body1">{labels?.p1?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="body1">{labels?.p2?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="body1">{labels?.p3?.[lang]}</Typography>
            </Grid>
          </Grid>
          <Grid item xs="12" md="0" alignSelf="flex-start" p-0 m-0 px-3 container spacing={3}>
            <Grid item xs="12">
              <img src={associationImg} alt="" loading="lazy" width={"95%"} className="" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container py-10 id="aboutus2">
        <Grid item xs="12" md="7" lg="8">
          <Paper elevation="0" outline="0"  pb-10>
            <Container >
              <Grid container spacing="3">
                <Grid item xs="12">
                  <div id="vision">
                    <Grid container spacing="3">
                      <Grid item xs="12" container>
                        <Grid item xs="0" md="auto" alignSelf={"flex-start"}>
                          <Avatar sx={{ width: "35px", height: "35px", border: `2px solid ${App_orang_color}` }} color={App_Primary_Color}>
                            <Icon icon="RemoveRedEye" />
                          </Avatar>
                        </Grid>
                        <Grid item xs="12" md="10" container spacing={0}>
                          <Grid item xs="12" alignSelf={"flex-start"}>
                            <Typography as="h6" color="primary">
                              {labels?.titleVision?.[lang]}
                            </Typography>
                          </Grid>
                          <Grid item xs="12">
                            <Typography as="body2">{labels?.strVision?.[lang]}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs="12">
                  <div  id="mission">
                    <Grid container spacing="3">
                      <Grid item xs="0" md="auto" alignSelf={"flex-start"}>
                        <Avatar sx={{ width: "35px", height: "35px", border: `2px solid ${App_orang_color}` }} color={App_Primary_Color}>
                          <Icon icon="sms" />
                        </Avatar>
                      </Grid>
                      <Grid item xs="12" md="10" container spacing={0}>
                        <Grid item xs="12" alignSelf={"flex-start"}>
                          <Typography as="h6" color="primary">
                            {labels?.titleMission?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="body2">{labels?.strMission?.[lang]}</Typography>
                        </Grid>

                        {labels?.lstMission?.map((item, index) => (
                          <Grid item xs="12" container key={index}>
                            <Grid item alignSelf={"flex-start"}>
                              <Icon icon="check" color="primary" />
                            </Grid>
                            <Grid item xs="10">
                              <Typography as="caption">{item?.[lang]}</Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
        <Grid
          item
          xs="12"
          md="5"
          lg="4"
          sx={lang === "arb" ? { right: "-100px", sm: { right: 0 } } : { left: "-100px", sm: { left: 0 } }}
          className={`${appState?.clientInfo?.appFontSize === "xl" || appState?.clientInfo?.appFontSize === "lg" ? (lang === "arb" ? "height300" : "height400") : ""}`}
        >
          <Slideshow slides={slides} dotsShow={true} autoShow={true} activeColor={"primary"} dir="ltr" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Aboutus;
