import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import TableStyle from "./TableStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const TableRow= forwardRef((/**@type {any}*/props,ref) => {
  const { sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleTableRowSx} = TableStyle(theTheme);
  const classesSx = styleTableRowSx({sx});
  const comID = id ||  String(useId()).slice(1,-1)

  const itemClasses = styleFunction.getClassName(classesName?.table_row, classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <tr className={itemClasses} {...styleFunction?.getRestProps({...rest})} ref={ref} key={comID}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </tr>
  );
});

export default TableRow;
