import { StyleBuilder, cssKeys, mainTheme, styleFunction, styleVariables } from "@cbmisorg/styles";

  export default(objThemeInfo)=>{
    const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  
    const classesName = {
      accordion_row: `${styleVariables?.prefixDefaultClassName}_accordion_row`,
      accordion_col: `${styleVariables?.prefixDefaultClassName}_accordion_col`,
      accordion_tabs: `${styleVariables?.prefixDefaultClassName}_accordion_tabs`,
      accordion_tab: `${styleVariables?.prefixDefaultClassName}_accordion_tab`,
      accordion_tab_summary_label: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_label`,
      accordion_tab_summary_label_content: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_label_content`,
      accordion_tab_summary_label_arrowIcon: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_label_arrowIcon`,
      accordion_tab_summary_icon: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_icon`,
      accordion_tab_summary_icon_arrowIcon: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_icon_arrowIcon`,
      accordion_tab_summary_label_content_children: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_label_content_children`,
      accordion_tab_summary_input: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_input`,
      accordion_tab_details: `${styleVariables?.prefixDefaultClassName}_accordion_tab_details`,
      accordion_tab_summary_input_dontOpen: `${styleVariables?.prefixDefaultClassName}_accordion_tab_summary_input_dontOpen`,
      accordion_horizontal_tabs: `${styleVariables?.prefixDefaultClassName}_accordion_horizontal_tabs`,
      sx: `${styleVariables?.prefixDefaultClassName}_accordion_sx`,
    };
  
    const style = StyleBuilder({
      blnRandomPrefix: false,
      blnIsDefaultStyle: true,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.accordion_row]:{
          width: "100%",
          display:"flex",
        },
        [classesName?.accordion_col]:{
          width: "100%",
          display:"block",
        },
        [classesName?.accordion_tabs]:{
          overflow: "hidden",
          position: "relative",
          padding: cssObject?.spacing?.xs,
          backgroundColor: "transparent",
        },
        [classesName?.accordion_tab]:{
          borderRadius: 0,
          width:"auto",
          overflow: "hidden",
          position: "relative",
          background: "transparent",
          cursor: "pointer",
          display:"block",
          backgroundColor:cssObject?.color?.[cssKeys?.white]

        },
        [classesName?.accordion_tab_summary_label]:{
          display: "flex",
          justifyContent: "space-between",
          verticalAlign: "middle",
          position: "relative",
          cursor: "pointer",
          // "&before":{
          //   content: "&rangle",
          //   textAlign: "end",
          //   transition: "all .35s",
          //   transform: "rotate(90deg)",
          //   position: "absolute",
          //   right:"0"
          // }
        },
        [classesName?.accordion_tab_summary_label_content]:{
          width: "100%",
          display: "flex",
        },
        [classesName?.accordion_tab_summary_label_arrowIcon]:{
          "&after":{
            content: "",
            width: 0,
            height: 0,
            padding: 0,
          }
        },
        [classesName?.accordion_tab_summary_icon]:{
          margin: "auto",
        },
        [classesName?.accordion_tab_summary_icon_arrowIcon]:{
          transition: "transform .5s"
        },
        [classesName?.accordion_tab_summary_label_content_children]:{
          width: "99%",
          margin: "auto",
        },
        [classesName?.accordion_tab_summary_input]:{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          minHeight: cssObject?.size?.[cssKeys?.item_lg],
          background: "#fff",
          cursor: "pointer",
          opacity: 0,
          // zIndex:1,
          "&checked":{
            "&~":{
              [`.${classesName?.accordion_tab_details}`]:{
              // zIndex: 2,
              visibility: "visible",
              opacity: "1",
              "&*":{
                // zIndex: 2,
              }
            }},
            "&+":{
              [`.${classesName?.accordion_tab_summary_label}`]:{
                "&after":{
                  marginTop:  cssObject?.spacing?.sm,
                  transform: "rotate(270deg) translateY(-50%)",
                },
                [`.${classesName?.accordion_tab_summary_icon_arrowIcon}`]:{
                    transform: "rotateZ(180deg)",
  
                }
              }
            }
          }
        },
        [classesName?.accordion_tab_summary_input_dontOpen]:{
          pointerEvents:"none",
        },
        [classesName?.accordion_tab_details]:{
          display: "none",
          // maxHeight: "0px",
          position: "relative",
          transition: "height 1s"
        },
        [classesName?.accordion_horizontal_tabs]:{
          display: "flex",
          flexWrap: "nowrap",
          overflow: "hidden",
          position: "relative",
          width: "auto",
          [`.${classesName?.accordion_tab}`]:{
            display: "flex",
            flexWrap: "nowrap",
            position: "relative",
            transition: "all .5s",
            height: "auto",
            width: "auto",
          },
          [`.${classesName?.accordion_tab_details}`]:{
            padding:`0 ${cssObject?.spacing?.xs}`,
            color:cssObject?.color?.bodyColor,
          },
          [`.${classesName?.accordion_tab_summary_input}`]:{
            "&checked":{
              "&~":{
              [`.${classesName?.accordion_tab_details}`]:{
                // width: "auto",
                // minWidth: "10vw",
                // maxWidth: "75vw",
                // visibility: "visible",
                // opacity: "1",
                // maxHeight: "100%",
                // padding: `0 ${cssObject?.spacing?.xs}`,
                // boxShadow: "0 0 0 0 transparent",
                // zIndex: "2",
                // overflowY: "auto",
               

                padding: cssObject?.spacing?.xs,
                boxShadow: cssObject?.boxShadow?.sm,
               },
              },
              "&+":{
                [`.${classesName?.accordion_tab_summary_label}`]:{
                "&after":{
                  marginTop: "0",
                  transform: "rotate(180deg) translateY(-100%)",
                }
                },
              }
            }
          },
          [`.${classesName?.accordion_tab_summary_label}`]:{
            maxWidth: "500px !important",
            minWidth: "110px !important",
            "&after":{
              transform: "rotate(0deg)"
            }
          }
        }
      },
    });
  
    const styleSx = StyleBuilder({
     blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles:{
        [classesName?.sx]: (props) => {
          if(props?.color){
            return{
              color: props?.color || "#000",
              ...props?.sx
            }
          }
          return props?.sx;
        },
  
      },
    });
  
    const styleTabSx = StyleBuilder({
     blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      className:"",
      objStyles:{
        [classesName?.sx]: (props) => {
        if(props?.color){
          return{
            color: props?.color || "#000",
            ...props?.sx
          }
        }
        return props?.sx;
        },
  
      },
    });
  
  
    const styleDetailsSx = StyleBuilder({
     blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles:{
        [classesName?.sx]: (props) => {
         const objCss={}
          if(props?.color){
            objCss.color= props?.color || "#000";
          }
          if(props?.openDetails){
            objCss.display= "block";
          }
          return {...props?.sx, ...objCss};
        },
      },
    });
  
    const styleSummarySx = StyleBuilder({
     blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles:{
        [classesName?.accordion_tab_summary_label]:{
          padding: `${cssObject?.spacing?.xs} ${cssObject?.spacing?.xs}`,
          fontWeight: cssObject?.text?.fontWeight?.fontWeight_lg,
          fontSize:cssObject?.text?.size?.subtitle2,
          minHeight: cssObject?.size?.[cssKeys?.item_lg],
          borderBottom: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor])}`,
        },
        [classesName?.accordion_tab_summary_label_content]:{
          maxWidth: "90%",
          minHeight: cssObject?.size?.[cssKeys?.item_sm],
          display: "flex",
        },
        [classesName?.accordion_tab_summary_icon_arrowIcon]:{
          fontSize:cssObject?.text?.size?.h6,
        },
        [classesName?.sx]: (props) => {
          if(props?.color){
            return{
              color:styleFunction?.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColor]),
              ...props?.sx
            }
          }
          return props?.sx;
        },
      },
    });
  
    return{
      classesName,
      style,
      styleSx,
      styleTabSx,
      styleDetailsSx,
      styleSummarySx
    }
  }