import { ClientFunction } from "@cbmisorg/helper";

const { checkParseObject } = ClientFunction;
//#region update Values

export const getOptions = (fieldName, field = {}, objDefLabel = {}, optionList = null) => {
  try {
    const newObjDefaultLabel = {
      ...objDefLabel?.labels?.[fieldName]?.options,
    };
    if (field?.options || optionList) {
      const newOptArr = [];
      let lstOptions = optionList ? optionList : field?.options || [];
      if (Array.isArray(lstOptions)) {
        (lstOptions || [])?.forEach((opt) => {
          if (typeof opt === "object") {
            newOptArr.push({
              key: opt?.key,
              value: opt?.value || newObjDefaultLabel?.[opt?.value] || newObjDefaultLabel?.[opt?.key],
              jsx: opt?.jsx || null,
            });
          } else {
            newOptArr.push({
              key: opt,
              value: newObjDefaultLabel?.[opt] || opt,
            });
          }
        });
      } else if (typeof lstOptions === "object") {
        Object?.entries(lstOptions || {})?.forEach((opt) => {
          newOptArr.push({
            key: opt[0],
            value: opt[1] || newObjDefaultLabel?.[opt[1]] || newObjDefaultLabel?.[opt[0]],
            jsx: opt[2] || null,
          });
        });
      } else if (typeof lstOptions === "string" || typeof lstOptions === "number" || typeof lstOptions === "boolean") {
        newOptArr.push({
          key: field?.options,
          value: newObjDefaultLabel?.[field?.options] || field?.options,
        });
      }
      return newOptArr;
    }
    return null;
  } catch (error) {
    return [];
  }
};
/**
 *
 * @param {"datetime" | "date"} type
 */
export function convertDateToInputForm(date, type) {
  try {
    if (type === "datetime") {
      let dtmInput = new Date(date);
      dtmInput.setMinutes(dtmInput.getMinutes() - dtmInput.getTimezoneOffset());
      let strInputDate = dtmInput.toISOString().slice(0, 16);
      return strInputDate;
    } else {
      let dtmInput = new Date(date);
      let strInputDate = dtmInput.toISOString().slice(0, 10);
      return strInputDate;
    }
  } catch {
    if (type === "datetime") {
      let dtmInput = new Date();
      dtmInput.setMinutes(dtmInput.getMinutes() - dtmInput.getTimezoneOffset());
      let strInputDate = dtmInput.toISOString().slice(0, 16);
      return strInputDate;
    } else {
      let dtmInput = new Date();
      let strInputDate = dtmInput.toISOString().slice(0, 10);
      return strInputDate;
    }
  }
}

export function updateValue(newValue, fieldName, formState = null, objDefLabel) {
  try {
    var theNewValue = typeof newValue === "boolean" ? newValue : checkParseObject(newValue) || "";

    if (formState && formState?.[fieldName]) {
      const newFormState = formState || {};
      const field = newFormState?.[fieldName];
      let lstOptionTmp = getOptions(fieldName, field, objDefLabel) || [];
      const strControl = String(field?.control).toLowerCase();

      if (strControl === "list" || strControl === "radio") {
        let objValue = {};
        for (let i = 0; i < lstOptionTmp.length; i++) {
          if (typeof theNewValue !== "object" && String(lstOptionTmp[i]?.key) === String(theNewValue)) {
            objValue = lstOptionTmp[i];
            break;
          } else if (typeof theNewValue === "object" && String(lstOptionTmp[i]?.key) === String(theNewValue.key)) {
            objValue = lstOptionTmp[i];
            break;
          }
        }

        formState[fieldName].value = objValue;
      } else if (strControl === "checkbox" || strControl === "multiplelist") {
        let valueTmp = Array.isArray(theNewValue) ? theNewValue : [];

        let letValue = [];
        let keysTmp = valueTmp.map((val) => {
          if (typeof val === "object") {
            return String(val?.key);
          }
          return String(val);
        });

        if (valueTmp?.length) {
          for (let i = 0; i < lstOptionTmp.length; i++) {
            if (typeof lstOptionTmp[i] !== "object" && keysTmp.includes(String(lstOptionTmp[i]))) {
              letValue.push(lstOptionTmp[i]);
            } else if (typeof lstOptionTmp[i] === "object" && keysTmp.includes(String(lstOptionTmp[i]?.key))) {
              letValue.push(lstOptionTmp[i]);
            }
          }
        }

        formState[fieldName].value = letValue;
      } else if (strControl === "date" || strControl === "datetime") {
        newFormState[fieldName].value = convertDateToInputForm(theNewValue, strControl);
      } else if (formState?.[fieldName]?.blnLanguage) {
        // var language = formState?.[fieldName]?.language?.field || formState?.[fieldName]?.language?.current || formState?.[fieldName]?.language?.default;
        newFormState[fieldName].value = { ...newFormState[fieldName].value, ...theNewValue };
      } else {
        newFormState[fieldName].value = typeof theNewValue === "boolean" ? theNewValue : String(theNewValue)?.trim();
      }
      return newFormState;
    }
    return theNewValue;
  } catch (error) {
    // console.error(error);
  }
}
/******************* checkbox add to array Of Values *******************/

export function addToCheckboxArray(newPairValue, fieldName, formState = null) {
  var currentArrayOfValues = formState?.[fieldName]?.value;
  if (!Array.isArray(currentArrayOfValues)) {
    currentArrayOfValues = [];
  }
  if (Array.isArray(newPairValue)) {
    currentArrayOfValues = [...currentArrayOfValues, ...newPairValue];
  } else if (typeof newPairValue === "object") {
    currentArrayOfValues = [...currentArrayOfValues, newPairValue];
  }
  if (formState) {
    formState[fieldName].value = currentArrayOfValues;
    return { ...formState };
  }
  return currentArrayOfValues;
}

/******************* checkbox delete from array Of Values *******************/

export function deleteFromCheckboxArray(key, fieldName, formState = null) {
  var arrayOfValues = formState?.[fieldName]?.value || [];
  var filtered = arrayOfValues?.filter((el) => String(el.key) !== String(key));
  if (filtered.length === 0) {
    filtered = [];
  }
  formState[fieldName].value = filtered;
  return { ...formState };
}

//#endregion

//#region get values formApp
export function getFormValues(formState) {
  const fields = Object.entries(formState);
  var valuesObject = {};
  fields.map((item) => (valuesObject[item[0]] = item[1]?.value));
  return valuesObject;
}

/******************* get value by fieldName *******************/
export function getValueByFieldName(field, objDefLabel, blnReset = false) {
  try {
    if (field && field?.value && !blnReset) {
      if (objDefLabel.blnDuplicateLangValue && field.blnLanguage) {
        const lstLang = Object.keys(objDefLabel?.objLanguage);
        let defaultValue =
          field?.value?.[field?.language?.field] ||
          field?.value?.[field?.language?.current] ||
          field?.value?.[field?.language?.default] ||
          field?.value?.[objDefLabel?.currentLang] ||
          field?.value?.[objDefLabel?.defaultLang] ||
          "";
        let defaultLangValue = field?.language?.field || field?.language?.current || field?.language?.default || objDefLabel?.currentLang || objDefLabel?.defaultLang;

        let prevDefaultLangValue = field?.prevValue; //String(defaultValue)?.length ? String(defaultValue)?.slice(0, String(defaultValue)?.length - 2) : defaultValue;
        if(!field?.language?.field){
          field.prevValue = defaultValue
        }
        
        for (let index = 0; index < lstLang.length; index++) {
          // console.log("#start", field?.value[lstLang[index]], lstLang[index], defaultValue, defaultLangValue);
          if (lstLang[index] === defaultLangValue && field?.value[lstLang[index]]) {
            // console.log("1");
            defaultValue = field?.value[lstLang[index]];
            // field.optionLanguage[lstLang[index]].isLangHaveValue = true;
          } else if (lstLang[index] !== defaultLangValue && !field?.language?.field) {
            if (
              !field?.value?.[lstLang[index]] ||
              field?.value?.[lstLang[index]] === prevDefaultLangValue ||
              field?.value?.[lstLang[index]] === defaultValue
              // || !field.optionLanguage[lstLang[index]].isLangHaveValue
            ) {
              // console.log("2");
              field.value[lstLang[index]] = defaultValue || "";
              // field.optionLanguage[lstLang[index]].isLangHaveValue = false;
            }
            // field.optionLanguage[lstLang[index]].isLangHaveValue = true;
          }
          
          // for (let index = 0; index < lstLang.length; index++) {
          //   if (lstLang[index] === defaultLangValue && field?.value[lstLang[index]]) {
          //     defaultValue = field?.value[lstLang[index]];
          //     field.optionLanguage[lstLang[index]].isLangHaveValue = true;
          //   }else 
          //   if (field?.value?.[lstLang[index]] && !field?.optionLanguage?.[lstLang[index]]?.isLangHaveValue) {
          //     field.value[lstLang[index]] = defaultValue || ""
          //     field.optionLanguage[lstLang[index]].isLangHaveValue = false;
          //   }else 
          //   if (field?.value?.[lstLang[index]] && !field?.optionLanguage?.[lstLang[index]]?.isLangHaveValue) {
          //     field.optionLanguage[lstLang[index]].isLangHaveValue = true;
          //   }else 
          //   if (!field?.value?.[lstLang[index]]) {
          //     field.value[lstLang[index]] = defaultValue || ""
          //     field.optionLanguage[lstLang[index]].isLangHaveValue = true;
          //   }
          // }

          // console.log("#end", field?.value[lstLang[index]], lstLang[index], defaultValue, defaultLangValue);
        }
      }
      return field;
    } else {
      if (String(field?.control).toLocaleLowerCase() === "checkbox" || String(field?.control).toLocaleLowerCase() === "multiplelist") {
        return [];
      } else if (String(field?.control).toLocaleLowerCase() === "switch") {
        return false;
      } else if ((String(field?.control).toLocaleLowerCase() === "text" || String(field?.control).toLocaleLowerCase() === "textarea") && field?.blnLanguage) {
        const lstLang = Object.keys(objDefLabel?.objLanguage);
        const objValue = {};
        lstLang?.forEach((lang) => {
          objValue[lang] = "";
        });
        return objValue;
      }
      return "";
    }
  } catch (error) {
    // console.error(error);
    if (String(field?.control).toLocaleLowerCase() === "checkbox" || String(field?.control).toLocaleLowerCase() === "multiplelist") {
      return [];
    } else if (String(field?.control).toLocaleLowerCase() === "switch") {
      return false;
    } else if ((String(field?.control).toLocaleLowerCase() === "text" || String(field?.control).toLocaleLowerCase() === "textarea") && field?.blnLanguage) {
      const lstLang = Object.keys(objDefLabel?.objLanguage);
      const objValue = {};
      lstLang?.forEach((lang) => {
        objValue[lang] = "";
      });
      return objValue;
    }
    return "";
  }
}

//#endregion

export const getDefaultValue = (list, key, value) => {
  if (key && value) {
    return list.filter((item) => String(item.key) === String(key) && String(item.value) === String(value));
  } else if (Array.isArray(key)) {
    return list.filter((item) => key.includes(String(item.key)));
  } else if (key) {
    return list.filter((item) => String(item.key) === String(key));
  } else if (value) {
    return list.filter((item) => String(item.value) === String(value));
  } else {
    return "";
  }
};
