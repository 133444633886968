import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ModalStyle from "./ModalStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const ModalFooter = forwardRef((/**@type {any}*/props, ref) => {
  const { sx, hidden, disabled, dir, className,handleClose=null, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleModalFooterSx} = ModalStyle(theTheme);
  const classes = style();
  const classesSx = styleModalFooterSx({sx});

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.modal_footer], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div className={itemClasses} {...styleFunction?.getRestProps({...rest})} ref={ref}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </div>
  );
});

export default ModalFooter;
