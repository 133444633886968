  import {
    StyleBuilder,
    styleVariables,
    cssKeys,
    mainTheme,
    styleFunction,
  } from "@cbmisorg/styles";

  export default(objThemeInfo)=>{
    const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

    const classesName = {
      table_contenter: `${styleVariables?.prefixDefaultClassName}_table_contenter`,
      mobile_mode_contenter: `${styleVariables?.prefixDefaultClassName}_mobile_mode_contenter`,
      table: `${styleVariables?.prefixDefaultClassName}_table`,
      table_head: `${styleVariables?.prefixDefaultClassName}_table_head`,
      table_body: `${styleVariables?.prefixDefaultClassName}_table_body`,
      table_footer: `${styleVariables?.prefixDefaultClassName}_table_footer`,
      table_row: `${styleVariables?.prefixDefaultClassName}_table_row`,
      table_cell: `${styleVariables?.prefixDefaultClassName}_table_cell`,

      sx:`${styleVariables?.prefixDefaultClassName}_table_sx`
    }
    const style = StyleBuilder({
      blnRandomPrefix: false,
      blnIsDefaultStyle: true,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.table_contenter]:{
          display:"block",
          maxHeight: "100%",
          position: "relative",
          margin: "auto",
          width: "100%",
          overflow:"auto"
        },
        [classesName?.mobile_mode_contenter]:{
          maxHeight: "95%",
          position: "relative",
          margin: "auto",
          width: "99%",
        },
        [classesName?.table]:{
          width: "max-content",
          minWidth:"100%",
          borderCollapse: "collapse",
          padding: "0px",
          margin: "auto",
          textAlign: "center",
          tableLayout:"auto",
          borderSpacing: "0px",
          position: "relative",
          border:"none",
          outline:"none",

          [`.${classesName?.table_head}`]:{
            position: "sticky",
            top: "0px",
          },
          [`.${classesName?.table_footer}`]:{
            position: "sticky",
            top: "0px",
          },
        },
        [classesName?.table_head]:{
          [`.${classesName?.table_cell}`]:{
            fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
          }
        },
        [classesName?.table_footer]:{
          [`.${classesName?.table_cell}`]:{
            fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
          }
        }
      },
    });

    const styleTableSx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.table]:{
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
            [`.${classesName?.table_body}`]:{
              [`.${classesName?.table_row}`]:{
                background: cssObject?.color?.white,
                borderBottom: `1px ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`,
                padding: cssObject?.spacing?.[cssKeys?.sm],
              },
            },
            [`.${classesName?.table_cell}`]:{
              padding:cssObject?.spacing?.[cssKeys?.sm],
              height: cssObject?.size?.baseSize,
              // border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`,
              fontSize: cssObject?.text?.size?.[cssKeys?.body1],
              fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_sm],
              textAlign: "center",
            }
          },
          [classesName?.sx]: (props) => {
            return {
              ...props?.sx,
              color: styleFunction.checkColorReturnValue(props?.color || props?.sx?.color || cssObject?.color?.[cssKeys?.bodyColor]),

            };
          },
        },
      });

    const styleTableRowSx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.sx]: (props) => {
            return props?.sx;
          },
        },
      });

    const styleTableHeadSx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.table_head]:{
            [`.${classesName?.table_row}`]:{
              fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
              background: cssObject?.color?.bodyColorBgLight,
            },
          },
          [classesName?.sx]: (props) => {
            return props?.sx;
          },
        },
      });

    const styleTableBodySx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.sx]: (props) => {
            return props?.sx;
          },
        },
      });

    const styleTableCellSx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.sx]: (props) => {
            return props?.sx;
          },
        },
      });

    const styleTableFooterSx = StyleBuilder({
        blnRandomPrefix: true,
        blnIsDefaultStyle: false,
        blnCbmisStyle: true,
        objStyles: {
          [classesName?.table_footer]:{
            [`.${classesName?.table_cell}`]:{
              fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
            }
          },
          [classesName?.sx]: (props) => {
            return props?.sx;
          },
        },
      });

    return{
      classesName,
      style,
      styleTableSx,
      styleTableRowSx,
      styleTableHeadSx,
      styleTableBodySx,
      styleTableCellSx,
      styleTableFooterSx
    }
  }