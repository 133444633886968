import React, { forwardRef, useEffect, useId } from "react";
import { classesName, style, styleSx } from "./DrawerStyle";
import { cssKeys, styleFunction, styleVariables } from "@cbmisorg/styles";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import useScreenSize from "../../_helper/hooks/useScreenSize";
import { useClickOutside } from "../../_helper/hooks/useClickOutside";

const Drawer = forwardRef(function Drawer(/**@type {any}*/ props, ref) {
  const { open, eventClose, blnSwap = false, mode, ...rest } = props;
  const classes = style();
  const { width } = useScreenSize();

  const dropdownNode = useClickOutside(() => {
    if (open === true && (mode !== "dashboard" || width < parseFloat(styleVariables?.mdScr))) {
      eventClose();
    }
  });

  return (
    <>
      <div ref={dropdownNode} className={classes?.[classesName?.drawerContainer]}>
        {open ? <DrawerChild ref={ref} open={open} mode={mode} {...rest} /> : null}
      </div>
      {
      //   !blnSwap ? null : (
      //   <button onClick={eventClose} className={classes?.[classesName?.buttonSwipe]} style={{ marginInlineStart: !open ? "0" : "auto" }}>
      //     {open ? "<" : ">"}
      //   </button>
      // )
    }
    </>
  );
});

const DrawerChild = forwardRef(function Drawer(/**@type {any}*/ props, ref) {
  const { open, position, eventClose, color, title, blnHaveTitle = true, mode, textColor, width, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, open });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.drawer],
    classesSx?.[classesName?.drawer],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const comID = id || String(useId()).slice(1, -1);

  return (
    <React.Fragment>
      <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)} key={comID}>
        <ErrorBoundary>{props?.children}</ErrorBoundary>
      </div>
    </React.Fragment>
  );
});
export default Drawer;
