import React, { forwardRef, useContext, useId, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import AccordionStyle from "./AccordionStyle";

const AccordionTab = forwardRef((/**@type {any}*/ props, ref) => {
  const { children, open = false, blnOpenOne = false, sx, hidden, disabled, id, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleTabSx } = AccordionStyle(theTheme);
  const classes = style();
  const classesSx = styleTabSx({ sx });
  const comID = id || String(useId()).slice(1, -1);
  const [openDetails, setOpenDetails] = useState(open);

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.accordion_tab],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const handleChangeInput = (event) => {
      setOpenDetails(event?.target?.checked);
  };
  return (
    <div className={itemClasses} ref={ref} {...styleFunction.getRestProps(rest)}>
      {!children[0] ? null : React.cloneElement(children[0], { open: openDetails, blnOpenOne: blnOpenOne, tabID: comID, handleChangeInput: handleChangeInput })}
      {!children[1] ? null : React.cloneElement(children[1], { openDetails: openDetails, tabID: comID, })}
    </div>
  );
});

export default AccordionTab;
