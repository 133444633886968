import React, { forwardRef } from "react";
import { Icon } from "@cbmisorg/icons";
import { styleFunction } from "@cbmisorg/styles";

const RatingRadioInput = forwardRef((props, ref) => {
  const { name, id, value, icon, iconSize, readOnly, classes, classesSx, classesName,color,inputValue, ...rest } = props;

  return (
    <React.Fragment>
      <input className={`${classes?.[classesName?.rating_input]} ${classesSx?.[classesName?.rating_input]}`} type="radio" name={name} id={id} ref={ref} defaultChecked={Number(inputValue)=== Number(value)} {...rest} readOnly={readOnly} disabled={readOnly} />
      <label htmlFor={id} className={`${classes?.[classesName?.star]} ${classesSx?.[classesName?.star]}`}>
        <Icon icon={icon || "starRate"} size={iconSize} color={Number(inputValue)>= Number(value)? styleFunction.checkColorReturnValue(color || "primary"):null}/>
      </label>
    </React.Fragment>
  );
});

export default RatingRadioInput;
