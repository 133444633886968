import React from "react";
// import useTheme from './useTheme';
import { defaultBaseSize } from "./cssVariables";
import { mainTheme, objCss } from "./cssVariables";

export const ContextThemeProvider = React.createContext({});

function ThemeProvider({ children, themes = null, currentTheme = null, baseSize = defaultBaseSize, currentBaseSize = defaultBaseSize }) {
  // const {theTheme}= useTheme({themes,currentTheme,baseSize})
  return (
    <ContextThemeProvider.Provider
      value={{
        ...mainTheme,
        baseSize: baseSize || mainTheme?.baseSize,
        cssObject: { ...objCss(Number(currentBaseSize || mainTheme?.baseSize)), ...(themes?.[currentTheme] || {}) },
        themes: themes,
        currentTheme: null,
        currentBaseSize: currentBaseSize || mainTheme?.currentBaseSize,
      }}
    >
      {children}
    </ContextThemeProvider.Provider>
  );
}

export default ThemeProvider;
