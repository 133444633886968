import React, { useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import LineStyle from "./LineStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Line = React?.forwardRef(function Line (/**@type {any}*/props, ref) {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = LineStyle(theTheme);
  const { sx, color, hidden, disabled, dir, className, label, mode, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({sx,color,mode});

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.line_container], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return(
      <div className={itemClasses} {...styleFunction?.getRestProps(rest)}>
        <div className={`${classesName?.line} ${mode === "fade"?classesName?.line_fade:""}`} ref={ref} />
        {label ? <label className={classesName?.line_label}><ErrorBoundary>{label}</ErrorBoundary></label> : ""}
      </div>
  )
});

export default Line