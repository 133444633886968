/* eslint-disable no-fallthrough */
import React, { forwardRef, useContext, useId, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import TabsStyle from "./TabsStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { useEffect } from "react";

const Tabs = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = TabsStyle(theTheme);
  const { tabsContent, currentTab = 1, activeColor = "primary", mode = "nav", sx, hidden, disabled, dir, className, id, ...rest } = props;
  const [tab, setTab] = useState(currentTab || 1)
  const classes = style();
  const classesSx = styleSx({ sx, activeColor, mode });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.tabs],
    classesSx?.[classesName?.tabs],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );
  const comID = id || String(useId()).slice(1, -1) + "tab";

  const handleClick = (funClick, ind) => {
    if (funClick) {
      funClick();
    }else{
      setTab(ind + 1)
    }
  };
  useEffect(() => {
  }, [props]);

  useEffect(() => {
    if (tab != currentTab) {
      setTab(currentTab)
    }
  }, [currentTab]);

  return (
    <React.Fragment>
      <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)}>
        <ErrorBoundary>
          {tabsContent?.map((item, ind) => {
            return (
              <React.Fragment key={comID + ind + 1}>
                <input
                  name={comID}
                  type="radio"
                  value={ind + 1}
                  className={`${classes?.[classesName?.tab_input]} ${classesSx?.[classesName?.tab_input]}`}
                  id={comID + `_${ind + 1}`}
                  defaultChecked={Number(currentTab) === ind + 1}
                  key={String(comID + ind + 2)}
                />
                <label
                  className={`${classes?.[classesName?.tab]} ${classesSx?.[classesName?.tab]}`}
                  htmlFor={comID + `_${ind + 1}`}
                  onClick={() => handleClick(item?.onClick || item?.onChange || null, ind)}
                  key={String(comID + ind + 3)}
                >
                  {item?.tab || item?.tabLabel || null}
                </label>
              </React.Fragment>
            );
          })}
        </ErrorBoundary>
      </div>

      <div className={classes?.[classesName?.tab_page]}>
        <ErrorBoundary>{tabsContent?.[tab-1]?.content} </ErrorBoundary>
      </div>
    </React.Fragment>
  );
});

export default Tabs;
