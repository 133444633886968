import * as formVariables from "../formVariables/FormVariables";


export function getError(name, formState){
  try{
    return formState?.[name]?.error
  }catch(error){
    console.error(error);
  }
}
export function updateError(newError, name,formState){
  try{
    formState[name].error = newError
    return {...formState};
  }catch(error){
    console.error(error);
  }
}

export  const getErrorLabel=(rule,name,formState, objDefLabel)=>{
  const theRule= String(rule).toLowerCase()
  const fieldValidationsMsg  =objDefLabel?.labels?.[name]?.validationsMsg?.[theRule] ||  objDefLabel?.labels?.[name]?.validationsMsg?.[rule] || null;
  const formValidationsMsg = objDefLabel?.defaultValidationsMsg?.[theRule] || objDefLabel?.defaultValidationsMsg?.[rule]

  return fieldValidationsMsg || formValidationsMsg
  }