/* eslint-disable no-fallthrough */
import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import PaperStyle from "./PaperStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Paper = forwardRef((/**@type {any}*/props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = PaperStyle(theTheme);
  const { elevation, width, outline, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({sx,elevation, width, outline});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.paper], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);


  return (
      <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)}>
        <ErrorBoundary>{props?.children}</ErrorBoundary>
      </div>
  );
});

export default Paper;
