import { ContextThemeProvider, styleFunction, styleVariables } from "@cbmisorg/styles";
import React, { Children, forwardRef, useContext, useEffect, useId, useMemo, useState } from "react";
import AppBarStyle from "./AppBarStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import Drawer from "../Drawer/Drawer";
import useScreenSize from "../../_helper/hooks/useScreenSize";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const AppBar = forwardRef(function AppBar(/**@type {any}*/ props, ref) {
  const { width } = useScreenSize();

  const theTheme = useContext(ContextThemeProvider);
  const {
    sx,
    hidden,
    disabled,
    dir,
    className,
    breadcrumbs = null,
    blnBurgerBar = true,
    blnSideBarSwap = true,
    mode,
    id,
    outlet = null,
    justifyContent = "space-between",
    ...rest
  } = props;
  const [openMenuSidebar, setOpenMenuSidebar] = useState(false);
  const { classesName, style, styleSx } = AppBarStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, justifyContent, open: openMenuSidebar, breadcrumbs: breadcrumbs ? true : false });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.appBar],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );
  const comID = id || useId().replace(/:/g, "");

  const getContent = useMemo(() => {
    let navBar = [];
    let sideBar = [];
    let bottomBar = [];
    // let logo = "";
    let screenSize = ["md", "sm", "xs"];

    if (width >= parseFloat(styleVariables?.xlScr)) {
      screenSize = ["xl", "lg", "md", "sm", "xs"];
    } else if (width <= parseFloat(styleVariables?.lgScr) && width > parseFloat(styleVariables?.mdScr)) {
      screenSize = ["lg", "md", "sm", "xs"];
    } else if (width <= parseFloat(styleVariables?.mdScr) && width > parseFloat(styleVariables?.smScr)) {
      screenSize = ["md", "sm", "xs"];
    } else if (width <= parseFloat(styleVariables?.smScr) && width > parseFloat(styleVariables?.xsScr)) {
      screenSize = ["sm", "xs"];
    } else {
      screenSize = ["xs"];
    }

    const checkMode = (position, item, defaultItem) => {
      let mode = position?.[screenSize?.[0]];
      if (!mode && screenSize.length > 1) {
        for (let index = 0; index < screenSize.length; index++) {
          if (position?.[screenSize?.[index]]) {
            mode = position?.[screenSize?.[index]];
            break;
          }
        }
      }
      switch (String(mode).toLowerCase()) {
        case "sidebar":
          sideBar.push(item);
          break;
        case "navbar":
          navBar.push(item);
          break;
        case "bottombar":
          const bottomItem = {
            icon: item?.props?.icon,
            path: item?.props?.path,
            label: item.props?.label || item?.props?.children,
            // onClick: handleDialogBottomMenu(item),
          };
          if (Number(item.props.bottomBarIndex)) {
            bottomBar[Number(item.props.bottomBarIndex)] = bottomItem;
          } else {
            bottomBar.push(bottomItem);
          }
          break;
        case "none":
          break;
        default:
          switch (String(defaultItem).toLowerCase()) {
            case "sidebar":
              sideBar.push(item);
              break;
            case "navbar":
              navBar.push(item);
              break;
            default:
              break;
          }
          break;
      }
    };
    Children.forEach(props?.children, (child) => {
      checkMode(child?.props?.position, child);
    });
    return { navBar, sideBar, bottomBar };
  }, [props?.children, width]);

  useEffect(() => {
    if ((width <= parseFloat(styleVariables?.smScr) && openMenuSidebar) || !getContent?.sideBar.length) {
      setOpenMenuSidebar(false);
    }else if(width > parseFloat(styleVariables?.smScr) && !openMenuSidebar && String(mode).toLowerCase()=== "dashboard" && getContent?.sideBar.length ){
      setOpenMenuSidebar(true);
    }
  },[width, mode, getContent?.sideBar])

  useEffect(() => {}, [sx, hidden, disabled, dir, className, mode, id, outlet, justifyContent]);

    return (
    <React.Fragment>
      <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)} key={width || comID}>
        <ErrorBoundary>
          {!(getContent?.sideBar?.length && blnBurgerBar) ? null : openMenuSidebar ? (
            <div className={`${classes?.[classesName?.bar_burger]} ${classesSx?.[classesName?.bar_burger]}`} onClick={() => setOpenMenuSidebar(!openMenuSidebar)}>
              <span className={classesName?.bar_1}></span>
              <span className={classesName?.bar_2}></span>
              <span className={classesName?.bar_3}></span>
            </div>
          ) : (
            <p className={`${classes?.[classesName?.bar_burger]} ${classesSx?.[classesName?.bar_burger]}`} onClick={() => setOpenMenuSidebar(true)}>
              <span className={classesName?.bar_1}></span>
              <span className={classesName?.bar_2}></span>
              <span className={classesName?.bar_3}></span>
            </p>
          )}

          <div className={`${classes?.[classesName?.appBarContent]} ${classesSx?.[classesName?.appBarContent]}`}>{getContent?.navBar}</div>
        </ErrorBoundary>
      </div>
      {mode === "dashboard" && (
        <div className={classes?.[classesName?.appBar_sidebarDbOutlet]}>
          <Drawer
            open={openMenuSidebar}
            blnSwap={blnSideBarSwap && getContent?.sideBar.length > 0}
            eventClose={() => setOpenMenuSidebar(!openMenuSidebar)}
            mode={mode}
            key={comID + "_Drawer"}
            className={`${classes?.[classesName?.appBar_sidebarDb]} ${classesSx?.[classesName?.appBar_sidebarDb]}`}
          >
            <ErrorBoundary>{getContent?.sideBar}</ErrorBoundary>
          </Drawer>
          <div className={`${classes?.[classesName?.appBar_outlet]} ${classesSx?.[classesName?.appBar_outlet]}`}>
            {!breadcrumbs ? null : (
              <div className={`${classesSx?.[classesName?.appBar_breadcrumbs]}`}>
                <ErrorBoundary>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                </ErrorBoundary>
              </div>
            )}
            {outlet}
          </div>
        </div>
      )}
      {mode !== "dashboard" && (
        <Drawer open={openMenuSidebar} blnSwap={blnSideBarSwap && getContent?.sideBar.length > 0} eventClose={() => setOpenMenuSidebar(!openMenuSidebar)} mode={mode} key={comID + "_Drawer-d"}>
          <ErrorBoundary>{getContent?.sideBar}</ErrorBoundary>
        </Drawer>
      )}
    </React.Fragment>
  );
});

export default AppBar;
