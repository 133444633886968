import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import SlideshowStyle from "./SlideshowStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { Icon } from "@cbmisorg/icons";

const Slideshow = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = SlideshowStyle(theTheme);
  const { blnAutoShow=true,  delay, activeColor, blnDotsShow = true, blnArrow = true, slideNumber = 1, sx, hidden, disabled, dir, className, ...rest } = props;
  const slides = props?.children || [];
  const classes = style();
  const classesSx = styleSx({ sx, activeColor, slideNumber });

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  // const sliderSplitNum = new Array(Math.round(slides.length / slideNumber));

  const canMove = Math.round(slides.length / slideNumber) > 1;
  const isAutoShow = blnAutoShow && canMove;
  const timeDelay = delay || 3000;

  useEffect(() => {}, [slides, blnAutoShow, delay, activeColor, blnDotsShow, blnArrow, sx, hidden, disabled, dir, className]);

  useEffect(() => {
    if (isAutoShow) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => setIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1)), timeDelay);

      return () => {
        resetTimeout();
      };
    }
  }, [index]);

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.slideshow],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const handleNext = () => {
    if (canMove && blnArrow) {
      var next = index + 1;
      // if (next === sliderSplitNum.length) {
      //   next = 0;
      // }
      setIndex(next);
    }
  };

  const handlePrev = () => {
    if (canMove && blnArrow) {
      var Prev = index - 1;
      if (Prev === -1) {
        // Prev = sliderSplitNum.length - 1;
      }
      setIndex(Prev);
    }
  };

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
      <ErrorBoundary>
        <div
          className={`${classes?.[classesName?.slideshowSlider]} ${classesSx?.[classesName?.slideshowSlider]}`}
          style={{ transform: `translateX(${document.querySelector(".cbmis-body-dir-rtl") || dir === "rtl" ? index * 100 : -index * 100}%)` }}
        >
          {props.children}
        </div>
      </ErrorBoundary>
      {canMove && blnArrow ? (
        <React.Fragment>
          <button className={`${classes?.[classesName?.slide_btn]} ${classes?.[classesName?.slide_btn_prev]}`} onClick={handlePrev}>
            <Icon icon="ArrowBackIos" />
          </button>

          <button className={`${classes?.[classesName?.slide_btn]} ${classes?.[classesName?.slide_btn_next]}`} onClick={handleNext}>
            <Icon icon="ArrowForwardIos" />
          </button>
        </React.Fragment>
      ) : (
        ""
      )}
      {blnDotsShow && canMove ? (
        <div className={classes?.[classesName?.slide_showDots]}>
          {(props.children || []).map((_, idx) => (
            <div
              key={idx}
              className={`${classes?.[classesName?.slide_showDot]} ${index === idx ? classesSx?.[classesName?.slide_showDot_active] : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
});

export default Slideshow;
