import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlRegistration = ({ appState, appDispatch, isLoading, setIsLoading }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    handleSubmit:
      async({ fields, navigate }) =>
       {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);
          let bigID = appFunctions.generateID(10);

          const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
            objectInput: {
              bigAppID: appVariables.App_ID,
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              bigUserID: bigID,

              strUserEmail: fields?.strUserEmail?.getValue(),
              strUserPassword: fields?.strUserPassword?.getValue(),

              jsnUserInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                intUserRoleFK: appVariables?.objUserRole?.Member,
              },
              blnIsManager: false,
              blnIsSponsor: false,
              blnIsStartUp: false,
              blnIsStudent: false,

              lstStatisticFelid: ["intCountJoinUser", "intCountJoinUserAll"],
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang]);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang]);
            }
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
          appState.userInfo = result.response;
          appDispatch();
          fields?.controller?.resetForm();
          navigate(appRouting?.Account?.url, { replace: true });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
