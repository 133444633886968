export function checkParseObject(object) {
    try {
      if (object === undefined || object === null) {
        throw new Error("Unsupported data-type");
      }
      if (typeof object === "string") {
        return JSON.parse(object);
      } else if (typeof object === "object") {
        return object;
      } else {
        throw new Error("Unsupported data-type");
      }
    } catch (error) {
      return {};
    }
  }

  