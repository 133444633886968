import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import { Icon } from "@cbmisorg/icons";
import ModalStyle from "./ModalStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const ModalHeader = forwardRef((/**@type {any}*/props, ref) => {
  let { sx, hidden, disabled, dir, className, id, closeIcon, handleClose=null, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleModalHeaderSx} = ModalStyle(theTheme);
  const classes = style();
  const classesSx = styleModalHeaderSx({ sx });
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.modal_header], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div className={itemClasses} {...styleFunction?.getRestProps({...rest})} ref={ref}>
      <ErrorBoundary>
        <button className={`${classes?.[classesName?.modal_close_btn]} ${classesSx?.[classesName?.modal_close_btn]}`} onClick={handleClose}>
          <Icon icon={closeIcon || "close"} />
        </button>
        <div className={classes?.[classesName?.modal_header_content]}>{props.children}</div>
      </ErrorBoundary>
    </div>
  );
});

export default ModalHeader;
