import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    button_container: `${styleVariables?.prefixDefaultClassName}_button_container`,
    button: `${styleVariables?.prefixDefaultClassName}_button`,
    btn_icon: `${styleVariables?.prefixDefaultClassName}_btn_icon`,
    btn_label: `${styleVariables?.prefixDefaultClassName}_btn_label`,
    sxContainer: `${styleVariables?.prefixDefaultClassName}_btn_container_sx`,
    sx: `${styleVariables?.prefixDefaultClassName}_btn_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.button]: {
        position: "relative",
        width: "auto",
        height: "fit-content",
        minHeight: cssObject?.size?.[cssKeys?.item_sm],
        fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
        fontSize: cssObject?.text?.size?.body1,
        letterSpacing: "0.02857em",
        textTransform: "capitalize",
        textAlign: "center",
        outline: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "inherit",
        margin: `${cssObject?.spacing?.[cssKeys?.xs]} ${cssObject?.spacing?.[cssKeys?.auto]}`,
        cursor: "pointer",
        transition: "all .5s ease",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        border: `${cssObject?.size?.[cssKeys?.border_sm]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${cssObject?.color?.[cssKeys?.transparent]}`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      [classesName?.btn_icon]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color: cssObject?.color?.[cssKeys?.currentColor],
      },
      [classesName?.btn_label]: {
        cursor: "pointer",
        color: cssObject?.color?.[cssKeys?.currentColor],
        padding: `0 ${cssObject?.spacing?.[cssKeys?.xs]}`,
        margin: "auto",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        return {
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
          lineHeight: `${cssObject?.spacing?.[cssKeys?.xs]}`,
          ...btnStyleClass(props),
          ...btnSize(props),
          ...props?.sx,
        };
      },
    },
  });

  const defaultStyle = (props) => {
    return {
      color: props?.color === "light" || props?.color === "#fff" || props?.color === "transparent" ? "dark" : "#fff",
      background: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.primary),
      "&active": {
        boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
      },
      "&hover": {
        boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
        opacity: 0.9,
      },
    };
  };
  const btnStyleClass = (props) => {
    const getStyle = String(props?.mode).toLowerCase();
    switch (getStyle) {
      case "outlined":
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.primary),
          border: `${cssObject?.size?.[cssKeys?.border_sm]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.primary)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
          "&active": {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
          },
          "&hover": {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
          },
        };
      case "contained":
        return defaultStyle(props);
      case "link":
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.primary),
          border: `${cssObject?.size?.[cssKeys?.border_sm]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${cssObject?.color?.[cssKeys?.transparent]}`,
          background: cssObject?.color?.[cssKeys?.transparent],
          "&active": {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.inner],
          },
          "&hover": {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.shadow],
          },
        };
      default:
        return defaultStyle(props);
    }
  };

  const btnSize = (props) => {
    const getSize = String(props?.size).toLowerCase();
    switch (getSize) {
      case "sm":
        return {
          padding: cssObject?.spacing?.[cssKeys?.sm],
          fontSize: cssObject?.text?.size?.body3,
          ".cbmis_icon_container_in_btn": {
            padding: 0,
            margin: 0,
          },
        };
      case "md":
        return {
          padding: `${cssObject?.spacing?.[cssKeys?.md]} ${cssObject?.spacing?.[cssKeys?.lg]}`,
          fontSize: cssObject?.text?.size?.body2,
          ".cbmis_icon_container_in_btn": {
            fontSize: cssObject?.text?.size?.body2,
          },
        };
      case "lg":
        return {
          padding: `${cssObject?.spacing?.[cssKeys?.lg]} ${cssObject?.spacing?.[cssKeys?.xl]}`,
          fontSize: cssObject?.text?.size?.body1,
          ".cbmis_icon_container_in_btn": {
            fontSize: cssObject?.text?.size?.body1,
          },
        };
      default:
        return {
          padding: `${cssObject?.spacing?.[cssKeys?.md]} ${cssObject?.spacing?.[cssKeys?.lg]}`,
          fontSize: cssObject?.text?.size?.body1,
          ".cbmis_icon_container_in_btn": {
            fontSize: cssObject?.text?.size?.body1,
          },
        };
    }
  };

  const btnIconSize = (size, iconSize = null) => {
    if (iconSize) {
      return iconSize;
    } else {
      const getSize = String(size).toLowerCase();
      switch (getSize) {
        case "sm":
          return cssObject?.text?.size?.body3;
        case "md":
          return cssObject?.text?.size?.body3;
        case "lg":
          return cssObject?.text?.size?.body1;
        default:
          return cssObject?.text?.size?.body1;
      }
    }
  };

  return {
    classesName,
    style,
    styleSx,
    btnIconSize,
  };
};
