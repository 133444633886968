
//#region public
export const landingPage = "/";
export const sheikProfile= "/sheikProfile";
export const login = "/login";
export const account= "/account";

export const regActivityGuest = "/:activityCode";
export const registration = "/register";

//#endregion 

