import React, { useState } from "react";
import { Container, Grid, Loader } from "@cbmisorg/material";
import { ClassesBuilder } from "@cbmisorg/styles";

import { useApp } from "../../../configuration/contextapi/context";
import { App_info_Color } from "../../../util/appStyle";

import ShredHeader from "../../shared/shredHeader";
import { objStyle } from "./sheikProfile.style";
import { dictionary } from "../../../util/appLanguage/dictionary";
import MemberInfo from "./component/MemberInfo";

const labels = dictionary?.components?.public?.sheikProfile;
function SheikProfileView() {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle(lang));
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
  });
  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBack={true} blnHome={true} blnLang={false} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <MemberInfo classes={classes} lang={lang} />
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default SheikProfileView;
