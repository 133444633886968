import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import CardStyle from "./CardStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Card = forwardRef((/**@type {any}*/props, ref) => {
  const { sx,id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleCardSx} = CardStyle(theTheme);
  const classes = style();
  const classesSx = styleCardSx({sx});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.card], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);
  const comID = id ||  String(useId()).slice(1,-1)

  return (
    <div className={`${classes?.[classesName?.card_container]} ${styleFunction.CheckDirectionItem(dir)} ${typeof props.children[1] !== "undefined" ? classes?.[classesName?.card_have_back]: ""}`} ref={ref} {...styleFunction?.getRestProps(rest)} key={comID+"_card_container"}>
      <div className={itemClasses} key={comID+"_card"}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
    </div>
  );
});

export default Card;
