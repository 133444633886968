import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    paper: `${styleVariables?.prefixDefaultClassName}_paper`,
    sx: `${styleVariables?.prefixDefaultClassName}_paper_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.paper]: {
        display: "block",
        margin: "auto",
        position: "relative",
        overflow: "auto",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],

      }
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        const {elevation=null, width=null, outline=null, sx={sm:null}} = props
        return {
          fontSize:cssObject?.text?.size?.body1,
          color: cssObject?.color?.bodyColor,
          padding: cssObject?.spacing?.[cssKeys?.md],
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.sm,
          ...getBorder(props),
          ...getWidth(props),
          ...getShadow(props),
          ...props?.sx,
          sm:{
            padding:  cssObject?.spacing?.[cssKeys?.sm],
            ...props?.sx?.sm,
          },
        };
      },
    },
  });

  const getWidth = (props) => {
    switch (String(props?.width).toLowerCase()) {
      case "xl":
        return {
          width: cssObject?.size?.[cssKeys?.xl]
        }
      case "lg":
        return {
          width: cssObject?.size?.[cssKeys?.lg]
        }
      case "md":
        return {
          width: cssObject?.size?.[cssKeys?.md]
        }
      case "sm":
        return {
          width: cssObject?.size?.[cssKeys?.sm]
        }
        case "sm":
        return {
          width: cssObject?.size?.[cssKeys?.xs]
        }
      case "fluid":
         return {
          width: cssObject?.size?.[cssKeys?.full]
        }
      case "half":
        return {
          width: cssObject?.size?.[cssKeys?.half]
        }
      default:
         return {
          width: cssObject?.size?.[cssKeys?.full]
        }
    }
  };
  const getBorder = (props) => {
    switch (parseInt(props?.outline)) {
      case 1:
        return {
          color: styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
        };
      case 2:
        return {
          border: `${cssObject?.size?.[cssKeys?.border_sm]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
        };
      case 3:
        return {
          border: `${cssObject?.size?.[cssKeys?.border_md]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
        };
      case 4:
        return {
          border: `${cssObject?.size?.[cssKeys?.border_lg]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
        };
      case 5:
        return {
          border: `${cssObject?.size?.[cssKeys?.border_xl]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
        };
      default:
        return {
          width: cssObject?.border?.size?.[cssKeys?.none]
        };
    }
  };

    const getShadow = (props) => {
      switch (parseInt(props?.elevation)) {
        case 1:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.xs],
          };
        case 2:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.sm],
          };
        case 3:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.md],
          };
        case 4:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
          };
        case 5:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.xl],
          };
        default:
          return {
            boxShadow: cssObject?.boxShadow?.[cssKeys?.sm],
          };
      }
    };

  return{
    classesName,
    style,
    styleSx,
  }
}