
export const App_Setting_Mode = "production"; // production | testing | localhost
const App_Version_Date = "11.09.2024 6PM"; // day.month.year Hour Am/PM
// aaiot_productionBuild_11.09.2024 6PM
// aaiot_testingBuild_11.09.2024 6PM
// aaiot_clientCode_11.09.2024 6PM
export const App_Version = (App_Setting_Mode === "production" ? "P" : "T") + App_Version_Date;
export const App_Name = "aaiotApp";
export const App_ID = 7253851520;

export var App_IsInTestingMode = false;

//#region hooks time
export var App_Login_HoursToCheck = 1 / 60;
export var System_Info_HoursToCheck = 1 / 60;
export var System_Info_Count_HoursToCheck = 5 / 60;
export var Cat_Info_HoursToCheck = 2;
//#endregion


export var App_Server_Url = "https://www.cbmisdb.link/";
export var App_Server_Url_DownloadFiles = "https://tms-cbmis-production.s3.eu-central-1.amazonaws.com/";
var App_Server_File_Url = "http://localhost:4000/";
export var App_Server_SendEmail_Path = "itcapp/sendmail";


export const App_LocalStorage_Name = "aaoitSystemLocalStorageState";
export var App_LocalStorage_Encrypted_KEY = "2024";
export var App_LocalStorage_Is_Encrypted = true;

if (App_Setting_Mode.toLowerCase() === "testing") {
  App_Server_Url = "https://www.cbmistesting.link/"; //"https://node.louayserver.click/" // "https://www.cbmistesting.link/"
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  App_Login_HoursToCheck = 1 / 60;
  System_Info_HoursToCheck = 1 / 60;
  Cat_Info_HoursToCheck = 2;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
} else if (App_Setting_Mode.toLowerCase() === "localhost") {
  App_Server_Url = "http://localhost:4000/";
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  System_Info_HoursToCheck = 0;
  Cat_Info_HoursToCheck = 0;
  App_Login_HoursToCheck = 0;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
}

export var App_Server_Url_GraphQL = App_Server_Url + "aaiotapp/graphql";
export var App_Server_Url_UploadFiles = App_Server_File_Url + "aaiotapp/upload/s3";
export var App_Server_Url_SendEmail = App_Server_Url + App_Server_SendEmail_Path;


export var App_Download_URL = "https://static-media-project.s3.eu-central-1.amazonaws.com/private-office-kuwait/";
export const objUploadType = {
  aaiot: { fileType: "aaiot", id: "aaiot", sizeMb: 75, accept: "FILE" },
  video: { fileType: "aaiot", id: "video", sizeMb: 120, accept: "video" },
  announcement: { id: "announcement", fileType: "itc-announcement", sizeMb: 30, accept: "FILE" },

  payment: "payment",
  activates: "activates",
  profile: "profile",
};
  

export const aaiot_System_ID = 2560918876;
export const Cat_Root_ID = 0;

export const Role_Public = 3553430577;
export const objUserRole = {
  "Owner": 1764,
  "Admin": 6517,
  "Employee": 1649,
  "Member": 7125,
  "Itc-Owner": 1742,
  "Itc-Admin": 7840,
  "Itc-Student": 7914,
  "Contractor": 8757,
  "RootAdmin": 7153,
};


// -------------------------------------------------------------------------------------------------

export const workSectorID = {
  academic: 1773983922,
  gov: 5460695364,
  prvt: 1376840499,
};

export const workSectorCat = {
    leader: 1773983901,
    employee: 1773983902,
    manager: 1773983903,
    programer: 1773983904,
    academic: 1773983905,
    student: 1773983906,
};

export const objUserStatus = {
  Approved: 2085578067,
  Rejected: 8715548754,
  Applicant: 2535501102,
};

export const objPartnerStatus = {
  New: 3819315825,
  Approved: 4394019674,
  Rejected: 1124393711,  
};

export const objSupportTransStatus = {
  Send_New: 1,
  Under_Process: 2,
  Approved: 3,
  Rejected: 4,
};

export const objSponserCat ={
  main:{key:"main", number:100},
  golden:{key:"golden", number:50},
  silver:{key:"silver", number:25},
  bronze:{key:"bronze", number:10}
}

export const objTrainingToSupport ={
  fullstack:{id:1, key:"fullstack"},
  manager:{id:2, key:"it-managers"},
  manager_fullstack: { id: 3, key: "manager_fullstack" },
}