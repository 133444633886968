"use client";

import { getCacheStoreTrack, getCacheStyleSheetIndex } from "../cache/MainCache";
import { checkColorReturnValue } from "./styleFunction";
import * as styleVariables from "./styleVariables";
import { defaultApply, objCSSEntities, objCssHtmlJxsElement, objCssProperties, objCssPseudoClasses, objCssPseudoElements, objCssSymbols, objScreenSize } from "./cssProperties";
import getCssFormatValue from "../defualtStyle/util/getCssFormatValue";
import { cleanOne } from "./cleanUp";
import { pkgConfigStyle } from "..";

const semicolonReplacement = "*-*";
const keyframeSelectorReplacement = "-|-";
const splitMedia = "-|-";
const contentReplacement = "~!";
const cssRuleType = {
  CSSStyleRule: "CSSStyleRule",
  CSSMediaRule: "CSSMediaRule",
  CSSKeyframesRule: "CSSKeyframesRule",
};

async function convertJsObjectToCss(orgClassName, idComp, newObjStyles, cacheTrackCssRule, styleSheetIdx, size = null) {
  const styleSheet = document?.styleSheets?.[styleSheetIdx];
  const cacheTrackPairs = cacheTrackCssRule.get(orgClassName);

  if (typeof newObjStyles !== "object") {
    return true;
  }

  const lstStyle = Object.entries(newObjStyles || {});
  if (!lstStyle?.length) {
    return true;
  }

  let blnIsInsertCorrectly = true;
  for (let i = 0; i < lstStyle.length; i++) {
    const cssRule = lstStyle[i];
    const subClassName = cssRule[0].trim();

    let cssTest = `${subClassName}${String(String(JSON.stringify(cssRule[1])).replaceAll('"', ""))
      .replaceAll(",", ";")
      .replaceAll(contentReplacement, `"`)
      .replaceAll(keyframeSelectorReplacement + ":", " ")
      .replaceAll(";" + keyframeSelectorReplacement, " ")
      .replaceAll(keyframeSelectorReplacement, " ")
      .replaceAll(semicolonReplacement, ",")}`;

    if (!size) {
      if (!cacheTrackPairs?.objCss) {
        blnIsInsertCorrectly = false;
        break;
      }
      if (cacheTrackPairs?.objCss?.has(subClassName)) {
        continue;
      }
      try {
        let intAfterAddIndex = styleSheet.insertRule(cssTest, styleSheet.cssRules.length);
        cacheTrackPairs.objCss.set(subClassName, { idx: intAfterAddIndex });
      } catch (error) {
        if (pkgConfigStyle?.blnIsTestingMode) {
          console.error(orgClassName, error);
        }
      }
    } else if (size) {
      if (!cacheTrackPairs?.objCss) {
        blnIsInsertCorrectly = false;
        break;
      }
      if (cacheTrackPairs?.objCss?.has(`${size}${splitMedia}${subClassName}`)) {
        continue;
      }
      try {
        let intAfterAddIndex = styleSheet.insertRule(
          `${size}{
            ${cssTest}
          }`,
          styleSheet.cssRules.length
        );
        cacheTrackPairs.objCss.set(`${size}${splitMedia}${subClassName}`, { idx: intAfterAddIndex });
      } catch (error) {
        if (pkgConfigStyle?.blnIsTestingMode) {
          console.error(orgClassName, error);
        }
      }
    }
  }

  return blnIsInsertCorrectly;
}

async function setSheetRule(orgClassName, className, blnIsDefaultStyle, blnCbmisStyle, objStyles, appStyleConfig, idComp) {
  try {
    const newObjStyles = { ...objStyles };

    const xsScrSizeObj = objStyles?.[styleVariables.xsScrSize] || null;
    const smScrSizeObj = objStyles?.[styleVariables.smScrSize] || null;
    const mdScrSizeObj = objStyles?.[styleVariables.mdScrSize] || null;
    const lgScrSizeObj = objStyles?.[styleVariables.lgScrSize] || null;
    const xlScrSizeObj = objStyles?.[styleVariables.xlScrSize] || null;

    delete newObjStyles?.[styleVariables.xsScrSize];
    delete newObjStyles?.[styleVariables.smScrSize];
    delete newObjStyles?.[styleVariables.mdScrSize];
    delete newObjStyles?.[styleVariables.lgScrSize];
    delete newObjStyles?.[styleVariables.xlScrSize];

    const cacheTrackCssRule = getCacheStoreTrack(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });
    const styleSheetIdx = getCacheStyleSheetIndex(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });

    let blnIsInsertCorrectly = true;

    if (newObjStyles?.[className]) {
      blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, { [className]: newObjStyles?.[className] }, cacheTrackCssRule, styleSheetIdx);
      delete newObjStyles?.[className];
    }
    if (newObjStyles) {
      blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, newObjStyles, cacheTrackCssRule, styleSheetIdx);
    }

    const classPairs = { [className]: className };

    if (xlScrSizeObj) {
      if (xlScrSizeObj?.[className]) {
        classPairs[className] = xlScrSizeObj?.[className];
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, classPairs, cacheTrackCssRule, styleSheetIdx, styleVariables.xlScrSize);
        delete xlScrSizeObj?.[className];
      }
      if (xlScrSizeObj) {
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, xlScrSizeObj, cacheTrackCssRule, styleSheetIdx, styleVariables.xlScrSize);
      }
    }
    if (lgScrSizeObj) {
      if (lgScrSizeObj?.[className]) {
        classPairs[className] = lgScrSizeObj?.[className];
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, classPairs, cacheTrackCssRule, styleSheetIdx, styleVariables.lgScrSize);
        delete lgScrSizeObj?.[className];
      }
      if (lgScrSizeObj) {
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, lgScrSizeObj, cacheTrackCssRule, styleSheetIdx, styleVariables.lgScrSize);
      }
    }
    if (mdScrSizeObj) {
      if (mdScrSizeObj?.[className]) {
        classPairs[className] = mdScrSizeObj?.[className];
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, classPairs, cacheTrackCssRule, styleSheetIdx, styleVariables.mdScrSize);
        delete mdScrSizeObj?.[className];
      }
      if (mdScrSizeObj) {
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, mdScrSizeObj, cacheTrackCssRule, styleSheetIdx, styleVariables.mdScrSize);
      }
    }
    if (smScrSizeObj) {
      if (smScrSizeObj?.[className]) {
        classPairs[className] = smScrSizeObj?.[className];
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, classPairs, cacheTrackCssRule, styleSheetIdx, styleVariables.smScrSize);
        delete smScrSizeObj?.[className];
      }
      if (smScrSizeObj) {
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, smScrSizeObj, cacheTrackCssRule, styleSheetIdx, styleVariables.smScrSize);
      }
    }
    if (xsScrSizeObj) {
      if (xsScrSizeObj?.[className]) {
        classPairs[className] = xsScrSizeObj?.[className];
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, classPairs, cacheTrackCssRule, styleSheetIdx, styleVariables.xsScrSize);
        delete xsScrSizeObj?.[className];
      }
      if (xsScrSizeObj) {
        blnIsInsertCorrectly &&= await convertJsObjectToCss(orgClassName, idComp, xsScrSizeObj, cacheTrackCssRule, styleSheetIdx, styleVariables.xsScrSize);
      }
    }
    return blnIsInsertCorrectly;
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
    return false;
  }
}

async function convertPropsFromJsToCss(_cssKey = "", _objInputStyle = {}, _objOutputResult = {}) {
  let objOutput = _objOutputResult;
  const objInputResult = {};

  if (typeof _objInputStyle !== "object") {
    return objOutput;
  }

  let lstSource = Object.entries(_objInputStyle || {});

  for (let i = 0; i < lstSource.length; i++) {
    const [key, value] = lstSource[i];

    if (objScreenSize?.[key]) {
      if (!objOutput?.[objScreenSize?.[key]]) {
        objOutput[objScreenSize?.[key]] = { [_cssKey]: value };
      }

      objOutput[objScreenSize?.[key]] = { ...objOutput?.[objScreenSize?.[key]], ...(await convertPropsFromJsToCss(_cssKey, value, {})) };
    } else if (typeof value !== "object" && objCssProperties?.[key]) {
      if (objCssProperties?.[key] === objCssProperties?.content) {
        lstSource[i][1] = contentReplacement + (objCSSEntities?.[value] ? objCSSEntities?.[value] : value) + contentReplacement;
      }

      if (String(objCssProperties?.[key]).toLowerCase().includes(objCssProperties?.color) || objCssProperties?.[key] === objCssProperties?.background || objCssProperties?.[key] === objCssProperties?.backgroundColor || objCssProperties?.[key] === objCssProperties?.borderColor) {
        lstSource[i][1] = checkColorReturnValue(value);
      }

      objInputResult[objCssProperties?.[key]] = String(lstSource[i][1]).replaceAll(",", semicolonReplacement);
      objOutput[_cssKey] = objInputResult;
    } else if (objCssPseudoClasses?.[key]) {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${_cssKey}:${objCssPseudoClasses?.[key]}`, value, {})) };
    } else if (objCssPseudoElements?.[key]) {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${_cssKey}::${objCssPseudoElements?.[key]}`, value, {})) };
    } else if (objCssHtmlJxsElement?.[key]) {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${_cssKey} ${objCssHtmlJxsElement?.[key]}`, value, {})) };
    } else if (objCssSymbols?.[key]) {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${_cssKey} ${objCssSymbols?.[key]}`, value, {})) };
    } else if (key?.[0] === "." || key?.[0] === "#" || key?.[0] === "," || key?.[0] === "+" || key?.[0] === ">") {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${_cssKey} ${key}`, value, objOutput)) };
    } else if (String(key).toLowerCase().includes("@keyframes")) {
      objOutput = { ...objOutput, ...(await convertPropsFromJsToCss(`${key}`, value, {})) };
    } else if (String(key).toLowerCase() === "from" || String(key).toLowerCase() === "to" || String(key).toLowerCase().includes("%")) {
      objOutput[_cssKey] = { ...objOutput[_cssKey], ...(await convertPropsFromJsToCss(`${keyframeSelectorReplacement}${key}${keyframeSelectorReplacement}`, value, {})) };
    } else if (defaultApply?.[key]) {
      const lstDefaultClasses = Array.isArray(value) ? value : String(value)?.split(" ");
      let outputDefault = {};
      for (let index = 0; index < lstDefaultClasses.length; index++) {
        const strDefClass = lstDefaultClasses?.[index];
        if (!strDefClass) {
          continue;
        }
        outputDefault = { ...outputDefault, ...getCssFormatValue(strDefClass) };
      }
      objOutput[_cssKey] = { ...objOutput[_cssKey], ...outputDefault };
    }
  }

  return objOutput;
}

//#region set sx style
async function setSxClassName(className, objStyle, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, idComp) {
  try {
    if (className) {
      let classNameTmp = String(className).includes("&[") ? `${className.replace("&[", "[")}` : `.${className}`;
      const objOutputResult = await convertPropsFromJsToCss(classNameTmp, objStyle, {});
      if (!Object.keys(objOutputResult)?.length) {
        return;
      }
      await setSheetRule(className, classNameTmp, blnIsDefaultStyle, blnCbmisStyle, objOutputResult, appStyleConfig, idComp);
      /* if (!result) {
        const cacheTrackCssRule = getCacheStoreTrack(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });
        if (!cacheTrackCssRule?.has(className)) {
          cacheTrackCssRule.set(className, { usage: new Set(), objCss: new Map() });
        }
        const result1 = await setSheetRule(className, classNameTmp, blnIsDefaultStyle, blnCbmisStyle, objOutputResult, appStyleConfig, idComp);
      } */
    }
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
  }
}

export async function setCssRules(_styleRef, _props, { idComp, lstStyleClasses, className, objStyles, sx, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig }, patch = false) {
  try {
    if (!objStyles && !className && !sx) {
      return;
    }

    if (!patch) {
      if (_styleRef?.blnInsertRun) {
        return;
      }
      _styleRef.blnInsertRun = true;
    }

    if (patch) {
      if (_styleRef?.blnPatchRun) {
        return;
      }
      _styleRef.blnPatchRun = true;
    }

    for (let i = 0; i < lstStyleClasses.length; i++) {
      let strClassName_client = className ? className : lstStyleClasses?.[i]?.[0];
      let strClassName_rule = _styleRef?.objClassesNames?.[strClassName_client];

      if (!strClassName_client || !strClassName_rule) {
        continue;
      }

      if (!patch && _styleRef?.objDelMicro?.[strClassName_client]) {
        continue;
      }

      if (patch && !_styleRef?.objDelMicro?.[strClassName_client]) {
        continue;
      }

      const cacheTrackCssRule = getCacheStoreTrack(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });

      if (patch && _styleRef?.objDelMicro?.[strClassName_client]) {
        if (pkgConfigStyle?.enableCleanChangedStyles) {
          await cleanOne(idComp, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, _styleRef?.objDelMicro?.[strClassName_client]?.strClassName_rule, strClassName_client);
        }
        delete _styleRef?.objDelMicro?.[strClassName_client];
      }

      let inlineStyle = lstStyleClasses?.[i]?.[1];
      if (typeof inlineStyle === "function") {
        inlineStyle = inlineStyle(_props);
        if (!inlineStyle) {
          continue;
        }
      }

      if (!cacheTrackCssRule?.has(strClassName_rule)) {
        cacheTrackCssRule.set(strClassName_rule, { usage: new Set(), objCss: new Map() });
        await setSxClassName(strClassName_rule, inlineStyle, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, idComp);
      } else if (!cacheTrackCssRule?.get(strClassName_rule)?.objCss?.size) {
        await setSxClassName(strClassName_rule, inlineStyle, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, idComp);
      }

      const currentGroupCss = cacheTrackCssRule?.get(strClassName_rule);

      if (idComp && currentGroupCss?.objCss?.size && !currentGroupCss?.usage?.has(idComp)) {
        currentGroupCss.usage.add(idComp);
      }

      if (!currentGroupCss?.objCss?.size) {
        cacheTrackCssRule.delete(strClassName_rule);
      }
    }

    if (!patch) {
      _styleRef.blnInsertRun = false;
    }
    if (patch) {
      _styleRef.blnPatchRun = false;
    }
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
    _styleRef.blnInsertRun = false;
    _styleRef.blnPatchRun = false;
  }
}
//#endregion
