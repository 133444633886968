import React from "react";
import AccountView from "./Account.view";

export default function Account() {
  return (
    <>
      <AccountView />
    </>
  );
}
