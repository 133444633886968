import { Icon } from '@cbmisorg/icons';
import { Grid, Typography } from '@cbmisorg/material';
import React from 'react'
import { App_info_Color, App_Light_Text_Color } from '../../../../../../../util/appStyle';
import { dictionary } from '../../../../../../../util/appLanguage/dictionary';

const labels = dictionary?.components?.stakeholders?.member?.account?.regActivityGuest;

const SubjectOption = ({ element, lang, state, setState, blnLst = false }) => {
    const handleOpenModal = () => {
      state.subjectSelected = element;
      state.blnOpenModal = true;
      setState({ ...state });
    };
  
    return (
      <Grid container px-2 py-0 spacing={0} m-0>
        <Grid item xs={blnLst ? "auto" : "0"} alignSelf="flex-start" pt-3>
          <Icon icon="circle" size="12px" />
        </Grid>
        <Grid item xs={blnLst ? "11" : "12"} container>
          <Grid item xs={blnLst ? "11" : "12"}>
            <Typography as="body2" color={App_Light_Text_Color}>
              {element?.value?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="10" lg="auto">
            <Typography as="body3" color={App_info_Color}>
              {labels?.targeted?.[lang]}: {element?.targeted?.value?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="2">
            <Icon icon="Feed" color={App_info_Color} mx-1 my-0 p-1 sx={{ border: "1px solid", borderRadius: "50%" }} onClick={handleOpenModal} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default SubjectOption