import { ContextThemeProvider, StyleBuilder, cssKeys, mainTheme, styleFunction, styleVariables } from "@cbmisorg/styles";
import React, { useContext } from "react";

const className = `${styleVariables?.prefixDefaultClassName}_error_message`
const errorMsgStyle=(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const style = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [className]:(props)=>{
          return{
            fontSize: cssObject?.text?.size?.[cssKeys?.caption],
            padding: `${cssObject?.spacing?.[cssKeys?.px]} ${cssObject?.spacing?.[cssKeys?.sm]}`,
            display:"block",
            position:"relative",
            width:"max-content",
            textAlign:"start",
            margin:0,
            color: styleFunction.checkColorReturnValue(props?.blnError?"error": "currentColor"),

          }
      },
    }
  });

  return {style}
}

const ErrorMessage = (props) => {
  const { message=null, rest } = props;
  if(message){
    return <ErrorMessageChild message={message} {...rest} />
  }
  return null;
};

const ErrorMessageChild = (props) => {
  const theTheme = useContext(ContextThemeProvider);
  const { message=null, blnError=false, rest } = props;
  const {style} = errorMsgStyle(theTheme)
  const classes= style({message:message?true:false, blnError: blnError})

    return <label className={classes?.[className]} {...rest}>{message}</label>
};


export default ErrorMessage;
