import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    rating_container: `${styleVariables?.prefixDefaultClassName}_rating_container`,
    rating: `${styleVariables?.prefixDefaultClassName}_rating`,
    label: `${styleVariables?.prefixDefaultClassName}_label`,
    rating_input: `${styleVariables?.prefixDefaultClassName}_rating_input`,
    star: `${styleVariables?.prefixDefaultClassName}_star`,
    sx: `${styleVariables?.prefixDefaultClassName}_rating_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.rating_container]: {
        display: cssObject?.display?.[cssKeys?.block],
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position: cssObject?.position?.[cssKeys?.relative],
        margin: "auto",
        minHeight: "max-content",
        width: "max-content",
        maxWidth: cssObject?.size?.[cssKeys?.full],
      },
      [classesName?.label]: {
        display: "flex",
        alignItems: "center",
        fontWeight: "600",
      },
      [classesName.rating]: {
        display: "cssObject?.display?.[cssKeys?.flex]",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-around",
        margin: `auto ${cssObject?.spacing?.[cssKeys?.sm]}`,
        overflow: "hidden",
        padding: 0,
      },
      [classesName.rating_input]: {
        opacity: 0,
        position: cssObject?.position?.[cssKeys?.relative],
        maxWidth: cssObject?.size?.[cssKeys?.px],
        margin: 0,
        padding: 0,
      },
      [classesName?.star]: {
        color: cssObject?.color?.[cssKeys?.bodyColor],
        textDecoration: "none",
        WebkitTransition: " color .4s",
        MozTransition: " color .4s",
        OTransition: " color .4s",
        transition: " color .4s",
        display: "inline-flex",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.label]: {
        fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
        textAlign: "start",
        maxWidth: "fit-content",
        margin: 0,
      },
      [classesName?.star]: (props) => {
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h6],
          "&hover": props.readOnly
            ? null
            : {
                cursor: "pointer",
                opacity: 0.7,
                color: styleFunction.checkColorReturnValue(props?.color || props?.sx?.color || "primary"),
              },
        };
      },
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
