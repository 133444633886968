import { ContextThemeProvider, styleFunction } from '@cbmisorg/styles';
import React, { forwardRef, useContext, useId } from 'react'
import MenuStyle from './MenuStyle';

const MenuItem = forwardRef((/**@type {any}*/props, ref) => {
  const { headItem, footerItem, outlined, selected, sx, hidden, disabled, dir, className, id, onClick, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleMenuItemSx} = MenuStyle(theTheme);
  const classes = style();
  const classesSx = styleMenuItemSx({sx,selected,outlined,onClick: onClick? true : false});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.menu_item], classesSx?.[classesName?.sxItem], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);
  const comID = id || String(useId()).slice(1, -1);


  const handelClicked=(e)=>{
    if(onClick){
      onClick(e);
    }
  }
  return (
    <div className={itemClasses} ref={ref} key={comID} onClick={handelClicked}>
      {!headItem ?null: <div className={classes?.[classesName?.menu_item_head]}  key={comID+"_headItem"}>{headItem}</div>}
      <div className={classes?.[classesName?.menu_item_item_content]} key={comID+"_contentItem"} {...styleFunction?.getRestProps(rest)}>
        {props.children}
      </div>
      <div className={classes?.[classesName?.menu_item_footer]} key={comID+"_footerItem"}>{!footerItem?null:footerItem}</div>
    </div>
  )
});

export default MenuItem 