import {  useEffect, useRef } from "react";

export const useClickOutside = (handler) => {
    const domNode = useRef();
    useEffect(() => {
        const eventHandler = (event) => {
            if (!domNode?.current?.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
  }