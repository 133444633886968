export const appLanguage = {
  arb: { blnActive: true, blnIsMandatory: false, label: "العربية" },
  eng: { blnActive: true, blnIsMandatory: true, label: "English" },
};

export const appLangDirection = {
  arb: "rtl",
  eng: "ltr",
};

export const appLangOrientation = {
  arb: "Right",
  eng: "Left",
};

export const appLangType = {
  arb: "arb",
  eng: "eng",
};

export const dictionary = {
  formapi: {
    strFullName: {
      label: { eng: "Name", arb: "الإسم" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strUserEmail: {
      label: { eng: "Email", arb: "البريد الإلكتروني" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
        email: { eng: "Invalid email address", arb: "البريد الإلكتروني غير صحيح" },
      },
    },
    bigWorkSector: {
      label: { eng: "The work sector", arb: "نوع القطاع" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    lstTargeted: {
      label: { eng: "Audience", arb: "الفئة" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strPlaceName: {
      label: { eng: "Name of the organization", arb: "اسم جهة العمل" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    lstSubject: {
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strUserPassword: {
      label: { eng: "Password", arb: "كلمة المرور" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
        minLength: { eng: "Minimum of 6 digits required", arb: "مسموح ب 6 خانات كحد أدنى" },
      },
    },
    strConfirmPassword: {
      label: { eng: "Conform Password", arb: "تأكيد كلمة المرور" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
        minLength: { eng: "Minimum of 6 digits required", arb: "مسموح ب 6 خانات كحد أدنى" },
        equal: { eng: "Passwords Don't match", arb: "كلمة المرور غير متطابقة" },
      },
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region account fields
    bigMajorID: {
      label: { eng: "Major", arb: "التخصص" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigDegreeID: {
      label: { eng: "Degree", arb: "الدرجة العلمية" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigGender: {
      label: { eng: "Gender", arb: "الجنس" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigEmploymentID: {
      label: { eng: "Employment Status", arb: "الحالة الوظيفية" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigCountryOfCitizenID: {
      label: { eng: "Country Of Citizen", arb: "بلد الجنسية" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigCountryOfResidenceID: {
      label: { eng: "Country Of Residence", arb: "بلد الإقامة" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strUniversityName: {
      label: { eng: "University", arb: "الجامعة" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigAcademicYearID: {
      label: { eng: "Academic Year", arb: "السنة الأكاديمية" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    bigGpaID: {
      label: { eng: "GPA", arb: "المعدل التراكمي" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },

    strWorkGovPlace: {
      label: { eng: "The workplace name", arb: "مكان العمل" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strPositionTitle: {
      label: { eng: "Position title", arb: "المسمى الوظيفي" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    strURL: {
      label: { eng: "Website Link", arb: "رابط الموقع" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    //#endregion
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    strSponsorTrainingType: {
      placeholder: { eng: "Support category", arb: "فئة الدعم" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
    blnIsFirstSections: {
      label: { eng: "From the first sections", arb: "من اوئل الاقسام" },
      validationsMsg: {
        required: { eng: "Required!", arb: "الحقل مطلوب" },
      },
    },
  },
  components: {
    public: {
      appName: {
        eng: "The association of Artificial Intelligence for Things",
        arb: " جمعية الذكاء الإصطناعي للأشياء",
      },
      landingPage: {
        hero: {
          body: {
            eng: "An association concerned with the software and technology sector in its various sectors, to be a real building block for supporting the national economic base and contributing to building a creative generation",
            arb: "جمعية تُعنى بقطاع البرمجيات والتكنولوجيا بمختلف قطاعاتها لتكون لبنة حقيقية لدعم القاعدة الاقتصادية الوطنية والمساهمة ببناء جيل مبدع",
          },
        },
      },
      registration: {
        title: { eng: "Joining the AAIOT", arb: "انضمام إلى الجمعية" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        invite: {
          eng: "We invite you to join us for this leading event to explore new horizons and drive innovation in the world of modern technology",
          arb: "ندعوكم للانضمام إلينا في هذا الحدث الرائد لفتح آفاق جديدة وتحقيق الابتكار في عالم التكنولوجيا الحديثة",
        },
        btn: { eng: "Registration", arb: "تسجيل" },
        alertThanke: {
          eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Week. We look forward to enriching your experience and maximizing your benefit",
          arb: "نشكركم على تسجيلكم في جمعية الذكاء الاصطناعي للأشياء ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
        },
      },
      regActivityGuest: {
        title: { eng: "Digital Empowerment and Artificial Intelligence Week", arb: "أُسبوع التمكين الرقمي و الذكاء الإصطناعي" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        detailsInfo: {
          eng: "Seminars and Workshops",
          arb: "الندوات وورش العمل",
        },
        detailInfo: {
          eng: "Seminars details",
          arb: "معلومات الندوة",
        },
        subtitle: {
          eng: "Contribute with us to building a prosperous future by nurturing a distinguished generation of leading programmers",
          arb: "ساهم معنا في بناء مستقبل مزدهر من خلال تنمية جيل متميز من المبرمجين المتمكنين",
        },
        dec: {
          eng: "The Digital Transformation and Artificial Intelligence Week in Kuwait will focus on digital transformation strategies and related challenges, as well as explaining artificial intelligence and data protection. It will also showcase modern platforms and tools that enhance work efficiency and develop government applications",
          arb: "أسبوع التحول الرقمي والذكاء الاصطناعي في الكويت سيركز على استراتيجيات التحول الرقمي والتحديات المتعلقة به، فضلاً عن شرح الذكاء الاصطناعي وحماية البيانات. كما سيستعرض المنصات والأدوات الحديثة التي تعزز كفاءة العمل وتطوير التطبيقات الحكومية",
        },
        invite: {
          eng: "We invite you to join us for this leading event to explore new horizons and drive innovation in the world of modern technology",
          arb: "ندعوكم للانضمام إلينا في هذا الحدث الرائد لفتح آفاق جديدة وتحقيق الابتكار في عالم التكنولوجيا الحديثة",
        },
        btn: { eng: "Registration", arb: "تسجيل" },
        alertThanke: {
          eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Week. We look forward to enriching your experience and maximizing your benefit",
          arb: "نشكركم على تسجيلكم في منتدى التمكين الرقمي ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
        },
        alertEndWeek: {
          eng: "We would like to inform you that the Digital Empowerment and Artificial Intelligence Week has concluded, and registration is now closed. Thank you for your interest and engagement",
          arb: "نود إبلاغكم بأن أسبوع التمكين الرقمي والذكاء الاصطناعي قد انتهى، والتسجيل مغلق الآن. نشكركم على اهتمامكم وتفاعلكم",
        },
        targeted: { eng: "Targeted", arb: "الفئات المستهدفة" },
      },
      sheikProfile:{
        title: { eng: "", arb:""},
        name:{arb:"الشيخ محمد أحمد الصباح", eng:""}
      }
    },
    stakeholders: {
      member: {
        account: {
          sponsorSetter: {
            title: { arb: "المسؤولية الاجتماعية واجب وطني", eng: "Social Responsibility is a National Duty" },
            sponsorInfo: { arb: "معلومات الجهة الداعمة", eng: "Supporting Organization Information" },
            supportInfo: { arb: "معلومات الدعم", eng: "Support Information" },
            description: {
              eng: `Supporting initiatives aimed at developing local competencies is a national duty. It reflects their role in community development and sustainability. The AAIOT is committed to launching initiatives focused on enhancing local competencies in technology. Supporting these initiatives will enable the association to expand its reach and provide its free training to a larger number of beneficiaries in Kuwait`,
              arb: `إن دعم المبادرات التي تهدف إلى تطوير الكفاءات المحلية يُعد واجب وطني على الشركات والأفراد، ويعكس دورهم في تنمية المجتمع وتعزيز استدامته. تحرص جمعية الذكاء الاصطناعي للأشياء على تقديم مبادرات موجهة نحو تطوير الكفاءات المحلية في مجال التكنولوجيا، بما يساهم في بناء مستقبل واعد وازدهار مستدام. إن دعم هذه المبادرات سيمكن الجمعية من توسيع نطاقها وتقديم أدواتها ودرواتها المجانية لأكبر عدد من المستفيدين في دولة الكويت، مما يعزز الابتكار ويعمق الأثر الإيجابي على الاقتصاد المحلي`,
            },
            contribution: {
              eng: "We appreciate your efforts in developing Kuwait by supporting training initiatives aimed at bridging the digital divide and increasing the number of specialists in designing secure and smart applications",
              arb: "نثمن جهودكم في تطوير دولة الكويت من خلال دعم مبادرات التدريب التي تهدف إلى سد الفجوة الرقمية وزيادة عدد المتخصصين في تصميم التطبيقات الذكية والآمنة",
            },
            thanksContribution: {
              emg: "Your presence and support enhance leadership and development in our community. We express our gratitude and appreciation for your contribution to the development of programmers in Kuwait and government sector managers",
              arb: "إن وجودك ودعمك يعززان الريادة والتطوير في مجتمعنا،  نعبر عن شكرنا وتقديرنا لمساهمتك في تطوير مبرمجي الكويت ومدراء القطاع الحكومي",
            },
            strViewAccountBank: {
              eng: "Please transfer the support amount to the following bank account details of the association",
              arb: "تفاصيل الحساب المصرفي للجمعية الرحاء تحويل المبلغ الدعم إليه",
            },
            logoUpload: {
              eng: "Please provide us with the logo of the supporting organization that will be displayed on the main page of the association's platform, if available",
              arb: "يرجى تزويدنا بشعار الجهة الداعمة الذي سيظهر على الصفحة الرئيسية للمنصة الجمعية، إذا كان متوفرًا",
            },
            receiptUpload: {
              eng: "Please provide the bank receipt after completing the support amount transfer, and we will send you the receipt",
              arb: "يرجى تحميل إيصال البنك بعد إتمام تحويل مبلغ الدعم، وسنرسل لكم سند القبض",
            },
            whatsappUpload: {
              eng: "After completing the support amount transfer, please send the bank receipt via WhatsApp",
              arb: "بعد إتمام تحويل مبلغ الدعم يرجى إرسال إيصال البنك عبر الواتساب ",
            },
            whatsappIsUpload: {
              eng: "The receipt has been sent via WhatsApp",
              arb: "تم إرسال الإيصال عبر الواتساب",
            },
            trainingCustomNumber: { eng: "Trainees", arb: "متدرب" },
            sponserInfo: {
              supportStatus: { eng: "Support status", arb: "حالة الدعم" },
              supportCat: { eng: "Support category", arb: "فئة الدعم" },
              date: { eng: "Support date", arb: "تاريخ الدعم" },
              studentNumber: { eng: "Number of trainees", arb: "عدد المتدربيين" },
              amount: { eng: "Amount", arb: "مقدار الدعم" },
            },
            objStatus: {
              1: { eng: "Under Process", arb: "قيد المعالجة" },
              2: { eng: "Under Process", arb: "قيد المعالجة" },
              3: { eng: "Approved", arb: "تم الموافقة" },
              4: { eng: "Rejected", arb: "تم الرفض" },
            },
            receiptCatch: { eng: "Receipt Voucher", arb: "سند قبض" },
            receipt: { eng: "Payment Voucher", arb: "سند إيصال" },
            support: { eng: "Support", arb: "الدعم" },
            btn: { arb: "إبدأ الدعم", eng: "Start Contributing" },
          },
          traineeSetter: {
            title: { arb: "تدريب هندسة برمجيات مجاني", eng: "Free Fullstack App Development Training" },
            description: {
              eng: `The AAIOT offers comprehensive free training that provides all the necessary skills for developing secure applications that function efficiently and effectively across all browsers and devices. As Kuwait moves towards adopting comprehensive digital empowerment strategies to develop government sectors and improve electronic services, this comprehensive training is a fundamental pillar to ensure the success and sustainability of these strategies`,
              arb: `تقدم جمعية الذكاء الإصطناعي للأشياء تدريب مجاني متكامل يوفر جميع المهارات اللازمة لبناء تطبيقات آمنة تعمل بكفاءة وفاعلية على جميع المتصفحات والأجهزة. ففي ظل توجه الكويت نحو تبني استراتيجيات التمكين الرقمي الشامل لتطوير القطاعات الحكومية وتحسين خدماتها الإلكترونية، يمثل هذا التدريب المتكامل ركيزة أساسية لضمان نجاح واستدامة هذه الاستراتيجيات. فتطوير الكفاءات المحلية ورفع مهاراتها البرمجية يضمن التنفيذ الفعّال للمشاريع التقنية الكبرى، ويوفر الخبرات الضرورية لدعم وتفعيل هذه الاستراتيجيات، بما يسهم في تحقيق الأهداف الوطنية وتعزيز القدرات التنافسية على المدى البعيد`,
            },
            proofUpload: {
              eng: "Please provide a receipt proving that you are among the top of the department, certified by the department head",
              arb: "يرجى تقديم شهادة يُثبت أنك من الأوائل في القسم، معتمدًا من رئيس القسم",
            },
            btn: { arb: "إبدأ التدريب", eng: "Start Training" },
          },
          runningActivity: {
            btnDetails: { arb: "عرض التفاصيل", eng: "View Details" },
            btnEdit: { arb: "تعديل", eng: "Edit" },
            btnReg: { arb: "تسجيل", eng: "Registration" },
          },
          regActivityGuest: {
            title: { eng: "Digital Empowerment and Artificial Intelligence Week", arb: "أُسبوع التمكين الرقمي و الذكاء الإصطناعي" },
            registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
            detailsInfo: {
              eng: "Seminars and Workshops",
              arb: "الندوات وورش العمل",
            },
            detailInfo: {
              eng: "Seminars details",
              arb: "معلومات الندوة",
            },
            subtitle: {
              eng: "Contribute with us to building a prosperous future by nurturing a distinguished generation of leading programmers",
              arb: "ساهم معنا في بناء مستقبل مزدهر من خلال تنمية جيل متميز من المبرمجين المتمكنين",
            },
            dec: {
              eng: "The Digital Transformation and Artificial Intelligence Week in Kuwait will focus on digital transformation strategies and related challenges, as well as explaining artificial intelligence and data protection. It will also showcase modern platforms and tools that enhance work efficiency and develop government applications",
              arb: "أسبوع التحول الرقمي والذكاء الاصطناعي في الكويت سيركز على استراتيجيات التحول الرقمي والتحديات المتعلقة به، فضلاً عن شرح الذكاء الاصطناعي وحماية البيانات. كما سيستعرض المنصات والأدوات الحديثة التي تعزز كفاءة العمل وتطوير التطبيقات الحكومية",
            },
            invite: {
              eng: "We invite you to join us for this leading event to explore new horizons and drive innovation in the world of modern technology",
              arb: "ندعوكم للانضمام إلينا في هذا الحدث الرائد لفتح آفاق جديدة وتحقيق الابتكار في عالم التكنولوجيا الحديثة",
            },
            btn: { eng: "Registration", arb: "تسجيل" },
            alertThanke: {
              eng: "Thank you for registering for the Digital Empowerment and Artificial Intelligence Week. We look forward to enriching your experience and maximizing your benefit",
              arb: "نشكركم على تسجيلكم في منتدى التمكين الرقمي ونتطلع إلى إثراء تجربتكم وتحقيق استفادة قصوى",
            },
            alertEndWeek: {
              eng: "We would like to inform you that the Digital Empowerment and Artificial Intelligence Week has concluded, and registration is now closed. Thank you for your interest and engagement",
              arb: "نود إبلاغكم بأن أسبوع التمكين الرقمي والذكاء الاصطناعي قد انتهى، والتسجيل مغلق الآن. نشكركم على اهتمامكم وتفاعلكم",
            },
            targeted: { eng: "Targeted", arb: "الفئات المستهدفة" },
          },
          services: {
            title: { eng: "Services", arb: "خدمات" },
          },
        },
      },
      shared: {
        account: {
          title: { eng: "Account", arb: "حسابي" },
        },
      },
    },
  },
  shared: {
    app: {
      gov: {
        title: { arb: "قطاع حكومي", eng: "Government sector" },
        targeted: {
          eng: "For Leadership or Supervisory in Information Systems / Employee in Information Systems",
          arb: "الفئه: قيادي او إشرافي في مجال نظم المعلومات / موظف في مجال نظم المعلومات",
        },
        currency: { eng: " Kuwaiti Dinar", arb: " دينار كويتي" },
        dinar: { eng: " Dinar", arb: " دينار" },
      },
      prvt: {
        title: { arb: "قطاع خاص", eng: "Private sector" },
        targeted: {
          arb: "الفئة: إداري في مجال نظم المعلومات  /مطور او مهندس برمجيات / اكاديمي او باحث / طالب",
          eng: "For Information Systems Manager / Software Developer or Engineer / Academic or Researcher / Student",
        },
      },
      leader: { eng: "Leadership or supervisory role in the field of information systems", arb: "قيادي او إشرافي في مجال نظم المعلومات" },
      employee: { eng: "Information Systems Specialist", arb: "موظف في مجال نظم المعلومات" },
      manager: { eng: "Information Systems Manager", arb: "إداري في مجال نظم المعلومات" },
      programer: { eng: "Software Developer or Software Engineer", arb: "مطور او مهندس برمجيات" },
      academic: { eng: "Academic or Researcher", arb: "اكاديمي او باحث" },
      student: { eng: "Student", arb: "طالب" },
      sponserLabelCat: {
        main: {
          eng: `Primary Sponsor`,
          arb: `راعي رئيسي`,
        },
        golden: {
          eng: `Golden Sponsors`,
          arb: `راعي ذهبي`,
        },
        silver: {
          eng: `Silver Sponsors`,
          arb: `راعي فضي`,
        },
        bronze: {
          eng: `Bronze Sponsors`,
          arb: `راعي برونزي`,
        },
      },

      currency: { eng: " Kuwaiti Dinar", arb: " دينار كويتي" },
      dinar: { eng: " Dinar", arb: " دينار" },
    },
    buttons: {
      sendBtn: { eng: "Send", arb: "ارسال" },
      submitBtn: { eng: "Submit", arb: "حفظ" },
      readMore: { eng: "Read More", arb: "اقرأ المزيد" },
      save: { eng: "Save", arb: "حفظ" },
      send: { eng: "Send", arb: "ارسال" },
      submit: { eng: "Submit", arb: "ارسال" },
      partnerInfo: { eng: "Partner Information ", arb: "معلومات المسؤول" },

      view: { eng: "View", arb: "عرض" },

      viewGovID: { eng: "View Government ID", arb: "عرض الهوية الشخصية" },
      viewRest: { eng: "View Bank receipt", arb: "عرض الوصل البنكي" },
      rest: { eng: "Rest", arb: "إلغاء الفلتر" },

      approve: { eng: "Approve", arb: "موافقة" },
      regect: { eng: "Reject", arb: "رفض" },
      resubmission: { eng: "Resubmission required files", arb: "إعادة تقديم الملفات المطلوبة" },
      addBtn: { eng: "Add", arb: "إضافة" },
      editBtn: { eng: "Edit", arb: "تعديل" },
      closeBtn: { eng: "Close", arb: "إغلاق" },
      otherBtn: { eng: "Other", arb: "أخرى" },
      update: { eng: "Update", arb: "تحديث" },
    },
    alertFetchNote: {
      confirmedPartner: {
        eng: "Are you sure the logo has been uploaded and the required fee has been paid",
        arb: "هل أنت متأكد من أن تم رفع الشعار ودفع الرسوم المطلوبة",
      },
      response_Not_Except: {
        eng: "An error has occurred, please use valid information and try again. If you keep getting the same error notification, please contact us via email",
        arb: "حدث خطأ ، يرجى استخدام المعلومات الصحيحة والمحاولة مرة أخرى. إذا استمر ظهور نفس رسالة الخطأ ، فيرجى الاتصال بنا عبر البريد الإلكتروني",
      },
      updated_Successfully: {
        eng: "Thank you for contacting us.  Your request has been received successfully, and we will contact you shortly",
        arb: "شكرا لإهتمامك. لقد تم إستلام طلبك بنجاح و سيتم التواصل معك قريبا",
      },
      updated_Successfully2: {
        eng: "Updated Successfully",
        arb: "تم التحديث بنجاح",
      },
      deleteded_Successfully: {
        eng: "Deleteded Successfully",
        arb: "تم الحذف بنجاح",
      },
      added_Successfully: {
        eng: "Added Successfully",
        arb: "اضيف بنجاح",
      },
      verificationCode: {
        eng: "The verification code is incorrect. Please verify your email and enter it accurately",
        arb: "الرمز التحقق غير صحيح، يرجى التحقق من البريد الإلكتروني وإعادة إدخاله بدقة",
      },

      added_Sponser_Successfully: {
        eng: "The sponsor has been successfully added",
        arb: "لقد تم إضافة الجهة الداعمة بننجاح",
      },
      submit_Successfully: {
        eng: "Your request has been successfully received, and we are currently reviewing it",
        arb: "لقد تم استلام طلبك بنجاح، ونحن الآن نعمل على مراجعته",
      },
      submit_Contact_Successfully: {
        eng: "We would like to thank you for reaching out to us, and we assure you that we will make every effort to respond to you at the earliest opportunity",
        arb: "نود أن نشكرك على اتصالك بنا، ونؤكد لك أننا سنبذل قصارى جهدنا للرد عليك في أقرب فرصة ممكنة",
      },
      cant_Update_Record: {
        eng: "Sorry, the update cannot be completed at this time. Please try again later",
        arb: "عذراً ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      cant_Delete_Record: {
        eng: "Sorry, the delete cannot be completed at this time. Please try again later",
        arb: "عذراً ، لا يمكن حذف المعلومات في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      cant_Save_Record: {
        eng: "Sorry, can't save your information at this time. Please try again later",
        arb: "عذراً ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقاً",
      },
      no_Data_To_Display: {
        eng: "Sorry, but there is no data to display yet",
        arb: "عذراً ، لا توجد أي بيانات لعرضها حتى الآن",
      },
      fetchData_InProgress: {
        eng: "Your request has been successfully submitted and is being processed... please wait",
        arb: "تم تقديم طلبك بنجاح وتتم معالجته ... يرجى الإنتظار",
      },
      invalidLoginInfo: {
        eng: "Invalid credentials. Please try again",
        arb: "بيانات الدخول غير صحيحه. يرجى المحاوله مرة أخرى",
      },
      already_Registered: {
        eng: "You have already registered using this email address",
        arb: "لقد قمت بالتسجيل بالفعل باستخدام عنوان البريد الإلكتروني هذا",
      },
      already_subscrbtion: {
        eng: "You have already subscribed using this email address",
        arb: "لقد قمت بالإشتراك من قبل باستخدام هذا البريد الإلكتروني ",
      },
      email_Already_Used: {
        eng: "This email has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا البريد الإلكتروني من قبل. الرجاء ادخال بريد الإلكتروني جديد",
      },
      phone_Already_Used: {
        eng: "This number has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا الرقم قبل. الرجاء ادخال رقم هاتف جديد",
      },
      "strUserPhone already used by another users!": {
        eng: "This number has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا الرقم قبل. الرجاء ادخال رقم هاتف جديد",
      },
      "strUserEmail already used by another users!": {
        eng: "This email has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا البريد الإلكتروني من قبل. الرجاء ادخال بريد الإلكتروني جديد",
      },
      "strUsername already used by another users!": {
        eng: "This username has been used before. Please enter a new one.",
        arb: "لقد تم استخدام  هذا إسم المستخد من قبل. الرجاء ادخال إسم مستخدم جديد ",
      },
      email_Not_Used: {
        eng: "This email is not used",
        arb: "هذا البريد الإلكتروني لم يُستخدم من قبل",
      },
      Already_Jion: {
        eng: "We have previously registered as a programmer development supporter. For any inquiries, please contact us",
        arb: "تم التسجيل كداعم لتطوير المبرمجين من قبل. لأي استفسار يرجى التواصل معنا",
      },
      email_CantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذراً ، لا يمكنني إرسال بريدك الإلكتروني في الوقت الحالي. الرجاء إعادة المحاولة لاحقاً",
      },
      exceed_Limit_Of_Email: {
        eng: "Thank you. we received your email",
        arb: "شكرا لك ، لقد تلقينا بريدك الإلكتروني",
      },
      requeioUploadFile: {
        eng: "Please make sure upload required files",
        arb: "يرجى التأكد من تحميل الملفات المطلوبة",
      },
      requeioUploadImage: {
        eng: "Please make sure upload main image",
        arb: "يرجى التأكد من تحميل الصورة الرئيسية",
      },
      invalid_Field: {
        eng: "Please make sure all required fields are filled out correctly",
        arb: "يرجى التأكد من ادخال جميع الحقول المطلوبة بشكل صحيح",
      },
      invalid_Field_Email: {
        eng: "Please enter your email address",
        arb: "الرجاء إدخال بريدك الإلكتروني",
      },
      invalid_Field_Email_phone: {
        eng: "Please enter either your phone number or email address",
        arb: "الرجاء إدخال إما رقم الهاتف أو عنوان البريد الإلكتروني",
      },
      cantFindRecords: {
        eng: "Sorry, But there nothing to view",
        arb: "عذراً ، ولكن لا يوجد شيء لعرضه",
      },
      cantUploadFile: {
        eng: "Sorry, But Can't upload file at this time please try again later",
        arb: "عذراً ، ولكن لا يمكن تحميل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      cantDownloadFile: {
        eng: "Sorry, But Can't download file at this time please try again later",
        arb: "عذراً ، ولكن لا يمكن تنزيل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      contactUs: {
        eng: "تم ارسال طلبك، سيتم التواصل معك في اقرب وقت",
        arb: "Your request has been sent. We will contact you as soon as possible",
      },
      emailVerificationSent: { eng: "Verification code sent to your inbox", arb: "تم إرسال رمز التحقق إلى صندوق الوارد الخاص بك" },
      emailCantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذرا، لا يمكن الإرسال للبريد الإلكتروني الخاص بك في هذا الوقت. الرجاء معاودة المحاولة في وقت لاحق",
      },
      uploadedVideo: { eng: "Please upload the desired video for publication", arb: "الرجاء رفع الفيديو المطلوب للنشر" },
    },
  },
};
