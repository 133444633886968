import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    avatar: `${styleVariables?.prefixDefaultClassName}_avatar`,
    avatar_contain: `${styleVariables?.prefixDefaultClassName}_avatar_contain`,
    shape_circle: `${styleVariables?.prefixDefaultClassName}_shape_circle`,
    sx:`${styleVariables?.prefixDefaultClassName}_avatar_sx`
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.avatar]: {
        display: cssObject?.display?.[cssKeys?.block],
        position: cssObject.position?.[cssKeys?.relative],
        margin: cssObject.spacing?.[cssKeys?.auto],
        padding: cssObject.spacing?.[cssKeys?.auto],
      },
      [classesName?.avatar_contain]: {
        display: cssObject?.display?.flex,
        width: cssObject?.size?.full,
        height: cssObject?.size?.full,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        margin: cssObject.spacing?.auto,
        padding: cssObject.spacing?.auto,
        position: cssObject.position?.absolute,
        top: "0",
        left: "0",
        zIndex: "var(--cbmis-z-index-1)",
        color: cssObject?.color?.currentColor,
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName.sx]: (props) => {
        const obj ={
          color: cssObject?.color?.[cssKeys?.bodyColorBgDark],
          width: cssObject.size?.[cssKeys?.item_lg],
          height: cssObject.size?.[cssKeys?.item_lg],
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle2],
          backgroundColor: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.bodyColorBgDark]),
          ...props?.sx
        }
        if(props?.src){
          obj.backgroundImage= `url(${props?.src})`
          obj.backgroundPosition= "center"
          obj.backgroundSize= "100% 100%"
          obj.backgroundRepeat= "no-repeat"
        }
        return {
          ...getAvatarMode(props?.mode),
          ...obj
        };
      },
    },
 });


 const getAvatarMode = (mode) => {
    switch (String(mode)?.toLowerCase()) {
      default:
        return {

          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.pill,
              WebkitBorderRadius: cssObject?.border?.[cssKeys?.rounded]?.pill,
              MozBorderRadius: cssObject?.border?.[cssKeys?.rounded]?.pill,
        };
    }
  };

  return{
    classesName,
    style,
    styleSx,
  }
}