import { StyleBuilder, cssKeys, mainTheme, styleFunction, styleVariables } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    menu_container: `${styleVariables?.prefixDefaultClassName}_menu_container`,
    menu: `${styleVariables?.prefixDefaultClassName}_menu`,
    menu_open: `${styleVariables?.prefixDefaultClassName}_menu_open`,
    body_back_menu_open: `${styleVariables?.prefixDefaultClassName}_body_back_menu_open`,
    menu_bg_dialog_open: `${styleVariables?.prefixDefaultClassName}_menu_bg_dialog_open`,

    menu_item: `${styleVariables?.prefixDefaultClassName}_menu_item`,
    menu_item_selected: `${styleVariables?.prefixDefaultClassName}_menu_item_selected`,
    menu_item_outlined: `${styleVariables?.prefixDefaultClassName}_menu_item_outlined`,
    menu_item_head_content: `${styleVariables?.prefixDefaultClassName}_menu_item_head_content`,
    menu_item_head: `${styleVariables?.prefixDefaultClassName}_menu_item_head`,
    menu_item_item_content: `${styleVariables?.prefixDefaultClassName}_menu_item_item_content`,
    menu_item_footer: `${styleVariables?.prefixDefaultClassName}_menu_item_footer`,

    sx: `${styleVariables?.prefixDefaultClassName}_menu_sx`,
    sxItem: `${styleVariables?.prefixDefaultClassName}_menu_item_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.menu_container]: {
        position: "relative",
        left: 0,
        right: 0,
        height: "max-content",
        display: "block",
      },
      [classesName?.menu]: {
        // textAlign: "start",
        // transition: `${currentBaseSize}s linear`,
        // minWidth: "max-content",
        // width: cssObject?.size?.[cssKeys?.full],
        // position: "absolute",
        // display: "-webkit-fill-available",
        // top:0,

        listStyle: "none",
        position: "fixed",
        display: "none",
        flexDirection: "row",
        justifyContent: "center",
        /* align-content: center, */
        flexWrap: "wrap",
        overflow: "auto",
        margin: "0",
        padding: "0",
        zIndex: "-1 !important",
        transition: "height .5s",
        // maxHeight: `${currentBaseSize * 110}rem`,
      },
      [classesName?.menu_item]: {
        width: "90%",
        margin: "auto",
        display: cssObject?.display?.[cssKeys?.flex],
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        "&hover": {
          background: cssObject?.color?.[cssKeys?.bodyColorLight],
          cursor: "pointer",
        },
      },
      [classesName?.menu_item_head]: {
        justifyContent: "center",
        textAlign: "center",
        // padding:cssObject?.spacing?.[cssKeys?.xs],
        background: "transparent",
        display: "flex",
        width: "maxContent",
        margin: cssObject?.spacing?.[cssKeys?.xs],
      },
      [classesName?.menu_item_item_content]: {
        textAlign: "start",
        background: "transparent",
        // padding:cssObject?.spacing?.[cssKeys?.xs],
        display: "flex",
        alignItems: "center",
        minWidth: "75%",
        // width: "100%",
        justifyContent: "flex-start",
      },
      [classesName?.menu_item_footer]: {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "maxContent",
        margin: cssObject?.spacing?.[cssKeys?.xs],
        background: "transparent",
      },
    },
  });
  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.menu_container]: (props) => {
        return {
          minWidth: props?.blnFullWidth ? "135px" :"1px",
          width: props?.blnFullWidth ? "100%" : "maxContent",
        };
      },
      [classesName?.menu]: (props) => {
        return {
          background: styleFunction.checkColorReturnValue(cssObject?.color?.bodyColorBgLight),
          color: styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor),
          fontSize: cssObject?.text?.size?.body2,
          height: 0,
          boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.sm,
          
        };
      },
      [classesName?.body_back_menu_open]: (props) => {
        return {
          "&>": {
            "&*": {
              zIndex: "-1 !important",
            },
          },
        };
      },
      [classesName?.menu_open]: (props) => {
        return {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          alignContent: "stretch",
          height: "min-content",
          zIndex: "1100 !important",
          minWidth:`${currentBaseSize * 25}rem`,
          width: "min-content",
          maxWidth: "100%",
          // maxHeight: `${currentBaseSize * props?.itemNumber * 100}rem`,
          pointerEvents: "auto !important",

          "&>": {
            "&*": {
              zIndex: "1110 !important",
              // pointerEvents: "auto !important",
            },
          },
        };
      },
      [classesName?.menu_bg_dialog_open]: (props) => {
        return {
          position: "fixed",
          display: "block",
          width: "100vw",
          top: 0,
          left: 0,
          height: "100vh",
          zIndex: "1000 !important",
        };
      },
      
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  const styleMenuItemSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.menu_item]: (props) => {
        return {
          "&hover":{
            color: cssObject?.color?.[cssKeys?.primary],
            background: props?.onClick ? cssObject?.color?.[`${cssKeys?.primary}_1`]: null,
          }
        };
      },
      [classesName?.sxItem]: (props) => {
        return {
          padding: `0px`,
          ...(props?.selected
            ? {
                color: cssObject?.color?.[cssKeys?.primary],
                background: cssObject?.color?.[cssKeys?.bodyColorLight],
              }
            : {}),
          ...(props?.outlined
            ? {
                borderBottom: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor)}`,
              }
            : {}),
          ...props?.sx,
        };
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
    styleMenuItemSx,
  };
};
