import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import CardStyle from "./CardStyle";

const CardImage = forwardRef((/**@type {any}*/props, ref) => {
  const { src, title, hoverContent, sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName,style, styleCardImageSx} = CardStyle(theTheme);
  const classes = style();
  const classesSx = styleCardImageSx({sx});
  const itemClasses = styleFunction.getClassName(classesName?.card_image, hoverContent ? classes?.[classesName?.card_image_hover] : "", classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  const comID = id ||  String(useId()).slice(1,-1)

  return (
    <div className={itemClasses} ref={ref} style={{ backgroundImage: `url(${src})` }} {...styleFunction?.getRestProps(rest)} title={title} key={comID}>
      <div className={classes?.[classesName?.card_frame_content]}>{props.children}</div>
      <div className={classes?.[classesName?.card_frame]}>
        <span className={classes?.[classesName?.card_frame_bg]}></span>
        <div className={classes?.[classesName?.card_frame_content]}>{hoverContent}</div>
      </div>
    </div>
  );
});

export default CardImage;
