"use client";
import React, { useEffect, useRef, useState } from "react";
import { pkgConfigIcon } from ".";
import IconStyle from "./IconStyle";

function IconBody({ icon, theTheme, comID, size, fontSize }) {
  const refIcon = useRef({ strIconName: null });
  const [dataIcon, setDataIcon] = useState(null);
  const { classesName, styleIconBody, styleSxIconBody } = IconStyle(theTheme);
  const classes = styleIconBody();
  const classesSx = styleSxIconBody({ size , fontSize});

  useEffect(() => {
    const funGetIcon = () => {
      try {
        const iconName = String(icon).toLowerCase().trim();
        if (refIcon?.current?.strIconName === iconName) {
          return;
        }

        return import(`./icons/${iconName}.json`)
          .then((iconSrc) => {
            let parseIcon = JSON.parse(iconSrc?.default || iconSrc);
            let dataIcon = parseIcon?.[iconName];

            refIcon.current.strIconName = iconName;
            setDataIcon(dataIcon);
          })
          .catch((error) => {
            if (pkgConfigIcon?.blnIsTestingMode) {
              console.log(error);
              console.error("Icon ( " + icon + " ) not found.");
            }
            refIcon.current.strIconName = iconName;
            setDataIcon(null);
          });
      } catch (error) {
        if (pkgConfigIcon?.blnIsTestingMode) {
          console.error("Icon ( " + icon + " ) not found.");
        }
        refIcon.current.strIconName = null;
        setDataIcon(null);
      }
    };
    funGetIcon();
    // const icon_container = typeof document !== "undefined" ? document.getElementById(comID) : null;
    // if (icon_container) {
    //   refIcon.current.comSize = window?.getComputedStyle(icon_container)?.getPropertyValue("font-size");
    // }
  }, [icon]);
  return (
    <React.Fragment>
      <svg className={`${classes?.[classesName?.icon]} ${classesSx?.[classesName?.icon]}`} viewBox="0 0 24 24">
        {!dataIcon ? null : (
          <>
            {dataIcon?.["circle"]
              ? dataIcon?.["circle"].map((crl, index) => {
                  return <circle key={index} cx={crl["cx"]} cy={crl["cy"]} r={crl["r"]} />;
                })
              : null}

            {dataIcon?.["path"]
              ? dataIcon?.["path"].map((d, index) => {
                  return <path key={index} d={d} />;
                })
              : null}

            {dataIcon?.["line"]
              ? dataIcon["line"].map((line, index) => {
                  return (
                    <line
                      key={index}
                      x1={line["x1"]}
                      y1={line["y1"]}
                      x2={line["x2"]}
                      y2={line["y2"]}
                      strokeWidth={line["strokeWidth"] ? line["strokeWidth"] : ""}
                      strokeLinecap={line["strokeLinecap"] ? line["strokeLinecap"] : ""}
                      stroke="currentColor"
                    />
                  );
                })
              : null}
          </>
        )}
      </svg>
    </React.Fragment>
  );
}

export default IconBody;
