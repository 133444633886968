import React, { forwardRef, useContext, useEffect, useId, useRef, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import MenuStyle from "./MenuStyle";

const funGetWidthPortionRef = (elementRef) => {
  if (elementRef?.current) {
    const react = elementRef.current.getBoundingClientRect();
    return {
      height: react?.height,
      width: react?.width,
      bottom: react?.bottom,
      top: react?.top,
      right: react?.right,
      left: react?.left,
      x: react?.x,
      y: react?.y,
    };
  }
  return null;
};

const MenuChild = forwardRef((/**@type {any}*/ props, ref) => {
  const { open= false, eventClose, jsxOpenEl = null, blnFullWidth = true, blnCloseOutMenuClick = true, sx, id, hidden, disabled, dir, className, ...rest } = props;
  
  const containerMenuRef = useRef(null);
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = MenuStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, open, blnFullWidth, itemNumber : props?.children?.length || 1 });

  let menu_ID = id || useId().replace(/:/g, "");

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.menu],
    classesSx?.[classesName?.menu],
    classesSx?.[classesName?.menu_open],
    classes?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const funChangeMenuStyle = () => {
    if (open) {
      const menu = document?.getElementById(menu_ID);
      if (menu) {
        const actWidth = menu ? menu?.offsetWidth : 0;
        const react = funGetWidthPortionRef(containerMenuRef);
        menu.style.minWidth = blnFullWidth ? `${react?.width}px` : "max-content";
        menu.style.top = `${react?.top}px`;
        menu.style.height ="max-content";
        menu.style.blockSize= "max-content";
        menu.style.inlineSize= "max-content";
        menu.style.maxHeight =`${window.innerHeight - react?.top -20}px`;
        if (!blnFullWidth) {
          if (react?.right > react?.left) {
            menu.style.right = actWidth + react?.right > window.innerWidth ? `${window.innerWidth - react?.right + 1}px` : `${react?.right - actWidth}px`;
          } else {
            menu.style.left = actWidth + react?.left > window.innerWidth ? `${window.innerWidth - react?.left + 1}px` : `${react?.left - actWidth}px`;
          }
        }
      }
    }
  };

  useEffect(() => {
    funChangeMenuStyle();
  }, [open]);

  useEffect(() => {
    window.addEventListener("resize", funChangeMenuStyle);
    window.addEventListener("scroll", funChangeMenuStyle, { passive: true });
    return () => {
      window.removeEventListener("resize", funChangeMenuStyle);
      window.removeEventListener("scroll", funChangeMenuStyle);
    };
  }, []);

  // const dropdownNode = useClickOutside(() => {
  //   if (eventClose && blnCloseOutMenuClick) {
  //     // eventClose();
  //   }
  // });

  const funClose = () => {
    if (eventClose) {
      eventClose();
    }
  };

  return (
    <React.Fragment>
      {!blnCloseOutMenuClick ? null : <div className={classesSx?.[classesName?.menu_bg_dialog_open]} onClick={funClose} />}
      <div className={classes[classesName?.menu_container]} key={`${menu_ID}_menu_container`} id={`${menu_ID}_menu_container`}>
        <div className={`${classes?.[classesName?.menu_container]} ${classesSx?.[classesName?.menu_container]}`} ref={containerMenuRef} key={`${menu_ID}_main_menu_container`}>
          {jsxOpenEl ? jsxOpenEl : null}
          <div className={itemClasses} ref={ref} key={`${menu_ID}_menu`} id={menu_ID}>
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

const Menu = forwardRef((/**@type {any}*/ props, ref) => {
  const { open, ...rest } = props;

  return !open ? null : <MenuChild ref={ref} open={open} {...rest} />;
});

export default Menu;
