import { App_Setting_Mode as App_Setting_Mode_New } from "../../client/configuration/appVariables";
import { dictionary } from "./appLanguage/dictionary";

export const App_Name = "aaiotApp";
export const App_ID = 7253851520;

export const cbmisHost = "https://www.cbmis.com/";

//=============================== Must be updated with any new changes ===============================================================

export const App_Setting_Mode = App_Setting_Mode_New;//"testing"; // production | testing | localhost
const App_Version_Date = "11.09.2024 6PM"; // day.month.year Hour Am/PM
// aaiot_productionBuild_11.09.2024 6PM
// aaiot_testingBuild_11.09.2024 6PM
// aaiot_clientCode_11.09.2024 6PM
//====================================================================================================================================

export const App_Version = (App_Setting_Mode === "production" ? "P" : "T") + App_Version_Date;

export const aaiot_System_ID = 2560918876;
export const Cat_Root_ID = 0;

export var App_IsInTestingMode = false;

export var App_Server_Url = "https://www.cbmisdb.link/";
export var App_Server_Url_DownloadFiles = "https://tms-cbmis-production.s3.eu-central-1.amazonaws.com/";

export const App_LocalStorage_Name = "aaoitSystemLocalStorageState";
export var App_LocalStorage_Encrypted_KEY = "2024";
export var App_LocalStorage_Is_Encrypted = true;

//#region hooks time
export var App_Login_HoursToCheck = 1 / 60;
export var System_Info_HoursToCheck = 1 / 60;
export var System_Info_Count_HoursToCheck = 5 / 60;
export var Cat_Info_HoursToCheck = 2;
//#endregion
if (App_Setting_Mode.toLowerCase() === "testing") {
  App_Server_Url = "https://www.cbmistesting.link/"; //"https://node.louayserver.click/" // "https://www.cbmistesting.link/"
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  App_Login_HoursToCheck = 1 / 60;
  System_Info_HoursToCheck = 1 / 60;
  Cat_Info_HoursToCheck = 2;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
} else if (App_Setting_Mode.toLowerCase() === "localhost") {
  App_Server_Url = "http://localhost:4000/";
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  System_Info_HoursToCheck = 0;
  Cat_Info_HoursToCheck = 0;
  App_Login_HoursToCheck = 0;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
}

export var App_Server_Url_GraphQL = App_Server_Url + "aaiotapp/graphql";
export var App_Server_Url_UploadFiles = App_Server_Url + "aaiotapp/upload/s3";
export var App_Server_Url_SendEmail = App_Server_Url + "aaiotapp/sendemail";


export const Role_Public = 3553430577;
export const objUserRole = {
  "Owner": 1764,
  "Admin": 6517,
  "Employee": 1649,
  "Member": 4137,
  "Entrepreneur": 9561,
  "Sponsor": 7125,
  "Student": 7457,
  "Itc-Owner": 1742,
  "Itc-Admin": 7840,
  "Itc-Student": 7914,
  "Marketer": 8757,
  "RootAdmin": 7153,
};
export const objUserStatus = {
  Approved: 2085578067,
  Rejected: 8715548754,
  Applicant: 2535501102,
};

export const objUploadType = {
  aaiot: { fileType: "aaiot", id: "aaiot", sizeMb: 75, accept: "FILE" },
  video: { fileType: "aaiot", id: "video", sizeMb: 120, accept: "video" },
  announcement: { id: "announcement", fileType: "itc-announcement", sizeMb: 30, accept: "FILE" },

  payment: "payment",
  activates: "activates",
  profile: "profile",
};

export var App_Download_URL = "https://static-media-project.s3.eu-central-1.amazonaws.com/private-office-kuwait/";

export const objMailType = {
  Contact_US: 5610433647,
  Enrollment: 6609632190,
};

export const objMailPurpose = {
  Contact_US: {
    Complaint: 2617277961,
    Suggestion: 1356313836,
    Inquiry: 2723785096,
  },
  Enrollment: {
    Enrollment_Request: 1641651315,
    Partnership_Request: 3329688095,
    Investment_Request: 3706261087,
  },
};

export const regulations = {
  8745693148: "main",
  7469213148: "financial",
  3652147895: "internal",
};

export const regulationsIDs = {
  main: 8745693148,
  financial: 7469213148,
  internal: 3652147895,
};

export const globalKey = "965+";
export const globalKeyZero = "00965";
export const counter = {
  1: "الأول",
  2: "الثاني",
  3: "الثالث",
  4: "الرابع",
  5: "الخامس",
  6: "السادس",
  7: "السابع",
  8: "الثامن",
  9: "التاسع",
  10: "العاشر",
};

export const memberWorkType = {
  1597536482: "student",
  1597534782: "notEmloyee",
  9517538246: "professor",
  7531598462: "govEmployee",
  3579512684: "prvtEmployee",
};

export const memberWorkTypeID = {
  student: 1597536482,
  professor: 9517538246,
  govEmployee: 7531598462,
  prvtEmployee: 3579512684,
  notEmloyee: 1597534782,
};

export const memberType = {
  8524569173: "founder",
  2586547319: "worker",
  4568521973: "associate",
  6542587391: "honorary",
};

export const memberTypeID = {
  founder: 8524569173,
  worker: 2586547319,
  associate: 4568521973,
  honorary: 6542587391,
};

export const arrMemberOption = [
  { key: 1, value: dictionary?.components?.public?.landingPage?.members.allMember },
  { key: memberWorkTypeID.professor, value: dictionary?.components?.public?.landingPage?.members.professor },
  { key: memberWorkTypeID.student, value: dictionary?.components?.public?.landingPage?.members.student },
  { key: memberWorkTypeID.notEmloyee, value: dictionary?.components?.public?.landingPage?.members.notEmployee },
  { key: memberWorkTypeID.govEmployee, value: dictionary?.components?.public?.landingPage?.members.govEmployee },
  { key: memberWorkTypeID.prvtEmployee, value: dictionary?.components?.public?.landingPage?.members.prvtEmployee },
];
export const arrMemberFormReqOption = [
  { key: memberWorkTypeID.govEmployee, value: dictionary?.components?.public?.landingPage?.members.govEmployee },
  { key: memberWorkTypeID.professor, value: dictionary?.components?.public?.landingPage?.members.professor },
  { key: memberWorkTypeID.prvtEmployee, value: dictionary?.components?.public?.landingPage?.members.prvtEmployee },
  { key: memberWorkTypeID.student, value: dictionary?.components?.public?.landingPage?.members.student },
  { key: memberWorkTypeID.notEmloyee, value: dictionary?.components?.public?.landingPage?.members.notEmployee },
];

export const nationalityID = {
  kuwaiti: 7026548765,
  notKuwaiti: 9806239387,
};
export const nationalityIDlabel = {
  7026548765: dictionary?.shared?.account?.country?.kwt,
  9806239387: dictionary?.shared?.account?.country?.notKwt,
};
export const genderID = {
  male: 3691478524,
  female: 7413698526,
};
export const genderIDLabel = {
  3691478524: dictionary?.shared?.account?.gender?.male,
  7413698526: dictionary?.shared?.account?.gender?.female,
};

export const degreeID = {
  bachelor: 1597536471,
  masters: 9517533254,
  doctorate: 7531597413,
  diploma: 1597539614,
};
export const degreeIDlabel = {
  1597536471: dictionary?.shared?.account?.degreey.bachelor,
  1597539614: dictionary?.shared?.account?.degreey?.diploma,
  9517533254: dictionary?.shared?.account?.degreey?.masters,
  7531597413: dictionary?.shared?.account?.degreey?.doctorate,
};
export const employmentStatusID = {
  student: 9324213119,
  professor: 1773983922,
  govEmployee: 5460695364,
  prvtEmployee: 1376840499,
  notEmployee: 6010974356,
};
export const employmentStatusIDlabel = {
  9324213119: dictionary?.components?.public?.landingPage?.members?.student,
  1773983922: dictionary?.components?.public?.landingPage?.members?.professor,
  5460695364: dictionary?.components?.public?.landingPage?.members?.govEmployee,
  1376840499: dictionary?.components?.public?.landingPage?.members?.prvtEmployee,
  6010974356: dictionary?.components?.public?.landingPage?.members?.notEmployee,
};

export const workSectorID = {
  academic: 1773983922,
  gov: 5460695364,
  prvt: 1376840499,
};
export const workSectorIDLabel = {
  1773983922: dictionary?.components?.joinSymposium?.academic,
  5460695364: dictionary?.components?.joinSymposium?.gov,
  1376840499: dictionary?.components?.joinSymposium?.prvt,
};
export const requirementFlow = {
  noRequirementInfo: 3585052244,
  requirementInfoDuringReg: 7431123385,
  requirementInfoAfterReg: 6115971999,
};
export const requirementContent = {
  note: 1296789939,
  bankAccount: 1514769077,
  govID: 714254094,
  amount: 9653145607,
  reset: 5053975536,
};

export const objContactReasonArbOptions = [
  { key: 9455559299, value: "استفسار" },
  { key: 5395152267, value: "شكوى" },
  { key: 6592151268, value: "تحسين" },
  { key: 3941794175, value: "دعم" },
  { key: 4831748730, value: "أخرى" },
];

export const objContactReasonOptions={
  support:3941794175,
  inquiry:9455559299,
  complaint:5395152267,
  improvement:6592151268, 
  other:4831748730,
}
export const newMember = "newmembers";

export const objSupportTransStatus = {
  Send_New: 1,
  Under_Process: 2,
  Approved: 3,
  Rejected: 4,
};


export const objSponserCat ={
  main:{key:"main", number:100},
  golden:{key:"golden", number:50},
  silver:{key:"silver", number:25},
  bronze:{key:"bronze", number:10}
}

export const objTrainingToSupport ={
  fullstack:{id:1, key:"fullstack"},
  manager:{id:2, key:"it-managers"},
  manager_fullstack: { id: 3, key: "manager_fullstack" },
}

export const objPotentialSponsorshipStatus = {
  Suggested: 1811872881,
  Submission: 8583939227,
  Follow_Up: 9877328668,
  Approved: 1728847199,
  Rejected: 7811872881,

};

export const objPartnerStatus = {
  New: 3819315825,
  Approved: 4394019674,
  Rejected: 1124393711,  
};

