import React from "react";
import { pkgConfigMaterial } from "../../../";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Changing the state to true if some error occurs
    if (pkgConfigMaterial?.blnIsTestingMode) {
      return {
        error: true,
      };
    }
  }

  render() {
    if (this.state.error && pkgConfigMaterial?.blnIsTestingMode) {
      return (
        <div style={{ color: "red", pointerEvents: "none" }} aria-disabled={this.state.error}>
          Oops! Something is wrong.
        </div>
      );
    }
    return this?.props?.children;
  }
}
