import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";

export const objStyle =(lang)=> makeStyle({
  secDark: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "94.5vh",
    margin: "auto",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
  },
  mainContainer: {
    width: `${(11 / 12) * 100}%`,
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs:{
      width: `${(11 / 12) * 100}%`,
    }
  },
  field: {
    fieldset: {
      borderRadius: 0,
    },
    input: {
      color: appStyle.App_Input_Text_Color,
      ...appStyle?.fontsMada,
    },
    "label":{
      ...appStyle?.fontsMada,
    },
    ".uicore_menu":{
      background: appStyle?.App_Primary_Opacity_Color,
      border: "1px solid",
      borderColor: appStyle?.App_info_Color,
      borderRadius: 0,
      color: appStyle?.App_Light_Text_Color,
      ...appStyle?.fontsMada,
      ".uicore_radio_opt_label":{
        "&hover":{
          background: appStyle?.App_Second_Opacity_Color,
        }
      }
    }
  },
  bgBox: {
    zIndex: 10,
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../util/assets/images/box1-v.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform:lang==="arb"?"rotateY(0deg)": "rotateY(180deg)"
    },
  },
  subtitle: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 5px 20px 0",
    padding: "25px",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      minWidth: "210px",
      // minHeight: "50px",
      position: "absolute",
      top: "0",
      background: `url(${require("../../../util/assets/images/gif1.gif")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      margin: "10px",
      transform:lang==="arb"?"rotateY(180deg)": "rotateY(0deg)"

    },
  },
  subtitleLabel: {
    color: appStyle.App_info_Color,
    position: "relative",
    right: "35px",
    left: "35px",
    padding: "5px 20px",
    ...appStyle?.fontsHandjet,
    fontWeight: "500",
  },
  mainTitle: {
    color: appStyle.App_info_Color,
    position: "relative",
    margin: "20px auto",
    ...appStyle.fontsChanga,
    textAlign: "center",
    fontWeight: "600",
    lineHeight: "100%",
  },
  labelsLight: {
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
  },
  labelsInfo: {
    color: appStyle?.App_info_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
  },
  line: {
    width: "50%",
    padding: "20px 0 10px",
    color: appStyle?.App_info_Color,
  },
  btn: {
    // clipPath: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    fontSize: "x-large",
    lineHeight: "40px",
    "span":{
      ...appStyle?.fontsHandjet,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
    },
  },
  anmition: {
    position: "absolute",
    bottom: "0",
    right: "0",
    overflow: "hidden",
    height: "200px",
    width: "100%",
    direction: "rtl",
  },
  anmitiongif: {
    position: "relative",
    display: "block",
    height: "350px",
    width: "350px",
    margin: "0",
    padding: "0",
    background: `url(${require("../../../util/assets/images/gif2.gif")})`,
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    backgroundSize: "contain !important",
    opacity: ".2",
    overflow: "hidden",
    backdropFilter: "blur(100px)",
  },

  modal:{
    backgroundColor: appStyle.App_Dark_Color,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    minHeight: "25%",
    width: "90%",
  },
  modalHeader:{
    // width: "85%",
    border: "none",
    color: appStyle.App_info_Color,
    margin: "auto",
    maxHeight: "10px",
    ".uicore_icon":{
      width:"25px",
      height:"25px"
    }
    // borderColor: appStyle.App_info_Color
  },
  modalBody:{
    // width: "80%",
    // border: "none",
    // color: appStyle.App_info_Color,
    // margin: "auto",
    // maxHeight: "500px",
    // overflowY: "auto",
  },
  imgModal:{
    maxWidth: "100%",
    borderRadius: "1rem"
  }
});
