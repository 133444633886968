import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../../util/appStyle";

export const objStyle = (lang) =>
  makeStyle({
    secDark: {
      position: "relative",
      display: "block",
      width: "100%",
      minHeight: "94.5vh",
      margin: "auto",
      backgroundColor: "rgb(0, 2, 36)",
      background: "linear-gradient(155deg, #000224 0%, #010219 50%, #0371a0 100%)",
    },
    mainContainer: {
      width: `${(11 / 12) * 100}%`,
      xl: {
        width: `${(9 / 12) * 100}%`,
      },
    },
    line: {
      width: "50%",
      padding: "20px 0 10px",
      color: appStyle?.App_info_Color,
    },
    anmition: {
      position: "absolute",
      bottom: "0",
      right: "0",
      overflow: "hidden",
      height: "200px",
      width: "100%",
      direction: "rtl",
    },
    anmitiongif: {
      position: "relative",
      display: "block",
      height: "350px",
      width: "350px",
      margin: "0",
      padding: "0",
      background: `url(${require("../../../../util/assets/images/gif2.gif")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      opacity: ".2",
      overflow: "hidden",
      backdropFilter: "blur(100px)",
    },
    field: {
      fieldset: {
        borderRadius: 0,
      },
      input: {
        color: appStyle.App_Input_Text_Color,
        ...appStyle?.fontsMada,
      },
      label: {
        ...appStyle?.fontsMada,
      },
      ".uicore_switch_label_1gtyshu":{
        color: appStyle?.App_Light_Text_Color,
      },
      ".uicore_menu": {
        background: appStyle?.App_Dark_Color,
        border: "1px solid",
        borderColor: appStyle?.App_info_Color,
        borderRadius: 0,
        color: appStyle?.App_Light_Text_Color,
        ...appStyle?.fontsMada,
        ".uicore_radio_opt_label": {
          "&hover": {
            background: appStyle?.App_Dark_menu_Color,
          },
        },
      },
    },
    bgBox: {
      height: "100%",
      padding: "20px",
      zIndex: 10,
      boxShadow: "none",
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        background: `url(${require("../../../../util/assets/images/box1-h.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
      },
    },
    bgBox2: {
      height: "100%",
      padding: "20px",
      zIndex: 10,
      boxShadow: "none",
      overflow: "hidden",
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        background: `url(${require("../../../../util/assets/images/box3.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
      },
    },
    btnBox: {
      fontSize: "large",
      borderWidth: "1px",
      span: {
        ...appStyle?.fontsMada,
      },
      "&hover": {
        background: appStyle.App_Second_Opacity_Color,
        color: appStyle.App_Light_Text_Color,
      },
    },
    btn: {
      span: {
        ...appStyle?.fontsMada,
      },
    },
    subtitle: {
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 5px 20px 0",
      padding: "25px",
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        minWidth: "210px",
        // minHeight: "50px",
        position: "absolute",
        top: "0",
        background: `url(${require("../../../../util/assets/images/gif1.gif")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        margin: "10px",
        transform: lang === "arb" ? "rotateY(180deg)" : "rotateY(0deg)",
      },
    },
    subtitleLabel: {
      color: appStyle.App_info_Color,
      position: "relative",
      right: "35px",
      left: "35px",
      padding: "5px 20px",
      ...appStyle?.fontsHandjet,
      fontWeight: "500",
    },
    mainTitle: {
      color: appStyle.App_info_Color,
      position: "relative",
      margin: "10px auto 0px",
      ...appStyle.fontsChanga,
      textAlign: "center",
      fontWeight: "600",
      xs: {
        padding: "22px 0 0",
      },
    },
    descriptionLightText: {
      lineClamp: 4,
      boxOrient: "vertical",
      WebkitLineClamp: 4,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      display: "-webkit-box",
      width: "100%",
      color: appStyle?.App_Light_Text_Color,
      ...appStyle?.fontsMada,
      textAlign: "justify",
      "&hover": {
        overflow: "visible",
        WebkitLineClamp: "unset",
        WebkitBoxOrient: "initial",
        xs: {
          padding: "0 0 20px 0",
        },
      },
    },
    labelModal: {
      ...appStyle.fontsChanga,
      textAlign: "justify",
    },
    modal: {
      backgroundColor: appStyle.App_Dark_Color,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      minHeight: "25%",
      color: appStyle.App_Light_Text_Color,
      padding: "20px",
      sm: {
        width: "88%",
      },
    },
    modalHeader: {
      border: "none",
      color: appStyle.App_info_Color,
      margin: "auto",
      maxHeight: "10px",
      ".uicore_icon": {
        width: "25px",
        height: "25px",
      },
    },
    robotImg: {
      height: "90%",
      position: "absolute",
      top: "5%",
      marginInlineStart: "90%",
      xs: {
        display: "none",
      },
      sm: {
        marginInlineStart: "80%",
      },
      md: {
        marginInlineStart: "85%",
      },
    },
    imgLine: {
      display: "block",
      margin: "10px auto 0",
      width: "90%",
    },

    card: {
      position: "relative",
      display: "block",
      height: "100%",
      width: "100%",
      minHeight: "260px",
      maxHeight: "260px",
      maxWidth: "260px",
      boxShadow: "none",
      cursor: "pointer",
      zIndex: 10,
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        background: `url(${require("../../../../util/assets/images/cir2.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
      },
      "&hover": {
        ".cardDescriptionHoverd": {
          height: "70%",
          width: "75%",
          padding: "1.5rem 1rem",
          overflowY: "auto",
        },
      },
    },
    cardContiner: {
      height: "100%",
      alignContent: "center",
    },
    cardTitle: {
      color: appStyle?.App_info_Color,
      ...appStyle?.fontsHandjet,
      textAlign: "center",
      width: "98%",
    },
    cardDescription: {
      // padding: "0 2em 2em",
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      alignContent: "center",
      ...appStyle?.fontsMada,
      textAlign: "justify",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      background: appStyle?.App_Second_Opacity_Color,
      zIndex: 11,
      overflow: "hidden",
      height: "0%",
      width: "0%",
      padding: "0rem",
      transition: "width 1s",
      color: appStyle?.App_Dark_Text_Color,
      borderRadius: "1rem",
    },
    icon: {
      zIndex: 12,
      opacity: 0.8,
      borderRadius: "5px",
      color: appStyle?.App_info_Color,
      "&hover": {
        background: appStyle?.App_info_Color,
        color: appStyle?.App_Dark_Color,
        opacity: 1,
      },
    },
    textareBankInfo: {
      borderColor: `${appStyle?.App_info_Color} !important`,
      color: `${appStyle?.App_info_Color} !important`,
      background: appStyle?.App_info_Opacity_Color,
    },
    partnerCat:{
      border: "1px solid",
      borderColor: `${appStyle?.App_info_Color} !important`,
      color: `${appStyle?.App_info_Color} !important`,
      borderRadius: "1rem",
      paddingInline:"1rem" 
    },
    tableRow:{
      backgroundColor: `${appStyle?.App_info_Opacity_Color} !important`,
      color: appStyle.App_info_Color,
    },
    tblBodyRow:{
      color: appStyle.App_Light_Text_Color,
      background: "transparent !important"
    }
  });
