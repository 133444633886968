import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { App_Server_Url_DownloadFiles, objSponserCat, objSupportTransStatus, objUploadType, workSectorID } from "../../../../../../configuration/appVariables";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography, TextArea, Table, TableHead, TableRow, TableCell, TableBody } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import sponsorSetterModalCtrl from "./sponsorSetterModal.controller";
import * as appStyle from "../../../../../../util/appStyle";
import WorkSectorOption from "../regActivityGuest/component/WorkSectorOption.view";
import { Icon } from "@cbmisorg/icons";
import { getDateUSFormat, getSponsorTypeAccordingToTraineeNum } from "../../../../../../util/appFunctions";
// import ContactUs from "../../../../../../../old/components/public/LandingPage/home/sections/ContactUs";

const labels = dictionary?.components?.stakeholders?.member?.account?.sponsorSetter;
export default function SponsorSetterModalView({ parentState, setParentState, isLoading, setIsLoading, classes }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;

  const [state, setState] = useState({
    blnIsInitDone: false,
    blnOpenSetterModal: false,
    blnOpenTranstionModal: false,
    blnOpenImgeModal: false,
    lstLogo: [],
    lstReceipt: [],
    blnIsRecordExist: false,
    selectedTrans: null,
    selectedLstImg: null,
    selectedImgType: null,
  });
  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const objContact = appState?.clientInfo?.objSystemInfo?.jsnContact;
  const blnHaveContactInfo = objContact?.strEmail || objContact?.strSupportEmail || objContact?.strPhoneNumber || objContact?.strSupportPhone || objContact?.strAddress;
  const objPartnerCount = getSponsorTypeAccordingToTraineeNum(parentState?.objPartnerInfo?.intTraineesCount);
  const uploaderLogo = Uploader(state, setState, "lstLogo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: dictionary?.components?.uploader?.logoNote?.[lang],
    file: dictionary?.components?.uploader?.logo?.[lang],
  });
  const uploaderReceipt = Uploader(state, setState, "lstReceipt", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
  });

  const fields = FormData({
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strURL: { control: "text", blnLanguage: false },

    strSponsorTrainingType: { control: "list", blnLanguage: false, validations: { required: true } },
    blnUploadedWhatsapp: {
      control: "checkbox",
      blnLanguage: false,
      options: [
        {
          key: "true",
          value: labels?.whatsappIsUpload?.[lang],
          jsx: () => (
            <Typography as="body3" className={classes?.descriptionLightText} p-2>
              {labels?.whatsappIsUpload?.[lang]}
            </Typography>
          ),
        },
      ],
    },
  });

  useEffect(() => {}, []);

  const handlers = sponsorSetterModalCtrl({ appDispatch, appState, isLoading, parentState, setIsLoading, setParentState });
  const openModal = handlers.openModal({ setState, state, fields });
  const initData = handlers.initData({ fields, setState, state });
  const updatePartnerInfo = handlers.updatePartnerInfo({ fields, setState, state, uploaderLogo, uploaderReceipt });
  const dilogeImgModal = handlers?.dilogeImgModal({ state, setState });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return {
    openEle: appState?.userInfo?.jsnUserInfo?.blnCloseSponsor ? null : (
      <Paper className={classes?.bgBox}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography as="subtitle2" className={classes?.mainTitle}>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
          {parentState?.objPartnerInfo?.jsnSupportTrans?.length ? (
            <React.Fragment>
              <Grid item xs="12" container justifyContent="space-between">
                <Grid item>
                  <Typography as="body2" className={classes?.partnerCat}>
                    {!objPartnerCount ? null : (
                      <Grid container py-0 spacing={0} alignItems={"center"} sx={{ flexWrap: "nowrap", minWidth: "max-content !important" }}>
                        <Grid item alignItems={"center"}>
                          <Icon icon={objPartnerCount !== objSponserCat?.main?.key ? "star" : "AutoAwesome"} />
                        </Grid>
                        <Grid item alignItems={"center"}>
                          {dictionary?.shared?.app?.sponserLabelCat[objPartnerCount]?.[lang]}
                        </Grid>
                      </Grid>
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon
                    icon="RequestQuoteOutlined"
                    color={appStyle?.App_info_Color}
                    size="24px"
                    className={classes?.icon}
                    onClick={() => setState({ ...state, blnOpenTranstionModal: true })}
                  />
                </Grid>
              </Grid>
              <Grid item xs="12" md="12">
                <Typography as="body2" className={classes?.descriptionLightText}>
                  {labels?.contribution?.[lang]}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs="12" md="12">
              <Typography as="body2" className={classes?.descriptionLightText}>
                {labels?.description?.[lang]}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Button label={labels?.btn?.[lang]} mode="outlined" color={appStyle.App_info_Color} onClick={openModal} className={classes?.btnBox} />
          </Grid>
        </Grid>
      </Paper>
    ),

    modal: (
      <Modal open={state.blnOpenSetterModal} eventClose={() => setState({ ...state, blnOpenSetterModal: false })} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          {appState?.userInfo?.blnIsSponsor || parentState?.objPartnerInfo?.jsnSupportTrans?.length ? null : (
            <React.Fragment>
              <Grid container>
                <Grid item className={classes?.subtitle} p-3>
                  <Typography as="subtitle2" className={classes?.subtitleLabel} style={{ padding: "5px" }}>
                    {labels?.sponsorInfo?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12} md="6">
                  <FormField
                    objHandler={fields.bigWorkSector}
                    icon="Work"
                    mode={"classic"}
                    iconColor={appStyle.App_info_Color}
                    color={appStyle.App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField
                    objHandler={fields.strPlaceName}
                    icon="business"
                    mode={"classic"}
                    iconColor={appStyle.App_info_Color}
                    color={appStyle.App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    objHandler={fields.strURL}
                    icon="link"
                    mode={"classic"}
                    iconColor={appStyle.App_info_Color}
                    color={appStyle.App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs="12" container sx={{ sm: { justifyContent: "center" } }}>
                  <Grid item xs="12" md="auto">
                    <Typography as="body2" className={classes?.descriptionLightText}>
                      {labels?.proofUpload?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item>{uploaderReceipt?.JSX}</Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <Grid container>
            <Grid item className={classes?.subtitle} p-3>
              <Typography as="subtitle1" className={classes?.subtitleLabel}>
                {labels?.supportInfo?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="body3" className={classes?.descriptionLightText}>
                {labels?.thanksContribution?.[lang]}
              </Typography>
            </Grid>
          </Grid>
          {objSystemConfig?.blnIsAutoPaid ? (
            <Grid container justifyContent={"center"}>
              <Grid item xs={12}>
                <FormField objHandler={fields.strSponsorTrainingType} icon="code" mode={"classic"} iconColor={appStyle.App_info_Color} className={classes?.field} />
              </Grid>
              {!fields?.strSponsorTrainingType?.getValue() ? null : (
                <Grid item xs="12">
                  <Paper p-1>
                    <Grid container>
                      <Grid item xs="12">
                        <Typography as="body3" className={classes?.descriptionLightText}>
                          {labels?.strViewAccountBank?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs="12">
                        <TextArea className={classes?.textareBankInfo} defaultValue={objSystemConfig?.strBankInfo?.[lang]} readOnly disabled={true} rows={3} />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
              {!fields?.strSponsorTrainingType?.getValue() ? null : (
                <React.Fragment>
                  <Grid container sx={{ sm: { justifyContent: "center" } }}>
                    <Grid item xs="12" md="auto">
                      <Typography as="body2" className={classes?.descriptionLightText}>
                        {labels?.proofUpload?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item>{uploaderReceipt?.JSX}</Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs="10" sm="11" md="auto">
                      <Typography as="caption">{labels?.whatsappUpload?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="2" sm={1} md="auto" p-0>
                      <Icon
                        icon="whatsApp"
                        color="#25d366"
                        p-1
                        sx={{ borderRadius: "50%", background: appStyle.App_info_Opacity_Color }}
                        onClick={() => {
                          window?.open(`https://wa.me/965${objContact?.strSupportPhone}`, "_blank");
                        }}
                      />
                    </Grid>
                    <Grid item xs="12" pt-0>
                      <FormField objHandler={fields.blnUploadedWhatsapp} pt-0 />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={blnHaveContactInfo ? "12" : "0"} container>
                <Grid item xs="12" container>
                  <Grid item>
                    <Typography as="body2">{labels?.contactopt?.[lang]}</Typography>
                  </Grid>
                </Grid>
                {!objContact?.strEmail && !objContact?.strSupportEmail ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="email" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a href={`mailto:${objContact?.strEmail}`}>{objContact?.strEmail}</a>
                        {objContact?.strEmail && objContact?.strSupportEmail ? ` / ` : null}
                        <a href={`mailto:${objContact?.strSupportEmail}`}>{objContact?.strEmail}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!objContact?.strPhoneNumber && !objContact?.strSupportPhone ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="phone" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a href={`tel:${objContact?.strPhoneNumber}`}>{objContact?.strPhoneNumber}</a>
                        {objContact?.strPhoneNumber && objContact?.strSupportPhone ? ` / ` : null}
                        <a href={`tel:${objContact?.strSupportPhone}`}>{objContact?.strSupportPhone}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!objContact?.strAddress ? null : (
                  <Grid item xs="12" container>
                    <Grid item>
                      <Icon icon="LocationOn" color="primary" />
                    </Grid>
                    <Grid item xs="10">
                      <Typography as="caption">
                        <a>{objContact?.strAddress?.[lang]}</a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs="12">
                <Typography as="body2">{labels?.contactopt2?.[lang]}</Typography>
              </Grid>
              <Grid item xs="12">
                {/* <ContactUs appState={appState} lang={lang} blnFromSponser={true} submitClicked={dilogeContributionModal()} />  */}
              </Grid>
            </Grid>
          )}

          {!state?.lstReceipt?.length && !fields?.blnUploadedWhatsapp?.getValue()?.length ? null : (
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs="12">
                <Button
                  className={classes?.btnBox}
                  color={appStyle.App_info_Color}
                  mode={"outlined"}
                  disabled={!state?.lstReceipt?.length && !fields?.blnUploadedWhatsapp?.getValue()?.length ? true : false}
                  px-9
                  label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                  onClick={updatePartnerInfo()}
                />
              </Grid>
            </Grid>
          )}
        </ModalBody>
      </Modal>
    ),
    modalTranstion: (
      <Modal open={state.blnOpenTranstionModal} eventClose={() => setState({ ...state, blnOpenTranstionModal: false })} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.support?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent={"center"} py-2>
            <Grid item xs="12">
              <Table>
                <TableHead>
                  <TableRow className={classes?.tableRow}>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.sponserInfo?.supportStatus?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.sponserInfo?.supportCat?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.sponserInfo?.studentNumber?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.sponserInfo?.amount?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.sponserInfo?.date?.[lang]}</Typography>
                    </TableCell>
                    {parentState?.objPartnerInfo?.jsnSupportTrans?.length === 1 &&
                    !parentState?.objPartnerInfo?.jsnSupportTrans[0]?.lstReceipt?.length &&
                    !parentState?.objPartnerInfo?.jsnSupportTrans[0]?.lstReceiptCatch?.length ? null : (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(parentState?.objPartnerInfo?.jsnSupportTrans || [])?.map((item) => (
                    <TableRow key={item?.id} className={classes?.tblBodyRow}>
                      <TableCell headLabel={labels?.sponserInfo?.supportStatus?.[lang]}>
                        <Typography
                          as="subtitle3"
                          color={
                            item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "success"
                              : item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "error"
                              : appStyle.App_Second_Color
                          }
                        >
                          {labels.objStatus?.[item?.intSupportTransStatus]?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.supportCat?.[lang]}>
                        <Typography
                          as="body3"
                          // color={appColor?.[appFunctions.getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount, item?.strTrainingType)]}
                        >
                          {dictionary?.shared?.app.sponserLabelCat?.[getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount, item?.strTrainingType)]?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.studentNumber?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center" }}>
                          {item?.intTraineesCount}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.amount?.[lang]}>
                        <Typography as="body3">
                          {Number(item?.intReceivedAmount ? item?.intReceivedAmount : item?.intTrainingProgPrice * item?.intTraineesCount).toLocaleString()}
                          {dictionary?.shared?.app?.dinar?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.date?.[lang]}>
                        <Typography as="body3">{getDateUSFormat(item?.dtmCreatedDate)}</Typography>
                      </TableCell>
                      {!item?.lstReceipt?.length && !item?.lstReceiptCatch?.length ? (
                        <TableCell>
                          <Grid container justifyContent={"center"}>
                            <Grid item>
                              <Button
                                label={dictionary?.shared?.buttons?.update?.[lang]}
                                color={appStyle?.App_Second_Color}
                                size="sm"
                                py-0
                                px-2
                                className={classes?.btn}
                                // onClick={dilogeContributionModal(item)}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      ) : (
                        <TableCell py-3>
                          <Grid container justifyContent={"center"}>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceipt?.length ? null : (
                                <Button label={labels?.receipt?.[lang]} size="sm" p-0 className={classes?.btn} onClick={dilogeImgModal(item?.lstReceipt, "receipt")} />
                              )}
                            </Grid>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceiptCatch?.length ? null : (
                                <Button
                                  label={labels?.receiptCatch?.[lang]}
                                  size="sm"
                                  p-0
                                  color="success"
                                  className={classes?.btn}
                                  onClick={dilogeImgModal(item?.lstReceiptCatch, "receiptCatch")}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
    modalImage: (
      <Modal open={state?.blnOpenImgeModal} eventClose={dilogeImgModal()} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.[state?.selectedImgType]?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <img src={App_Server_Url_DownloadFiles + state?.selectedLstImg?.[0]?.path} alt={labels?.[state?.selectedImgType]?.[lang]} width="100%" />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
