import React, { forwardRef, useContext, useId, useRef, useState } from "react";
import NavStyle from "./NavStyle";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import { Icon } from "@cbmisorg/icons";
import Menu from "../Menu/Menu";

const Nav = forwardRef((/**@type {any}*/ props, ref) => {
  const { label, to, replace, menu, state, headItem, footerItem,blnCloseOutMenuClick, sx, id, hidden, disabled, dir, className, onClick, eventNavClick, ...rest } = props;
  let cmp_ID = id || useId().replace(/:/g, "");
  const [open, setOpen] = useState(false);

  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = NavStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, open, hasContent: menu?.length ? true : false });

  const handleClicked = (event) => {
    if (menu && menu?.length) {
      setOpen(!open);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={`${classes?.[classesName?.nav_nav_container]} ${styleFunction.CheckDisabledItem(disabled)} ${styleFunction.CheckHiddenItem(hidden)} ${styleFunction.checkExistsMargin(
        rest
      )} ${styleFunction.checkExistsPadding(rest)} ${styleFunction.CheckDirectionItem(dir)} ${className || ""}`}
    >
      <div
        className={`${classes?.[classesName?.nav]} ${classesSx?.[classesName?.nav]} ${classesSx?.[classesName?.sx]}`}
        onClick={handleClicked}
        ref={ref}
        id={cmp_ID}
        {...styleFunction?.getRestProps(rest)}
        key={cmp_ID}
      >
        {!headItem ? null : <div className={classes?.[classesName?.nav_item_head]}>{headItem}</div>}
        <div className={classes?.[classesName?.nav_item_item_content]} key={cmp_ID + "_item_content"}>
          {label || props?.children}
        </div>
        {!footerItem && !menu ? null : (
          <div className={classes?.[classesName?.nav_item_footer]} key={cmp_ID + "_item_footer"}>
            {footerItem ? footerItem : menu ? <Icon icon="arrowDropDown" /> : null}
          </div>
        )}
      </div>
      <Menu blnFullWidth={true} blnCloseOnClick={true} open={open} eventClose={() => setOpen(false)} blnCloseOutMenuClick={blnCloseOutMenuClick} className={classesSx?.[classesName?.nav_menu]}>
        <div style={{ width: "100%" }}>{menu}</div>
      </Menu>
    </div>
  );
});

export default Nav;

// // if(to){
// //   if(typeof window !=="undefined"){
// //     if(replace){
// //         window.history.replaceState(state, "", path);
// //     }else{
// //         window.history.pushState(state, "", path);
// //     }
// //   }
// // }
// console.log(open);
// // event.preventDefault()
// // if(open){
// // }else{
// //   if(menu?.length){
// //     setOpen(true);
// //   }
// // }

// alert(cmp_ID)
// if(!open?.cmp_ID && menu && menu?.length>0){
//   setOpen(true);
// }else{
//   setOpen({cmp_ID: !open?.cmp_ID});
// }
