import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as XLSX from "xlsx";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { generateQueries, objTableAttributes } from "../../../../appHelper/appQuery/GenerateQuery";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";

const tblActivityGuestFetch = generateQueries("tblActivityGuest");
const strPageInfo = "@src/components/stakeholders/admin/registeredActivities/RegisteredActivities.controller.js";

export const strTransactionNeedToModify = "TransactionNeedToModify";
export default function registeredActivitiesCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }

          state.blnIsFetchDone = true;
          setIsLoading(true);

          const result = await tblActivityGuestFetch(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });

          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          state.lstActivities = result.response;
          state.lstActivitiesRead = result.response;

          const objCat = appFunctions.getCategoryCache();
          let lstActivitiesOption = [];
          let objActivitiesOptionLang = {};

          Object.entries(objCat?.Root?.activity || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstActivitiesOption.push({ key: value?.id, value: value?.value?.[lang] });
              objActivitiesOptionLang[key] = {
                eng: value?.value?.eng,
                arb: value?.value?.arb,
              };
            }
          });

          const optAllActivityType = { key: "all", value: { eng: "All", arb: "الجميع" }?.[lang] };
          fields.lstActivityType.setOptions([optAllActivityType, ...lstActivitiesOption]);
          if (lstActivitiesOption.length === 1) {
            fields.lstActivityType.setValue(lstActivitiesOption[0]);
          } else {
            fields.lstActivityType.setValue(optAllActivityType);
          }
          handler.onChangeActivety({ fields, state, setState })();

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
          setIsLoading(false);
        }
      },

    onChangeActivety:
      ({ fields, state, setState }) =>
      () => {
        const optAllSubject = { key: "all", value: { eng: "All", arb: "الكل" }?.[lang] };
        const activityValue = fields?.lstActivityType?.getValue();

        if (activityValue?.key === "all") {
          fields.lstSubject.setOptions([optAllSubject]);
          state.lstActivitiesRead = state.lstActivities;
          setState({ ...state });

          return;
        }

        const objCat = appFunctions.getCategoryCache();
        let lstSubjectOption = [];
        let objSubjectOptionLang = {};

        const objActivityInfo = objCat?.Root?.activity?.[appFunctions?.appCategoryGetKey(objCat?.Root?.activity, fields?.lstActivityType?.getValue()?.key)] || {};
        Object.entries(objActivityInfo?.subject).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstSubjectOption.push({ key: value?.id, value: value?.value?.[lang] });
            objSubjectOptionLang[value?.id] = {
              eng: value?.value?.eng,
              arb: value?.value?.arb,
            };
          }
        });
        fields.lstSubject.setOptions([optAllSubject, ...lstSubjectOption]);
        if (lstSubjectOption.length === 1) {
          fields.lstSubject.setValue(lstSubjectOption[0]);
        } else {
          fields.lstSubject.setValue(optAllSubject);
        }

        state.lstActivitiesRead = state.lstActivities?.filter((item) => item?.bigActivityType === fields?.lstActivityType?.getValue()?.key);
        setState({ ...state });
      },

    onChangeSubject:
      ({ fields, state, setState }) =>
      () => {
        const subjectValue = fields?.lstSubject?.getValue();
        state.lstActivitiesRead = state.lstActivitiesRead?.filter((item) => item?.jsnGuestInfo?.objTargeted?.id == subjectValue?.key);
        setState({ ...state });
      },

    exportExcelFile:
      ({ state }) =>
      () => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = [
          lang === "arb"
            ? [
                "الرقم التعريفي",
                "اسم الفاعلية",
                "اسم الندوة أو ورشة العمل",
                "تاريخ الندوة أو ورشة العمل",
                "الفئة المستهدفة",
                "الاسم",
                "البريد الالكتروني",
                "قطاع العمل",
                "مكان العمل",
                "تاريخ التسجيل",
              ]
            : [
                "Id",
                "Name of the event",
                "Name of the seminar or workshop",
                "Date of the seminar or workshop",
                "Target group",
                "Name",
                "Email",
                "Work sector",
                "Workplace",
                "Registration date",
              ],
        ];
        const objCat = appFunctions.getCategoryCache();

        (state?.lstActivitiesRead || [])?.forEach((child) => {
          const newlist = [
            child?.bigActivityGuestID,
            appFunctions.appCategoryGetValue(objCat?.Root?.activity, child?.bigActivityType)?.[lang] || "",
            child?.jsnGuestInfo?.objTargeted?.value?.[lang] || "",
            child?.jsnGuestInfo?.objTargeted?.date?.value?.[lang] || "",
            child?.jsnGuestInfo?.objTargeted?.targeted?.value?.[lang] || "",

            child?.jsnGuestInfo?.strFullName || "",
            child?.jsnGuestInfo?.strEmail || "",
            appVariables.workSectorIDLabel?.[child?.bigWorkSector]?.[lang] || "",
            child?.strPlaceName || "",
            appFunctions.getDateUSFormat(child?.dtmCreatedDate) || "",
          ];
          worksheet_data?.push(Object?.values(newlist));
        });
        var worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);
        worksheet["!autofilter"] = { ref: "F1" };
        workbook.SheetNames.push("registered_in_activities");
        workbook.Sheets["registered_in_activities"] = worksheet;
        XLSX.writeFile(workbook, `registered_in_activities_${appFunctions.getDateUSFormat(appFunctions?.getCurrentDate())}.xlsx`);
      },
  };

  return handler;
}

/*
strTrainingType: String(trainingType).toLowerCase();
intTraineesCount : trainingNumber,
intTrainingProgPrice : trainingNumber * objTrainingFromCat?.price?.value?.[lang],
*/

///tblPartnershipTrack
