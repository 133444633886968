import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import AccordionStyle from "./AccordionStyle";

const AccordionDetails = forwardRef((/**@type {any}*/ props, ref) => {
  const { blnAddToDomClose = false, openDetails=false, onClick, tabID, children, hidden, sx, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleDetailsSx } = AccordionStyle(theTheme);
  const classes = style();
  const classesSx = styleDetailsSx({ sx, openDetails: openDetails });


  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.accordion_tab_details],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );


  return (
    <div className={itemClasses} ref={ref} {...styleFunction.getRestProps(rest)} key={tabID+"_Details"}>
      {blnAddToDomClose || openDetails? children : null}
    </div>
  );
});

export default AccordionDetails;
