//Split variables
export const Split_Main_Message_Type_Symbol = "$"
export const Split_Message_Symbol = "~"
export const Split_Value_Type_Symbol = "-"
export const Split_Different_Type_Symbol = "*"
export const Split_Same_Message_Type_Symbol = "/"

//#region 
export const Split_validations_Symbol = "~"

//#endregion

//#region 
export const fieldType = {
    text:"text",
    textArea:"textarea",
    password:"password",
    radio:"radio",
    checkbox:"checkbox",
    list:"list",
    multipleList:"multiplelist",
    date:"date",
    dateTime:"datetime",
    number:"number",
    rangeSlider:"rangeslider",
    rating:"rating",
    switch:"switch",
    color:"color",
}

export const formValidationsMsg={
    required:{eng:"Required!",arb:"الحقل مطلوب"},
    email: {eng:"The email not valid",arb:"البريد اللكتروني غير صحيح"},
    onlynumbers: {eng:"Only number Required", arb:"يجب ان تحتوي القيم فقط أرقام"},
    onlyletters: {eng:"Only letters Required", arb:"يجب ان تحتوي القيم فقط أحرف"},
    maxlength:{eng: "The digits entered are greater than the allowed limit", arb: "عدد الخانات التي تم إدخالها أكبر من الحد المسموح به"},
    minlength:{eng: "The digits entered are less than the allowed limit", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به"},
    maxword:{eng: "The words entered are greater than the allowed limit", arb: "عدد الكلمات التي تم إدخالها أكبر من الحد المسموح به"},
    minword:{eng: "The words entered are less than the allowed limit", arb: "عدد الكلمات التي تم إدخالها أقل من الحد المسموح به"},
    equal:{eng: "The entered value does not match the required field", arb: "القيمة المدخلة غير مطابقة للحقل المطلوب"},
    started: {eng:"The value entered did not start with the required", arb:"لم تبدأ القيمة المدخلة بالمطلوب"},
    ended: {eng:"The value entered did not end with the required", arb:"لم تنتهي القيمة المدخلة بالمطلوب"},
    included: {eng:"The value you entered did not have the required value", arb:"لم تحتوي القيمة المدخلة بالقيمة المطلوبة"},
    password:{
        eng:"The password must be at least 8 characters long including a number, upper and lowercase letters, and one special character",
        arb:"يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك رقم وأحرف كبيرة وصغيرة وحرف خاص واحد"}
}
