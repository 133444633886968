import { useMemo, useRef, useState } from "react";
import * as FormHelper from "../formHelper/FormHelper";
import { formValidationsMsg } from "../formHelper/formVariables/FormVariables";

const defaultLabels = {
  currentLang: "eng",
  defaultLang: "eng",
  blnDuplicateLangValue: false,
  objLanguage: {
    arb: { blnIsActive: true, blnIsMandatory: false, label: "العربية" },
    eng: { blnIsActive: true, blnIsMandatory: true, label: "English" },
  },
  labels: {
    strFullName: {
      label: { eng: "Full Name", arb: "اسم" },
      placeholder: { eng: "Full Name" },
      options: {
        opt1: { eng: "", arb: "" },
        opt2: { eng: "", arb: "" },
      },
      validationsMsg: {
        customName: {},
      },
    },
  },
  defaultValidationsMsg: formValidationsMsg,
};
function useForm(initialState = {}, objDefLabel = {}) {
  const refObjDefLabel = useRef({
    ...defaultLabels,
    ...objDefLabel,
  });
  const orderNewFields = useRef([]);
  const newFormState = useMemo(() => {
    const newFormState = {};
    const arrayState = Object.keys(initialState);
    for (let index = 0; index < arrayState.length; index++) {
      const elementKey = arrayState[index];

      const fieldValue = FormHelper.getValueByFieldName(initialState?.[elementKey], refObjDefLabel?.current);
      newFormState[elementKey] = {
        name: elementKey,
        label: refObjDefLabel?.current?.labels?.[elementKey]?.label || initialState?.[elementKey]?.label || "",
        placeholder: refObjDefLabel?.current?.labels?.[elementKey]?.placeholder || initialState?.[elementKey]?.placeholder || "",
        validationsMsg: {
          ...refObjDefLabel?.current?.defaultValidationsMsg,
          ...refObjDefLabel?.current?.labels?.[elementKey]?.validationsMsg,
        },
        value: fieldValue && fieldValue?.value ? fieldValue?.value : "",

        error: "",
        hidden: false,
        disabled: false,
        blnLanguage: false,
        language: {
          current: refObjDefLabel?.current?.currentLang,
          default: refObjDefLabel?.current?.defaultLang,
        },
        optionLanguage: { ...refObjDefLabel?.current?.objLanguage, ...(fieldValue ? fieldValue.optionLanguage : {}) },
        options: FormHelper?.getOptions(elementKey, initialState?.[elementKey], refObjDefLabel?.current), //??
        ...initialState?.[elementKey],

        isValid: () => isFormValid(elementKey),
        getValue: () => getValueByFieldName(formState?.[elementKey]),
        getOptions: () => FormHelper?.getOptions(elementKey, formState?.[elementKey], refObjDefLabel?.current, null),

        setControl : (newControl)=>setControl(elementKey, newControl),
        setLanguage: (newLang) => setLanguage(elementKey, newLang),
        setOptionLanguage: (newObjLang) => setOptionLanguage(elementKey, newObjLang),
        setOptions: (newArrayOpt, blnReplaceAll) => setOptions(elementKey, newArrayOpt, blnReplaceAll),
        setOption: (optKey, newOptValue) => setOption(elementKey, optKey, newOptValue),
        setLabel: (newLabel) => setLabel(elementKey, newLabel),
        setPlaceholder: (newPlaceholder) => setPlaceholder(elementKey, newPlaceholder),
        setValue: (newValue = null, blnInFormApi = false, blnSetState = true) => setValueByFieldName(elementKey, newValue, blnInFormApi, blnSetState),
        setHidden: (hiddenValue = false) => setHidden(elementKey, hiddenValue),
        setDisabled: (disabledValue = false) => setDisabled(elementKey, disabledValue),
        setError: (newError = "") => setError(elementKey, newError),
        setValidation: (validationKey = null, validationValue = { condition: true, msg: {} }, newObjValidation = null) =>
          setValidationByFieldName(elementKey, validationKey, validationValue, newObjValidation),
      };
    }
    return newFormState;
  }, [initialState, refObjDefLabel.current]); //=> function

  const [formState, setFormState] = useState(newFormState); // =>useEffect

  /************************ values functions ***********************/
  //#region
  const getCurrentFormValues = () => {
    return FormHelper.getFormValues(formState);
  };
  const getValueByFieldName = (field) => {
    const fieldValue = FormHelper.getValueByFieldName(field, refObjDefLabel?.current);
    if (fieldValue && fieldValue?.value) {
      return fieldValue?.value;
    }
    return "";
  };
  //checkbox value
  const addCheckboxValue = (fieldName, newPairValue) => {
    let newFormState = FormHelper.addToCheckboxArray(newPairValue, fieldName, formState);
    const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState, refObjDefLabel.current)?.error;
    newFormState = FormHelper.updateError(errorMessage, fieldName, newFormState);
    setFormState({ ...newFormState });
  };
  const deleteCheckboxValue = (fieldName, key) => {
    let newFormState = FormHelper.deleteFromCheckboxArray(key, fieldName, formState);
    const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState, refObjDefLabel.current)?.error;
    newFormState = FormHelper.updateError(errorMessage, fieldName, newFormState);
    setFormState({ ...newFormState });
  };

  const setValueByFieldName = (fieldName, newValue, blnInFormApi = false, blnSetState = true) => {
    try {
      const handler = formState?.[fieldName];
      if ((String(handler?.control)?.toLowerCase() !== "checkbox" && String(handler?.control)?.toLowerCase() !== "multiplelist") || !blnInFormApi) {
        const theNewValue = typeof newValue === "boolean" ? newValue : JSON.stringify(newValue);
        let newFormState = FormHelper.updateValue(theNewValue, fieldName, formState, objDefLabel);
        const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState, refObjDefLabel.current)?.error;
        newFormState = FormHelper.updateError(errorMessage, fieldName, newFormState);
        if (blnSetState) {
          setFormState({ ...newFormState });
        }
      } else {
        const valueIndex = (handler?.value || [])?.findIndex(
          (item) => item?.key === newValue?.key || String(item?.key) === String(newValue?.key) || item?.key === newValue || String(item?.key) === String(newValue)
        );
        if (valueIndex === -1) {
          addCheckboxValue(fieldName, newValue);
        } else {
          deleteCheckboxValue(fieldName, newValue?.key || newValue);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  /********************** end values functions *********************/

  /************************ validate functions ***********************/
  //#region
  const isFormValid = (fieldName = null) => {
    if (fieldName) {
      return FormHelper.getError(fieldName, formState) ? true : false;
    } else {
      let newFormState = FormHelper.checkFormValues(formState, refObjDefLabel?.current);
      setFormState({ ...newFormState });
      return FormHelper.isFormValid(newFormState);
    }
  };

  const setValidationByFieldName = (fieldName, validationKey, validationValue, newObjValidation) => {
    try {
      if (fieldName) {
        let newFormState = { ...formState };
        if (newObjValidation) {
          newFormState[fieldName].validations = newObjValidation;
        } else if (validationKey) {
          newFormState[fieldName].validations[validationKey] = validationValue;
        }
        const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState)?.error;
        newFormState = FormHelper.updateError(errorMessage, fieldName, newFormState);
        setFormState({ ...newFormState });
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion
  /********************** end validate functions *********************/

  /************************ set functions ***********************/
  //#region

  const setControl = (fieldName, newControl) => {
    const newFormState = formState;
    newFormState[fieldName].control = newControl;
    setFormState({ ...newFormState });
  };
  
  const setHidden = (fieldName, newConation) => {
    const newFormState = formState;
    newFormState[fieldName].hidden = newConation;
    setFormState({ ...newFormState });
  };

  const setDisabled = (fieldName, newConation) => {
    const newFormState = formState;
    newFormState[fieldName].disabled = newConation;
    setFormState({ ...newFormState });
  };

  const setLabel = (fieldName, label) => {
    const newFormState = formState;
    newFormState[fieldName].label = label;
    setFormState({ ...newFormState });
  };

  const setPlaceholder = (fieldName, placeholder) => {
    const newFormState = formState;
    newFormState[fieldName].placeholder = placeholder;
    setFormState({ ...newFormState });
  };
  const setLanguage = (fieldName, newLang) => {
    const newFormState = formState;
    newFormState[fieldName].language.field = newLang;
    setFormState({ ...newFormState });
  };
  const setOptionLanguage = (fieldName, newOptionsLang) => {
    const newFormState = formState;
    // field.language.prevField = field?.language?.field
    newFormState[fieldName].objOptionLangLabel = newOptionsLang;
    setFormState({ ...newFormState });
  };
  const setError = (fieldName, newError) => {
    const newFormState = formState;
    newFormState[fieldName].error = newError;
    setFormState({ ...newFormState });
  };
  //#endregion
  /************************ end set functions ***********************/

  //#region options
  const setOptions = (fieldName, lstOptions = [], blnReplaceAll = false) => {
    const newOptions = FormHelper.getOptions(fieldName, formState?.[fieldName], refObjDefLabel?.current, lstOptions);

    if (fieldName && formState?.[fieldName] && JSON.stringify(formState[fieldName].getOptions()) !== JSON.stringify(newOptions)) {
      if (blnReplaceAll) {
        formState[fieldName].options = newOptions;
      } else {
        formState[fieldName].options = [...(formState[fieldName].getOptions() || []), ...newOptions];
        setFormState({ ...formState });
      }
    }
  };
  const setOption = (fieldName, newOptKey, newOptValue) => {
    const arrOptions = formState?.[fieldName]?.options || [];
    const indexOptions = arrOptions.findIndex((item) => item.key === newOptKey);
    const newOptions = arrOptions;
    if (indexOptions > -1) {
      newOptions[indexOptions] = { key: newOptKey, value: newOptValue };
    } else {
      newOptions.push({ key: newOptKey, value: newOptValue });
    }
    formState[fieldName].options = newOptions;
    setFormState({ ...formState });
  };

  //#endregion

  const resetForm = () => {
    Object.keys(formState).forEach((filed) => {
      formState[filed].value = FormHelper?.getValueByFieldName(filed, objDefLabel, true);
      formState[filed].error = "";
      formState[filed].language = {
        current: refObjDefLabel?.current?.currentLang,
        default: refObjDefLabel?.current?.defaultLang,
      };
    });
    setFormState(formState);
  };

  //#region Add List Fields
  const addListFields = (fields = {}, blnReplaceAll = false) => {
    const newFormState = blnReplaceAll ? {} : formState;
    if (typeof fields === "object") {
      Object?.entries(fields || {}).forEach((field) => {
        const [fieldName, theField] = field;

        // order

        const fieldValue = FormHelper.getValueByFieldName(theField, refObjDefLabel?.current);
        newFormState[fieldName] = {
          name: fieldName,
          label: refObjDefLabel?.current?.labels?.[fieldName]?.label || theField?.label || "",
          placeholder: refObjDefLabel?.current?.labels?.[fieldName]?.placeholder || theField?.placeholder || "",
          validationsMsg: {
            ...refObjDefLabel?.current?.defaultValidationsMsg,
            ...refObjDefLabel?.current?.labels?.[fieldName]?.validationsMsg,
          },
          value: fieldValue && fieldValue?.value ? fieldValue?.value : "",

          error: "",
          hidden: false,
          disabled: false,
          blnLanguage: false,
          language: {
            current: refObjDefLabel?.current?.currentLang,
            default: refObjDefLabel?.current?.defaultLang,
          },
          optionLanguage: { ...refObjDefLabel?.current?.objLanguage, ...(fieldValue.optionLanguage ? fieldValue.optionLanguage : {}) },
          options: FormHelper?.getOptions(fieldName, theField, refObjDefLabel?.current), //??
          ...theField,

          isValid: () => isFormValid(fieldName),
          getValue: () => getValueByFieldName(formState?.[fieldName]),
          getOptions: () => FormHelper?.getOptions(fieldName, formState?.[fieldName], refObjDefLabel?.current, null),

          setLanguage: (newLang) => setLanguage(fieldName, newLang),
          setOptionLanguage: (newObjLang) => setOptionLanguage(fieldName, newObjLang),
          setOptions: (newArrayOpt, blnReplaceAll) => setOptions(fieldName, newArrayOpt, blnReplaceAll),
          setOption: (optKey, newOptValue) => setOption(fieldName, optKey, newOptValue),
          setLabel: (newLabel) => setLabel(fieldName, newLabel),
          setPlaceholder: (newPlaceholder) => setPlaceholder(fieldName, newPlaceholder),
          setValue: (newValue = null, blnInFormApi = false, blnSetState = true) => setValueByFieldName(fieldName, newValue, blnInFormApi, blnSetState),
          setHidden: (hiddenValue = false) => setHidden(fieldName, hiddenValue),
          setDisabled: (disabledValue = false) => setDisabled(fieldName, disabledValue),
          setError: (newError = "") => setError(fieldName, newError),
          setValidation: (validationKey = null, validationValue = { condition: true, msg: {} }, newObjValidation = null) =>
            setValidationByFieldName(fieldName, validationKey, validationValue, newObjValidation),
        };
      });

      setFormState(newFormState);
    }
  };

  const deleteListFields = (fields = []) => {
    fields.forEach((theField) => {
      delete formState?.[theField];
    });
    setFormState({ ...formState });
  };

  const deleteForm = () => {
    setFormState({});
  };
  const setFormLanguage = (newLang) => {
    refObjDefLabel.current.currentLang = newLang;
    const arrFiled = Object.keys(formState);
    for (let index = 0; index < arrFiled.length; index++) {
      const element = arrFiled[index];
      formState[element].language.current = newLang;
      if (formState[element].language.field) {
        formState[element].language.field = newLang;
      }
    }
    setFormState({ ...formState });
  };
  const getFormLanguage = () => {
    return {
      current: refObjDefLabel.current.currentLang,
      default: refObjDefLabel.current.defaultLang,
    };
  };
  return {
    ...formState,
    controller: {
      step: 0,
      isFormValid,
      getValues: getCurrentFormValues,
      addListFields, //[]
      deleteListFields,
      deleteForm,
      getFormLanguage,
      setFormLanguage,
      resetForm,
      orderNewFields: ["", ""],
    },
  };
}

export default useForm;
