export const keysPosition={
    relative:"relative",
    absolute:"absolute",
    fixed:"fixed",
    sticky:"sticky",

    X:"x",
    y:"y",
    t:"top",
    b:"bottom",
    r:"right",
    l:"left",
    inset:"inset",

    px:"px",
    auto:"auto",

    xs:"xs",
    sm:"sm",
    md:"md",
    lg:"lg",
    xl:"xl",

    "1/2":"1/2",
    "1/3":"1/3",
    "2/3":"2/3",
    "1/4":"1/4",
    "2/4":"2/4",
    "3/4":"3/4",
    "1/5":"1/5",
    "2/5":"2/5",
    "3/5":"3/5",
    "4/5":"4/5",

    "1/6":"1/6",
    "2/6":"2/6",
    "3/6":"3/6",
    "4/6":"4/6",
    "5/6":"5/6",

    "1/12":"1/12",
    "2/12":"2/12",
    "3/12":"3/12",
    "4/12":"4/12",
    "5/12":"5/12",
    "6/12":"6/12",
    "7/12":"7/12",
    "8/12":"8/12",
    "9/12":"9/12",
    "10/12":"10/12",
    "11/12":"11/12",

    X:"x",
    y:"y",
    t:"t",
    b:"b",
    r:"r",
    l:"l",
}

export default{
    posVal:{
        [keysPosition?.relative]:"relative",
        [keysPosition?.absolute]:"absolute",
        [keysPosition?.fixed]:"fixed",
        [keysPosition?.sticky]:"sticky",
    },
    size:{
        [keysPosition?.px]:"1px",
        [keysPosition?.auto]:"auto",

       
        [keysPosition?.xs]:".5rem",
        [keysPosition?.sm]:"1rem",
        [keysPosition?.md]:"1.5rem",
        [keysPosition?.lg]:"3rem",
        [keysPosition?.xl]:"5rem",

        [keysPosition?.["1/2"]]:"50%",
        [keysPosition?.["1/3"]]:"33.333333%",
        [keysPosition?.["2/3"]]:"66.666667%",
        [keysPosition?.["1/4"]]:"25%",
        [keysPosition?.["2/4"]]:"50%",
        [keysPosition?.["3/4"]]:"75%",
        [keysPosition?.["1/5"]]:"20%",
        [keysPosition?.["2/5"]]:"40%",
        [keysPosition?.["3/5"]]:"60%",
        [keysPosition?.["4/5"]]:"80%",
        [keysPosition?.["1/6"]]:"16.666667%",
        [keysPosition?.["2/6"]]:"33.333333%",
        [keysPosition?.["3/6"]]:"50%",
        [keysPosition?.["4/6"]]:"66.666667%",
        [keysPosition?.["5/6"]]:"83.333333%",

        [keysPosition?.["1/12"]]:"8.333333%",
        [keysPosition?.["2/12"]]:"16.666667%",
        [keysPosition?.["3/12"]]:"25%",
        [keysPosition?.["4/12"]]:"33.333333%",
        [keysPosition?.["5/12"]]:"41.666667%",
        [keysPosition?.["6/12"]]:"50%",
        [keysPosition?.["7/12"]]:"58.333333%",
        [keysPosition?.["8/12"]]:"66.666667%",
        [keysPosition?.["9/12"]]:"75%",
        [keysPosition?.["10/12"]]:"83.333333%",
        [keysPosition?.["11/12"]]:"91.666667%",


        baseSize: 0.25,

    },
    side:{
        X:"x",
        y:"y",
        t:"top",
        b:"bottom",
        r:"right",
        l:"left",
        inset:"inset"
    }
}