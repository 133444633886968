import { objCssProperties } from "packages/style/src/helper/cssProperties";
import keys, { keysSize } from "./keys";

const getSize=(arrClasses, key)=>{
    const size = arrClasses?.[2] ||  arrClasses?.[1]
    if(!isNaN(Number(size)) ){
        return `${keys?.size?.baseSize * Number(size)}rem`
    }else if(keysSize?.[size]){
        if(size === keysSize?.screen){
            return keys?.size?.[keysSize?.screen] + key
        }else{
            return keys?.size?.[keysSize?.[size]]
        }
    }
}

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    if(arrClasses?.[0] === keysSize?.width){
        return {[objCssProperties?.width] : getSize(arrClasses, keysSize?.width)}
    }else if(arrClasses?.[0] === keysSize?.max && arrClasses?.[1] === keysSize?.width){
        return {[objCssProperties?.maxWidth] : getSize(arrClasses, keysSize?.width)}
    }else if(arrClasses?.[0] === keysSize?.min && arrClasses?.[1] === keysSize?.width){
        return {[objCssProperties?.minWidth] : getSize(arrClasses, keysSize?.width)}
    }
    else if(arrClasses?.[0] === keysSize?.height){
        return {[objCssProperties?.height] : getSize(arrClasses, keysSize?.height)}
    }else if(arrClasses?.[0] === keysSize?.max && arrClasses?.[1] === keysSize?.height){
        return {[objCssProperties?.maxHeight] : getSize(arrClasses, keysSize?.height)}
    }else if(arrClasses?.[0] === keysSize?.min && arrClasses?.[1] === keysSize?.height){
        return {[objCssProperties?.minHeight] : getSize(arrClasses, keysSize?.height)}
    }
}