"use client";
// @ts-nocheck
import React, { forwardRef, useContext, useId, useMemo } from "react";
import { styleFunction, ContextThemeProvider, getSpecificCssRule } from "@cbmisorg/styles";
import IconStyle from "./IconStyle";
import IconBody from "./IconBody";

const Icon = forwardRef((/**@type{any}*/ props, ref) => {
  const { icon, color, size, title, sx, hidden, disabled, dir, id, className, ...rest } = props;
  const comID = id || String(useId()).slice(1, -1) + "icon";
  const theTheme = useContext(ContextThemeProvider);

  const { classesName, style, styleSx } = IconStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, color, disabled, onClick: props?.onClick ? true : false });

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.icon_container],
    classesSx?.[classesName?.icon_container],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  // useEffect(() => {
  //   // setComSize("84px");
  //   if (icon_container) {
  //     const containerFontSize = window?.getComputedStyle(icon_container)?.getPropertyValue("font-size");

  //     if (containerFontSize !== comSize) {
  //       setComSize(containerFontSize);
  //     }
  //   }

  //   console.log(classesSx, "classesSx");
  //   console.log(classesSx?.[classesName?.sx], "classesSx?.[classesName?.sx]");
  // }, [icon, window?.getComputedStyle(icon_container)?.getPropertyValue("font-size")]);

  const fontSize = useMemo(() => {
    const fontSizeSx = getSpecificCssRule(["fontSize"], sx, (key, value) => {
      return { height: value, width: value };
    });
    if (!Object.keys(fontSizeSx || {})?.length) {
      return null;
    }
    return fontSizeSx;
  }, [sx]);

  return props?.onClick ? (
    <button className={itemClasses} title={title || ""} id={comID} {...styleFunction?.getRestProps(rest)} ref={ref}>
      {typeof icon === "string" ? <IconBody icon={icon} theTheme={theTheme} comID={comID} size={size} fontSize={fontSize} /> : null}
    </button>
  ) : (
    <div className={itemClasses} title={title || ""} id={comID} {...styleFunction?.getRestProps(rest)} ref={ref}>
      {typeof icon === "string" ? <IconBody icon={icon} theTheme={theTheme} comID={comID} size={size} fontSize={fontSize} /> : null}
    </div>
  );
});

export default Icon;
