import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    slideshow: `${styleVariables?.prefixDefaultClassName}_slideshow`,
    slideshowSlider: `${styleVariables?.prefixDefaultClassName}_slideshowSlider`,
    slide: `${styleVariables?.prefixDefaultClassName}_slide`,
    slide_containt: `${styleVariables?.prefixDefaultClassName}_slide_containt`,
    slide_showDots: `${styleVariables?.prefixDefaultClassName}_slide_showDots`,
    slide_showDot: `${styleVariables?.prefixDefaultClassName}_slide_showDot`,
    slide_showDot_active: `${styleVariables?.prefixDefaultClassName}_slide_showDot_active`,
    slide_btn: `${styleVariables?.prefixDefaultClassName}_slide_btn`,
    slide_btn_prev: `${styleVariables?.prefixDefaultClassName}_slide_btn_prev`,
    slide_btn_next: `${styleVariables?.prefixDefaultClassName}_slide_btn_next`,

    sx: `${styleVariables?.prefixDefaultClassName}_slideshow_sx`,
    sx_slide: `${styleVariables?.prefixDefaultClassName}_slide_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.slideshow]: {
        display: cssObject?.display?.[cssKeys?.block],
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position: cssObject?.position?.[cssKeys?.relative],
        minHeight: "max-content",
        margin: 0,
        overflowY: "visible",
        overflowX: "clip",
        width: "100%",
      },
      [classesName?.slideshowSlider]: {
        whiteSpace: "nowrap",
        transition: "ease 1000ms",
        position: cssObject?.position?.[cssKeys?.relative],
        direction: "inherit",
        display: "flex",
      },
      [classesName?.slide]: {
        display: cssObject?.display?.[cssKeys?.block],
        minHeight: "250px",
        height: "100%",
        borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
        background: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.light]),
        position: cssObject?.position?.[cssKeys?.relative],
        margin: "0 auto",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      },
      [classesName?.slide_containt]: {
        position: cssObject?.position?.[cssKeys?.relative],
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: cssObject?.display?.[cssKeys?.block],
        padding: "1px",
        width: "100%",
        height: "100%",
      },
      [classesName?.slide_showDots]: {
        textAlign: "center",
        width: "100%",
        position: cssObject?.position?.[cssKeys?.absolute],
        bottom: 0,
        zIndex: 1,
      },
      [classesName?.slide_showDot]: {
        position: "relative",
        display: "inline-block",
        height: "7px",
        width: "7px",
        borderRadius: "50%",
        cursor: "pointer",
        margin: "10px 7px",
        background: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColor]),
        border: `2px solid ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColor])}`,
        "&hover": {
          opacity: 0.9,
          background: "transparent",
        },
      },
      [classesName?.slide_btn]: {
        position: "absolute",
        width: "25px",
        height: "25px",
        background: "transparent",
        border: `1px solid ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColor])}`,
        cursor: "pointer",
        overflow: "hidden",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColor]),
        fontSize: "18px",
        borderRadius: "100%",
        top: "50%",
        transform: "translateY(-60%)",
      },
      [classesName?.slide_btn_prev]: {
        left: "20px",
      },
      [classesName?.slide_btn_next]: {
        right: "20px",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.slide_showDot_active]: (props) => {
        return {
          background: styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary]),
          borderColor: styleFunction.checkColorReturnValue(props?.activeColor || cssObject?.color?.[cssKeys?.primary]),
        };
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
      [classesName?.slideshowSlider]: (props) => {
        if (props.slideNumber) {
          return {
            [`.${classesName?.slide}`]: {
              flex: ` 1 0 ${100 / (Number(props.slideNumber) || 1)}%`,
            },
          };
        }
        return {
          [`.${classesName?.slide}`]: {
            flex: ` 1 0 100%`,
          },
        };
      },
      [classesName?.slide]: (props) => {
        if (props.image) {
          return {
            backgroundImage: `url(${props.image})`,
          };
        }
        return null;
      },
      [classesName?.sx_slide]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
