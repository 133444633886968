import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import TableStyle from "./TableStyle";

const Table = forwardRef((/**@type {any}*/props,ref) => {
  const { blnMobileMode, sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleTableSx} = TableStyle(theTheme);
  const classes = style();
  const classesSx = styleTableSx({sx, blnMobileMode:blnMobileMode});
  const comID = id ||  String(useId()).slice(1,-1)

  const itemClasses = styleFunction.getClassName( classes?.[classesName?.table], classesSx?.[classesName?.table], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div className={blnMobileMode?classes?.[classesName?.mobile_mode_contenter]:classes?.[classesName?.table_contenter]} {...styleFunction?.getRestProps({...rest})} ref={ref} key={comID+"_tabel_container"}>
      <table className={itemClasses} key={comID+"_tabel"}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </table>
    </div>
  );
});

export default Table;
