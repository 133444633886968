// @ts-nocheck
import React, { forwardRef, useMemo, useRef, useState } from 'react'
import Menu from '../../navigation/Menu/Menu';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import GroupField from '../GroupField/GroupField.js';
import { checkParseObject } from '../../_helper/functions';
import { useEffect } from 'react';


const MultipleList = forwardRef((/**@type {any}*/props, ref) => {
  const {children,defaultValue,value,options=[],autocomplete,endIcon,onFocus,onChange,...rest} = props
  const refListKey = useRef([])
  const refListValue = useRef([])


  const [initialState, setInitialState] = useState({
    openMenu:false,
    filterValue :""
  })

  const handleFocus=(event)=>{
    initialState.openMenu= !initialState.openMenu;
    setInitialState({...initialState})
    if(onFocus){
      onFocus(event)
    }
  }
  const handleChange=(event)=>{
    const checkValue ={key:event?.target?.id,value:event?.target?.value}
    if(!(refListKey.current.includes(String(checkValue?.key))) && !(refListKey.current.includes(checkValue?.key))){
      refListKey.current=[...refListKey.current,checkValue?.key];
      refListValue.current.push(checkValue?.value)
    }else{
      refListKey.current = refListKey?.current?.filter(item=> item !== String(checkValue?.key))
      refListValue.current = refListValue?.current?.filter(item=> item !== String(checkValue?.value))
      
    }

    // console.log(event.target.checked, refListKey.current, !(refListKey.current.includes(String(checkValue?.key))) && !(refListKey.current.includes(checkValue?.key)));

    // // initialState.openMenu= false;
    // initialState.filterValue="";
    // inputRef.current.value= event?.target?.value || refRadioValue.current?.value || null
    // if(event?.target?.checked || refListKey.current.includes(event?.target?.id) || refListKey.current.includes(String(event?.target?.id))){
    //   refListKey.current = refListKey?.current?.filter(item=> item !== String(event?.target?.id))
    //   refListValue.current = refListValue?.current?.filter(item=> item !== event?.target?.value)
    // }else{
    //   refListKey.current.push(event?.target?.id);
    //   refListValue.current.push(event?.target?.value)
    // }


    
    if(onChange){
      onChange(event)
    }else{
      setInitialState({...initialState})
    }
  }
  
  const eventCloseMenu = () => {
    // inputRef?.current.blur();
    initialState.openMenu= false;
    initialState.filterValue="";
    setInitialState({...initialState})
  }

//   useEffect(() => {
//     if(value || defaultValue){
//       const refCheckValue =checkParseObject(value || defaultValue)
//       refListKey.current = []
//       refListValue.current = []
//       // refCheckValue?.forEach(item=>{
//       //   refListKey.current.push(String(item.key))
//       //   refListValue.current.push(item.value)
//       // })
//     setInitialState({...initialState})
//     }
// }, [value, defaultValue, options])

  useEffect(() => {
    if(value || defaultValue){
      const checkValue =checkParseObject(value || defaultValue)
      refListKey.current = []
      refListValue.current = []
      checkValue?.forEach(item=>{
        refListKey.current.push(String(item.key))
        refListValue.current.push(item.value)
      })
      setInitialState({...initialState})
    }
  }, [defaultValue,value])
  

  useEffect(() => {
  }, [children,options,autocomplete,endIcon,onFocus,onChange,rest])

  const MenuList =({comID,classes,classesName,currentLang}) =>{
    return(
     <Menu open={initialState?.openMenu} id={comID+"_opt"} className={classes?.[classesName?.menuList]} eventClose={eventCloseMenu} blnFullWidth={true}>
        <div className={classes?.[classesName?.menuOptList]} key={`${comID}_ListMenuContainer`}>
            <CheckboxGroup
                options={options} 
                id={`${comID}_ListMenu`} 
                onChange={handleChange} 
                defaultValue={refListKey.current}
                blnMenuInputList={true}
                currentLang={currentLang}
                key={`${comID}_ListMenu`}
              >
                {children}
            </CheckboxGroup>
        </div>
        
         {
          // <Line />
          // <Text 
          //   id={`${comID}_ListMenu_FilterValue`}
          //   color={props?.color}
          //   icon="search"
          //   iconColor={props?.color}
          //   mode="classic"
          // />
        }
      </Menu >
  )}
 
  return (
    <GroupField
      ref={ref}
      onFocus={handleFocus}
      readOnly={true}
      defaultValue={refListValue.current}
      endIcon={endIcon || "arrowDropDown"}
      MenuList={MenuList}
      {...rest}
  />
  )
});

export default MultipleList