"use client";

import { pkgConfigStyle } from "..";

function murmurhash2_32_gc(str) {
  let l = str.length;
  let h = l;
  let i = 0;
  let k;

  while (l >= 4) {
    k = (str.charCodeAt(i) & 0xff) | ((str.charCodeAt(++i) & 0xff) << 8) | ((str.charCodeAt(++i) & 0xff) << 16) | ((str.charCodeAt(++i) & 0xff) << 24);

    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);
    k ^= k >>> 24;
    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);

    h = ((h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16)) ^ k;

    l -= 4;
    ++i;
  } // forgive existing code

  /* eslint-disable no-fallthrough */ switch (l) {
    case 3:
      h ^= (str.charCodeAt(i + 2) & 0xff) << 16;
    case 2:
      h ^= (str.charCodeAt(i + 1) & 0xff) << 8;
    case 1:
      h ^= str.charCodeAt(i) & 0xff;
      h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  }
  /* eslint-enable no-fallthrough */

  h ^= h >>> 13;
  h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  h ^= h >>> 15;

  return (h >>> 0).toString(36);
}

function generateClassName(objPrevCache, blnRandomPrefix, objStyles, className, sx, props) {
  try {
    let objClassesNameLocal = {};

    if (objStyles && !className && !sx) {
      let lstClasses = Object.entries(objStyles || {});
      for (let i = 0; i < lstClasses.length; i++) {
        let [classNameMulti, objStyleMulti] = lstClasses[i];
        if (!classNameMulti) {
          continue;
        }

        if (!objPrevCache?.[classNameMulti]) {
          let hashPrefix = "";
          let objStyleAsString = "";
          if (blnRandomPrefix) {
            if (typeof objStyleMulti === "function") {
              objStyleAsString = JSON.stringify(objStyleMulti(props));
            } else {
              objStyleAsString = JSON.stringify(objStyleMulti);
            }
            if (!objStyleAsString) {
              objStyleAsString = String(i);
            }
            hashPrefix = murmurhash2_32_gc(objStyleAsString);
          }

          objClassesNameLocal[classNameMulti] = blnRandomPrefix ? `${classNameMulti}_${hashPrefix}` : classNameMulti;
        } else {
          objClassesNameLocal[classNameMulti] = objPrevCache[classNameMulti];
        }
      }
    } else if (!objStyles && className && sx) {
      if (!objPrevCache?.[className]) {
        let hashPrefix = "";
        let objStyleAsString = "";
        if (blnRandomPrefix) {
          if (typeof sx === "function") {
            objStyleAsString = JSON.stringify(sx(props));
          } else {
            objStyleAsString = JSON.stringify(sx);
          }

          if (!objStyleAsString) {
            objStyleAsString = "";
          }

          hashPrefix = murmurhash2_32_gc(objStyleAsString);
        }

        objClassesNameLocal[className] = blnRandomPrefix ? `${className}_${hashPrefix}` : className;
      } else {
        objClassesNameLocal[className] = objPrevCache[className];
      }
    }

    return objClassesNameLocal;
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
    if (objStyles && !className) {
      return Object.keys(objStyles || {});
    } else {
      return className;
    }
  }
}

function generateClassNameOnFly(intIndexInList, blnRandomPrefix, className, objInlineStyle) {
  try {
    let hashPrefix = "";
    let objStyleAsString = "";
    if (blnRandomPrefix) {
      objStyleAsString = JSON.stringify(objInlineStyle);
      if (!objStyleAsString) {
        objStyleAsString = String(intIndexInList);
      }

      hashPrefix = murmurhash2_32_gc(objStyleAsString);
    }

    return blnRandomPrefix ? `${className}_${hashPrefix}` : className;
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
    return null;
  }
}

export function setClassHash(_styleRef, _props, { blnRandomPrefix, lstStyleClasses, className, objStyles, sx }) {
  try {
    if (!_styleRef?.objClassesNames) {
      let obj = generateClassName(_styleRef?.objClassesNames, blnRandomPrefix, objStyles, className, sx, _props);
      _styleRef.objClassesNames = obj;
      _styleRef.prevProps = JSON.stringify(_props);

      return obj;
    } else if (_styleRef?.blnInitRun && _styleRef?.objClassesNames && blnRandomPrefix) {
      if (JSON.stringify(_props) === _styleRef?.prevProps) {
        return _styleRef.objClassesNames;
      }

      _styleRef.prevProps = JSON.stringify(_props);

      let obj = _styleRef.objClassesNames;
      for (let i = 0; i < lstStyleClasses.length; i++) {
        if (!lstStyleClasses?.[i]?.[0]) {
          continue;
        }

        let strClassName_client = className ? className : lstStyleClasses?.[i]?.[0];
        let strClassName_rule = _styleRef.objClassesNames[strClassName_client];
        let inlineStyle = lstStyleClasses?.[i]?.[1];
        if (typeof inlineStyle !== "function") {
          continue;
        }

        inlineStyle = inlineStyle(_props);

        if (!inlineStyle) {
          continue;
        }

        let strClassNameCheck = generateClassNameOnFly(i, blnRandomPrefix, strClassName_client, inlineStyle);

        if (strClassNameCheck !== strClassName_rule) {
          _styleRef.objClassesNames[strClassName_client] = strClassNameCheck;
          obj[strClassName_client] = strClassNameCheck;
          _styleRef.objDelMicro = { ..._styleRef?.objDelMicro };
          _styleRef.objDelMicro[strClassName_client] = { strClassName_rule: strClassName_rule };
          if (_styleRef?.intVersion >= Number.MAX_SAFE_INTEGER) {
            _styleRef.intVersion = 1;
          }
          ++_styleRef.intVersion;
        }
      }
      return obj;
    }
    return _styleRef?.objClassesNames || {};
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.error(error);
    }
    return {};
  }
}
