// @ts-nocheck

"use client";

import { baseSizeLG, baseSizeMD, baseSizeSM, baseSizeXL, baseSizeXS } from "./cssVariables";

export const localStorageName = "app-themes-list-name";
export const localStorageNameBaseSiz = "app-themes-base-size";

export const appDefaultThemes = ["lightMode", "engMode", "sizeSM"];

export default function ChangeThemes(listThemes = appDefaultThemes) {
  var appThemes = listThemes;
  // if(Array.isArray(listThemes?.lst)){
  //     appThemes = JSON.stringify(listThemes?.lst)
  // }
  if (Array.isArray(listThemes)) {
    appThemes = JSON.stringify(listThemes);
  }
  // switch (String(appThemes?.[2]).slice(-2)) {
  //     case "xs":
  //         localStorage?.setItem(localStorageNameBaseSiz, baseSizeXS)
  //         break;
  //     case "sm":
  //         localStorage?.setItem(localStorageNameBaseSiz, baseSizeSM)
  //         break;
  //     case "lg":
  //         localStorage?.setItem(localStorageNameBaseSiz, baseSizeLG)
  //         break;
  //     case "xl":
  //         localStorage?.setItem(localStorageNameBaseSiz, baseSizeXL)
  //         break;
  //     default:
  //         localStorage?.setItem(localStorageNameBaseSiz, baseSizeMD)
  //         break;
  // }
  // localStorage?.setItem(localStorageName, appThemes)
}
