import { baseSize } from "../../cssVariable/cssVariable"

export const keysSize={
    width:"w",
    height:"h",
    min:"min",
    max:"max",

    px:"px",
    auto:"auto",
    full:"full",
    half:"half",
    screen:"screen",

    xs:"xs",
    sm:"sm",
    md:"md",
    lg:"lg",
    xl:"xl",

    item_xs:"item_xs",
    item_sm:"item_sm",
    item_md:"item_md",
    item_lg:"item_lg",
    item_xl:"item_xl",

    "1/2":"1/2",
    "1/3":"1/3",
    "2/3":"2/3",
    "1/4":"1/4",
    "2/4":"2/4",
    "3/4":"3/4",
    "1/5":"1/5",
    "2/5":"2/5",
    "3/5":"3/5",
    "4/5":"4/5",

    "1/6":"1/6",
    "2/6":"2/6",
    "3/6":"3/6",
    "4/6":"4/6",
    "5/6":"5/6",

    "1/12":"1/12",
    "2/12":"2/12",
    "3/12":"3/12",
    "4/12":"4/12",
    "5/12":"5/12",
    "6/12":"6/12",
    "7/12":"7/12",
    "8/12":"8/12",
    "9/12":"9/12",
    "10/12":"10/12",
    "11/12":"11/12",
}

export default{
    size:{
        [keysSize?.px]:"1px",
        [keysSize?.auto]:"auto",
        [keysSize?.full]:"100%",
        [keysSize?.half]:"50%",
        [keysSize?.screen]:"100v",

        [keysSize?.xs]:"25%",
        [keysSize?.sm]:"41.666667%",
        [keysSize?.md]:"58.333333%",
        [keysSize?.lg]:"75%",
        [keysSize?.xl]:"91.666667%",

        [keysSize?.item_xs]:`${baseSize * 6}rem`,
        [keysSize?.item_sm]:`${baseSize * 8}rem`,
        [keysSize?.item_md]:`${baseSize * 10}rem`,
        [keysSize?.item_lg]:`${baseSize * 12}rem`,
        [keysSize?.item_xl]:`${baseSize * 15}rem`,

        [keysSize?.["1/2"]]:"50%",
        [keysSize?.["1/3"]]:"33.333333%",
        [keysSize?.["2/3"]]:"66.666667%",
        [keysSize?.["1/4"]]:"25%",
        [keysSize?.["2/4"]]:"50%",
        [keysSize?.["3/4"]]:"75%",
        [keysSize?.["1/5"]]:"20%",
        [keysSize?.["2/5"]]:"40%",
        [keysSize?.["3/5"]]:"60%",
        [keysSize?.["4/5"]]:"80%",
        [keysSize?.["1/6"]]:"16.666667%",
        [keysSize?.["2/6"]]:"33.333333%",
        [keysSize?.["3/6"]]:"50%",
        [keysSize?.["4/6"]]:"66.666667%",
        [keysSize?.["5/6"]]:"83.333333%",

        [keysSize?.["1/12"]]:"8.333333%",
        [keysSize?.["2/12"]]:"16.666667%",
        [keysSize?.["3/12"]]:"25%",
        [keysSize?.["4/12"]]:"33.333333%",
        [keysSize?.["5/12"]]:"41.666667%",
        [keysSize?.["6/12"]]:"50%",
        [keysSize?.["7/12"]]:"58.333333%",
        [keysSize?.["8/12"]]:"66.666667%",
        [keysSize?.["9/12"]]:"75%",
        [keysSize?.["10/12"]]:"83.333333%",
        [keysSize?.["11/12"]]:"91.666667%",
    },
}