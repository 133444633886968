import React, { forwardRef, useContext} from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ContainerStyle from "./ContainerStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Container = forwardRef((/**@type {any}*/props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = ContainerStyle(theTheme);
  const { width, sx, hidden, disabled, dir, className, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({sx, width});

  const itemClasses =  styleFunction.getClassName(
    classes?.[classesName?.drawer],
    classesSx?.[classesName?.drawer],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );
  return (
      <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
  );
});

export default Container;