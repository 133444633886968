import { objCss } from "../themeProvider/cssVariables";
const defaultCssValue = objCss();
export const checkColorReturnValue = (color) => {
  const getColor = String(color).toLowerCase();
  return defaultCssValue?.color?.[getColor] || getColor;
};
export const getShapeReturnClassName = (shape) => {
  const theShape = String(shape).toLowerCase();

  switch (theShape) {
    case "circle":
      return "cbmis-shape-circle";
    case "oval":
      return "cbmis-shape-oval";
    case "square":
      return "cbmis-shape-square";
    case "rectangle":
      return "cbmis-shape-rectangle";
    case "pentagon":
      return "cbmis-shape-pentagon";
    case "hexagon":
      return "cbmis-shape-hexagon";
    case "heptagon":
      return "cbmis-shape-heptagon";
    case "octagon":
      return "cbmis-shape-octagon";
    case "star":
      return "cbmis-shape-star";
    case "topchevron":
      return "cbmis-shape-top-chevron";
    case "leftchevron":
      return "cbmis-shape-left-chevron";
    case "bottomchevron":
      return "cbmis-shape-bottom-chevron";
    case "rightchevron":
      return "cbmis-shape-right-chevron";
    default:
      return "cbmis-shape-circle";
  }
};
/*******************************************margin ***********************************************/
export const changeItemMarginClassName = (margin) => {
  const getArrMargin = String(margin).toLowerCase().split("-");
  if (getArrMargin.length === 2) {
    switch (getArrMargin[0]) {
      case "m":
        return `cbmis-margin-${getArrMargin[1]}`;
      case "mt":
        return `cbmis-margin-top-${getArrMargin[1]}`;
      case "ml":
        return `cbmis-margin-left-${getArrMargin[1]}`;
      case "mb":
        return `cbmis-margin-bottom-${getArrMargin[1]}`;
      case "mr":
        return `cbmis-margin-right-${getArrMargin[1]}`;
      case "my":
        return `cbmis-margin-y-${getArrMargin[1]}`;
      case "mx":
        return `cbmis-margin-x-${getArrMargin[1]}`;
      default:
        break;
    }
  } else if (getArrMargin.length === 3) {
    const screenWidth = window.innerWidth;
    var marginClassName = "";

    switch (getArrMargin[0]) {
      case "m":
        marginClassName = `cbmis-margin-${getArrMargin[2]}`;
        break;
      case "mt":
        marginClassName = `cbmis-margin-top-${getArrMargin[2]}`;
        break;
      case "ml":
        marginClassName = `cbmis-margin-left-${getArrMargin[2]}`;
        break;
      case "mb":
        marginClassName = `cbmis-margin-bottom-${getArrMargin[2]}`;
        break;
      case "mr":
        marginClassName = `cbmis-margin-right-${getArrMargin[2]}`;
        break;
      case "my":
        marginClassName = `cbmis-margin-y-${getArrMargin[2]}`;
        break;
      case "mx":
        marginClassName = `cbmis-margin-x-${getArrMargin[2]}`;
        break;
      default:
        break;
    }
    if (getArrMargin[1] === "xs" && screenWidth < 576) {
      return marginClassName;
    } else if (getArrMargin[1] === "sm" && screenWidth >= 576 && screenWidth < 768) {
      return marginClassName;
    } else if (getArrMargin[1] === "md" && screenWidth >= 768 && screenWidth < 992) {
      return marginClassName;
    } else if (getArrMargin[1] === "lg" && screenWidth >= 992 && screenWidth < 1200) {
      return marginClassName;
    } else if (getArrMargin[1] === "xl" && screenWidth >= 1200) {
      return marginClassName;
    }
  }
};

export const checkExistsMargin = (obj) => {
  var marginClassesName = "";
  const margin = Object.keys(obj).filter((item) => item.includes("m-"));
  const marginTop = Object.keys(obj).filter((item) => item.includes("mt-"));
  const marginLeft = Object.keys(obj).filter((item) => item.includes("ml-"));
  const marginBottom = Object.keys(obj).filter((item) => item.includes("mb-"));
  const marginRight = Object.keys(obj).filter((item) => item.includes("mr-"));
  const marginY = Object.keys(obj).filter((item) => item.includes("my-"));
  const marginX = Object.keys(obj).filter((item) => item.includes("mx-"));

  for (let index = 0; index < margin.length; index++) {
    marginClassesName += changeItemMarginClassName(margin[index]) + " ";
  }
  for (let index = 0; index < marginTop.length; index++) {
    marginClassesName += changeItemMarginClassName(marginTop[index]) + " ";
  }
  for (let index = 0; index < marginLeft.length; index++) {
    marginClassesName += changeItemMarginClassName(marginLeft[index]) + " ";
  }
  for (let index = 0; index < marginBottom.length; index++) {
    marginClassesName += changeItemMarginClassName(marginBottom[index]) + " ";
  }
  for (let index = 0; index < marginRight.length; index++) {
    marginClassesName += changeItemMarginClassName(marginRight[index]) + " ";
  }
  for (let index = 0; index < marginBottom.length; index++) {
    marginClassesName += changeItemMarginClassName(marginBottom[index]) + " ";
  }
  for (let index = 0; index < marginRight.length; index++) {
    marginClassesName += changeItemMarginClassName(marginRight[index]) + " ";
  }
  for (let index = 0; index < marginY.length; index++) {
    marginClassesName += changeItemMarginClassName(marginY[index]) + " ";
  }
  for (let index = 0; index < marginX.length; index++) {
    marginClassesName += changeItemMarginClassName(marginX[index]) + " ";
  }
  return marginClassesName;
};

/*******************************************padding ***********************************************/
export const changeItemPaddingClassName = (padding) => {
  const getArrPadding = String(padding).toLowerCase().split("-");
  if (getArrPadding.length === 2) {
    switch (getArrPadding[0]) {
      case "p":
        return `cbmis-padding-${getArrPadding[1]}`;
      case "pt":
        return `cbmis-padding-top-${getArrPadding[1]}`;
      case "pl":
        return `cbmis-padding-left-${getArrPadding[1]}`;
      case "pb":
        return `cbmis-padding-bottom-${getArrPadding[1]}`;
      case "pr":
        return `cbmis-padding-right-${getArrPadding[1]}`;
      case "py":
        return `cbmis-padding-y-${getArrPadding[1]}`;
      case "px":
        return `cbmis-padding-x-${getArrPadding[1]}`;
      default:
        break;
    }
  } else if (getArrPadding.length === 3) {
    const screenWidth = window.innerWidth;
    var paddingClassName = "";

    switch (getArrPadding[0]) {
      case "p":
        paddingClassName = `cbmis-padding-${getArrPadding[2]}`;
        break;
      case "pt":
        paddingClassName = `cbmis-padding-top-${getArrPadding[2]}`;
        break;
      case "pl":
        paddingClassName = `cbmis-padding-left-${getArrPadding[2]}`;
        break;
      case "pb":
        paddingClassName = `cbmis-padding-bottom-${getArrPadding[2]}`;
        break;
      case "pr":
        paddingClassName = `cbmis-padding-right-${getArrPadding[2]}`;
        break;
      case "py":
        paddingClassName = `cbmis-padding-y-${getArrPadding[2]}`;
        break;
      case "px":
        paddingClassName = `cbmis-padding-x-${getArrPadding[2]}`;
        break;
      default:
        break;
    }
    if (getArrPadding[1] === "xs" && screenWidth < 576) {
      return paddingClassName;
    } else if (getArrPadding[1] === "sm" && screenWidth >= 576 && screenWidth < 768) {
      return paddingClassName;
    } else if (getArrPadding[1] === "md" && screenWidth >= 768 && screenWidth < 992) {
      return paddingClassName;
    } else if (getArrPadding[1] === "lg" && screenWidth >= 992 && screenWidth < 1200) {
      return paddingClassName;
    } else if (getArrPadding[1] === "xl" && screenWidth >= 1200) {
      return paddingClassName;
    }
  }
};

export const checkExistsPadding = (obj) => {
  var paddingClassesName = "";
  const padding = Object.keys(obj).filter((item) => item.includes("p-"));
  const paddingTop = Object.keys(obj).filter((item) => item.includes("pt-"));
  const paddingLeft = Object.keys(obj).filter((item) => item.includes("pl-"));
  const paddingBottom = Object.keys(obj).filter((item) => item.includes("pb-"));
  const paddingRight = Object.keys(obj).filter((item) => item.includes("pr-"));
  const paddingY = Object.keys(obj).filter((item) => item.includes("py-"));
  const paddingX = Object.keys(obj).filter((item) => item.includes("px-"));

  for (let index = 0; index < padding.length; index++) {
    paddingClassesName += changeItemPaddingClassName(padding[index]) + " ";
  }
  for (let index = 0; index < paddingTop.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingTop[index]) + " ";
  }
  for (let index = 0; index < paddingLeft.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingLeft[index]) + " ";
  }
  for (let index = 0; index < paddingBottom.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingBottom[index]) + " ";
  }
  for (let index = 0; index < paddingRight.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingRight[index]) + " ";
  }
  for (let index = 0; index < paddingY.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingY[index]) + " ";
  }
  for (let index = 0; index < paddingX.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingX[index]) + " ";
  }
  return paddingClassesName;
};

export const filterPaddingAndMargin = (obj) => {
  const rest = Object.entries(obj).filter(([key, value]) => !key.includes("p-") && !key.includes("pt-") && !key.includes("pl-") && !key.includes("pb-") && !key.includes("pr-") && !key.includes("px-") && !key.includes("py-") && !key.includes("m-") && !key.includes("mt-") && !key.includes("ml-") && !key.includes("mb-") && !key.includes("mr-") && !key.includes("mx-") && !key.includes("my-"));
  return Object.fromEntries(rest);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
export function hasClass(el, className) {
  try {
    if (el.classList) {
      return el.classList.contains(className);
    }
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
  } catch {
    return false;
  }
}

export function addClass(el, className) {
  try {
    if (!hasClass(el, className)) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  } catch {}
}

export function removeClass(el, className) {
  try {
    if (el.classList) {
      el.classList.remove(className);
    } else if (hasClass(el, className)) {
      var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      el.className = el.className.replace(reg, " ");
    }
  } catch {}
}

export function replaceClass(el, oldClassName, newClassName) {
  try {
    if (el.classList) {
      el.classList.replace(oldClassName, newClassName);
    } else if (hasClass(el, oldClassName)) {
      let oldReg = new RegExp("(\\s|^)" + oldClassName + "(\\s|$)");
      el.className = el.className.replace(oldReg, newClassName + " ");
    }
  } catch {}
}

export const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/*********************************************************************************************/
export const CheckDisabledItem = (disabled) => {
  if (disabled) {
    return "client_disabled";
  } else {
    return "";
  }
};

export const CheckHiddenItem = (hidden) => {
  if (hidden) {
    return "client_invisible_hidden";
  } else {
    return "";
  }
};

export const CheckDirectionItem = (dir) => {
  if (dir) {
    return `client_dir_${dir}`;
  } else {
    return "";
  }
};

export const getRestProps = (rest) => {
  const newRest = filterPaddingAndMargin({ ...rest });
  return { ...newRest };
};

export const getClassName = (...restClassName) => {
  let lstClassName = Array.isArray(restClassName) ? restClassName : [];
  if (typeof lstClassName[lstClassName.length - 1] === "undefined") {
    lstClassName.pop();
  }
  return lstClassName.join(" ").trim();
};
