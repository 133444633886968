
function getPkgConfigRouter() {
    const initConfig = {
      blnIsTestingMode: true,
      blnIsReactRouter: true,
    };
    return initConfig;
  }

  export var pkgConfigRouter = getPkgConfigRouter();

  export function setPkgConfigRouter({ blnIsTestingMode = true, blnIsReactRouter= false, }) {
    pkgConfigRouter.blnIsTestingMode = blnIsTestingMode;
    pkgConfigRouter.blnIsReactRouter = blnIsReactRouter;
  }

  export {
    BrowserRouter,
    createBrowserRouter,
    Link,
    Navigate,
    Outlet,
    Route,
    Router,
    RouterProvider,
    Routes,
    createPath,
    createRoutesFromChildren,
    createRoutesFromElements,
    useLocation,
    useMatch,
    useNavigate,
    useOutlet,
    useParams,
    useRoutes,
    useSearchParams,
  } from "react-router-dom";

// //#region SecureRoute
export * as SecureRoute from './secureRoute/SecureRoute';
// export * as SecureRoute from './secureRoute/SecureRouteNext';

// //#endregion