import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    badge_container: `${styleVariables?.prefixDefaultClassName}_badge_container`,
    badge: `${styleVariables?.prefixDefaultClassName}_badge`,
    mdl_badge: `${styleVariables?.prefixDefaultClassName}_mdl_badge`,
    badge_content:`${styleVariables?.prefixDefaultClassName}_badge_content`,
    sx:`${styleVariables?.prefixDefaultClassName}_badge_sx`,
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.badge_container]: {
        position: cssObject.position?.[cssKeys?.relative],
        boxSizing: "inherit",
        display: "inline-flex",
        verticalAlign: "middle",
        flexShrink: 0,
        margin: 0,
        flexDirection: "column",
        alignItems: "center",
      },
      [classesName?.badge]: {
        lineHeight: 1,
        display: "block",
        margin: 0,
      },
      [classesName?.mdl_badge]: {
        display: "block",
        margin: 0,
        position:"relative",
      },
      [classesName?.badge_content]: {
        position: "absolute",
        zIndex: 1,
        display: "flex",
        justifyContent:"center",
        textAlign:"center",
        flexFlow: "row wrap",
        boxPack: "center",
        WebkitBoxPack: "center",
        placeContent: "center",
        boxAlign: "center",
        WebkitBoxAlign: "center",
        alignItems: "center",
        boxSizing: "border-box",
        fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
        lineHeight: 10,
        padding: "auto",
        transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.mdl_badge]: {
        fontSize: cssObject?.size?.[cssKeys?.body1]
      },
      [classesName?.badge_content]:(props)=>{
        return {
        fontWeight: cssObject?.fontWeight?.[cssKeys?.fontWeight_md],
          color:cssObject?.color?.[cssKeys?.light],
          border: `${currentBaseSize/5}rem solid ${cssObject?.color?.[cssKeys?.white]}`,
          fontSize: `${currentBaseSize*2.7}rem`,
          minWidth: `${currentBaseSize*4}rem`,
          height: `${currentBaseSize*4}rem`,
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.pill,
          backgroundColor: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary]),
          ...getVertical(props?.vertical),
          ...getHorizontal(props?.horizontal),
          ...getStyleMode(props?.mode),
        }
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
 });

  const getHorizontal = (horizontal) => {
    const getHorizontal = String(horizontal).toLowerCase();
    switch (getHorizontal) {
      case "left":
        return {
            position: "absolute",
            left: `-${cssObject.spacing?.[cssKeys?.md]}`,
            right: "auto",
            transform: "scale(1) translateX(-50%)",
            transformOrigin: "0% 100%",
        }
      default:
        return {
            position: "absolute",
            right: `-${cssObject.spacing?.[cssKeys?.md]}`,
            left: "auto",
            transform: "scale(1) translateX(-50%)",
            transformOrigin: "0% 100%",
        }
    }
  };

  const getVertical = (vertical) => {
    const getVertical = String(vertical).toLowerCase();
    switch (getVertical) {
      case "bottom":
        return {
            position: "absolute",
            bottom: `-${cssObject.spacing?.[cssKeys?.sm]}`,
            auto: "auto",
            transform: "scale(1) translateY(-50%)",
            transformOrigin: "100% 100%",
        };
      default:
        return {
            position: "absolute",
            top: `-${cssObject.spacing?.[cssKeys?.sm]}`,
            bottom: "auto",
            transform: "scale(1) translateY(-50%)",
            transformOrigin: "100% 0%",
        };
    }
  };

  const getStyleMode = (mode) => {
    const getStyle = String(mode).toLowerCase();
    switch (getStyle) {
      case "dot":
        return  {
          minWidth: cssObject.spacing?.[cssKeys?.sm],
          padding: "0px",
          margin: "0px",
          height:cssObject.spacing?.[cssKeys?.sm],
          border: "none",
      };
      default:
        return null;
    }
  };

  return{
    classesName,
    style,
    styleSx,
  }
}