import colorGradientName from "./colorGradientName"

// let HEX = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i
// let SHORT_HEX = /^#([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i
// let VALUE = /(?:\d+|\d*\.\d+)%?/
// let SEP = /(?:\s*,\s*|\s+)/
// let ALPHA_SEP = /\s*[,/]\s*/
// let CUSTOM_PROPERTY = /var\(--(?:[^ )]*?)(?:,(?:[^ )]*?|var\(--[^ )]*?\)))?\)/


// let RGB = new RegExp(
//   `^(rgba?)\\(\\s*(${VALUE.source}|${CUSTOM_PROPERTY.source})(?:${SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?(?:${SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?(?:${ALPHA_SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?\\s*\\)$`
// )
// let HSL = new RegExp(
//   `^(hsla?)\\(\\s*((?:${VALUE.source})(?:deg|rad|grad|turn)?|${CUSTOM_PROPERTY.source})(?:${SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?(?:${SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?(?:${ALPHA_SEP.source}(${VALUE.source}|${CUSTOM_PROPERTY.source}))?\\s*\\)$`
// )

export const returnColor = (arrCssValue)=>{
  if(arrCssValue.length===3){
      return colorGradientName?.[arrCssValue[1]]?.[arrCssValue[2]]
  }else if(typeof colorGradientName?.[arrCssValue[1]] === "string"){
      return colorGradientName?.[arrCssValue[1]]
  }else if(typeof colorGradientName?.[arrCssValue[1]] === "object"){
      return colorGradientName?.[arrCssValue[1]]?.["500"]
  }else{
      return colorGradientName?.transparent
  }
}

// export function changeColorAlpha(color, opacity) 
// {
//     //if it has an alpha, remove it
//     if (color.length > 7)
//         color = color.substring(0, color.length - 2);

//     // coerce values so ti is between 0 and 1.
//     const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
//     let opacityHex = _opacity.toString(16).toUpperCase()
// }

// function changeColorAlpha(color, opacity) 
// {
//     //if it has an alpha, remove it
//     if (color.length > 7)
//         color = color.substring(0, color.length - 2);

//     // coerce values so ti is between 0 and 1.
//     const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
//     let opacityHex = _opacity.toString(16).toUpperCase()

//     // opacities near 0 need a trailing 0
//     if (opacityHex.length == 1)
//         opacityHex = "0" + opacityHex;

//     return color + opacityHex;
// }
// function rgb2hex(rgb, rgba){
//     rgb = rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
//     rgba = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

//   var red = Math.round((rgba[1] * rgba[4]) + (rgb[1] * (1-rgba[4])));
//   var blue = Math.round((rgba[2] * rgba[4]) + (rgb[2] * (1-rgba[4])));
//   var green = Math.round((rgba[3] * rgba[4]) + (rgb[3] * (1-rgba[4])));  
//  return (rgba && rgba.length === 5) ? "#" +
//   ("0" + red.toString(16)).slice(-2) +
//   ("0" + blue.toString(16)).slice(-2) +
//   ("0" + green.toString(16)).slice(-2) : '';
// }