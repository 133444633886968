import { objCssProperties } from "packages/style/src/helper/cssProperties";
import { returnColor } from "../color/color";
import keys, { keysBG } from "./keys";

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    if(arrClasses?.[1] === keysBG?.none){
        return {[objCssProperties?.backgroundImage] : keysBG?.none}
    }else if(arrClasses?.[1] === keysBG?.repeat){
        if(arrClasses?.length === 2){
            return {[`${objCssProperties?.background}-${keysBG?.repeat}`] : keys?.[keysBG?.repeat]?.repeat}
        }else if(arrClasses?.length === 3){
            return {[`${objCssProperties?.background}-${keysBG?.repeat}`] : keys?.[keysBG?.repeat]?.[arrClasses?.[2]]}
        }
    }else if(arrClasses?.[1] === "no"){
        return {[`${objCssProperties?.background}-${keysBG?.repeat}`] : keys?.[keysBG?.repeat]?.no}
    }else if(arrClasses?.[1] === keysBG?.opacity){
        return {[keysBG?.opacity]: (Number(arrClasses?.[1]) / 100 ) || 1}
    }else if(keys?.[keysBG?.position]?.[arrClasses?.[1]]){
        if(arrClasses?.length === 2){
            return {[`${objCssProperties?.background}-${keysBG?.position}`] : keys?.[keysBG?.position]?.[arrClasses?.[1]]}
        }else if(arrClasses?.length === 3){
            return {[`${objCssProperties?.background}-${keysBG?.position}`] : `${keys?.[keysBG?.position]?.[arrClasses?.[1]]} ${keys?.[keysBG?.position]?.[arrClasses?.[2]]}` }
        }
    }else if(keys?.[keysBG?.size]?.[arrClasses?.[1]]){
        return {[`${objCssProperties?.background}-${keysBG?.size}`]: keys?.[keysBG?.size]?.[arrClasses?.[1]]}
    }else if(keys?.[keysBG?.attachment]?.[arrClasses?.[1]]){
        return {[`${objCssProperties?.background}-${keysBG?.attachment}`]: keys?.[keysBG?.attachment]?.[arrClasses?.[1]]}
    }else if(arrClasses?.[1] === keysBG?.clip){
        return {[`${objCssProperties?.background}-${keysBG?.clip}`]: keys?.[keysBG?.attachment]?.[arrClasses?.[2]]}
    }else{
        return {[objCssProperties?.backgroundColor] : returnColor(arrClasses)}

    }
}