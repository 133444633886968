import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import BadgeStyle from "./BadgeStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Badge = forwardRef((/**@type {any}*/props, ref) => {
  const { content, mode, vertical, horizontal, color, sx, hidden, id, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = BadgeStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({...props?.sx, mode, vertical, horizontal, color});
  const comID = id ||  String(useId()).slice(1,-1)

  const classesNamesTag0 = styleFunction?.getClassName(classes?.[classesName?.badge_container], styleFunction.CheckDirectionItem(dir));
  const classesNamesTag1 = styleFunction?.getClassName(classes?.[classesName?.badge], classes?.[classesName?.mdl_badge], classesSx?.[classesName?.mdl_badge]);
  const classesNamesTag2 = styleFunction?.getClassName(classes?.[classesName?.badge_content], classesSx?.[classesName?.badge_content], classesSx?.[classesName?.sx], styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden), styleFunction.checkExistsMargin(rest), styleFunction.checkExistsPadding(rest), className || "");

  return (
    <div className={classesNamesTag0} {...styleFunction?.getRestProps({...rest})} key={comID+"_badge_container"}>
      <div className={classesNamesTag1} key={comID+"_badge"}>
        <ErrorBoundary>{props?.children}</ErrorBoundary>
        <div className={classesNamesTag2} ref={ref} key={comID+"_badge_content"}>
          {content}
        </div>
      </div>
    </div>
  );
});

export default Badge;
