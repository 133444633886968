import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { App_info_Color } from "../../../../../../util/appStyle";
// import robot1 from "../../../../../../util/assets/images/robot1.png";
// import robot2 from "../../../../../../util/assets/images/robot2.png";
// import robot3 from "../../../../../../util/assets/images/robot3.png";
// import robot4 from "../../../../../../util/assets/images/robot4.png";
// import robot5 from "../../../../../../util/assets/images/robot5.png";
// import robot6 from "../../../../../../util/assets/images/robot6.png";
import robot7 from "../../../../../../util/assets/images/robot7.png";
import robot8 from "../../../../../../util/assets/images/robot8.png";
// import robot9 from "../../../../../../util/assets/images/robot12.png";
// import robot10 from "../../../../../../util/assets/images/robot10.png";

import RegActivityGuestView from "../regActivityGuest/RegActivityGuest.view";

// const lstImg = [robot1,robot2, robot3, robot4, robot5, robot6, robot7, robot8, robot9, robot10]
const labels = dictionary?.components?.stakeholders?.member?.account?.runningActivity;
function RunningActivityView({ isLoading, setIsLoading, parentState, classes }) {
  const { appState } = useApp();
  const [state, setState] = useState({
    blnOpenModal: false,
    selectedObj: null,
  });
  const lang = appState?.clientInfo?.strLanguage;

  const dialogModal =
    (selectedObj = null) =>
    () => {
      setState({ ...state, blnOpenModal: !state?.blnOpenModal, selectedObj: selectedObj });
    };

  return {
    lstElm: (
      <React.Fragment>
        {parentState?.lstRunningActivity?.map((item) => {
          return (
            <Paper className={classes?.bgBox2} key={item?.objActivity?.id} p-0>
              <Grid container justifyContent="center" spacing={0}>
                <Grid item xs="12">
                  <Typography as="h6" className={classes?.mainTitle} p-0>
                    {item?.objActivity?.value?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="12">
                  <Typography as="subtitle2" className={classes?.mainTitle} dir="ltr" p-0>
                    {`(${new Date(item?.lstActiveSubjectOrdered?.[0]?.subjectValue?.date?.value?.eng).getDate()}-${item?.dtmLastDate})`}
                  </Typography>
                </Grid>
                {!appState?.userInfo?.blnIsActivityUser ? (
                  <Grid item pb-3>
                    <Button label={labels?.btnReg?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} onClick={dialogModal(item)} />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item pb-3 px-2>
                      <Button label={labels?.btnDetails?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} />
                    </Grid>
                    <Grid item pb-3 px-2>
                      <Button label={labels?.btnEdit?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} onClick={dialogModal(item)} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <img src={lang==="arb"? robot8 :robot7} alt="" className={classes?.robotImg} />
            </Paper>
          );
        })}
      </React.Fragment>
    ),
    modal: (
      <React.Fragment>
        <Modal open={state?.blnOpenModal} eventClose={dialogModal()} className={classes?.modal}>
          <ModalHeader className={classes?.modalHeader}>
            <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
              {state?.selectedObj?.objActivity?.value?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <RegActivityGuestView isLoading={isLoading} setIsLoading={setIsLoading} parentState={parentState} activityInfo={state?.selectedObj} closeModal={dialogModal()} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    ),
  };
}

export default RunningActivityView;
