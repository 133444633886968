import React from 'react'
import { fieldType } from '../formVariables/FormVariables';

function inputHelper(objectHandler) {
  //#region
      //#region updateValue

      //#region for text and textarea
      const handleTextValueChange = (event, blnSetState) => {
        const value = event.target.value
        objectHandler?.setValue(value, true, blnSetState)
      };

      // for text and textarea with languages
      const handleTextLangValueChange = (event, blnSetState) => {
        const value = event.target.value
        var fieldValue = objectHandler?.getValue() || {};
        var language = objectHandler?.language?.field || objectHandler?.language?.current || objectHandler?.language?.default;
        if(typeof fieldValue === "string"  && fieldValue){
          if(language && typeof fieldValue === "string"){
            fieldValue = {
              [language]:fieldValue,
            }
          }else if(typeof fieldValue !=="object" && !fieldValue){
            fieldValue = {}
          }
        }
        fieldValue[language] = value
        if(objectHandler?.validations?.email === true || objectHandler?.validations?.email?.condition === true){
          fieldValue[language] = String(value)?.toLowerCase()?.trim()
        }
        if(objectHandler?.validations?.onlynumbers === true || objectHandler?.validations?.onlynumbers?.condition === true){
          fieldValue[language] = String(value)?.trim()
        }

        
        objectHandler?.setValue(fieldValue, true, blnSetState);
        
      };

      const handleTextChange = (event, blnSetState)=>{
        const isMultiLanguage = objectHandler?.blnLanguage || false
        if(!isMultiLanguage){
          handleTextValueChange(event, blnSetState)
        }else{
          handleTextLangValueChange(event, blnSetState)
        }
      }
      //#endregion

      //#region number & rangeSlider
      const handleNumberChange = (event, blnSetState)=>{
        objectHandler?.setValue(parseInt(event?.target?.value), true, blnSetState)
      }
      //#endregion

      //#region Ratting
      const handleRattingChange = (event, blnSetState)=>{
        const id = String(event.target.id).split("-")
        const value = parseInt(id[0].charAt(id[0].length - 1))
        objectHandler?.setValue(value, true, blnSetState)
      }
      //#endregion

      const handleColorChange = (event, blnSetState)=>{
        const value = event.target.value
        objectHandler?.setValue(value, true, blnSetState)

      }

      const handleSwitchChange = (event, blnSetState)=>{
        // var options = formState?.fields?.[fieldName]?.options
        // if(!Array.isArray(options) && typeof options === "object"){
        //   options= Object.keys(options).map(value => {
        //     return {key: options[value], value: value}
        //   })
        // }
        // var item = options?.[0] || { key: false, value: "off" }
        // if(event.target.checked){
        // item = options?.[1] || { key: true, value: "on" }
        // }
        // setValue(fieldName, item, blnSetState)
        if(event.target.checked)
          objectHandler?.setValue(true, true, blnSetState)
        else
          objectHandler?.setValue(false, true, blnSetState)
      }
    //#endregion

      //#region for radio and list
        const handleOptionInputChange = (event, blnSetState) => {
            const value = event.target.value
            var key = event.target.value
            if (event.target.id !== "") {
              key = event.target.id;
            }
            let item= {"key":key,"value":value}
            objectHandler?.setValue(item,true, blnSetState)
        };
      //#endregion

      //#region for checkbox and multipleList
        const handleMultipleOptionsInputChange = (event, blnSetState) => {
            const value = event.target.value
            var key = event.target.value
            if (event.target.id !== "") {
              key = event.target.id;
            }

            let item= {"key":key,"value":value}
            objectHandler?.setValue(item,true)

        };
      //#endregion

      const fieldHandelChange=(event, blnSetState=true)=>{
        const control = String(objectHandler?.control).toLowerCase()
        if(control === fieldType.number || control === fieldType.rangeSlider){
          handleNumberChange(event, blnSetState)
        }else if(control === fieldType.radio || control === fieldType.list){
          handleOptionInputChange(event, blnSetState)
        }else if(control === fieldType.checkbox || control === fieldType.multipleList){
          handleMultipleOptionsInputChange(event, blnSetState)
        }else if(control === fieldType.rating){
            handleRattingChange(event, blnSetState)
        }else if(control === fieldType.color){
          handleColorChange(event, blnSetState)
        }else if(control === fieldType.switch){
          handleSwitchChange(event, blnSetState)
        }else{
          handleTextChange(event, blnSetState)
        }
      }

    //#endregion
  return {
    fieldHandelChange
  }
}

export default inputHelper