"use client";

import React, { forwardRef, useEffect, useRef, useState } from "react";

const ShowIcon = forwardRef((/**@type{any}*/ props, ref) => {
  const { imageName, ...rest } = props;
  const refIcon = useRef({ strPrevName: null });
  const [IconComp, setIconComp] = useState(null);

  useEffect(() => {
    IconBody();
  }, [imageName]);

  async function IconBody() {
    try {
      const iconName = imageName;

      if (refIcon.current.strPrevName === iconName) {
        return;
      }

      return import(`./icons/${iconName}`)
        .then((iconSrc) => {
          let impIcon = iconSrc?.default || iconSrc;

          let jsxIcon = (
            <img
              src={impIcon?.src || impIcon}
              style={{
                blurHeight: impIcon?.blurHeight || "0px",
                blurWidth: impIcon?.blurWidth || "0px",
                ...(rest?.width || rest?.height ? {} : { width: impIcon?.width || "32px" }),
                ...(rest?.width || rest?.height ? {} : { height: impIcon?.height || "32px" }),
              }}
              {...rest}
            />
          );
          refIcon.current.strPrevName = iconName;
          setIconComp(jsxIcon);
          return jsxIcon;
        })
        .catch((error) => {
          console.error("Icon ( " + imageName + " ) not found.");

          return import(`./icons/default_file.svg`)
            .then((iconSrcDefault) => {
              let impIcon = iconSrcDefault?.default || iconSrcDefault;
              let jsxIcon = (
                <img
                  src={impIcon?.src || impIcon}
                  style={{
                    blurHeight: impIcon?.blurHeight || "0px",
                    blurWidth: impIcon?.blurWidth || "0px",
                    ...(rest?.width || rest?.height ? {} : { width: impIcon?.width || "32px" }),
                    ...(rest?.width || rest?.height ? {} : { height: impIcon?.height || "32px" }),
                  }}
                  {...rest}
                />
              );
              refIcon.current.strPrevName = iconName;
              setIconComp(jsxIcon);
              return jsxIcon;
            })
            .catch((error) => {
              refIcon.current.strPrevName = iconName;
              setIconComp(null);
              return null;
            });
        });
    } catch (error) {
      console.error("Icon ( " + imageName + " ) not found.");
      refIcon.current.strPrevName = null;
      setIconComp(null);
      return null;
    }
  }

  return IconComp;
});

export default ShowIcon;

/* webpackChunkName:  "./packages/icons/dist/[request]" */
/* webpackIgnore: true */

// /**
//  * @param {{imageName:string }} imageInfo
//  */
//   export default function ShowIcon({ imageName, ...rest }) {
//   let Comp = require(`./icons/${imageName}`)?.default ? require(`./icons/${imageName}`)?.default : DefaultFile;

//   return <Comp {...rest} />;
// }

// export default function ShowIcon({ imageName, ...rest }) {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [Image, setImage] = useState(DefaultFile);

//   useEffect(() => {
//       const fetchImage = async () => {
//       try {
//         const response = require(`./icons/${imageName}`); // change relative path to suit your needs
//         setImage(response?.default ? response?.default : DefaultFile);
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchImage();
//   }, [imageName]);

//    if (loading) {
//     return <DefaultFile {...rest} />;
//   } else if (error) {
//     return <DefaultFile {...rest} />;
//   } else {
//     return <Image {...rest} />;
//   }

//  }
