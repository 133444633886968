import { Grid, Typography } from "@cbmisorg/material";
import React from "react";
import { App_info_Color } from "../../../../../../../util/appStyle";
import { dictionary } from "../../../../../../../util/appLanguage/dictionary";


const SponsorTrainingTypeOptionsView = ({ lang, value, getPartnerTransPlanTotalPrice }) => {
  const optionLabel = {
    eng: value?.value?.eng + " - " + value?.studentNumber?.value?.eng + " " + dictionary?.components?.stakeholders?.member?.account?.sponsorSetter.trainingCustomNumber?.eng,
    arb:
      value?.value?.arb +
      " - " +
      value?.studentNumber?.value?.arb +
      " " +
      dictionary?.components?.stakeholders?.member?.account?.sponsorSetter?.trainingCustomNumber?.arb +
      " بتكلفة " +
      getPartnerTransPlanTotalPrice,
  };

  return {
    optionLabel:optionLabel,
    jsx: (
      <Grid container px-2  m-0>
        <Grid item xs="12" lg="auto">
          <Typography as="body3" color={App_info_Color}>
            {optionLabel?.[lang]}
          </Typography>
        </Grid>
      </Grid>
    )
  }
 
};

export default SponsorTrainingTypeOptionsView;
