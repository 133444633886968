import { StyleBuilder, styleVariables, cssKeys, mainTheme } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    stepper: `${styleVariables?.prefixDefaultClassName}_stepper`,
    stepper_head: `${styleVariables?.prefixDefaultClassName}_stepper_head`,
    stepper_label: `${styleVariables?.prefixDefaultClassName}_stepper_label`,
    stepper_body: `${styleVariables?.prefixDefaultClassName}_stepper_body`,
    stepper_steps: `${styleVariables?.prefixDefaultClassName}_stepper_steps`,
    stepper_step: `${styleVariables?.prefixDefaultClassName}_stepper_step`,
    stepper_step_input: `${styleVariables?.prefixDefaultClassName}_stepper_step_input`,

    sx: `${styleVariables?.prefixDefaultClassName}_stepper_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.stepper]: {
        display: cssObject?.display?.[cssKeys?.block],
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position: cssObject?.position?.[cssKeys?.relative],
        margin: "auto",
        minHeight: "max-content",
        width: "100%",
        ">*": {
          boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
          position: cssObject?.position?.[cssKeys?.relative],
        },
      },
      [classesName?.stepper_body]: {
        flex: 1,
        padding: 0,
        position: cssObject?.position?.[cssKeys?.relative],
      },
      [classesName?.stepper_head]: {
        display: "flex",
        justifyContent:"space-between",
        position: "relative",
        width: "100%",
        userSelect: "none",
        counterReset: "current-step", 
        gridTemplateColumns: "repeat( auto-fit, minmax(1px, 1fr) )",
        zIndex: 1,
        "&after":{
          content:"' '",
          display: cssObject?.display?.[cssKeys?.block],
          position: "absolute",
          top:"25%",
          left:"0",
          margin: "auto",
          width: "100%",
          minHeight: "1px",
          background:"currentColor"

        }
      },
      [classesName?.stepper_step_input]: {
        position: "absolute",
        display: "initial",
        top: "-2rem",
        opacity:0,
      },
      [classesName?.stepper_steps]: {
        position: "relative",
        display: "block",
        margin: "0",
        cursor:"pointer",
        fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
        fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],

      },
      [classesName?.stepper_step]: {
        position: "relative",
        display: "flex",
        width:"35px",
        height:"35px",
        border:`1px solid currentColor`,
        borderRadius:"100%",
        textAlign:"center",
        fontSize: cssObject?.text?.size?.[cssKeys?.h6],
        cursor:"pointer",
        justifyContent:"center",
        alignItems:"center",
      },
      [classesName?.stepper_label]: {
        position: "relative",
        display: "block",
        margin: "0.5rem 0",
        zIndex: 2,
        cursor:"pointer",

      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      // [classesName?.stepper_head]: (props) => {
      //   if (String(props?.mode).toLowerCase() === "isvertical") {
      //     return {
      //       flexDirection: "column",
      //       width: "auto",
      //       [`.${classesName?.stepper_step}`]: {
      //         display: "flex",
      //         alignItems: "center",
      //         textAlign: "start",
      //         "&after": {
      //           content: " ",
      //           position: "absolute",
      //           margin: "auto 1rem",
      //           top: "2rem",
      //           bottom: "-0.5rem",
      //           width: 0,
      //           height: "auto",
      //           borderLeft: "0.125rem solid #ccc",
      //         },
      //       },
      //       [`.${classesName?.stepper_label}`]: {
      //         paddingInlineStart: "1rem"
      //       },
      //       "&+": {
      //         [`.${classesName?.stepper_body}`]: {
      //           marginInlineStart: " 3.5rem",
      //         },
      //       },
      //     };
      //   }else{
      //     return {
      //       flexDirection: "row",
      //       width: "100%",
      //       [`.${classesName?.stepper_step}`]: {
      //         display: "flex",
      //         alignItems: "center",
      //         textAlign: "start",
      //         "&after": {
      //           content: " ",
      //           position: "absolute",
      //           margin: "auto 1rem",
      //           top: "50%",
      //           transform: "translateY(-50%)",
      //         },
      //       },
      //       [`.${classesName?.stepper_label}`]: {
      //         paddingInlineStart: "1rem"
      //       },
      //       "&+": {
      //         [`.${classesName?.stepper_body}`]: {
      //           marginInlineStart: " 3.5rem",
      //         },
      //       },
      //     };
      //   }
      // },
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
