import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import NoteStyle from "./NoteStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { Icon } from "@cbmisorg/icons";

const iconName={info: "info", warning: "warning", error:"error", success: "checkcircle", primary:"Campaign"  }
const Note = forwardRef((/**@mode {any}*/props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = NoteStyle(theTheme);
  const { mode ="primary" ,sx = null, hidden=false, disabled=false, dir=null, className=null, id=null, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx , mode});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.note], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);


  return (
      <div className={itemClasses} {...styleFunction?.getRestProps(rest)} ref={ref}>
        <div className={classes?.[classesName?.note_icon]}>
        <Icon icon={iconName?.[String(mode)?.toLowerCase()]} />
        </div>
        <div className={classes?.[classesName?.note_content]}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </div>
      </div>
  );
});

export default Note;
