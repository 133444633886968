import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    note: `${styleVariables?.prefixDefaultClassName}_note`,
    note_icon: `${styleVariables?.prefixDefaultClassName}_note_icon`,
    note_content: `${styleVariables?.prefixDefaultClassName}_note_content`,

    sx: `${styleVariables?.prefixDefaultClassName}_note_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.note]: {
        display: "flex",
        position: "relative",
        width: cssObject?.size?.[cssKeys?.full],
        maxWidth: cssObject?.size?.[cssKeys?.full],
        textAlign: "start",
        justifyContent:"flex-start",
        alignItems:"center",
      },
      [classesName?.note_icon]: {
        display: "inline-block",
        position: "relative",
        fontSize: cssObject?.text?.size?.[cssKeys?.h6],
        width: "max-content",
        margin:0,

      },
      [classesName?.note_content]: {
        display: "inline-block",
        position: "relative",
        width: "max-content",
        maxWidth: "90%",
        fontSize: cssObject?.text?.size?.[cssKeys?.body2],
        color: styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor),
        paddingInline: cssObject?.spacing?.[cssKeys?.sm],
        margin:0,


      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        return {
          fontSize: cssObject?.text?.size?.body2,
          padding: cssObject?.spacing?.[cssKeys?.sm],
          margin: `${cssObject?.spacing?.[cssKeys?.sm]} auto`,
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.md,
          color: styleFunction.checkColorReturnValue(props?.mode || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.px]} ${cssObject?.border?.[cssKeys?.border]?.dashed} ${styleFunction.checkColorReturnValue(props?.mode|| cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
          ...props?.sx,
        };
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
