import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    line_container: `${styleVariables?.prefixDefaultClassName}_line_container`,
    line_label: `${styleVariables?.prefixDefaultClassName}_line_Label`,
    line: `${styleVariables?.prefixDefaultClassName}_line`,
    line_fade: `${styleVariables?.prefixDefaultClassName}_line_fade`,
    sx:`${styleVariables?.prefixDefaultClassName}_line_sx`
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
      objStyles: {
        [classesName?.line_container]:{
          display: cssObject?.size?.[cssKeys?.flex],
          justifyContent: "center",
          position:cssObject?.position?.[cssKeys?.relative],
          alignItems: "center",
          flexWrap: "wrap",
          width: "95%",
          [`.${classesName?.line_label}`]:{
            position:cssObject?.position?.[cssKeys?.absolute],
            top: "-50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            color: cssObject?.color?.currentColor,
          },
          [`.${classesName?.line}`]:{
            display: cssObject?.size?.[cssKeys?.flex],
            position:cssObject?.position?.[cssKeys?.relative],
            height: cssObject?.spacing?.[cssKeys?.xs],
            padding: "0px",
            margin:"auto",
            outline: "none",
            border: "none",
            flex:1,
            background: cssObject?.color?.currentColor,
          },
          [`.${classesName?.line_fade}`]:{
            backgroundImage: `linear-gradient(to left ,${cssObject?.color?.currentColor} 2%, #6b7280 50%, ${cssObject?.color?.currentColor} 98%)`,
          },
        },
      },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.borderColor),
          fontSize:cssObject?.text?.size?.[cssKeys?.subtitle2],
          [`.${classesName?.line_label}`]:{
            padding: `0 ${cssObject?.spacing?.[cssKeys?.sm]}`,
            background: cssObject?.color?.bodyColorBg,
          },
          [`.${classesName?.line}`]:{
            height: cssObject?.spacing?.[cssKeys?.["3xs"]],
          },
          ...props?.sx,
        };
      },
    },
  });

  return{
    classesName,
    style,
    styleSx,
  }
}