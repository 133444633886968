import React, { forwardRef, useRef } from 'react'
import { useMemo } from 'react'
import FormControl from './FormControl'
import inputHelper from '../formHelper/formFunctions/inputHelper'


const FormField = forwardRef((/**@type{any}*/props, ref) => {
  const {objHandler=null, children, onChange, currentLang=null,...rest} = props

  const {fieldHandelChange} =inputHelper(objHandler)

  const onFieldChange=(event, blnSetState=true)=>{
    fieldHandelChange(event, blnSetState);
    if(onChange) onChange(event)
 }

  const FormCrl = useMemo(() => {
    const objLang = objHandler?.language
    return(
      !objHandler? null:(
        <React.Fragment>
          <FormControl 
              id={objHandler?.name}
              handler={objHandler}
              children={children}
              defaultValue={objHandler?.getValue()}
              label={objHandler?.label?.[objLang?.field] || objHandler?.label?.[objLang?.current] || objHandler?.label?.[objLang?.default] || objHandler?.label || props?.label}
              placeholder={objHandler?.placeholder?.[objLang?.field] || objHandler?.placeholder?.[objLang?.current] || objHandler?.placeholder?.[objLang?.default] || objHandler?.placeholder || props?.placeholder}
              funChange={onFieldChange}
              hidden={objHandler?.hidden}
              disabled={objHandler?.disabled}
              options={objHandler?.getOptions()}
              currentLang={currentLang || objLang?.field || objLang?.current || objLang?.default}
              defaultLang={objLang?.default}
              optionLang={objHandler?.blnLanguage? objHandler?.optionLanguage: null}
              setCurrentLang={objHandler?.setLanguage}
              ref={ref}
              dir={typeof objLang?.field === "undefined"? objLang?.current==="arb"?"rtl":"ltr" : objLang?.field=== "arb"?"rtl":"ltr"}
              errorMessage={typeof objHandler?.error === "object" ? objHandler?.error?.[objLang?.field || objLang?.current || objLang?.default] : (objHandler?.error)?.toString()}
              {...rest}
            />
        </React.Fragment>
      )
    )
  }, [children, objHandler, rest])

  return FormCrl
})

export default FormField