import * as appPages from "./appPages";


const appRouting = {
  landingPage: { url: appPages?.landingPage, featureID: 8786507229 },
  SheikProfile: { url: appPages?.sheikProfile, featureID: 8786507229 },

  Public_Login: { url: appPages?.login, featureID: 2580267594 },
  Reg_Activity_Guest: { url: appPages?.regActivityGuest, featureID: 8786507229 },
  Registration: { url: appPages?.registration, featureID: 5094561645 },

  Account: { url: appPages?.account, featureID: 6759118668 , featureAuth: true},



};
export default appRouting;