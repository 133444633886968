"use client";

import { useId, useInsertionEffect, useMemo, useRef } from "react";
import { setClassHash } from "../helper/generateClassName";
import { setCssRules } from "../helper/convertJsCss";
import { cleanRule } from "../helper/cleanUp";
import { pkgConfigStyle } from "..";

/**
 * @typedef {{[x:string]: {[Property in keyof import("../../dist/types/cssProperties").CBMISCSSProperties]?: any}}} jsCSS
 */
export const StyleBuilder = (appStyleConfig) =>
  /**
   * @template S
   * @param {{objStyles?: S | jsCSS, sx?: {[x: string] : any}, className?: string, blnRandomPrefix?: boolean, blnIsDefaultStyle?: boolean, blnCbmisStyle?: boolean }} styleBuilderProps
   */
  function StyleBuilder({ className = "", objStyles = null, sx = null, blnRandomPrefix = false, blnIsDefaultStyle = false, blnCbmisStyle = false }) {
    /**
     * @returns {S}
     */
    function useMakTheme(props) {
      const idComp = useId();
      const styleRef = useRef({ intVersion: 1, objDelMicro: {}, prevProps: null, objClassesNames: null, blnInitRun: false, blnInsertRun: false, blnPatchRun: false, lstStyleClasses: Object.entries((objStyles ? objStyles : className && sx ? { [className]: sx } : {}) || {}) });

      const styleObjectName = useMemo(() => {
        const prevVersion = styleRef?.current?.intVersion;
        const objClassNameHash = setClassHash(styleRef?.current, props, { blnRandomPrefix, lstStyleClasses: styleRef?.current?.lstStyleClasses, className, objStyles, sx });
        if (styleRef?.current?.intVersion !== prevVersion && styleRef?.current?.blnInitRun) {
          setCssRules(styleRef?.current, props, { idComp, lstStyleClasses: styleRef?.current?.lstStyleClasses, className, objStyles, sx, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig }, true);
        }
        styleRef.current.blnInitRun = true;
        return objClassNameHash;
      }, [props]);

      useInsertionEffect(() => {
        if (typeof document !== "undefined" && typeof window !== "undefined") {
          setCssRules(styleRef?.current, props, { idComp, lstStyleClasses: styleRef?.current?.lstStyleClasses, className, objStyles, sx, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig });
        }
        return () => {
          if (typeof document !== "undefined" && typeof window !== "undefined" && pkgConfigStyle?.enableCleanUpStyles) {
            cleanRule(idComp, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, styleRef?.current, props, styleRef?.current?.lstStyleClasses);
          }
        };
      }, []);

      return styleObjectName;
    }

    return useMakTheme;
  };
