import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../../../../util/appFunctions";
import * as appVariables from "../../../../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { workSectorCat, workSectorID } from "../../../../../../configuration/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblActivityGuest = generateQueries("tblActivityGuest");

const labels = dictionary?.components?.public?.regActivityGuest;
export const CtrlRegActivityGuest = ({ appState, appDispatch, isLoading, setIsLoading }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData: async ({ activityInfo, state, setState, parentState, fields, WorkSectorOptionComponent, SubjectOptionComponent, closeModal }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "", appLangDirection?.[lang]);
          return;
        }
        //#region tblCategory & filter activity
        setIsLoading(true);

        if (activityInfo?.lstActiveSubjectOrdered?.length) {
          const lstCurentSubject = activityInfo?.lstActiveSubjectOrdered?.filter((item) => {
            const subjectDate = appFunctions.blnIsTodayBiggerThanTheDate(new Date(item?.subjectValue?.date?.value?.["eng"]));
            return !subjectDate && item?.subjectValue?.blnView?.value?.["eng"] === "true";
          });

          if (lstCurentSubject?.length === 0) {
            Alert.viewAlert(labels?.alertEndWeek?.[lang], "", appLangDirection?.[lang]);
            closeModal();
            return;
          }
          state.lstCurentSubject = lstCurentSubject;

          if (activityInfo?.objActivity?.type?.value?.["eng"]) {
            fields?.lstSubject?.setControl(activityInfo?.objActivity?.type?.value?.["eng"]?.replace(" ", ""));

            if (activityInfo?.objActivity?.type?.value?.["eng"] !== "none" && state.lstCurentSubject?.length) {
              fields?.lstSubject?.setValidation("required", true);
            }
          }
        }
        //#endregion

        //#region setValue if User Login
        const activityIndex = parentState?.lstUserRegActivity?.findIndex((item) => item?.bigActivityType === activityInfo?.objActivity?.id);

        if (activityIndex > -1) {
          const objInfo = parentState?.lstUserRegActivity?.[activityIndex];
          if (objInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(objInfo?.bigWorkSector);
            handler?.handelWorkSectorChange({ fields, state, setState, WorkSectorOptionComponent, SubjectOptionComponent })();
          }
          if (objInfo?.jsnGuestInfo?.objTargted) {
            fields?.lstTargeted?.setValue(objInfo?.jsnGuestInfo?.objTargted);
          }
          if (objInfo?.strPlaceName) {
            fields?.strPlaceName?.setValue(objInfo?.strPlaceName);
          }
          if (objInfo?.jsnGuestInfo?.objSubject?.length) {
            fields?.lstSubject?.setValue(objInfo?.jsnGuestInfo?.objSubject);
          }
        } else {
          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
            handler?.handelWorkSectorChange({ fields, state, setState, WorkSectorOptionComponent, SubjectOptionComponent })();
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields?.strPlaceName?.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
        }

        //#endregion

        setState({ ...state });
        setIsLoading(false);
        appDispatch();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "initData-ERROR", error);
        setIsLoading(false);
      }
    },
    addGuest: async ({ state, setState, fields, closeModal, activityInfo }) => {
      try {
        //#region Form Valid
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
          return;
        }
        //#endregion
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
          return;
        }
        setIsLoading(true);

        //#region  get SubjectValue  by control type
        const getSubjectValue = () => {
          let lstValue = [];
          if (activityInfo?.objActivity?.type?.value?.["eng"] === "radio") {
            lstValue.push(fields?.lstSubject?.getValue()?.key);
          } else if (activityInfo?.objActivity?.type?.value?.["eng"] === "checkbox") {
            lstValue = fields?.lstSubject?.getValue()?.map((item) => item.key);
          }
          return lstValue;
        };
        //#endregion
        const result = await tblActivityGuest(strPageInfo, appState, "aaiotActivityGuestAdd", {
          objectInput: {
            //#region tblUser Information
            bigAppID: appVariables.App_ID,
            bigSystemID: appVariables.aaiot_System_ID,
            bigUserID: appState?.userInfo?.bigUserID,
            strUserEmail: appState?.userInfo?.strUserEmail,
            //strUserPhone: "strUserPhone",
            //strUsername: "strUsername",

            bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            strPlaceName: fields?.strPlaceName?.getValue(),
            // intUserRoleFK: appVariables.objUserRole.Member,

            blnIsUserLoggedIn: appState?.clientInfo?.blnIsUserLoggedIn,
            // jsnUserInfo: {
            //   //reset values
            // },

            // blnIsManager: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.manager),
            // blnIsStudent: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.student),
            // blnIsStartUp: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.programer),
            // blnIsSponsor: false, //String(fields?.bigWorkSector?.getValue()?.key) === String(appVariables.workSectorID.gov),

            blnIsActivityUser: true,
            //#endregion
            ///////////////////////////////////////////////////
            //#region tblActivityGuest information
            bigActivityType: activityInfo?.objActivity?.id,
            jsnGuestInfo: {
              objSubject: getSubjectValue(),
              objTargted: fields.lstTargeted?.getValue()?.key,
            },
            //#endregion
            ///////////////////////////////////////////////////
            //#region Statistic ??
            lstStatisticFelidIfUserNew: ["intCountJoinUser", "intCountJoinUserAll"],
            lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuest", "intCountActivityGuestAll"],
            //#endregion
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang]);
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang]);
          }
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setState({ ...state });
        Alert.viewAlert(labels?.alertThanke?.[lang], "success", appLangDirection?.[lang]);
        fields?.controller?.resetForm();
        appState.clientInfo.blnIsUserLoggedIn = true;
        appState.userInfo = result.response?.userInfo;
        appDispatch();
        closeModal();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addGuest-ERROR", error);
        setIsLoading(false);
      }
    },

    handelWorkSectorChange:
      ({ fields, state, setState, WorkSectorOptionComponent, SubjectOptionComponent }) =>
      () => {
        try {
          //#region rest Value
          fields.lstTargeted?.setValue("");
          //#endregion
          //#region set Option for lstTargeted field
          let optLst = [];
          const bigWorkSectorVal = fields?.bigWorkSector?.getValue();
          if (bigWorkSectorVal?.key === workSectorID?.gov) {
            optLst = [
              { key: workSectorCat?.leader, value: dictionary?.shared?.app?.leader, jsx: () => <WorkSectorOptionComponent type="leader" lang="arb" /> },
              { key: workSectorCat?.employee, value: dictionary?.shared?.app?.employee, jsx: () => <WorkSectorOptionComponent type="employee" lang="arb" /> },
            ];
          } else if (bigWorkSectorVal?.key === workSectorID?.prvt) {
            optLst = [
              { key: workSectorCat?.manager, value: dictionary?.shared?.app?.manager, jsx: () => <WorkSectorOptionComponent type="manager" lang="arb" /> },
              { key: workSectorCat?.programer, value: dictionary?.shared?.app?.programer, jsx: () => <WorkSectorOptionComponent type="programer" lang="arb" /> },
              { key: workSectorCat?.student, value: dictionary?.shared?.app?.student, jsx: () => <WorkSectorOptionComponent type="student" lang="arb" /> },
            ];
          }
          fields?.lstTargeted?.setOptions(optLst, true);
          //#endregion
          //#region set Option for fields field
          const subjectLstOption = [];
          for (let index = 0; index < state?.lstCurentSubject.length; index++) {
            const element = state?.lstCurentSubject?.[index]?.subjectValue;

            if (
              JSON.parse(element?.bigworkSectorID?.value?.["eng"])?.includes(bigWorkSectorVal?.key) ||
              String(element?.bigworkSectorID?.value?.["eng"])?.includes(String(bigWorkSectorVal?.key))
            ) {
              subjectLstOption.push({
                key: element?.id,
                value: element?.value,
                jsx: () => <SubjectOptionComponent key={element?.id} element={element} lang={lang} state={state} setState={setState} />,
              });
            }
          }
          fields.lstSubject.setOptions(subjectLstOption, true);
          //#endregion
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handelWorkSectorChange-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
