import React, { forwardRef, useEffect, useState } from "react";
import { styleFunction } from "@cbmisorg/styles";
import { style, styleSx } from "./CounterStyle";

const Counter = forwardRef((props,ref) =>{
  const { intCount=100, intStep=1, intSeconds = 1, sx, blnCountdown, hidden, disabled, dir, className, ...rest } = props;
  const classes = style();
  const classesSx = styleSx(props);
  const count = parseInt(intCount) || 100;
  const [counterState, setCounterState] = useState(blnCountdown?count:0);

  const getRestProps = () => {
    const newRest = styleFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [count,sx, blnCountdown, hidden, disabled, dir, className]);

  useEffect(() => {
    let myInterval = setInterval(() => {
     if(blnCountdown && counterState !==0 ){
      setCounterState(counterState - intStep)
     }else if(!blnCountdown && counterState < count ){
        setCounterState(counterState + intStep)
     }
    }, intSeconds * 1000)
    return () => {
      clearInterval(myInterval)
    }
  });

  return (
    <React.Fragment>
      <div
        className={`${classes?.cbmis_counter} ${classesSx?.cbmis_counter_sx} 
        ${styleFunction.CheckDisabledItem(disabled)} ${styleFunction.CheckHiddenItem(hidden)} ${styleFunction.CheckDirectionItem(dir)} 
        ${styleFunction.checkExistsMargin(rest)} ${styleFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
        ref={ref}
      >
        {counterState}
      </div>
    </React.Fragment>
  );
});

export default Counter;
