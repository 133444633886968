import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    typography: `${styleVariables?.prefixDefaultClassName}_typography`,
    sx:`${styleVariables?.prefixDefaultClassName}_typography_sx`
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.typography]: {
        display: cssObject?.display?.[cssKeys?.block],
        margin: 0,
        padding: 0,
        whiteSpace: cssObject?.whiteSpace?.[cssKeys?.breakSpaces],
        textAlign: cssObject?.side?.[cssKeys?.start],
        color: cssObject?.color?.[cssKeys?.bodyColor],
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
        [classesName?.sx]: (props) => {
          return {
            ...getFontSizeAsReturnValue(props?.as),
            ...props?.sx,
            color: styleFunction.checkColorReturnValue(props?.color || props?.sx?.color || "currentColor"),
    
          };
        },
  }
 });


  const getFontSizeAsReturnValue = (as="body1") => {
    const getSize = String(as).toLowerCase();
    switch (getSize) {
      case "h1":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h1],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xl],
        };
      case "h2":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h2],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xl],
        };
      case "h3":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h3],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xl],
        };
      case "h4":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h4],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_lg],
        };
      case "h5":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h5],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_lg],
        };
      case "h6":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.h6],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_lg],
        };
      case "subtitle1":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle1],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
        };
      case "subtitle2":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle2],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
        };
      case "subtitle3":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
        };
      case "body1":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.body1],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xs],
        };
      case "body2":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.body2],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xs],
        };
        case "body3":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.body3],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xs],
        };
      case "caption":
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.caption],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_xs],
        };
      case "overline":
        return {
          fontSize:cssObject?.text?.size?.[cssKeys?.overline],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_fontWeight_2xs],
          letterSpacing: cssObject?.spacing?.[cssKeys?.xs],
          textTransform: cssObject?.text?.[cssKeys?.transform]?.uppercase,
        };
      default:
        return {
          fontSize: cssObject?.text?.size?.[cssKeys?.body1],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_sm],
        };
    }
  };

  return{
    classesName,
    style,
    styleSx,
  }
}