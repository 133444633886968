"use client";
import { ContextProvider } from "@cbmisorg/helper";
import appInitialState from "./appInitialState";
import * as appVariables from "../../configuration/appVariables";

const contextName = "app";
export const { AppProvider, useApp } = ContextProvider(
  appInitialState,
  contextName,
  appVariables.App_Version,
  appVariables.App_LocalStorage_Name,
  appVariables.App_LocalStorage_Is_Encrypted,
  appVariables.App_LocalStorage_Encrypted_KEY,
  appVariables.App_IsInTestingMode
);
