import { forwardRef } from "react";

import * as FormVariables from "../formHelper/formVariables/FormVariables";
import {
  Text,
  RadioButtons,
  List,
  CheckboxGroup,
  MultipleList,
  Password,
  TextArea,
  DatePicker,
  Number,
  RangeSlider,
  // Rating,
  // Color,
  Switch,
} from "@cbmisorg/material";

const fieldsType = FormVariables.fieldType;
const FormControl = forwardRef((/**@type{any}*/ props, ref) => {
  const { handler = {}, defaultValue, children, optionLang, setCurrentLang, funChange, iconColor, iconSize, ...rest } = props;
  let value = defaultValue;
  if (value?.value) {
    value.value = typeof value.value === "object" ? JSON?.stringify(value.value) : value.value;
  }
  value = typeof value === "object" ? JSON?.stringify(value) : value;

  const onChangeText = (event) => {
    funChange(event, false);
  };
  const onBlurText = (event) => {
    funChange(event, true);
  };
  switch (String(handler?.control).toLowerCase()) {
    case fieldsType.text:
      return <Text value={value} optionLang={optionLang} setCurrentLang={setCurrentLang} onChange={onChangeText} onBlur={onBlurText} iconColor={iconColor} iconSize={iconSize}  ref={ref} {...rest} />;
    case fieldsType.textArea:
      return (
        <TextArea value={value} optionLang={optionLang} setCurrentLang={setCurrentLang} ref={ref} onChange={onChangeText} onBlur={onBlurText} iconColor={iconColor} iconSize={iconSize} {...rest} />
      );
    case fieldsType.password:
      return <Password value={value} ref={ref} onChange={onChangeText} onBlur={onBlurText} iconColor={iconColor} iconSize={iconSize} {...rest} />;
    case fieldsType.number:
      return <Number value={value} setValue={handler?.setValue} iconColor={iconColor} iconSize={iconSize} {...rest} />;
    case fieldsType.date:
      return <DatePicker value={value} ref={ref} iconColor={iconColor} iconSize={iconSize} {...rest} onChange={funChange} />;
    case fieldsType.dateTime:
      return <DatePicker type="datetime-local" value={value} ref={ref} iconColor={iconColor} iconSize={iconSize} {...rest} onChange={funChange} />;
    case fieldsType.radio:
      return <RadioButtons value={value} ref={ref} {...rest} onChange={funChange} />;
    case fieldsType.list:
      return <List value={value} ref={ref} {...rest} iconColor={iconColor} iconSize={iconSize} onChange={funChange} />;
    case fieldsType.checkbox:
      return <CheckboxGroup value={value} ref={ref} {...rest} onChange={funChange} />;
    case fieldsType.multipleList:
      return <MultipleList value={value} ref={ref} {...rest} iconColor={iconColor} iconSize={iconSize} onChange={funChange} />;
    case fieldsType.rangeSlider:
      return <RangeSlider value={value} {...rest} onChange={funChange} />;
    // case fieldsType.rating:
    //   return <Rating {...rest} />;
    case fieldsType.switch:
      return <Switch {...rest} value={value} onChange={funChange} />;
    // case fieldsType.color:
    // return <Color {...rest} />;
    default:
      return null;
  }
});

export default FormControl;
