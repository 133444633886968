import React, { useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useNavigate } from "@cbmisorg/router";
import { Button, Container, Grid, Line, Typography, Loader } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { CtrlRegistration } from "./Registration.controller";
import { objStyle } from "./Registration.style";

import ShredHeader from "../../shared/shredHeader";
import { App_info_Color } from "../../../util/appStyle";

const labels = dictionary?.components?.public?.registration;

function RegistrationView() {
  useCheckHooksGlb();
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle(lang));

  const [isLoading, setIsLoading] = useState(false);

  const fields = FormData({
    strFullName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: true,
        // minLength: { condition: 6 }
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: true,
        // minLength: { condition: 6 },
        // equal: { condition: "strUserPassword" },
      },
    },
  });

  const handlers = CtrlRegistration({ appState, appDispatch, isLoading, setIsLoading });
  const handleSubmit = () =>handlers.handleSubmit({ fields, navigate });

  return (
    <React.Fragment>
      <ShredHeader lang={lang} blnBack={true} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="9" lg="8" xl="7" className={classes?.bgBox}>
              <Container className={classes?.container} py-10>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography as="h5" className={classes?.mainTitle}>
                      {labels?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="body2" className={classes?.labelsLight}>
                      {labels?.invite?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Line className={classes?.line} />

                <Grid container spacing={3}>
                  <Grid item className={classes?.subtitle}>
                    <Typography as="subtitle1" className={classes?.subtitleLabel}>
                      {labels?.registrationInfo?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt-0>
                    <FormField objHandler={fields.strFullName} icon="AccountCircle" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      objHandler={fields.strUserEmail}
                      icon="email"
                      mode={"classic"}
                      iconColor={App_info_Color}
                      color={App_info_Color}
                      inputDir="ltr"
                      className={classes?.field}
                    />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                  </Grid>
                </Grid>

                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs="12" pt-5 pb-10>
                    <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={handleSubmit} className={classes?.btn} />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
        <div className={classes?.anmition}>
          <div className={classes?.anmitiongif} />
        </div>
      </section>
    </React.Fragment>
  );
}

export default RegistrationView;
