import React, { useEffect } from "react";
import { App_Dark_Color, App_Light_Text_Color, App_Primary_Color, App_Second_Color } from "../../../util/appStyle";
import { ClassesBuilder } from "@cbmisorg/styles";
import { ClientDirection } from "@cbmisorg/helper";
import { AppBar, GroupBar, Typography, Switch, Grid } from "@cbmisorg/material";
import { Icon } from "@cbmisorg/icons";
import { useNavigate } from "@cbmisorg/router";
import appRouting from "../../../configuration/routing/appRouting";

const objStyle = {
  appbar: {
    background: App_Dark_Color,
    poposition: "fixed",
  },
  icons: {
    borderRadius: "50%",
    color: App_Primary_Color,
  },
};

function ShredHeader({ title = "", lang = "arb", blnBack = true, blnHome = true, blnLang = false, changLang }) {
  const classes = ClassesBuilder(objStyle);
  const navigate = useNavigate();

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const goPath = (path) => () => {
    navigate(path);
  };
  return (
    <AppBar className={classes.appbar}>
      <GroupBar position={{ xs: "navbar" }}>{blnHome ? <Icon icon="home" onClick={goPath(appRouting?.landingPage?.url)} className={classes?.icons} size="24px" /> : null}</GroupBar>
      <GroupBar position={{ xs: "navbar" }}>
        {title ? (
          <Typography as="subtitle2" color={App_Second_Color}>
            {title}
          </Typography>
        ) : null}
      </GroupBar>
      <GroupBar position={{ xs: "navbar" }} dir="ltr">
        {blnBack ? <Icon icon="arrowback" onClick={goPath(-1)} className={classes?.icons} size="24px" /> : null}
        {blnLang? <Icon icon="translate"  className={classes?.icons} size="24px" onClick={changLang} />:null}

      </GroupBar>
    </AppBar>
  );
}

export default ShredHeader;
