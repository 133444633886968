import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import AccordionStyle from "./AccordionStyle";

const Accordion = forwardRef((/**@type {any}*/props, ref) => {
  const { children, ariaControl, sx, hidden, disabled, dir, className, id,mode, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = AccordionStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({sx,mode});
  const comID = id ||  String(useId()).slice(1,-1) + "_accordion";

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.accordion_tabs], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div id={comID} className={classes?.[classesName?.accordion_row]}>
      <div className={classes?.[classesName?.accordion_col]}>
        <div className={itemClasses}
            custom-attribute={JSON.stringify({ ariaControl: ariaControl ? ariaControl : `accordionSummaryInput-${comID}` })}
            {...styleFunction?.getRestProps(rest)}
            ref={ref}
          >
          {children}
        </div>
      </div>
    </div>
  );
});

export default Accordion;
