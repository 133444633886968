import React, { forwardRef, useRef, useState } from "react";
import GroupField from "../GroupField/GroupField";


const Password = forwardRef((props, ref) => {
    const {...rest} = props
    const [type, setType] = useState("password");


  return (
        <GroupField
          ref={ref}
          type={type}
          endIcon={type === "password" ? "visibilityoff" : "visibility"}
          inputDir="ltr"
          endIconRest={{onMouseOver:() => setType("text"), onMouseLeave:() => setType("password"), onTouchEnd:() => setType(type ==="text"?"password":"text"),}}
          {...rest}
          />
    )
});

export default Password