import { Icon } from "@cbmisorg/icons";
import { Grid, Typography, Paper, Button } from "@cbmisorg/material";
import React from "react";
import { App_info_Color, appStyle } from "../../../../../../util/appStyle";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { useNavigate } from "@cbmisorg/router";

const labels = dictionary?.components?.stakeholders?.member?.account?.services
function ServicesView({ isLoading, setIsLoading, parentState, classes, lang }) {
  const navigate = useNavigate()
  const gorToUrl = (url)=>()=>{
      navigate(url)
  }
  return (
    <React.Fragment>
      <Grid container spacing={3}>
          <Grid item xs="12">
            <Typography as="subtitle1" className={classes.mainTitle}>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
        {parentState?.lstServices?.map((service) => {
          return (
            <Grid item xs={12} sm="6" md="4" lg="3" key={service?.id}>
              <Paper className={classes?.card}>
                <Grid container justifyContent="center" className={classes?.cardContiner}>
                  <Grid item xs="12">
                    <Icon icon={service?.icon?.value?.[lang]} color={App_info_Color} size="32px" />
                  </Grid>
                  <Grid item>
                    <Typography as="h6" className={classes?.cardTitle}>
                      {service?.value?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs="12">
                    <Button icon="openInNew" iconSize="28px" className={!service?.url ?null: classes?.icon} color={App_info_Color} mode={!service?.url ? "link" : "outlined"} disabled={!service?.url} onClick={gorToUrl(service?.url?.value?.[lang])} p-0 />
                  </Grid>
                </Grid>
                  <Typography as="body2" m-auto color={appStyle?.App_Dark_Text_Color} className={`${classes?.cardDescription} cardDescriptionHoverd`}>
                    {service?.description?.value?.[lang]}
                  </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default ServicesView;
