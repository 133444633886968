import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import AvatarStyle from "./AvatarStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Avatar = forwardRef((/**@type {any}*/props, ref) => {
  const { src, alt, color, mode, sx, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = AvatarStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx: props?.sx, src, color });

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.avatar], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)} >
      <div className={classes?.[classesName?.avatar_contain]}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
    </div>
  );
});

export default Avatar;
