import React, { forwardRef, useContext, useEffect, useId, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import StepperStyle from "./StepperStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { Icon } from "@cbmisorg/icons";

const Stepper = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = StepperStyle(theTheme);
  const { mode, stepperContent, currentStep, completedColor, activeColor, stepClicked, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, activeColor });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [stepperContentArr, setStepperContentArr] = useState([]);
  const comID = id || String(useId()).slice(1, -1) + "Stepper";

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.stepper],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest)
  );

  const navigateToStepHandler = (e) => {
    if (Number(e.target.value) !== currentTabIndex) {
      setCurrentTabIndex(Number(e.target.value));
    }
  };

  useEffect(() => {}, [mode, stepperContent, currentStep, completedColor, stepClicked, activeColor, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (currentStep && parseInt(currentStep) > 1) {
      setCurrentTabIndex(parseInt(currentStep) - 1);
    } else {
      setCurrentTabIndex(0);
    }
  }, [currentStep]);

  useEffect(() => {
    if (stepperContent) {
      setStepperContentArr(stepperContent || []);
    }
  }, [stepperContent]);

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
      <div className={classes?.[classesName?.stepper_head]} data-debug="data-debug">
        {stepperContentArr.map((el, i) => (
          <React.Fragment key={i}>
            <input className={classes?.[classesName?.stepper_step_input]} id={`${comID}_${i+1}`} value={i} name={comID} type="radio" defaultChecked={currentTabIndex === i} onChange={navigateToStepHandler}/>
            <div className={classes?.[classesName?.stepper_steps]} style={{color: currentTabIndex >= i? styleFunction.checkColorReturnValue(activeColor || "primary") :null}}>
              <label className={classes?.[classesName?.stepper_step]} htmlFor={`${comID}_${i+1}`}>{currentTabIndex<= i? i+1: <Icon icon="check" />}</label>
              <label className={classes?.[classesName?.stepper_label]} htmlFor={`${comID}_${i+1}`}>
                {el?.label}
              </label>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className={classes?.[classesName?.stepper_body]}>
      <React.Fragment>{stepperContentArr?.[currentTabIndex]?.content}</React.Fragment>

      </div>
    </div>
  );
});

export default Stepper;
