import {
    StyleBuilder,
    styleVariables,
    cssKeys,
    mainTheme,
    styleFunction,
  } from "@cbmisorg/styles";
  
  export default(objThemeInfo)=>{
    const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
    const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;
  
    const classesName = {
      fieldset_comp_container: `${styleVariables?.prefixDefaultClassName}_fieldset_comp_container`,
      fieldset_container: `${styleVariables?.prefixDefaultClassName}_fieldset_container`,
      fieldset_input: `${styleVariables?.prefixDefaultClassName}_fieldset_input`,
      fieldset_label: `${styleVariables?.prefixDefaultClassName}_fieldset_label`,
      fieldset_label_text: `${styleVariables?.prefixDefaultClassName}_fieldset_label_text`,
      fieldset_icon: `${styleVariables?.prefixDefaultClassName}_fieldset_icon`,
      fieldset_front_icon: `${styleVariables?.prefixDefaultClassName}_fieldset_front_icon`,
      fieldset_end_icon: `${styleVariables?.prefixDefaultClassName}_fieldset_end_icon`,
      menuList:`${styleVariables?.prefixDefaultClassName}_fieldset_menuList`,
      menuLang:`${styleVariables?.prefixDefaultClassName}_fieldset_menuLang`,
      menuOptList:`${styleVariables?.prefixDefaultClassName}_fieldset_menuOptList`,

      inputValid: `${styleVariables?.prefixDefaultClassName}_inputValid`,
  
      sx:`${styleVariables?.prefixDefaultClassName}_input_sx`
    };
  
    const activeLabelPlace=(props)=>{
      return {
        [`.${classesName?.fieldset_label}`]:{
          position: cssObject?.position?.[cssKeys?.relative],
          top:0,
          fontSize:cssObject?.text?.[cssKeys?.size]?.body3,
          transform: `translateY(-${cssObject?.spacing?.[cssKeys?.md]})`,
          display:"inline-grid",
        }
      }
    }
  
    const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.fieldset_comp_container]:{
        display: cssObject?.display?.[cssKeys?.block],
        width: cssObject?.size?.[cssKeys?.full],
        minWidth: cssObject?.size?.[cssKeys?.full],
        position: cssObject?.position?.[cssKeys?.relative],
        margin:"auto",
        border:"none",
        outline:"none",
        height: "auto"
      },
      [classesName?.fieldset_container]:{
        display: cssObject?.display?.[cssKeys?.flex],
        width: "auto",
        position: cssObject?.position?.[cssKeys?.relative],
        margin:"auto",
        color: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor]),
        flexBasis:"100%",
        height: "auto",
        alignItems:"center"
  
      },
      [classesName?.fieldset_input]:{
        width: cssObject?.size?.[cssKeys?.full],
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        border:"none",
        outline:"none",
        background:"transparent",
        color: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor]),
        height: "auto",
      },
      [classesName?.fieldset_label]:{
        position: cssObject?.position?.[cssKeys?.absolute],
        bottom: 0,
        left: 0,
        right: 0,
        top:"15%",
        display: cssObject?.display?.[cssKeys?.block],
        alignItems: cssObject?.flex?.[cssKeys?.baseline],
        pointerEvents: "none",
        textAlign:"start",
        height:"0px",
        width:"fitContent",
        margin:`0px`,
        padding:`0px`,
        transform: "translateY(-50%)",
        maxWidth: "75%",//`${currentBaseSize * 30}rem`,
        [`.${classesName?.fieldset_label_text}`]:{
          width:"100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display:"inline-block",
          padding:"0",
          margin:0,
        }

      },
      [classesName?.fieldset_icon]:{
        position: cssObject?.position?.[cssKeys?.relative],
        margin: cssObject?.spacing?.[cssKeys?.xs],
        display: cssObject?.display?.[cssKeys?.flex],
        alignItems:"center",
        justifyContent: "center"
      },
      [classesName?.menuLang]:{
        zIndex:"10 !important",
        maxHeight:`${currentBaseSize*80}rem !important`,
        position: "fixed !important",
        left: "unset !important",
        right: "unset !important",
        marginInlineStart:`-${currentBaseSize * 30}rem !important`,
        marginTop: "0%",
        minWidth: `${currentBaseSize * 30}rem !important`,
        maxWidth: `max-content  !important`,
        overflow: "hidden"
      },
    },
    });
  
  
    const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.fieldset_comp_container]:(props)=>{
        return{
          color: props?.errorMessage?styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.error]): styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor]),
        }
      },
      [classesName?.fieldset_container]:(props)=>{
        return{
          border: String(props.mode).toLowerCase() ==="classic"?"none": `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor])}`,
          borderBottom: String(props.mode).toLowerCase() !=="classic"?"": `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor])}`,
          minHeight: cssObject?.size?.[cssKeys?.item_md],
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
          cursor: props?.menuList? "pointer":""

        }
      },
      [classesName?.fieldset_input]:(props)=>{
        return{
        width: cssObject?.size?.[cssKeys?.full],
        fontSize:cssObject?.text?.[cssKeys?.size]?.body2,
        padding:`${cssObject?.spacing?.[cssKeys?.xs]}`,
        minHeight: cssObject?.size?.[cssKeys?.item_md],
        cursor: props?.menuList? "pointer":"",
        color: cssObject?.color?.[cssKeys?.currentColor],
        direction: props?.inputDir || "",
        "&focus":String(props.mode).toLowerCase() === "outlined"?null:{
          "&+":activeLabelPlace(props)
        },
        "&notPlaceholderShown":String(props.mode).toLowerCase() === "outlined"?null:{
          "&+":activeLabelPlace(props)
        },
        "&placeholderShown":String(props.mode).toLowerCase() === "outlined" || !props?.placeholder?null:{
          "&+":activeLabelPlace(props)
        },
       }
      },
      [classesName?.fieldset_label]:(props)=>{
        return{
          fontSize:cssObject?.text?.size?.[cssKeys?.body2],
          color: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor]),
          padding:props?.icon || props?.adornment? `${cssObject?.spacing?.[cssKeys?.px]} ${cssObject?.spacing?.[cssKeys?.xs]}`:`${cssObject?.spacing?.[cssKeys?.px]}`,
          marginInlineStart: (props?.icon || props?.adornment) && String(props.mode).toLowerCase() !== "outlined"? cssObject?.size?.[cssKeys?.item_xs]: cssObject?.spacing?.[cssKeys?.xs],
          [`.${classesName?.fieldset_label_text}`]:{
            // padding: `0 ${cssObject?.spacing?.[cssKeys?.xs]}`,
            // margin: `0 ${cssObject?.spacing?.[cssKeys?.sm]}`,

          }
        }
  
      },
      [classesName?.fieldset_icon]:(props)=>{
        return{
          color: styleFunction.checkColorReturnValue(props?.iconColor || cssObject?.color?.[cssKeys?.currentColor]),
          display: "flex",
          // height: cssObject?.size?.[cssKeys?.item_sm],
          height:"100%",
          alignItems: "center",
          padding: `0 ${cssObject?.spacing?.[cssKeys?.px]}`,
        }
      },
      [classesName?.fieldset_front_icon]:(props)=>{
        return{
          fontSize:props?.adornment ? cssObject?.text?.size?.[cssKeys?.caption]:cssObject?.text?.size?.[cssKeys?.subtitle1],
          // borderInlineEnd: props?.adornment || props?.icon ? `${cssObject?.size?.[cssKeys?.border_2xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`:""
        }
      },
      [classesName?.fieldset_end_icon]:(props)=>{
        return{
          fontSize:props?.endAdornment ? cssObject?.text?.size?.[cssKeys?.caption]:cssObject?.text?.size?.[cssKeys?.subtitle1],
          borderInlineStart: props?.endAdornment || props?.endIcon ?`${cssObject?.size?.[cssKeys?.border_2xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`:"",
        }
      },
      [classesName?.menuList]:(props)=>{
          return{
            maxHeight:`${currentBaseSize*80}rem`,
            overflow:"hidden",
          }
      },
      [classesName?.menuOptList]:(props)=>{
          return{
            position: cssObject?.position?.[cssKeys?.relative],
            maxHeight:"inherit",
            overflowY:"auto",
            overflowX:"hidden",
            width:"100%"
          }
      },
      [classesName.sx]: (props) => {
        return{
          ... props?.sx,
        }
      }
    }
    });
  
    return{
      classesName,
      style,
      styleSx,
    }
  }