import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    container: `${styleVariables?.prefixDefaultClassName}_main_switch_container`,

    switch_container: `${styleVariables?.prefixDefaultClassName}_switch_container`,
    switch: `${styleVariables?.prefixDefaultClassName}_switch`,
    switch_input: `${styleVariables?.prefixDefaultClassName}_switch_input`,
    switch_slider: `${styleVariables?.prefixDefaultClassName}_switch_slider`,
    switch_slider_toggle: `${styleVariables?.prefixDefaultClassName}_switch_slider_toggle`,
    switch_label: `${styleVariables?.prefixDefaultClassName}_switch_label`,
    sx: `${styleVariables?.prefixDefaultClassName}_switch_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles:{
      [classesName?.container]:{
        position: "relative",        
        width: "max-content",
        display:"flex",
        margin:"0"
      },
      [classesName?.switch_container]:{
        position: "relative",        
        width: "max-content",
        display:"flex",
        alignItems:"center",
        margin:"0"

      },
      [classesName?.switch]:{
        display: "inline-block",
        position:"relative",
        margin:"0"
      },
      [classesName?.switch_input]:{
        display: "none",
        "&focus":{
          "&+":{
            [`.${classesName?.switch_slider}`]:{
              boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",

            }
          }
        },
      },
      [classesName?.switch_label]:{
        display: "block",
        cursor: "pointer",
        position:"relative",
      },
      [classesName?.switch]:{
        display: "inline-block",
        position:"relative"
      },
      [classesName?.switch_slider]: {
        position: "relative",
        display:"block",
        border: 0,
        outline: "none",
        backgroundColor: "#ccc",
        transition: ".4s",
        cursor: "pointer",
        padding:"0px",
        margin:"auto 0",
      },
      [classesName?.switch_slider_toggle]:{
        content: "",
        position: "absolute",
        display:"block",
        left: "1px",
        right: "1px",
        bottom: "0px",
        backgroundColor: "#fff",
        transition: ".4s",
        borderRadius: "50%",
        margin:"0",
      }
    }
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.switch_input]:(props)=>{
       return { 
        "&checked":{
        "&+":{
          [`.${classesName?.switch_slider}`]:{
            background:cssObject?.color?.[cssKeys?.currentColor],
            [`.${classesName?.switch_slider_toggle}`]:{
              transform:props?.dir === "rtl"?`translateX(-${cssObject?.size?.[cssKeys?.item_xs]})`: `translateX(${cssObject?.size?.[cssKeys?.item_xs]})`
            }
          }
        }
      }
      }
      },
      [classesName?.switch_label]:{
        margin:`auto ${cssObject?.spacing?.[cssKeys?.xs]}`,
        color:cssObject?.color?.[cssKeys?.bodyColor],
        fontSize:cssObject?.text?.size?.[cssKeys?.body2],
        padding: `0 ${cssObject?.spacing?.[cssKeys?.px]}`,
      },
      [classesName?.switch_slider]: {
        width: cssObject?.size?.[cssKeys?.item_lg],
        height: cssObject?.size?.[cssKeys?.item_xs],
        borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xl,

      },
      [classesName?.switch_slider_toggle]:{
        height: `${currentBaseSize*5}rem`,
        width: `${currentBaseSize*5}rem`,
        top:`${currentBaseSize/2}rem`,
      },
      [classesName?.sx]: (props) => {
        return {
          color:props?.color? styleFunction?.checkColorReturnValue(props?.color) : cssObject?.color?.[cssKeys?.primary],
          ...props?.sx,

        };
      },
    },
  });


  return{
    classesName,
    style,
    styleSx,
  }
}