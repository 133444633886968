import React, { forwardRef, useContext, useEffect, useId } from "react";
import { styleFunction, ContextThemeProvider } from "@cbmisorg/styles";
import CheckboxGroupStyle from "./CheckboxGroupStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { checkParseObject } from "../../_helper/functions";

const CheckboxGroup = forwardRef((/**@type {any}*/ props, ref) => {
  const {
    currentLang = "eng",
    defaultLang = "eng",
    label,
    options,
    children,
    display,
    color,
    mode,
    sx,
    hidden,
    disabled,
    dir,
    className,
    value = null,
    defaultValue = null,
    id,
    errorMessage,
    helperMessage,
    blnMenuInputList=true,
    ...rest
  } = props;
  const comID = String(useId()).slice(1, -1) + "checkbox" || id;
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = CheckboxGroupStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, color, display, mode, disabled });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.checkbox_container],
    classesSx?.[classesName?.checkbox_container],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const CheckboxComp = () => {
    let lstOptions = [];
    if (children) {
      lstOptions = children;
    } else if (options) {
      lstOptions = options;
    }
    const funOptValue = (opt) => {
      const theValue = checkParseObject(value || defaultValue || [])?.map((item) => {
        return item?.key? item?.key : item;
      });
      return theValue.includes(String(typeof opt === "object" ? opt?.key : opt)) || theValue.includes(typeof opt === "object" ? opt?.key : opt);
    };

    return (
      <div className={itemClasses} key={props?.key || comID}>
        {!label ? null : <label className={`${classes?.[classesName?.checkbox_label]} classesSx?.[classesName?.checkbox_label]`}>{label}</label>}
        {lstOptions?.map((opt, index) => {
          const blnChecked = funOptValue(opt);

          return (
            <label key={opt?.key + index} className={`${classes?.[classesName?.checkbox_opt]} ${classesSx?.[classesName?.checkbox_opt]}`}>
              <input
                name={comID}
                type="checkbox"
                className={`${classes?.[classesName?.checkbox]} ${classesSx?.[classesName?.checkbox]}`}
                ref={ref}
                id={opt?.key}
                value={opt?.props?.value || opt?.value?.[currentLang] || opt?.value?.[defaultLang] || opt?.value || opt?.key || opt}
                defaultChecked={blnChecked}
                {...styleFunction?.getRestProps(rest)}
                key={opt?.key + "_input_" + index}
              />
              <div className={`${classes?.[classesName?.checkbox_opt_label]}`}>
                <ErrorBoundary>
                  {opt?.props?.children || opt?.props?.value || typeof opt?.jsx === "function"
                    ? opt?.jsx()
                    : typeof opt?.value !== "object"
                    ? opt?.value
                    : opt?.value?.[currentLang] || opt?.value?.[defaultLang] || ""}
                </ErrorBoundary>
              </div>
            </label>
          );
        })}
        <ErrorMessage message={errorMessage || helperMessage || ""} />
      </div>
    );
  };
  useEffect(() => {}, [currentLang, label, options, children, display, color, mode, sx, hidden, disabled, dir, className, value, defaultValue, id, errorMessage, helperMessage, blnMenuInputList]);
  return (
    <React.Fragment>
      <CheckboxComp />
      <span
        style={{ display: "none" }}
        hidden={true}
        className={`${classes?.[classesName?.checkbox_opt]} ${classes?.[classesName?.checkbox_opt_label]} ${classes?.[classesName?.checkbox]} ${classesSx?.[classesName?.checkbox]}`}
      />
    </React.Fragment>
  );
});

export default CheckboxGroup;
