import React, { useContext, useEffect, useState } from "react";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { Container, Grid, Icon, Line, Loader, MenuItem, Typography } from "@cbmisorg/client-app";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { App_Dark_Color, App_Primary_Color, App_Text_Color } from "../../../../appHelper/appColor";
import registeredActivitiesCtrl from "./RegisteredActivities.controller";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { workSectorIDLabel } from "../../../../appHelper/appVariables";
import excelIcon from "../../../../assets/images/excel-icon.png";

function RegisteredActivitiesView() {
  useCheckSystemInfo();
  useCheckCat();

  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const labels = dictionary?.components?.registeredActivities;

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsFetchDone: false,
    lstActivities: [],
    lstActivitiesRead: [],
  });

  const fields = FormData({
    lstActivityType: {
        strControl: "list",
        lstOption: []
    },
    lstSubject:{
        strControl: "list",
        lstOption:[]
    }
  });

  const handlers = registeredActivitiesCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, fields });
  const exportExcelFile = handlers.exportExcelFile({ state });
  const onChangeActivety = handlers.onChangeActivety({state, setState, fields})
  const onChangeSubject = handlers.onChangeSubject({state, setState, fields})

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      initData();
    }
  }, []);

  console.log(state?.lstActivitiesRead);
  
  return (
    <>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}

        {!state.lstActivities?.length ? (
          <Grid container justify={"center"}>
            <NoResultImg xs={8} md={6} lg={6} />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container justify={"space-between"}>
              <Grid item>
                <Typography as="body1">
                  {labels?.number?.[lang]}: {state?.lstActivitiesRead?.length}
                </Typography>
              </Grid>
              <Grid item justify={"end"} mt-1 px-1>
                <span onClick={exportExcelFile}>
                  <img src={excelIcon} alt="" width={"25px"} style={{ borderRadius: "4px" }} />
                </span>
              </Grid>
            </Grid>
            <Line />
            <Grid container justify={"space-between"}>
              <Grid item xs="12" md="6">
                <FormField objHandler={fields?.lstActivityType} dir={lang === "arb"? "rtl":"ltr"} onChange={onChangeActivety}/>
              </Grid>
              <Grid item xs="12" md="6">
              <FormField objHandler={fields?.lstSubject} dir={lang === "arb"? "rtl":"ltr"} onChange={onChangeSubject}/>
              </Grid>
            </Grid>
            <Grid container justify={"center"}>
              {(state.lstActivitiesRead || [])?.map((activaty, intIndex) => {
                return (
                  <Grid item xs="12" key={activaty?.id}>
                    <MenuItem
                      sx={{
                        width: "100%",
                        border: `1px solid ${App_Text_Color}`,
                        color: App_Primary_Color,
                        display: "block",
                      }}
                      outlined
                    >
                      <div style={{ display: "block", width: "98%" }}>
                        <Grid container>
                          <Grid item xs="10">
                            <Grid container>
                              <Grid item xs="12">
                                <Typography as="subtitle2" className="inline-ellipsis">
                                  {activaty?.jsnGuestInfo?.strFullName}
                                </Typography>
                              </Grid>
                              <Grid item xs="12" container>
                                <Icon icon="work" color="dark" px-2 />
                                <Typography as="caption" className="inline-ellipsis" color={"dark"}>
                                  {workSectorIDLabel?.[activaty?.bigWorkSector]?.[lang]} - {activaty?.strPlaceName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs="2" container spacing={0} justify={"end"}>
                            <Grid item xs="12" container justify={"end"}>
                              <Grid item>
                                <Icon icon="email" color={App_Primary_Color} onClick={() => window.open(`mailto:${activaty?.jsnGuestInfo?.strEmail}`)} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Line />
                        <Grid container>
                          {activaty?.jsnGuestInfo?.objTargeted?.value?.[lang] && activaty?.jsnGuestInfo?.objTargeted?.date?.value?.[lang] && (
                            <Grid item xs="12">
                              <Typography as="caption" color={"dark"}>
                                -{activaty?.jsnGuestInfo?.objTargeted?.value?.[lang]} ({activaty?.jsnGuestInfo?.objTargeted?.date?.value?.[lang]})
                              </Typography>
                            </Grid>
                          )}
                          {activaty?.jsnGuestInfo?.objTargeted?.targeted?.value?.[lang] && (
                            <Grid item xs="12">
                              <Typography as="caption" color={"dark"}>
                                -{activaty?.jsnGuestInfo?.objTargeted?.targeted?.value?.[lang]}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </MenuItem>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </>
  );
}

export default RegisteredActivitiesView;
