import React, { useContext, useState } from "react";
import * as appVariables from "../../../appHelper/appVariables";
import * as appFunctions from "../../../appHelper/appFunctions";
import { Alert, Grid, Icon, Modal, ModalBody, ModalHeader, Typography } from "@cbmisorg/client-app";
import { ShowIcon, getIconForFile } from "@cbmisorg/file-icons";
import { App_Light_Bluewood, App_Primary_Color } from "../../../appHelper/appColor";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";

export default function UploadRead({ lstFiles, onDownloadFile = (event) => null, blnUploadBuilder = false }) {
  const { appState } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;

  const [dialogState, setDialogState] = useState({ blnIsOpen: false });

  const downloadFile = (objUploadFileInfo) => async (event) => {
    try {
      if (onDownloadFile) {
        onDownloadFile(event);
      }
      let newFile = new File([await (await fetch(appVariables.App_Server_Url_DownloadFiles + objUploadFileInfo.path)).blob()], objUploadFileInfo?.name);
      appFunctions.downloadFile(newFile);
    } catch {
     
      Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantDownloadFile?.[lang], "warning");

    }
  };

  const buildTheListForBuilder = () => {
    let objFileCat = {};

    for (let i = 0; i < lstFiles.length; i++) {
      if (Array.isArray(objFileCat?.[lstFiles?.[i]?.cat])) {
        objFileCat?.[lstFiles?.[i]?.cat].push(lstFiles[i]);
      } else {
        objFileCat[lstFiles?.[i]?.cat] = { ...objFileCat[lstFiles?.[i]?.cat] };
        objFileCat[lstFiles?.[i]?.cat] = [lstFiles[i]];
      }
    }

    return objFileCat;
  };

  const jsxFileViewer = (file, intIndex) => {
    return (
      <Grid container justify="center" my-3 py-1 sx={{ direction: "ltr", "&hover": { background: "#dfebf7" }, borderRadius: "0.7em", border: `1px solid ${App_Primary_Color}` }} key={file?.name + intIndex || "file-" + intIndex}>
        <Grid item xs={2}>
          <ShowIcon imageName={getIconForFile(file.name || "")} width="38px" />
        </Grid>
        <Grid item xs={4} mt-3>
          <Typography as="caption" color={"#1f1f1f"} sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {file.name}
          </Typography>
        </Grid>
        <Grid item xs={2} mt-3>
          <Typography as="caption" color={"#1f1f1f"}>
            {Number(file.bSize / Math.pow(1024, 1)).toFixed(2)} KB
          </Typography>
        </Grid>
        <Grid item xs={2} mt-3>
          <Typography as="caption" color={"#1f1f1f"}>
            {file.dtm}
          </Typography>
        </Grid>

        <Grid item xs={1} mt-2>
          <Icon title="download" icon="download" sx={{ color: "#3872b0", background: "#e8e8e8", fontSize: "22px", padding: "0px 2px 4px 2px" }} onClick={downloadFile(file)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
    <Grid container p-0 spacing={0}>
    <Grid item p-0 sx={{ borderRadius: "3em", border: "1px dashed #ddd !important" }}>
      <Grid container p-0 spacing={0}>
        <Grid
          item
          p-0
          pr-1
          sx={{
            borderRadius: "3em",
            cursor: "pointer",
            "&hover": { boxShadow: "-1px 1px 3px #333", borderRadius: "3em" },
          }}
          title="Download Files"
          onClick={() => setDialogState({ ...dialogState, blnIsOpen: true })}
        >
          <Icon
            sx={{
              fontSize: "24px",
              boxShadow: "1px 0 2px #333",
              color: "#fff",
              background: "#ddd",
              "&hover": { background: "#ddd" },
            }}
            title="Upload Files"
            icon="download"
            onClick={(event) => {
              event?.stopPropagation();
            }}
            pt-1
          />

          <Typography as="body2"  px-3>
            {lstFiles?.length || "0"} {dictionary.shared.ui.filesNum?.[lang]}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>

  <Modal size="md" open={dialogState.blnIsOpen} eventClose={() => setDialogState({ ...dialogState, blnIsOpen: false })}>
    <ModalHeader>{dictionary.shared.ui.viewFiles?.[lang]}</ModalHeader>
    <ModalBody>
      <Grid container>
        <Grid item xs={12}>
          {!lstFiles || !lstFiles?.length ? (
            <Grid container justify="center" my-10>
              <Grid item>
                <Typography as="subtitle1">{dictionary.shared.ui.noFileYet?.[lang]}</Typography>
              </Grid>
            </Grid>
          ) : blnUploadBuilder ? (
            Object.entries(buildTheListForBuilder() || {})?.map?.(([id, lstFile], intIndex) => {
              return (
                <Grid container key={id + "-reading-file-" + intIndex} my-3>
                  <Grid item>
                    <Typography as="subtitle2">{lstFile?.[0]?.lbl?.[lang]}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ border: `2px solid ${App_Light_Bluewood}`, minHeight: "60px" }}>
                    {lstFile?.map?.((file, intIndex) => {
                      if (String(file?.cat) !== String(id)) {
                        return null;
                      }
                      return jsxFileViewer(file, intIndex);
                    })}
                  </Grid>
                </Grid>
              );
            })
          ) : (
            lstFiles?.map?.((file, intIndex) => {
              return jsxFileViewer(file, intIndex);
            })
          )}
        </Grid>
      </Grid>
    </ModalBody>
  </Modal>
    </>
  );
}
