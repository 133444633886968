import React, { forwardRef } from "react";
import GroupField from "../GroupField/GroupField";


const DatePicker = forwardRef((/**@type= {any} */props, ref) => {
    const {type="date",endIcon,onMouseOver,onBlur,defaultValue,value, ...rest} = props
    // const [theType, setTheType] = useState("text");
    
    // const handleOnMouseOver=(event)=>{
    //   if(theType === "text"){
    //     setTheType(type || "date")
    //   }
    //   if(onMouseOver){
    //     onMouseOver(event)
    //   }
    // }

    // const handleBlur=(event)=>{
    //   if(!event.target.value){
    //     setTheType("text")
    //   }
    //   if(onBlur){
    //     onBlur(event)
    //   }
    // }

  return (
        <GroupField
          ref={ref}
          type={type}
          defaultValue={value || defaultValue}
          // onBlur={handleBlur}
          endIcon={endIcon || type === "time" ? "accessTime" : "calendarToday"}
          // endIconRest={{onMouseOver:() => setTheType(type || "date"), onMouseLeave:() => setTheType("text"), onTouchEnd:() => setTheType(type ==="text"?type || "date":"text"),}}
          {...rest}
          />
    )
});

export default DatePicker