import { objCssProperties } from "packages/style/src/helper/cssProperties";
import keys, { keysSpacing } from "./keys";

const getSpace=(arrClasses)=>{
    const spacer = arrClasses?.[2] ||  arrClasses?.[1]
    if(!isNaN(Number(spacer)) ){
        return `${keys?.spacer?.baseSize * Number(spacer)}rem`
    }else if(keysSpacing?.[spacer]){
        return keys?.spacer?.[keysSpacing?.[spacer]]
    }
}

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    const value = getSpace(arrClasses)
    if(arrClasses?.[0] === keysSpacing?.mrg){
        return {[objCssProperties?.margin] : value}
    }else if(arrClasses?.[0] === keysSpacing?.mrgTop){
        return {[objCssProperties?.marginTop] : value}
    }else if(arrClasses?.[0] === keysSpacing?.mrgBottom){
        return {[objCssProperties?.marginBottom] : value}
    }else if(arrClasses?.[0] === keysSpacing?.mrgLeft){
        return {[objCssProperties?.marginLeft] : value}
    }else if(arrClasses?.[0] === keysSpacing?.mrgRight){
        return {[objCssProperties?.marginRight] : value}
    }else if(arrClasses?.[0] === keysSpacing?.mrgX){
        return {
            [objCssProperties?.marginRight] : value,
            [objCssProperties?.marginLeft] : value
        }
    }else if(arrClasses?.[0] === keysSpacing?.mrgY){
        return {
            [objCssProperties?.marginTop] : value,
            [objCssProperties?.marginBottom] : value
        }
    }else if( arrClasses?.[0] === keysSpacing?.start && arrClasses?.[1] === keysSpacing?.mrgX){
        return {[objCssProperties?.marginInlineStart] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.end && arrClasses?.[1] === keysSpacing?.mrgX){
        return {[objCssProperties?.marginInlineEnd] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.start && arrClasses?.[1] === keysSpacing?.mrgY){
        return {[objCssProperties?.marginBlockStart] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.end && arrClasses?.[1] === keysSpacing?.mrgY){
        return {[objCssProperties?.marginBlockEnd] : value,}
    }
    else  if(arrClasses?.[0] === keysSpacing?.pdn){
        return {[objCssProperties?.padding] : value}
    }else if(arrClasses?.[0] === keysSpacing?.pdnTop){
        return {[objCssProperties?.paddingTop] : value}
    }else if(arrClasses?.[0] === keysSpacing?.pdnBottom){
        return {[objCssProperties?.paddingBottom] : value}
    }else if(arrClasses?.[0] === keysSpacing?.pdnLeft){
        return {[objCssProperties?.paddingLeft] : value}
    }else if(arrClasses?.[0] === keysSpacing?.pdnRight){
        return {[objCssProperties?.paddingRight] : value}
    }else if(arrClasses?.[0] === keysSpacing?.pdnX){
        return {
            [objCssProperties?.paddingRight] : value,
            [objCssProperties?.paddingLeft] : value
        }
    }else if(arrClasses?.[0] === keysSpacing?.pdnY){
        return {
            [objCssProperties?.paddingTop] : value,
            [objCssProperties?.paddingBottom] : value
        }
    }else if( arrClasses?.[0] === keysSpacing?.start && arrClasses?.[1] === keysSpacing?.pdnX){
        return {[objCssProperties?.paddingInlineStart] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.end && arrClasses?.[1] === keysSpacing?.pdnX){
        return {[objCssProperties?.paddingInlineEnd] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.start && arrClasses?.[1] === keysSpacing?.pdnY){
        return {[objCssProperties?.paddingBlockStart] : value,}
    }else if( arrClasses?.[0] === keysSpacing?.end && arrClasses?.[1] === keysSpacing?.pdnY){
        return {[objCssProperties?.paddingBlockEnd] : value,}
    }

}