import { StyleBuilder, cssKeys, mainTheme, styleFunction, styleVariables } from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const  classesName = {
    appBar: `${styleVariables?.prefixDefaultClassName}_app_bar`,
    appBarContent: `${styleVariables?.prefixDefaultClassName}_app_bar_Content`,
    bar_burger: `${styleVariables?.prefixDefaultClassName}_bar_burger`,
    bar_close: `${styleVariables?.prefixDefaultClassName}_bar_close`,
    bar_1: `${styleVariables?.prefixDefaultClassName}_bar_1`,
    bar_2: `${styleVariables?.prefixDefaultClassName}_bar_2`,
    bar_3: `${styleVariables?.prefixDefaultClassName}_bar_3`,

    appBar_sidebarDbOutlet: `${styleVariables?.prefixDefaultClassName}_app_bar_sidebarDbOutlet`,
    appBar_sidebarDb: `${styleVariables?.prefixDefaultClassName}_app_bar_sidebarDb`,
    appBar_outlet: `${styleVariables?.prefixDefaultClassName}_app_bar_outlet`,
    drawer: `${styleVariables?.prefixDefaultClassName}_drawer`,
    appBar_breadcrumbs: `${styleVariables?.prefixDefaultClassName}_app_bar_breadcrumbs`,


    sx:`${styleVariables?.prefixDefaultClassName}_appBar_sx`
  }
  const getWidth=()=>{
   if(open){
    return{
      xs:{
        minWidth: "100vw",
      },
      sm:{
        minWidth: "70vw",
      },
      md:{
        minWidth: "80vw",
      },
      lg:{
        minWidth: "83.8vw",
      },
      xl:{
        minWidth: "90vw",
      },
    }
   }
  }
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.appBar]:{
        transition: "top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent:"space-between",
        boxSizing: "border-box",
        zIndex: 1200,
        // boxShadow: cssObject?.boxShadow?.[cssKeys?.outline],
        overflowY: "hidden",
        position: "sticky",
        top:"0px",
        left:"0px",
        right:"0px",
        background:"#f9fafb",
        width:"100%",
        height: cssObject?.size?.[cssKeys?.item_md],
        margin:"0px",
        padding:`0px ${cssObject?.spacing?.[cssKeys?.xl]}`,
        sm:{
          padding:`0px ${cssObject?.spacing?.[cssKeys?.sm]}`,
        }
      },
      [classesName?.appBarContent]:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent:"space-between",
        boxSizing: "border-box",
        zIndex: 1,
        overflowY: "hidden",
        position: "relative",
        top:"0px",
        left:"0px",
        right:"0px",
        width:"100%",
        height: cssObject?.size?.[cssKeys?.item_md],
        margin:"0px",
      },

      [classesName?.appBar_sidebarDbOutlet]:{
        display:"flex",
        width:"100vw",
        position: "relative",
        padding:"0",
        margin:"0 auto",
        overflow:"hidden",
        minHeight:"calc(100vh - 68px)",

      },
      [classesName?.appBar_sidebarDb]:{
        [`.${classesName?.drawer}`]:{
          position: "sticky",
          height:"calc(100vh - 68px)",
        },
      },
      [classesName?.appBar_outlet]:{
        position: "relative",
        minHeight:"100%",
        width:"max-content",
        margin: "0 auto",
        marginInlineEnd: 0,
        padding:"0px",
        ...getWidth(),
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
      objStyles:{
      [classesName?.appBarContent]: (props) => {
        return {
          width: props?.open? "calc(100% - 30px)": "100%"
        };
      },
      [classesName?.bar_burger]: (props) =>{
        return{
        display: "block",
        position: "relative",
        cursor: "pointer",
        zIndex: "2",
        transition: "all 0.7s ease",
        margin:0,
        padding:0,
        width: `${currentBaseSize * 7}rem`,
        height: `${currentBaseSize * 5}rem`,
        color: cssObject?.color?.[cssKeys?.bodyColor],
        "span":{
          background: cssObject?.color?.[cssKeys?.currentColor],
          position: "absolute",
          width: "100%",
          height: "20%",
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.sm,
          transition: props.open? ".4s top, .4s bottom, .4s .4s transform": ".4s .4s top, .4s .4s bottom, .3s transform"
        },
        [`.${classesName?.bar_1}`]:{
          // ...(props.open?{
          //   top: "40%",
          //   transform: "rotate(45deg)"
          // }:{
            top:  0
          // })
        },
        [`.${classesName?.bar_2}`]:{
        //   ...(props.open?{
        //   opacity: "0 ",
        //   transition: "opacity .1s .4s ",
        // }:{
          top: "40%",
          opacity: "1",
          transition: "opacity .1s .4s"
        // }),
        },
        [`.${classesName?.bar_3}`]:{
          // ...(props.open?{
          //   bottom: "40%",
          //   transform: "rotate(-45deg)"
          // }:{
            bottom:  0
          // })
        },
        }
      },
      [classesName?.appBar_outlet]: (props) =>{
        return{
        position: "relative",
        minHeight:"100%",
        width: props.open? "auto" :"100vw",
        marginInlineEnd: 0,
        padding:0,
        
        }
      },


      [classesName?.appBar_sidebarDb]:{
        [`.${classesName?.drawer}`]:{
          position: "sticky",
          height:"calc(100vh - 68px)",
        },
      },
      [classesName?.appBar_breadcrumbs]:(props)=>{
        if(props?.breadcrumbs){
          return{
            // borderBottom: `1px solid #ccc`,
            background: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.bodyColorBgLight]),
            marginInlineEnd: 0,
            width: "100%",
            display:"flex",
            position:"relative",
            ...getWidth()
          }
        }
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
          justifyContent:props?.justifyContent,
        };
      },
    },
  });

  return{
    classesName,
    style,
    styleSx
  }
}