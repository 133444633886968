import { useContext, useEffect } from "react";
import * as appVariables from "../appVariables";
import * as appFunctions from "../appFunctions";
import { ClientFunction } from "@cbmisorg/client-app";
import { AppContext } from "../../configuration/contextapi/AppContext";
import { generateQueries } from "../appQuery/GenerateQuery";

const strPageInfo = "@src/appHelper/hooks/useCheckCat.js";
const tblCategory = generateQueries("tblCategory");

export default function useCheckCat(blnIsNotHook = false) {
  const { appState } = useContext(AppContext);

  useEffect(() => {
    if (!blnIsNotHook) {
      checkHookValidity();
    }
  }, [appState, blnIsNotHook]);

  async function checkHookValidity() {
    try {
      let intLastChecked = ClientFunction.getDifferenceBetweenTwoDate(appState?.clientInfo?.dtmLastCheckedCatInfo, ClientFunction?.getCurrentDate(), "hour");
      if (intLastChecked && intLastChecked < appVariables.Cat_Info_HoursToCheck && String(appState?.clientInfo?.objCatInfo).length > 20) {
        return appFunctions.getCategoryCache();
      }

      return await getNewCatInfo();
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "checkHookValidity-ERROR", error);
    }
  }

  async function getNewCatInfo() {
    try {
      const result = await tblCategory(strPageInfo, appState, "aaiotAppFindAll", {
        arrAttributes: ["bigID", "strName", "jsnValue", "bigParentID", "jsnParentSeries", "blnIsDeleted", "blnIsActive"],
        objectCondition: {},
      });

      if (!result?.blnIsRequestSuccessful) {
        return;
      }

      let lstCatInfo = result.response;
      lstCatInfo = Array.isArray(lstCatInfo) ? lstCatInfo : [];

      let objCatInfo = {};
      const jsnParentSeriesID = {};

      for (let i = 0; i < lstCatInfo.length; i++) {
        let lstParentSeries = Array.isArray(lstCatInfo[i]?.jsnParentSeries) ? lstCatInfo[i]?.jsnParentSeries : [];
        jsnParentSeriesID[lstCatInfo[i].bigID] = lstParentSeries;
        jsnParentSeriesID[lstCatInfo[i].bigID] = [[lstCatInfo[i]?.bigID, lstCatInfo[i]?.strName], ...lstParentSeries];
        if (lstParentSeries.length > 0) {
          resolve(lstParentSeries, objCatInfo, lstCatInfo[i], lstParentSeries.length - 1);
        }
      }

      objCatInfo = appFunctions.compressText(objCatInfo);

      if (JSON.stringify(appState?.clientInfo?.objCatInfo) !== JSON.stringify(objCatInfo)) {
        appState.clientInfo.objCatInfo = objCatInfo;
        appState.clientInfo.dtmLastCheckedCatInfo = ClientFunction.getCurrentDate();
        appFunctions.setAppLocalStorage(appState);
      } else {
        changeLastCheckedDateNoRender();
      }
      
      return appFunctions.decompressText(objCatInfo);
    } catch (error) {
      appFunctions.logMessage(strPageInfo, error, "getNewCatInfo-ERROR");
    }
  }

  function changeLastCheckedDateNoRender() {
    try {
      appState.clientInfo.dtmLastCheckedCatInfo = ClientFunction.getCurrentDate();
      appFunctions.setAppLocalStorage(appState);
    } catch {}
  }

  return {
    getNewCatInfo,
  };
}

function resolve(lstArr, obj, objCatInfo, intIndex = 0) {
  try {
    if (intIndex < 0 || !lstArr?.[intIndex]) {
      if (objCatInfo?.bigID && objCatInfo?.strName) {
        obj[objCatInfo?.strName] = {
          id: objCatInfo?.bigID,
          value: objCatInfo?.jsnValue,
        };
      }
      return obj;
    }

    let [bigParentID, strParentName] = lstArr[intIndex];
    --intIndex;

    if (bigParentID === appVariables.Cat_Root_ID) {
      resolve(lstArr, obj, objCatInfo, intIndex);
    } else {
      obj[strParentName] = { ...obj[strParentName] };
      resolve(lstArr, obj[strParentName], objCatInfo, intIndex);
    }
  } catch (error) {
    console.log(error);
  }
}
