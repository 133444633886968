import React, { forwardRef, useContext, useId, useRef} from 'react'
import GroupField from '../GroupField/GroupField';

const TextArea = forwardRef((/**@type {any}*/props, ref) => {
  // const {
  //   id, label,placeholder,errorMessage=null, helperMessage, language="eng",
  //   icon,adornment,endIcon,endAdornment,
  //   mode,color,iconColor,
  //   type="TextArea",value="",defaultValue="",
  //   inputDir,
  //   onFocus, onBlur,
  // objLang,currentLang,setCurrentLang, getCurrentValue, // FORM API
  //   sx,hidden,disabled,dir,className,MenuList=null,...rest} = props

  const {objLang,...rest} = props
  return (
    <GroupField
      type="textarea"
      ref={ref}
      optionLang={objLang}
      {...props} />
  )
});

export default TextArea