import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    radio_container: `${styleVariables?.prefixDefaultClassName}_radio_container`,
    radio: `${styleVariables?.prefixDefaultClassName}_radio`,
    radio_opt_label: `${styleVariables?.prefixDefaultClassName}_radio_opt_label`,
    radio_opt: `${styleVariables?.prefixDefaultClassName}_radio_opt`,
    radio_label: `${styleVariables?.prefixDefaultClassName}_radio_label`,
    sx: `${styleVariables?.prefixDefaultClassName}_radio_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.radio_container]: {
        position: "relative",
        width: "100%",
        margin: 0,
        display: "inline-block",
      },
      [classesName?.radio]: {
        appearance: "none",
        WebkitAppearance: "none",
        /* remove default */
        display: "flex",
        alignSelf: "flex-start",
        position: "relative",
        borderRadius: cssObject?.border?.[cssKeys?.rounded]?.pill,
        cursor: "pointer",
        verticalAlign: "middle",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
        backgroundColor: cssObject?.color?.[cssKeys?.light],
        WebkitTransition: "background-position .15s cubic-bezier(.8, 0, 1, 1), -webkit-transform .25s cubic-bezier(.8, 0, 1, 1)",
        outline: "none",
        margin: 0,
        top: ".7rem",
        "&active": {
          WebkitTransform: "scale(1.5)",
          WebkitTransition: "-webkit-transform .1s cubic-bezier(0, 0, .2, 1)",
        },
      },
      [classesName?.radio_label]: {
        display: "flex",
        alignItems: "center",
        fontWeight:"600",
        fontSize:cssObject?.text?.size?.[cssKeys?.subtitle3],
        textAlign: "start",
        width:"100%"
      },
      [classesName?.radio_opt]: {
        display: "flex",
        width: "inherit",
        justifyContent: "flex-start",
        alignItems: "baseline",
        // flexWrap: "wrap",
      },
      [classesName?.radio_opt_label]: {
        display: "inline-block",
        width: "max-content",
        margin: "0px"
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.radio_container]: (props) => {
        return {
          padding: `${cssObject?.spacing?.[cssKeys?.px]}`,
          margin:"auto",
          display: `${String(props?.display).toLowerCase() === "inline" && !props?.blnMenuInputList ? "flex" : "block"}`,
          width: props?.blnMenuInputList ? "99%" : "maxContent",
          // flexWrap: "wrap",
        };
      },
      [classesName?.radio]: (props) => {
        return {
          minWidth: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          width: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          height: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          opacity: props?.blnMenuInputList ? 0 : 1,
          "&hover": {
            backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
            ...props?.sx?.["&hover"],
          },
          "&checked": {
            WebkitTransition: "background-position .1s .15s cubic-bezier(0, 0, .2, 1),-webkit-transform .25s cubic-bezier(0, 0, .2, 1)",
            backgroundImage: `-webkit-radial-gradient( ${styleFunction?.checkColorReturnValue(props?.color || cssObject?.color?.[`${cssKeys?.primary}_1`])} 0%, ${styleFunction?.checkColorReturnValue(
              props?.color || cssObject?.color?.[`${cssKeys?.primary}_3`]
            )} 20%, ${styleFunction?.checkColorReturnValue(`${props?.color || cssObject?.color?.[`${cssKeys?.primary}_5`]}f5`)} 30%, ${styleFunction?.checkColorReturnValue(
              `${props?.color || cssObject?.color?.[`${cssKeys?.primary}`]}63`
            )} 70%)`,
            backgroundRepeat: "no-repeat",
            boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.white]}, 0 0 0.15em 0.1em ${cssObject?.color?.[cssKeys?.primary]}`,
            ...props?.sx?.["&checked"],
            "&+": props?.blnMenuInputList
              ? {
                  [`.${classesName?.radio_opt_label}`]: {
                    backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
                    color: cssObject?.color?.[cssKeys?.primary],
                  },
                }
              : null,
          },
        };
      },
      [classesName?.radio_opt]: (props) => {
        return {
          color: cssObject?.color?.[cssKeys?.currentColor],
          fontSize: cssObject?.text?.size?.[cssKeys?.body3],
          margin: "auto 0px",
          // margin:String(props?.display).toLowerCase() === "inline"?  `${cssObject?.spacing?.[cssKeys?.sm]}` : `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
          justlyContent: "center",
          alignItem: "center",
          textAlign: "start",
          width: props?.blnMenuInputList ? "100%" : "inherit",
          [`.${classesName?.radio_opt_label}`]: {
            padding:String(props?.display).toLowerCase() === "inline"? `${cssObject?.spacing?.[cssKeys?.sm]}`: `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
            fontSize: cssObject?.text?.size?.[cssKeys?.body3],
            ...(props?.blnMenuInputList
              ? {
                  width: "100%",
                  cursor: "pointer",
                  "&hover": {
                    backgroundColor: cssObject?.color?.[cssKeys?.light],
                  },
                }
              : null),
          },
        };
      },
      [classesName?.radio_label]: {
        fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
        textAlign: "start",
        maxWidth: "fit-content",
        margin: 0,
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
