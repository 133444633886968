
import { StyleBuilder, cssKeys, mainTheme, styleVariables } from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const  classesName = {
    groupBar: `${styleVariables?.prefixDefaultClassName}_group_bar`,
    sx:`${styleVariables?.prefixDefaultClassName}_groupBar_sx`
  }
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.groupBar]:{
        transition: "top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        position: "relative",
        width: "auto",
        margin: "0px",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "inherit",
        alignItems: "center",
        textTransform: "capitalize",
        listStyle: "none",
        justifyContent: "flex-start",
        background: "transparent",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
      objStyles:{
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  return{
    classesName,
    style,
    styleSx
  }
}