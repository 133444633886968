import React, { forwardRef } from 'react'


const Option = forwardRef((/**@type {any}*/props, ref) => {
    const {children, rest} = props
  return (
    <span ref={ref} {...rest}>{children}</span>
  )
})

export default Option
