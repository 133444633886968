import React, { forwardRef, useContext, useEffect, useId, useRef, useState } from "react";
import { ContextThemeProvider, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";
import { Icon } from "@cbmisorg/icons";
import GroupFieldStyle from "./GroupFieldStyle";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Typography from "../../dataDisplay/Typography/Typography";
import Menu from "../../navigation/Menu/Menu";
import MenuItem from "../../navigation/Menu/MenuItem";
import Button from "../Button/Button";
import { checkParseObject } from "../../_helper/functions";

const GroupField = forwardRef((/**@type {any}*/ props, ref) => {
  const {
    id,
    label,
    placeholder,
    errorMessage = null,
    helperMessage,
    icon,
    adornment,
    endIcon,
    endAdornment,
    mode,
    color,
    iconColor,
    iconSize,
    type = "text",
    value,
    defaultValue,
    inputDir,
    endIconRest = {},
    endComponent, // to use in other inputs
    onFocus,
    onBlur,
    onChange,
    optionLang = null,
    currentLang = "eng",
    defaultLang = "eng",
    setCurrentLang, // FORM API
    sx,
    hidden,
    disabled,
    dir,
    className,
    MenuList = null,
    ...rest
  } = props;
  // endIconRest,endComponent, // to use in other inputs
  const inputRef = useRef(null);
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = GroupFieldStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, color, mode, icon: icon? true: false, adornment, endIcon:endIcon? true: false, endAdornment, dir, inputDir, placeholder, errorMessage, type, menuList: MenuList ? true : false });
  const cssObject = theTheme?.cssObject || mainTheme?.cssObject;
  const comID = id || String(useId()).slice(1, -1) + "text";
  const itemClassesContainer = styleFunction.getClassName(
    classes?.[classesName?.fieldset_comp_container],
    classesSx?.[classesName?.fieldset_comp_container],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.fieldset_input], classesSx?.[classesName?.fieldset_input], classesSx?.[classesName?.sx], classesSx?.[classesName?.inputValid]);
  const ref_item_container = useRef(null);
  const ref_input_action_color = useRef(styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.currentColor]));

  let checkValue = checkParseObject(value || defaultValue);
  checkValue = Object?.keys(checkValue)?.length ? checkValue : value || defaultValue || "";

  const [initialState, setInitialState] = useState({
    currentLangSelected:currentLang || defaultLang,
    optionLang: optionLang || {},
    openLangMenu: false,
    blnIsFull: false,
  });

  const handleFocus = (event) => {
    ref_item_container.current.style.color = styleFunction.checkColorReturnValue(color ? color :cssObject?.color?.[cssKeys?.primary]);
    ref_input_action_color.current = styleFunction.checkColorReturnValue(color ? color : cssObject?.color?.[cssKeys?.primary]);
    if (errorMessage) {
      ref_item_container.current.style.color = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.error]);
    }
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleBlur = (event) => {
    ref_item_container.current.style.color = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor]);
    ref_input_action_color.current = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor]);

    if (errorMessage) {
      ref_item_container.current.style.color = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.error]);
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = (event) => {
    ref_item_container.current.style.color = styleFunction.checkColorReturnValue(color ? color :cssObject?.color?.[cssKeys?.primary]);
    ref_input_action_color.current = styleFunction.checkColorReturnValue(color ? color : cssObject?.color?.[cssKeys?.primary]);
    if (errorMessage) {
      ref_item_container.current.style.color = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.error]);
    }
    ref_item_container.current.style.boxShadow = cssObject?.boxShadow?.input_box_shadow;
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {}, [
    id,
    label,
    placeholder,
    errorMessage,
    helperMessage,
    icon,
    adornment,
    endIcon,
    endAdornment,
    mode,
    color,
    iconColor,
    iconSize,
    type,
    inputDir,
    endIconRest,
    endComponent, // to use in other inputs
    onFocus,
    onBlur,
    onChange,
    optionLang,
    setCurrentLang, // FORM API
    sx,
    hidden,
    disabled,
    dir,
    className,
    MenuList,
    rest,
  ]);

  useEffect(() => {
    if (errorMessage) {
      ref_item_container.current.style.color = styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.error]);
    } else {
      ref_item_container.current.style.color = ref_input_action_color?.current;
    }
  }, [errorMessage, ref_input_action_color]);

  useEffect(() => {
    if (value || defaultValue) {
      inputRef.current.value = Array.isArray(checkValue)
        ? checkValue?.map((item) => (typeof item === "object" ? item?.[currentLang] || item?.[defaultLang] : item))
        : typeof checkValue?.[currentLang] !== "undefined"
        ? checkValue?.[currentLang]
        : String(checkValue);
      if (!inputRef.current.value && !optionLang?.[initialState?.currentLangSelected].blnIsMandatory && optionLang?.[initialState?.currentLangSelected].blnIsActive) {
        checkValue?.[defaultLang];
      }
    } else {
      inputRef.current.value = "";
    }
  }, [value, defaultValue, currentLang, defaultLang, ref]);

  useEffect(() => {
    if(currentLang !== initialState?.currentLangSelected) {
      initialState.currentLangSelected = currentLang;
      setInitialState({...initialState})
    }
  },[currentLang]);


  function openList() {
    initialState.openLangMenu = !initialState.openLangMenu;
    setInitialState({ ...initialState });
  }

  function closeList() {
    initialState.openLangMenu = false;
    setInitialState({ ...initialState });
  }

  const handleChangeLang = (lang) => ()=>{
    initialState.currentLangSelected = lang;
    initialState.openLangMenu = false;
    inputRef.current.value = checkValue?.[lang] || checkValue?.[defaultLang];
    // if(ref && ref?.current){
    // ref.current.value =  inputRef.current.value
    // }
    setInitialState({ ...initialState });
    setCurrentLang(lang);
  };


  return (
    <div className={itemClassesContainer} ref={ref_item_container} key={comID + "_fieldset_container"}>
      <fieldset className={`${classes?.[classesName?.fieldset_container]} ${classesSx?.[classesName?.fieldset_container]}`} key={comID + "_fieldset"}>
        {icon || adornment ? (
          <div className={`${classes?.[classesName?.fieldset_icon]} ${classesSx?.[classesName?.fieldset_icon]} ${classesSx?.[classesName?.fieldset_front_icon]}`} id={`${comID}_icon`}>
            {icon ? (
              typeof icon !== "string" ? (
                icon
              ) : (
                <Icon icon={icon} size={iconSize} color={errorMessage ? "error" : iconColor ? styleFunction.checkColorReturnValue(iconColor) : ""} />
              )
            ) : adornment ? (
              <label>{adornment}</label>
            ) : null}
          </div>
        ) : null}
        {type === "textarea" ? (
          <textarea
            id={`${comID}`}
            name={`${comID}`}
            aria-label={`label-${comID}`}
            className={itemClasses}
            ref={ref || inputRef}
            placeholder={placeholder || " "}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            {...styleFunction?.getRestProps(rest)}
            rows={5}
            key={comID + "_textArea"}
          />
        ) : (
          <input
            type={type}
            id={`${comID}`}
            name={`${comID}`}
            aria-label={`label-${comID}`}
            className={itemClasses}
            ref={ref || inputRef}
            placeholder={placeholder || " "}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            {...styleFunction?.getRestProps(rest)}
            key={comID + "_inputField"}
          />
        )}
        {!label ? null : (
          <legend className={`${classes?.[classesName?.fieldset_label]} ${classesSx?.[classesName?.fieldset_label]}`} id={`label-${comID}`}>
            <label className={classesName?.fieldset_label_text}>{label}</label>
          </legend>
        )}
        {optionLang && Object.keys(optionLang)?.length ? (
          <div className={`${classes?.[classesName?.fieldset_icon]} ${classesSx?.[classesName?.fieldset_icon]}`}>
            <Button
              mode="link"
              size="sm"
              label={!initialState.currentLangSelected ? null : initialState.optionLang?.[initialState.currentLangSelected].label}
              icon={initialState.currentLangSelected ? "language" : !initialState.blnIsFull ? "arrowDropDownCircleOutlined" : "checkCircleOutlined"}
              color={
                optionLang?.[initialState.currentLangSelected]?.blnIsMandatory &&
                (optionLang?.[initialState.currentLangSelected]?.blnActive || optionLang?.[initialState.currentLangSelected]?.blnIsActive) &&
                !(ref || inputRef)?.current?.value
                  ? "error"
                  : "primary"
              }
              onClick={openList}
              m-0
              p-0
            />
            <Menu open={initialState.openLangMenu} eventClose={closeList} className={`${classes?.[classesName?.menuLang]}`} blnFullWidth={false}>
              {Object.keys(initialState.optionLang)
                .filter((item) => optionLang[item]?.blnActive || optionLang[item]?.blnIsActive)
                .map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      selected={initialState.currentLangSelected === item}
                      p-1
                      headItem={
                        <Icon
                          icon={checkValue?.[item] ? "check" : initialState.optionLang[item]?.blnIsMandatory === false ? "NotInterested" : "close"}
                          color={
                            checkValue?.[item]
                              ? styleFunction.checkColorReturnValue("success")
                              : initialState.optionLang[item]?.blnIsMandatory === false
                              ? ""
                              : styleFunction.checkColorReturnValue("error")
                          }
                        />
                      }
                      onClick={handleChangeLang(item)}
                    >
                      <Typography as="body2">{initialState.optionLang[item]?.label || label?.[currentLang] || String(label)}</Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
        ) : endIcon || endAdornment ? (
          <div
            className={`${classes?.[classesName?.fieldset_icon]} ${classesSx?.[classesName?.fieldset_icon]} ${classesSx?.[classesName?.fieldset_end_icon]}`}
            {...endIconRest}
            key={comID + "_endIcon"}
            onClick={handleFocus}
          >
            {endIcon ? (
              typeof endIcon !== "string" ? (
                endIcon
              ) : (
                <Icon icon={endIcon} size={iconSize} color={errorMessage ? "error" : iconColor ? styleFunction.checkColorReturnValue(iconColor) : ""} />
              )
            ) : endAdornment ? (
              <label>{endAdornment}</label>
            ) : null}
          </div>
        ) : null}
      </fieldset>
      {!MenuList ? null : <MenuList comID={comID} classes={classesSx} classesName={classesName} currentLang={currentLang} />}
      <ErrorMessage message={errorMessage || helperMessage || null} key={comID + "_error"} />
      <span className={`${classes?.[classesName?.menuLang]} ${classes?.[classesName?.menuOptList]} ${classesSx?.[classesName?.menuOptList]} ${classesSx?.[classesName?.menuList]} ${classesSx?.[classesName?.menuLang]}`} hidden style={{ display: "none" }} /> {/* for style mnie list*/}
    </div>
  );
});

export default GroupField;
