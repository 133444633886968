import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [`${styleVariables?.prefixDefaultClassName}_grid_container`]: {
        width:cssObject?.size?.[cssKeys?.full],
        flexWrap: "wrap",
        justifyContent: "flex-start",
        margin: "0 auto",
        padding: 0,
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position:cssObject?.position?.[cssKeys?.relative],
        display: cssObject?.display?.[cssKeys?.flex],
        alignContent:"flex-start"
      },
      [`${styleVariables?.prefixDefaultClassName}_grid_item`]: {
        margin: 0,
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignSelf: "stretch",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position:cssObject?.position?.[cssKeys?.relative],
        display: cssObject?.display?.[cssKeys?.flex],
      },
    }
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [`${styleVariables?.prefixDefaultClassName}_grid`]: (props) => {
        let objsx = {};
        if (props?.item) {
          let objColStyle = { ...getGridCol(null) };
          if (props?.xs) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.xs),
              xs: {
                ...getGridCol(props?.xs),
              },
            };
          }
          if (props?.sm) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.sm),
              sm: {
                ...getGridCol(props?.sm),
              },
            };
          }
          if (props?.md) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.md),
              md: {
                ...getGridCol(props?.md),
              },
            };
          }
          if (props?.lg) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.lg),
              lg: {
                ...getGridCol(props?.lg),
              },
            };
          }
          if (props?.xl) {
            objColStyle = {
              ...objColStyle,
              xl: {
                ...getGridCol(props?.xl),
              },
            };
          }
          objsx = { ...objsx, ...objColStyle };
        }
        if (props?.container) {
          const spacing = isNaN(parseInt(props?.spacing)) ? 1 : parseInt(props?.spacing);
          objsx = {
            ...objsx,
            "&>": {
              [`.${styleVariables?.prefixDefaultClassName}_grid_item`]: {
                padding: `${currentBaseSize * spacing}rem`,
              },
            },
          };
        }
        if (props?.alignItems) {
          if(typeof props?.alignItems === "object"){
            objsx.xl={...objsx.xl, alignItems: props?.alignItems?.xl};
            objsx.lg={...objsx.lg, alignItems: props?.alignItems?.lg};
            objsx.md={...objsx.md, alignItems: props?.alignItems?.md};
            objsx.sm={...objsx.sm, alignItems: props?.alignItems?.sm};
            objsx.xs={...objsx.xs, alignItems: props?.alignItems?.xs};
          }else{
            objsx = { ...objsx, alignItems: props?.alignItems };
          }
        }
        if (props?.alignSelf) {
          if(typeof props?.alignSelf === "object"){
            objsx.xl={...objsx.xl, alignSelf: props?.alignSelf?.xl};
            objsx.lg={...objsx.lg, alignSelf: props?.alignSelf?.lg};
            objsx.md={...objsx.md, alignSelf: props?.alignSelf?.md};
            objsx.sm={...objsx.sm, alignSelf: props?.alignSelf?.sm};
            objsx.xs={...objsx.xs, alignSelf: props?.alignSelf?.xs};
          }else{
            objsx = { ...objsx, alignSelf: props?.alignSelf };
          }
        }
        if (props?.justifyContent) {
          if(typeof props?.justifyContent === "object"){
            objsx.xl={...objsx.xl, justifyContent: props?.justifyContent?.xl, textAlign: props?.justifyContent?.xl === "center" ? "center" : props?.justifyContent?.xl === "flex-end" ? "end" : "start",};
            objsx.lg={...objsx.lg, justifyContent: props?.justifyContent?.lg, textAlign: props?.justifyContent?.lg === "center" ? "center" : props?.justifyContent?.lg === "flex-end" ? "end" : "start",};
            objsx.md={...objsx.md, justifyContent: props?.justifyContent?.md, textAlign: props?.justifyContent?.md === "center" ? "center" : props?.justifyContent?.md === "flex-end" ? "end" : "start",};
            objsx.sm={...objsx.sm, justifyContent: props?.justifyContent?.sm, textAlign: props?.justifyContent?.sm === "center" ? "center" : props?.justifyContent?.sm === "flex-end" ? "end" : "start",};
            objsx.xs={...objsx.xs, justifyContent: props?.justifyContent?.xs, textAlign: props?.justifyContent?.xs === "center" ? "center" : props?.justifyContent?.xs === "flex-end" ? "end" : "start",};
          }else{
            objsx = { ...objsx, justifyContent: props?.justifyContent, textAlign: props?.justifyContent === "center" ? "center" : props?.justifyContent === "flex-end" ? "end" : "start", };
          }
        }
        if (props?.direction) {
          if(typeof props?.direction === "object"){
            objsx.xl={...objsx.xl, flexDirection: props?.direction?.xl};
            objsx.lg={...objsx.lg, flexDirection: props?.direction?.lg};
            objsx.md={...objsx.md, flexDirection: props?.direction?.md};
            objsx.sm={...objsx.sm, flexDirection: props?.direction?.sm};
            objsx.xs={...objsx.xs, flexDirection: props?.direction?.xs};
          }else{
            objsx = { ...objsx, flexDirection: props?.direction };
          }
        }
        objsx = {
          fontSize:cssObject?.text?.size?.body1,
          color: cssObject?.color?.[cssKeys?.currentColor],
          ...objsx,
        };
  
        return objsx;
      },
      [`${styleVariables?.prefixDefaultClassName}_grid_sx`]: (props) => {
        return {
          ...props?.sx
        };
      },
    },
  });

  const getGridCol = (col) => {
    const gridCol = parseInt(col);
    switch (gridCol) {
      case 0:
        return {
          flexGrow: 0,
          width: "0",
          flexBasis: 0,
          display: "none",
        };
      case 1:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["1/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["1/12"]],
          display: "flex",

        };
      case 2:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["2/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["2/12"]],
          display: "flex",
        };
      case 3:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["3/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["3/12"]],
          display: "flex",
        };
      case 4:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["4/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["4/12"]],
          display: "flex",
        };
      case 5:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["5/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["5/12"]],
          display: "flex",
        };
      case 6:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["6/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["6/12"]],
          display: "flex",
        };
      case 7:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["7/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["7/12"]],
          display: "flex",
        };
      case 8:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["8/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["8/12"]],
          display: "flex",
        };
      case 9:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["9/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["9/12"]],
          display: "flex",
        };
      case 10:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["10/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["10/12"]],
          display: "flex",
        };
      case 11:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["11/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["11/12"]],
          display: "flex",
        };
      case 12:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.full],
          flexBasis: cssObject?.size?.[cssKeys?.full],

          display: "flex",
        };
      default:
        return {
          flexGrow: 0,
          width: "auto",
          display: "flex",
          flexBasis: "auto",
        };
    }
  }

  const getGridClassName = ({container, item, classes, classesSx}) => {
    const isContainer = container ? `${classes?.[`${styleVariables?.prefixDefaultClassName}_grid_container`]}` : "";
    const isItem = item ? `${classes?.[`${styleVariables?.prefixDefaultClassName}_grid_item`]}` : "";
    return `${isContainer} ${isItem} ${classesSx?.[`${styleVariables?.prefixDefaultClassName}_grid`]} ${classesSx?.[`${styleVariables?.prefixDefaultClassName}_grid_sx`]}`;
  };

  

  return{
    getGridClassName,
    style,
    styleSx,
  }
}