export default{
    text: "text",
    font:"font",
    break:"break",
   
    border:"border",
    rounded:"rounded",

    width:"w",
    height:"h",
    max:"max",
    min:"min",
}