import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    breadcrumbs: `${styleVariables?.prefixDefaultClassName}_breadcrumbs`,
    breadcrumbs_item: `${styleVariables?.prefixDefaultClassName}_breadcrumbs_item`,
    breadcrumbs_item_hover: `${styleVariables?.prefixDefaultClassName}_breadcrumbs_item_hover`,
    breadcrumbs_item_last: `${styleVariables?.prefixDefaultClassName}_breadcrumbs_item_last`,
    breadcrumbs_separator: `${styleVariables?.prefixDefaultClassName}_breadcrumbs_separator`,

    sx:`${styleVariables?.prefixDefaultClassName}_breadcrumbs_sx`
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.breadcrumbs]: {
        display: cssObject?.display?.[cssKeys?.flex],
        margin: `0 auto`,
        padding: `0`,
        whiteSpace:cssObject?.whiteSpace?.[cssKeys?.normal],
        textAlign: cssObject?.side?.[cssKeys?.start],
        listStyle:"none",
        color: cssObject?.color?.[cssKeys?.bodyColor],
        width: "98%",
        justifyContent: "flex-start",
        overflowX: "auto",
        alignItems: "center",
        position: "relative",
        fontSize: cssObject?.text?.size?.[cssKeys?.body3],
      },
      [classesName?.breadcrumbs_item]: {
        display: cssObject?.display?.[cssKeys?.inlineFlex],
        margin: `0`,
        padding: `1px ${cssObject?.spacing?.[cssKeys?.xs]}`,
        textAlign: cssObject?.side?.[cssKeys?.start],
        listStyle:"none",
        position: "relative",
        color: cssObject?.color?.[cssKeys?.currentColor],
        // width: "100%",
        justifyContent: "flex-start",
        overflowX: "auto",
        alignItems: "center",
        "&last-of-type":{
          [`.${classesName?.breadcrumbs_separator}`]:{
            display: "none"
          }
        }
      },
      [classesName?.breadcrumbs_item_hover]: {
        "&hover":{
          borderBlockEnd:"1px solid",
          borderColor: cssObject?.color?.[cssKeys?.currentColor],
          cursor: "pointer"

        }
      },
      [classesName?.breadcrumbs_item_last]: {
        color: styleFunction.checkColorReturnValue("primary"),
        borderBlockEnd:"1px solid",
          borderColor: cssObject?.color?.[cssKeys?.currentColor],
      },
      [classesName?.breadcrumbs_separator]: {
        display: cssObject?.display?.[cssKeys?.inlineBlock],
        margin:"0 auto",
        padding: `0 ${cssObject?.spacing?.[cssKeys?.xs]}`,
        textAlign: cssObject?.side?.[cssKeys?.center],
        position: "relative",
        color: cssObject?.color?.[cssKeys?.currentColor],
        width: "100%",
        justifyContent: "flex-start",
        overflowX: "auto",
        alignItems: "center",
        opacity: ".8",

      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
        [classesName?.sx]: (props) => {
          return {
            ...props?.sx,
            color: styleFunction.checkColorReturnValue(props?.color || props?.sx?.color || "var(--cbmis-body-color-dark)"),
    
          };
        },
  }
 });

  return{
    classesName,
    style,
    styleSx,
  }
}