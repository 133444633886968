import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    card_container: `${styleVariables?.prefixDefaultClassName}_card_container`,
    card: `${styleVariables?.prefixDefaultClassName}_card`,
    card_have_back: `${styleVariables?.prefixDefaultClassName}_card_have_back`,
    card_image: `${styleVariables?.prefixDefaultClassName}_card_image`,
    card_frame: `${styleVariables?.prefixDefaultClassName}_card_frame`,
    card_image_hover: `${styleVariables?.prefixDefaultClassName}_card_image_hover`,
    card_frame_bg: `${styleVariables?.prefixDefaultClassName}_card_frame_bg`,
    card_frame_content: `${styleVariables?.prefixDefaultClassName}_card_frame_content`,
    card_front: `${styleVariables?.prefixDefaultClassName}_card_front`,
    card_back: `${styleVariables?.prefixDefaultClassName}_card_back`,
    card_content: `${styleVariables?.prefixDefaultClassName}_card_content`,
    card_details: `${styleVariables?.prefixDefaultClassName}_card_details`,

    sx: `${styleVariables?.prefixDefaultClassName}_card_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.card_container]: {
        display: "flex",
        boxOrient: "horizontal",
        WebkitBoxDirection :"normal",
        boxPack: "center",
        WebkitBoxPack: "center",
        MozBoxPack: "center",
        justifyContent: "center",
        width:"100%",
        height:"100%",
        margin: "auto",
        padding: 0,
        position: "relative",
      },
      [classesName?.card]:{
        width: "100%",
        borderRadius: "5px",
        position: "relative",
        transition: "transform 0.5s",
        transformStyle: "preserve-3d",
        margin: "auto",
        padding: 0,
        maxHeight: "500vh",
        display: "block",
        [`.${classesName?.card_front}`]:{
          position:"relative",
          width: "100%",
          top: 0,
          left: 0,
          backfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden",
          transition: "all 0.5s",
          zIndex: 3,
          transform: "rotateY( 0deg )",
          boxShadow: cssObject?.boxShadow?.[cssKeys?.sm]
        },
        [`.${classesName?.card_back}`]:{
          position:"absolute",
          width: "100%",
          top: 0,
          left: 0,
          backfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden",
          transition: "all 0.5s",
          zIndex:2,
          transform: "rotateY( 180deg )",
          boxShadow: cssObject?.boxShadow?.[cssKeys?.sm]

        },
        [`.${classesName?.card_content}`]:{
            position: "relative",
            top: "0px",
            width: "auto",
            margin: "auto",
            // boxShadow: "var(--cbmis-box-shadow-1)" ,
            borderRadius: "5px",
            padding: "0.25rem",
            background: "#fff",
            display: "block",
        },
        [`.${classesName?.card_details}`] :{
          position: "relative",
          background: "#fff",
          padding: "0.5rem",
          display:"block",
          zIndex:1,
        }
      },
      [classesName?.card_have_back]:{
        "&hover":{
          [`.${classesName?.card}`]:{
            cursor: "pointer",
          },
          [`.${classesName?.card_front}`]:{
            transform: "rotateY(180deg)",
          },
          [`.${classesName?.card_back}`]:{
            transform: "rotateY(0deg)",
          }
        }
      },
      [classesName?.card_image]:{
        width: "100%",
        height: "175px",
        maxHeight: "calc(45.1977vw)",
        display: "block;",
        transformStyle: "preserve-3d",
        border: "0px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        position: "relative",
        color: "#fff",
        padding: "0PX",
        zIndex:1,
      },
      [classesName?.card_frame]:{
        opacity: "0",
        width: "0%",
        height: "0%",
        display: "block",
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "var( --cbmis-z-index-10)",
        transition: "height .12s",
      },
      [classesName?.card_image_hover]:{
        "&hover":{
          [`.${classesName?.card_frame}`]:{
            opacity: 1,
            height: "100%",
            width: "100%",
          }
        }
      },
      [classesName?.card_frame_bg]: {
        width: "100%",
        height: "100%",
        display: "block",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        background: "#000",
        backdropFilter: "blur(50px)",
        opacity: ".88",
        zIndex: "0",
      },
      [classesName?.card_frame_content]:{
        width: "100%",
        height: "100%",
        display: "block",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        opacity: "1",
        zIndex: "var( --cbmis-z-index-1)",
      },
    },
  });

  const styleCardSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
     objStyles:{
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  const styleCardFrontSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles:{
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  const styleCardBackSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
     objStyles:{
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  const styleCardImageSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles:{
      [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });

  const styleCardDetailsSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles:{
    [classesName?.sx]: (props) => {
        return props?.sx;
      },
    },
  });


  return{
    classesName,
    style,
    styleCardSx,
    styleCardFrontSx,
    styleCardBackSx,
    styleCardImageSx,
    styleCardDetailsSx
  }
}