"use client";
import { SecureRoute } from "@cbmisorg/router";
import * as appVariables from "../appVariables";
import * as appFunctions from "../appFunctions";
import { useContext } from "react";
import { AppContext } from "../../configuration/contextapi/AppContext";
import objAppRouting from "./appRouting";
import appRouting from "../../../client/configuration/routing/appRouting";

function useGetUserAuth() {
  const { appState } = useContext(AppContext);


  const getUserAuth = () => {
    let appLocalStorage = appFunctions?.getAppLocalStorage();

    let userAuth = { userRole: null, userFeature: null };

    if (!appLocalStorage?.userInfo) {
      userAuth.userRole = appState?.userInfo?.bigUserRoleID;
      userAuth.userFeature = appState?.userInfo?.lstUserFeature;
      return userAuth;
    }

    if (appLocalStorage?.clientInfo?.blnIsUserLoggedIn && appLocalStorage?.clientInfo?.dtmLastLoggingIn) {
      let intLoginBefore = appFunctions.getDifferenceBetweenTwoDate(appLocalStorage?.clientInfo?.dtmLastLoggingIn, new Date(), "day");
      if (intLoginBefore >= 6.9) {
        if (appVariables.App_IsInTestingMode) {
          alert(intLoginBefore);
        }
        userAuth = { userRole: null, userFeature: null };
        appFunctions?.clearAppLocalStorage(appLocalStorage);
      }
    }

    userAuth.userRole = appLocalStorage?.userInfo?.bigUserRoleID;
    userAuth.userFeature = appLocalStorage?.userInfo?.lstUserFeature;
    return userAuth;
  };

  return getUserAuth();
}

export default SecureRoute.SecureRoute({...objAppRouting,...appRouting}, { userAuth: useGetUserAuth }, appRouting.landingPage.url, {
  strRoleType: "defaultRole",
  blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
  strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
  blnIsTestingMode: appVariables.App_IsInTestingMode,
});
