import React, { useContext } from 'react'
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import GridStyle from './GridStyle';
import ErrorBoundary from '../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary';

const Grid = React?.forwardRef(function Grid(/**@type {any}*/props, ref) {
  const theTheme = useContext(ContextThemeProvider);
  const {getGridClassName, style, styleSx} = GridStyle(theTheme);

  const {
    container,
    item,
    xs,
    sm,
    md,
    lg,
    xl,
    spacing,
    alignItems,
    alignSelf,
    justifyContent,
    direction,
    sx,
    hidden,
    disabled,
    dir,
    className,
    ...rest
  } = props;

  const classes = style()
  const classesSx = styleSx({ sx, container, item, xs, sm, md, lg, xl, spacing, alignItems, alignSelf, justifyContent, direction });

  const itemClasses = styleFunction.getClassName(getGridClassName({container, item, classes, classesSx}), styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return(
      <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
        <ErrorBoundary>{props?.children}</ErrorBoundary>
      </div>
  )
});

export default Grid