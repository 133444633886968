export const keysShadow={
    shadow:"shadow",
    xl2:"2xl",
    xl:"xl",
    lg:"lg",
    md:"md",
    sm:"sm",
    xs:"xs",
    inner:"inner",
    outline:"outline",
    none:"none"
}
export default{
    [keysShadow?.shadow]:"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    [keysShadow?.inner]:"inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    [keysShadow?.outline]:"0 0 0 3px rgba(0, 0, 0, 0.5)",
    [keysShadow?.none]:"none",
    [keysShadow?.xl2]:"0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    [keysShadow?.xl]:"0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    [keysShadow?.lg]:"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    [keysShadow?.md]:"0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    [keysShadow?.sm]:"0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    [keysShadow?.xs]:"0 0 0 1px rgba(0, 0, 0, 0.05)",
}