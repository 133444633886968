export const keysBorder={
    border:"border",
    divide:"divide",
    rounded:"rounded",
    borderSides:"borderSides"
}

export default{
    [keysBorder?.border]:{ //bg-value
        solid:"solid",
        dashed:"dashed",
        dotted:"dotted",
        none:"none",
    },
    [keysBorder?.divide]:{ // bg-v-value-h-value
    },
    [keysBorder?.rounded]:{ //bg-value
      xs:"0.25rem",
      sm:"0.5rem",
      md:".0.75rem",
      lg:"1rem",
      xl:"1.5rem",
      pill:"100%",
    },
    [keysBorder?.borderSides]:{
        X:"x",
        y:"y",
        t:"t",
        b:"b",
        r:"r",
        l:"l",
        xs:"xs",
        ys:"ys",
        xe:"xe",
        ye:"ye",
        tr:"tr",
        tl:"tl",
        br:"br",
        bl:"bl",
    }
}