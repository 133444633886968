import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ModalStyle from "./ModalStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import ModalContainer from "./ModalContainer";

const Modal = forwardRef((/**@type {any}*/props, ref) => {
  const { open } = props;
  
  return open ? (
    <ModalContainer {...props}/>
  ) : null;
});

export default Modal;
