import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import SlideshowStyle from "./SlideshowStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { useId } from "react";

const Slide = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = SlideshowStyle(theTheme);
  const { image, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, image });
  const comID = id || String(useId()).slice(1, -1) + "slide";

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.slide],
    classesSx?.[classesName?.slide],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  return (
    <div ref={ref} id={comID} className={itemClasses} key={comID} {...styleFunction?.getRestProps(rest)}>
      <ErrorBoundary>
        <div className={`${classes?.[classesName?.slide_containt]}`}>{props.children}</div>
      </ErrorBoundary>
    </div>
  );
});

export default Slide;
