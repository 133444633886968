import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    loader_container: `${styleVariables?.prefixDefaultClassName}_loader_container`,
    loader: `${styleVariables?.prefixDefaultClassName}_loader`,
    sx: `${styleVariables?.prefixDefaultClassName}_loader_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.loader_container]: {
        height: "100vh",
        width: "100vw",
        maxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        margin: "auto",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "auto",
        zIndex: 10000,
        background: "#00000050",
      },
      [classesName?.loader]: {
        display: "inline-block",
        width: cssObject?.size?.[cssKeys?.item_sm],
        height: cssObject?.size?.[cssKeys?.item_sm],
        verticalAlign: "middle",
        pointerEvents: "none",
        margin: "auto",
        zIndex: 1,

        border: "0.25rem dotted currentcolor",
        borderBottomColor: "transparent",
        borderRadius: "50%",
        animation: "1s loader-01 linear infinite",

        "@keyframes loader-01": {
          from: {
            WebkitTransform: "rotate(0deg)",
            transform: "rotate(0deg)",
          },
          to: {
            WebkitTransform: "rotate(360deg)",
            transform: "rotate(360deg)",
          },
        },
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.loader]: (props) => {
        return {
          color: styleFunction?.checkColorReturnValue(props.color || cssObject?.color?.[cssKeys?.primary]),
        };
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  return {
    classesName,
    style,
    styleSx,
  };
};
