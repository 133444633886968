import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    chip: `${styleVariables?.prefixDefaultClassName}_chip`,
    chip_content: `${styleVariables?.prefixDefaultClassName}_chip_content`,
    shape_circle: `${styleVariables?.prefixDefaultClassName}_shape_circle`,
    sx:`${styleVariables?.prefixDefaultClassName}_chip_sx`
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.chip]: {
        display: "inline-flex",
        flexDirection: "row",
        border: "none",
        cursor: "default",
        outline: "none",
        padding: "0",
        whiteSpace: "nowrap",
        alignItems: "center",
        verticalAlign: "middle",
        textDecoration: "none",
        justifyContent: "center",
      },
      [classesName?.chip_contain]: {
        display: cssObject?.display?.flex,
        width: cssObject?.size?.full,
        height: cssObject?.size?.full,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        margin: cssObject.spacing?.auto,
        padding: cssObject.spacing?.auto,
        position: cssObject.position?.absolute,
        top: "0",
        left: "0",
        zIndex: "var(--cbmis-z-index-1)",
        color: cssObject?.color?.currentColor,
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.chip]: (props) => {
        return{
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.lg,
          margin:  cssObject.spacing?.[cssKeys?.xs],
          padding: `0 ${cssObject.spacing?.[cssKeys?.xs]}`,
          color:String(props?.mode).toLowerCase() === "outlined"?cssObject?.color?.[cssKeys?.bodyColor] : cssObject?.color?.[cssKeys?.light],
          border: String(props.mode).toLowerCase() !=="outlined"?"none": `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.currentColor])}`,
          width: "fitContent",
          backgroundColor:String(props?.mode).toLowerCase() === "outlined"?"transpose": styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.bodyColorBgDark]),
          height: cssObject.size?.[cssKeys?.[`item_${props?.size}`]] || cssObject.size?.[cssKeys?.item_sm],
          fontSize: "12px" || cssObject?.text.size?.[cssKeys?.[props?.size]] || cssObject?.text.size?.[cssKeys?.sm],
        
        }
      },
      [classesName.sx]: (props) => {
        return {...props?.sx}
      },
    },
 });


  return{
    classesName,
    style,
    styleSx,
  }
}