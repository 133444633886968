import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import CardStyle from "./CardStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const CardDetails = forwardRef((/**@type {any}*/props, ref) => {
  const { sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {
    classesName,
    // style, 
    styleCardDetailsSx} = CardStyle(theTheme);
  // const classes = style();
  const classesSx = styleCardDetailsSx({sx});
  const itemClasses = styleFunction.getClassName(classesName?.card_details, classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  const comID = id ||  String(useId()).slice(1,-1)

  return (
    <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)} key={comID}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </div>
  );
});

export default CardDetails;
