import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import BoxStyle from "./BoxStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Box = forwardRef((/**@type {any}*/props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = BoxStyle(theTheme);
  const { color=null, outline=0, elevation=0, hoverElevation=0, sx = null, hidden=false, disabled=false, dir=null, className=null, id=null, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx ,outline, elevation, hoverElevation, color});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.box], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);


  return (
      <div  className={itemClasses} {...styleFunction?.getRestProps(rest)} ref={ref}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
  );
});

export default Box;
