import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import TypographyStyle from "./TypographyStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const Typography = forwardRef((/**@type {any}*/props, ref) => {
  const { as, color, sx, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = TypographyStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({as, color, sx});

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.typography], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)} >
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </div>
  );
});

export default Typography;
