"use client";

import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject ? objThemeInfo?.cssObject : mainTheme?.cssObject;

  const classesName = {
    icon_container: `${styleVariables?.prefixDefaultClassName}_icon_container`,
    icon: `${styleVariables?.prefixDefaultClassName}_icon`,
    sx: `${styleVariables?.prefixDefaultClassName}_icon_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.icon_container]: {
        position: "relative",
        background: cssObject?.color?.[cssKeys?.transparent],
        backgroundColor: cssObject?.color?.[cssKeys?.transparent],
        margin: "auto",
        padding: 0,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        placeContent: "center",
        outline: "none",
        border: "none",
        height: "fit-content",
        width: "fit-content",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        // fontSize: cssObject?.text?.size?.body1,
        overflow: "hidden",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.icon_container]: (props) => {
        return {
          "&hover": props?.onClick
            ? {
                background: cssObject?.color?.[cssKeys?.bodyColorBgLight],
                cursor: "pointer",
              }
            : null,
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.currentColor]),
        };
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  const styleIconBody = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.icon]: {
        fill: cssObject?.color?.[cssKeys?.currentColor],
        // width:"100%",
        // height:"100%"
      },
    },
  });

  const styleSxIconBody = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.icon]: (props) => {
        if (props?.size) {
          return {
            width: props?.size,
            height: props?.size,
          };
        }
        if (props?.fontSize) {
          return { ...props?.fontSize };
        }
        return {
          width: cssObject?.text?.[cssKeys?.size]?.[cssKeys?.body1],
          height: cssObject?.text?.[cssKeys?.size]?.[cssKeys?.body1],
        };
      },
    },
  });
  return {
    classesName,
    style,
    styleSx,
    styleIconBody,
    styleSxIconBody,
  };
};

// if (typeof window !== "undefined") {
//   icon_container.classList.add(classesSx?.[classesName?.sx]);
// }
