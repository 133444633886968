import { ContextThemeProvider, styleFunction } from '@cbmisorg/styles';
import React, { useEffect, useRef } from 'react'
import { useContext } from 'react';
import { forwardRef } from 'react';
import { useId } from 'react';
import RangeSliderStyle from './RangeSliderStyle';

const RangeSlider = forwardRef((/**@type {any}*/props, ref) => {
  const {id,label,errorMessage,helperMessage,
    color,currentLang,readOnly,
    value,defaultValue, showMaxMin=true, showValue=true,
    min=0,max=100, step=1, thumb=true, gauge="%",
    sx,hidden,disabled,dir,className,...rest} = props
    const comID = String(useId()).slice(1,-1) + "checkbox" || id;
    const theTheme = useContext(ContextThemeProvider);
    const {classesName, style, styleSx} = RangeSliderStyle(theTheme);
    const classes = style();
    const classesSx = styleSx({sx, color,thumb, showMaxMin, showValue, readOnly});
    const itemClasses = styleFunction.getClassName(classes?.[classesName?.range_slider],classesSx?.[classesName?.range_slider], classesSx?.[classesName?.sx], className);
    const containerClasses = styleFunction.getClassName(classes?.[classesName?.range_container], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest));

    const inputRef = useRef({})

    useEffect(()=>{
      // inputRef.current.value = value || defaultValue || 0
      // if(ref){
      //   ref.current.value = value || defaultValue || 0
      // }
      // setInputValue({
      //   ...inputValue,
      //   value:  ref?.current?.value || inputRef.current.value || 0,
      // })

  },[value, defaultValue])

  return (
    <div className={containerClasses}>
     {
      // !showValue?null:(
      //   <div className={`${classes?.[classesName?.range_lider_tooltip]} ${classesSx?.[classesName?.range_lider_tooltip]}`}>
      //   <span className={classes?.[classesName?.range_lider_tooltip_text]}></span>
      // </div>
      // )
     }
      {
        !showValue?null:(
          <output htmlFor={comID} className={`${classes?.[classesName?.range_slider_value_output]} ${classesSx?.[classesName?.range_slider_value_output]}`}>
          {parseInt(value || defaultValue)}{gauge}
          </output>
        )
      }
      <label className={classes?.[classesName?.range_label]}>{label}</label>
      <input className={itemClasses} 
         type="range"
          min={min} 
          max={max} 
          step={step}
          defaultValue={value || defaultValue || min}
          disabled={!thumb}
          readOnly={readOnly}
          id={comID}
          {...styleFunction?.getRestProps({...rest})} key={comID+"_input"}
          />
          {
            // !showValue?null:(
            //   <output htmlFor={nameID.current} className={`cbmis-range-slider-value-output ${Number(inputRef.current.value) === Number(max)?"cbmis-range-slider-value-output-light":""} ${!label?.default && ! label? "outputRangeNoHaveLabel":""}`}>
            //     {ref?.current?.value || inputRef?.current?.value}{gauge}
            //   </output>
            // )
          }
          {!showMaxMin ? null:(
            <div className={classes?.[classesName?.range_slider_min_max]}>
              {min || min ===0  ? <span className="cbmis-range-slider-min-max-label">{min}</span> : ""}
              {max ? <span className="cbmis-range-slider-min-max-label">{max}</span> : ""}
            </div>
          )}
    </div>
  )
});

export default RangeSlider