import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Typography, Container, Paper, useNavigate, Loader, Modal, ModalHeader, ModalBody } from "@cbmisorg/client-app";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { CtrlProfile } from "./controler/CtrlProfile";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { App_Primary_Color, App_orang_color } from "../../../../appHelper/appColor";
import { FormField, FormData } from "../../../../appHelper/formApi/FormAPI";
import { objUserRole } from "../../../../appHelper/appVariables";

function RouteProfile() {
  useCheckSystemInfo();
  useCheckCat();
  const navigate = useNavigate();
  const { appState, appDispatch } = useContext(AppContext);
  const appLanguage = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.manageMembers;
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    blnIsInitDone: false,
    modalPasswordOpen: false,
    modalEmailOpen: false,
    modalPhoneOpen: false,
    listLogo: [],
  });

  const fields = FormData({
    strFullName: { strControl: "text", objValidations: { required: true }, objLanguage: false },
    bigMajorID: { strControl: "list", lstOption: [] },
    bigDegreeID: { strControl: "list", lstOption: [] },
    bigGender: { strControl: "list", lstOption: [] },
    bigEmploymentID: { strControl: "list", lstOption: [] },
    bigCountryOfCitizenID: { strControl: "list", lstOption: [] },
    bigCountryOfResidenceID: { strControl: "list", lstOption: [] },
    strUniversityName: { strControl: "list", lstOption: [] },
    bigAcademicYearID: { strControl: "list", lstOption: [] },
    bigGpaID: { strControl: "list", lstOption: [] },

    strWorkGovPlace: { strControl: "text", objLanguage: false },
    strPositionTitle: { strControl: "text", objLanguage: false },
  });

  const fieldPassword = FormData({
    strOldUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 } },
    },
    strRestPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 } },
    },
    strConfirmPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 6 }, equal: { condition: "strRestPassword" } },
    },
  });

  const fieldEmail = FormData({
    strRestEmail: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, email: true },
    },
  });

  const fieldPhone = FormData({
    strUserPhone: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } },
    },
  });

  const handlers = CtrlProfile(appState, appDispatch);
  const initData = handlers?.initData({ isLoading, setIsLoading, state, setState, fields, fieldEmail, fieldPhone });
  const onChangeCountry = handlers?.onChangeCountry({ fields });
  const handelSubmit = handlers?.handelSubmit({ fields, isLoading, setIsLoading, navigate });
  const handleRestPassword = handlers?.funRestPassword({ isLoading, setIsLoading, state, setState, fields: fieldPassword });
  const handleRestEmail = handlers?.funRestEmail({ isLoading, setIsLoading, state, setState, fields: fieldEmail });
  const funRestPhone = handlers?.funRestPhone({ isLoading, setIsLoading, state, setState, fields: fieldPhone });
  const funCloseModal = handlers?.funCloseModal({ state, setState });
  const getPhoneGlobalKey = handlers?.getPhoneGlobalKey;

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.profile?.[appLanguage]} lang={appLanguage} />
      <Container py-5 pb-xs-10 mb-xs-10 pb-sm-10 mb-sm-10>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} pb-xs-10 mb-xs-10 spacing={2}>
          <Grid item xs="12" md="10">
            <Paper px-0 py-2 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormField objHandler={fields.strFullName} icon="person" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                </Grid>
                {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Admin ||
                appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Owner ||
                (appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Sponsor && appState?.userInfo?.blnIsSponsor) ? null : (
                  <React.Fragment>
                    {appState?.userInfo?.blnIsManager ? null : (
                      <React.Fragment>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField
                            objHandler={fields.bigCountryOfResidenceID}
                            onChange={onChangeCountry}
                            icon="LocationOn"
                            dir={appLanguage === "arb" ? "rtl" : "ltr"}
                          />
                        </Grid>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField objHandler={fields.bigGender} icon="Wc" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item xs={12} sm="6" md={appState?.userInfo?.blnIsManager ? "6" : "4"}>
                      <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                    {appState?.userInfo?.blnIsManager ? null : (
                      <React.Fragment>
                        <Grid item xs={12} sm="6" md={"4"}>
                          <FormField objHandler={fields.bigEmploymentID} icon="work" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField objHandler={fields.strUniversityName} icon="accountBalance" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item xs={12} sm="6" md={appState?.userInfo?.blnIsManager ? "6" : "4"}>
                      <FormField objHandler={fields.bigMajorID} icon="book" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                    {appState?.userInfo?.blnIsManager ? (
                      <React.Fragment>
                        <Grid item xs={12} sm="6">
                          <FormField objHandler={fields.strWorkGovPlace} icon="accountBalance" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                        <Grid item xs={12} sm="6">
                          <FormField objHandler={fields.strPositionTitle} icon="work" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField objHandler={fields.bigAcademicYearID} icon="AutoStories" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                        <Grid item xs={12} sm="6" md="4">
                          <FormField objHandler={fields.bigGpaID} icon="insertChartOutlined" dir={appLanguage === "arb" ? "rtl" : "ltr"} />
                        </Grid>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Grid>
              <Grid container justify={"center"}>
                <Grid item xs="12" pt-5 pb-8>
                  <Button py-0 px-10 label={dictionary?.shared?.btn?.save?.[appLanguage]} onClick={handelSubmit} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs="12" md="10">
            <Paper p-0 elevation={4} outline={1} sx={{ borderRadius: "8px !important", borderColor: "var(--cbmis-dark)" }} className="cardContainer" px-2 py-3>
              <Grid container xs="6" sm="auto" justify={"space-between"}>
                <Grid item xs="12" sm="9">
                  <Typography as="caption">{labels?.sensitiveInfo?.[appLanguage]}</Typography>
                </Grid>
                <Grid item container justify={"space-between"} spacing={2} m-xs-auto>
                  <Grid item>
                    <Button icon="email" size="sm" pt-0 mx-0 color={App_Primary_Color} onClick={() => setState({ ...state, modalEmailOpen: true })} />
                  </Grid>
                  <Grid item>
                    <Button icon="phoneOutlined" size="sm" pt-0 mx-0 color={App_orang_color} onClick={() => setState({ ...state, modalPhoneOpen: true })} />
                  </Grid>
                  <Grid item>
                    <Button icon="lock" size="sm" pt-0 mx-0 color={App_Primary_Color} onClick={() => setState({ ...state, modalPasswordOpen: true })} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={state?.modalPasswordOpen} eventClose={funCloseModal}>
        <ModalHeader>{labels?.resetPassword?.[appLanguage]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ alignSelf: "flex-start" }}>
              <FormField objHandler={fieldPassword.strOldUserPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md="6" sx={{ alignSelf: "flex-start" }}>
              <FormField objHandler={fieldPassword.strRestPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md="6" sx={{ alignSelf: "flex-start" }}>
              <FormField objHandler={fieldPassword.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={appLanguage === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12">
              <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[appLanguage]} mx-auto onClick={handleRestPassword} px-3 />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={state?.modalEmailOpen} eventClose={funCloseModal}>
        <ModalHeader>{labels?.resetEmail?.[appLanguage]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField objHandler={fieldEmail.strRestEmail} icon={"email"} className={`inputLeft inputLeft${appLanguage}`} />
            </Grid>
            <Grid item xs="12">
              <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[appLanguage]} mx-auto onClick={handleRestEmail} px-3 />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={state?.modalPhoneOpen} eventClose={funCloseModal}>
        <ModalHeader>{labels?.resetPhone?.[appLanguage]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                objHandler={fieldPhone.strUserPhone}
                icon={appLanguage === "arb" ? "phone" : null}
                endIcon={appLanguage !== "arb" ? "phone" : null}
                endAdornment={appLanguage === "arb" ? getPhoneGlobalKey(fields.bigCountryOfResidenceID?.getValue()?.key)?.[appLanguage] : null}
                adornment={appLanguage !== "arb" ? getPhoneGlobalKey(fields.bigCountryOfResidenceID?.getValue()?.key)?.[appLanguage] : null}
                className={`inputLeft`}
              />
            </Grid>
            <Grid item xs="12">
              <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[appLanguage]} mx-auto onClick={funRestPhone} px-3 />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RouteProfile;
