import { AppBar, ClientDirection, GroupBar, Nav, Typography } from "@cbmisorg/client-app";
import React, { useEffect } from "react";
import objAppRouting from "../../../appHelper/routing/appRouting";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { App_Primary_Color } from "../../../appHelper/appColor";
import {  getNextStrURL } from "../../../appHelper/appFunctions";
import logo from "../../../assets/images/logo.png";

function ShredHeader({ title, lang = "arb",blnDark=false, blnBottomBar = true, blnBack = true, blnHome = true, blnLogout= false,blnLogo=false, funLogout, blnLang , changLang, blnLangStart=false}) {
  
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  return (
    <AppBar dir="ltr" sx={blnDark ?{background:"var(--app-dark)"}:{}}>
      <GroupBar sxMode={"navbar"} ssMode={"navbar"} sx={{ color: App_Primary_Color }}>
        {
          !blnLogo? null:<img src={logo} alt="" height={"55px"} style={{ maxWidth: "98%" }} />
        }
        {blnBack ? <Nav icon="arrowBack" path={"-1"} /> : null}
        {blnLang  && !blnLangStart? <Nav icon="translate" onClick={changLang} />:null}
      </GroupBar>
      <GroupBar sxMode={"navbar"} ssMode={"navbar"}>
        <Typography as="h6" color="primary" className={blnDark?"mainTitle":null}>
          {title}
        </Typography>
      </GroupBar>
      <GroupBar sxMode={"navbar"} ssMode={"navbar"} sx={{ color: App_Primary_Color }}>
      {!blnHome ? null : <Nav icon="home" path={objAppRouting?.Public_LandingPage?.url} />}
      {!blnLogout ? null : <Nav icon="logout" onClick={funLogout} />}
      {blnLang && blnLangStart?  <Nav icon="translate" onClick={changLang} />:null}

      </GroupBar>

      {blnBottomBar ? (
        <GroupBar sxMode="none" dir="rtl">
          <Nav ssMode="bottombar" icon="accountCircle" label={<>{dictionary?.components?.public?.footer?.account?.[lang]}</>} path={getNextStrURL()} />
          <Nav ssMode="bottombar" icon="event" label={<>{dictionary?.components?.public?.landingPage?.home?.events?.title?.[lang]}</>} path={objAppRouting.Public_Events.url} />
          <Nav ssMode="bottombar" icon="home" label={<>{dictionary?.components?.public?.footer?.home?.[lang]}</>} path={objAppRouting.Public_LandingPage.url} />
          <Nav ssMode="bottombar" icon="people" label={<>{dictionary?.components?.public?.landingPage?.members?.title?.[lang]}</>} path={objAppRouting.Public_Team.url} />
          <Nav ssMode="bottombar" icon="settings" label={<>{dictionary?.components?.public?.footer?.settings?.[lang]}</>} path={objAppRouting.Public_Settings.url} />
        </GroupBar>
      ) : (
        <GroupBar sxMode="none" dir="rtl" />
      )}
    </AppBar>
  );
}

export default ShredHeader;
