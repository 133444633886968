import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ChipStyle from "./ChipStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import { Avatar } from "@cbmisorg/material";
import { Icon } from "@cbmisorg/icons";

const Chip = forwardRef((/**@type {any}*/props, ref) => {
  const { label, mode, size, color, avatar, endAvatar,icon, endIcon, sx, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx} = ChipStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, label, mode, size, color, avatar, endAvatar,icon, endIcon });

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.chip], classesSx?.[classesName?.chip], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)} >
        <ErrorBoundary>
        {avatar? (
          <Avatar alt={avatar.props.alt} src={avatar.props.src} color={avatar.props.color} shape={avatar.props.shape} sx={{ width: "30px", height: "30px" }}>
            {avatar?.props?.children}
          </Avatar>
        ) : icon?(
            <Icon icon={icon} m-1/>
        ):""
      }
        <div className={classes?.[classesName?.chip_content]}>{label || props?.children}</div>
        {endAvatar? (
          <Avatar
            alt={endAvatar.props.alt}
            src={endAvatar.props.src}
            color={endAvatar.props.color}
            shape={endAvatar.props.shape}
            sx={{ width: "30px", height: "30px" }}
            {...endAvatar.props}
          >
            {endAvatar?.props?.children}
          </Avatar>
        ) : endIcon?(<Icon icon={endIcon} m-1/>):""
        }
        </ErrorBoundary>
    </div>
  );
});

export default Chip;
