"use client";
import useCheckSystemInfo from "./useCheckSystemInfo";
import useCheckCat from "./useCheckCat";
// import { useCheckUserLoginDevice } from "./useCheckUserLoginDevice ";
import { useApp } from "../../configuration/contextapi/context";

export default function useCheckHooksGlb() {
  const { appState } = useApp(); 


  useCheckSystemInfo({ appState });
  useCheckCat({ appState });
  // useCheckUserLoginDevice({ appState });

  return null;
}
