import React, { useState, useEffect } from "react";

function StyledComponentsRegistry(props) {
  const { LoadingComponent = null } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call
    // setTimeout(() => {
    setIsLoading(false);
    // }, 5);
  }, []);

  if (isLoading) {
    return LoadingComponent ? <LoadingComponent /> : null;
  }
  return props.children;
}

export default StyledComponentsRegistry;
