import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import ModalStyle from "./ModalStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const ModalContainer = forwardRef((/**@type {any}*/props, ref) => {
  const { eventClose, size="md", sx, hidden, disabled, dir, className, id, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleModalSx} = ModalStyle(theTheme);
  const classes = style();
  const classesSx = styleModalSx({ sx, size });
  const idModal =id || useId().replace(/:/g, "");

  const itemClasses = styleFunction.getClassName(classes?.[classesName?.modal], classesSx?.[classesName?.modal], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <div id={id || idModal} className={classes?.[classesName?.modal_container]} tabIndex={-1} {...styleFunction?.getRestProps({...rest})}>
      <div className={classes?.[classesName?.modal_out]} onClick={eventClose}></div>
      <div className={itemClasses} ref={ref} >
        <div className={classes?.[classesName?.modal_details]}>
          <ErrorBoundary>
            {React.Children.map(props.children, child => React.cloneElement(child, { handleClose: eventClose })     )}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
});

export default ModalContainer;
