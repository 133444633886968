import React, { forwardRef, useContext, useEffect, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import { Icon } from "@cbmisorg/icons";
import AccordionStyle from "./AccordionStyle";

const AccordionSummary = forwardRef((/**@type {any}*/ props, ref) => {
  const {
    id,
    children,
    onChange,
    handleChangeInput,
    inputRef,
    expandIcon,
    tabID,
    open = false,
    blnOpenOne = false,
    arrowIcon = "expandMore",
    iconSize,
    sx,
    hidden,
    disabled,
    dir,
    className,
    ...rest
  } = props;
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSummarySx } = AccordionStyle(theTheme);
  const classes = style();
  const classesSx = styleSummarySx({ sx });
  const comID = tabID ? tabID + "_inputAccordion" : id || String(useId()).slice(1, -1) + "accordion";

  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.accordion_tab_summary_label],
    classesSx?.[classesName?.accordion_tab_summary_label],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const handleChangeInputSummary = (event) => {
    if (handleChangeInput) {
      handleChangeInput(event);
    }
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <React.Fragment key={comID}>
      <input
        type={blnOpenOne ? "radio" : "checkbox"}
        className={`${classes?.[classesName?.accordion_tab_summary_input]} ${!disabled ? "" : classes?.[classesName?.accordion_tab_summary_input_dontOpen]}`}
        name={comID}
        id={comID}
        defaultChecked={open}
        onChange={handleChangeInputSummary}
        ref={ref}
        {...styleFunction.getRestProps(rest)}
        key={comID + "_input"}
      />
      <label className={itemClasses} htmlFor={comID} ref={ref} key={comID + "_input__tab_summary"}>
        <label className={`${classes?.[classesName?.accordion_tab_summary_label_content]} ${classesSx?.[classesName?.accordion_tab_summary_label_content]}`} htmlFor={comID}>
          {expandIcon && (
            <label className={classes?.[classesName?.accordion_tab_summary_icon]} htmlFor={comID}>
              {expandIcon}
            </label>
          )}
          <label className={classes?.[classesName?.accordion_tab_summary_label_content_children]} htmlFor={comID}>
            {children}
          </label>
        </label>
        {!arrowIcon ? null : (
          <label
            className={`${classes?.[classesName?.accordion_tab_summary_icon]} ${classes?.[classesName?.accordion_tab_summary_icon_arrowIcon]} ${
              classesSx?.[classesName?.accordion_tab_summary_icon_arrowIcon]
            }`}
            htmlFor={comID}
          >
            <Icon icon={typeof arrowIcon === "string" ? arrowIcon : "expandMore"} />
          </label>
        )}
      </label>
    </React.Fragment>
  );
});

export default AccordionSummary;
