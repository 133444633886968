// @ts-nocheck
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Menu from '../../navigation/Menu/Menu';
import RadioButtons from '../RadioButtons/RadioButtons';
import GroupField from '../GroupField/GroupField.js';
import { checkParseObject } from '../../_helper/functions';


const List = forwardRef((/**@type {any}*/props, ref) => {
  const {children,defaultValue=null,value=null,options=[],autocomplete,endIcon,onFocus,onChange,...rest} = props
  const refRadioValue = useRef({})

  const [initialState, setInitialState] = useState({
    openMenu:false,
    filterValue :""
  })

  const handleFocus=(event)=>{
    initialState.openMenu= !initialState.openMenu;
    setInitialState({...initialState})
    if(onFocus){
      onFocus(event)
    }
  }
  const handleChange=(event)=>{
    refRadioValue.current={key:event?.target?.id,value:event?.target?.value}
    initialState.openMenu= false;
    initialState.filterValue="";
    setInitialState({...initialState})
    if(onChange){
      onChange(event)
    }
  }
  
  const eventCloseMenu = () => {
    if(initialState.openMenu){
      initialState.openMenu= false;
      initialState.filterValue="";
      setInitialState({...initialState})
    }
    
  }

  const MenuList =({comID,classes,classesName,currentLang})=> {return(
     <Menu open={initialState?.openMenu} id={comID+"_opt"} className={classes?.[classesName?.menuList]} eventClose={eventCloseMenu} blnFullWidth={true}>
        <div className={classes?.[classesName?.menuOptList]} key={`${comID}_ListMenuContainer`}>
            <RadioButtons
                options={options} 
                id={`${comID}_ListMenu`} 
                onChange={handleChange} 
                defaultValue={refRadioValue.current?.key || null}
                blnMenuInputList={true}
                currentLang={currentLang}
                key={`${comID}_ListMenu`}
              >
                {children}
            </RadioButtons>
        </div>

         {
          // <Line />
          // <Text 
          //   id={`${comID}_ListMenu_FilterValue`}
          //   color={props?.color}
          //   icon="search"
          //   iconColor={props?.color}
          //   mode="classic"
          // />
        }
      </Menu >
  )}
 
  useEffect(() => {
    if(value || defaultValue){
      const theValue = checkParseObject(value || defaultValue);
      if(!theValue?.key){
        const indexValue = options.findIndex(item=>String(item.key) === String(theValue))
        refRadioValue.current =checkParseObject(options[indexValue])
      }else{
        refRadioValue.current = checkParseObject(theValue)
      }
      setInitialState({...initialState})
    }

  }, [value || defaultValue])

  return (
    <React.Fragment>
      <GroupField
        ref={ref}
        onFocus={handleFocus}
        readOnly={true}
        defaultValue={refRadioValue.current?.value || ""}
        endIcon={endIcon || "arrowDropDown"}
        MenuList={MenuList}
        {...rest}
    />
    </React.Fragment>
  )
});

export default List