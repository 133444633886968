import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    nav: `${styleVariables?.prefixDefaultClassName}_nav_item`,
    nav_menu: `${styleVariables?.prefixDefaultClassName}_nav_menu`,
    nav_nav_container: `${styleVariables?.prefixDefaultClassName}_nav_nav_container`,
    nav_item_head: `${styleVariables?.prefixDefaultClassName}_nav_item_head`,
    nav_item_item_content: `${styleVariables?.prefixDefaultClassName}_nav_item_item_content`,
    nav_item_footer: `${styleVariables?.prefixDefaultClassName}_nav_item_footer`,
    
    sx:`${styleVariables?.prefixDefaultClassName}_nav_sx`
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.nav_nav_container]: {
        margin: "auto",
        cursor: "pointer",
        position:"relative",
        textAlign:"center",
        display: cssObject?.display?.[cssKeys?.block],
        width:"100%",
        flexBasis:"1"

      },
      [classesName?.nav]: {
        transition: "all ease-in-out 350ms",
        margin: "auto",
        cursor: "pointer",
        // listStyle: "none",
        // display: "flex",
        // justifyContent:"start",
        // alignContent:"center",
        position:"relative",
        textAlign:"center",
        display: cssObject?.display?.[cssKeys?.flex],
        flex:1,
        justifyContent:"space-between",
        alignItems: "flex-start",
        width:"max-content",


      },
      [classesName?.nav_item_head]:{
          justifyContent: "center",
          textAlign: "center",
          // padding:cssObject?.spacing?.[cssKeys?.xs],
          background: "transparent",
          display: "flex",
          width: "max-content",
          margin:cssObject?.spacing?.[cssKeys?.xs],
      },
      [classesName?.nav_item_item_content]:{
        textAlign: "start",
        background: "transparent",
        // padding:cssObject?.spacing?.[cssKeys?.xs],
        display: "flex",
        alignItems: "center",
        minWidth: "max-content",
        justifyContent: "flex-start",
        width:"100%"
      },
      [classesName?.nav_item_footer]:{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "max-content",
          margin:cssObject?.spacing?.[cssKeys?.xs],
          background: "transparent",
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.nav]: (props) => {
        return{
         padding: `${cssObject?.spacing?.[cssKeys?.xs]}`,
         minHeight: `${cssObject?.size?.[cssKeys?.item_sm]}`,
        "&hover":{
          background: cssObject?.color?.[cssKeys?.bodyColorLight],
          cursor:"pointer"
        }
      }
      },
      [classesName?.nav_menu]: (props) => {
          return{
            boxSizing: "border-content",
            minWidth: `max-content`,
            maxHeight: "75%",
            width:"100%",
            padding: props?.hasContent?`${cssObject?.spacing?.[cssKeys?.xs]}`: "0px",
            [`.${classesName?.nav}`]:{
              minWidth:"90% !important"
            }

          }
      },
      [classesName?.sx]: (props) => {
        return {
          ...props?.sx
        };
      },
    },
  });


  return{
    classesName,
    style,
    styleSx,
  }
}