import React, { useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import traineeSetterModalCtrl from "./traineeSetterModal.controller";
import { App_info_Color } from "../../../../../../util/appStyle";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { objUploadType } from "../../../../../../configuration/appVariables";

const labels = dictionary?.components?.stakeholders?.member?.account?.traineeSetter;

export default function TraineeSetterModalView({ isLoading, setIsLoading, blnIsStartUp = false, classes }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({ blnOpenSetterModal: false, blnIsNew: true, lstFirstProofSection: [] });
  const uploaderFirstProofSection = Uploader(state, setState, "lstFirstProofSection", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
  });
  const fields = FormData({
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigDegreeID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    bigEmploymentID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfResidenceID: { control: "list", validations: { required: true }, blnLanguage: false },

    // student
    strUniversityName: { control: "list", validations: {}, blnLanguage: false },
    bigAcademicYearID: { control: "list", validations: {}, blnLanguage: false },
    bigGpaID: { control: "list", validations: {}, blnLanguage: false },
    blnIsFirstSections: { control: "switch", blnLanguage: false },

    // manager
    strWorkGovPlace: { control: "text", validations: {}, blnLanguage: false },
    strPositionTitle: { control: "text", validations: {}, blnLanguage: false },
  });
  
  const handlers = traineeSetterModalCtrl({ appDispatch, appState, isLoading, setIsLoading, blnIsStartUp });
  const openModal = handlers.openModal({ fields, setState, state });
  const onChangeCountry = handlers.onChangeCountry({ fields });
  const updateTraineeInfo = handlers.updateTraineeInfo({ fields, setState, state, uploaderFirstProofSection });
  const handelChangeEmployment = handlers.handelChangeEmployment({ fields });

  console.log(fields?.strUniversityName?.isValid());
  
  return {
    // openEle: (blnIsStartUp ? appState?.userInfo?.blnIsStartUp : appState?.userInfo?.blnIsStudent) ?
    openEle:
      appState?.userInfo?.jsnUserInfo?.blnIsStartUp && appState?.userInfo?.jsnUserInfo?.blnIsStudent ? null : (
        <Paper className={classes?.bgBox}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography as="subtitle2" className={classes?.mainTitle}>
                {labels?.title?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12" md="12">
              <Typography as="body2" className={classes?.descriptionLightText}>
                {labels?.description?.[lang]}
              </Typography>
            </Grid>
            <Grid item>
              <Button label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={openModal} className={classes?.btnBox} />
            </Grid>
          </Grid>
        </Paper>
      ),

    modal: (
      <Modal open={state.blnOpenSetterModal} eventClose={() => setState({ ...state, blnOpenSetterModal: false })} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField
                objHandler={fields.bigCountryOfResidenceID}
                onChange={onChangeCountry}
                icon="LocationOn"
                mode={"classic"}
                iconColor={App_info_Color}
                color={App_info_Color}
                className={classes?.field}
              />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField
                objHandler={fields.bigDegreeID}
                icon="documentScannerRounded"
                mode={"classic"}
                iconColor={App_info_Color}
                color={App_info_Color}
                className={classes?.field}
              />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField
                objHandler={fields.bigEmploymentID}
                icon="work"
                mode={"classic"}
                iconColor={App_info_Color}
                color={App_info_Color}
                className={classes?.field}
                onChange={handelChangeEmployment}
              />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
            </Grid>
            {handlers?.objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key ||
            handlers?.objCat?.Root?.employment?.unemployed?.id === fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.strUniversityName}
                    icon="accountBalance"
                    mode={"classic"}
                    iconColor={App_info_Color}
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.bigAcademicYearID}
                    icon="AutoStories"
                    mode={"classic"}
                    iconColor={App_info_Color}
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.bigGpaID}
                    icon="insertChartOutlined"
                    mode={"classic"}
                    iconColor={App_info_Color}
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.blnIsFirstSections} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                </Grid>
                {!fields?.blnIsFirstSections?.getValue() ? null : (
                  <Grid item xs="12" container sx={{ sm: { justifyContent: "center" } }}>
                    <Grid item xs="12" md="auto">
                      <Typography as="body2" className={classes?.descriptionLightText}>
                        {labels?.proofUpload?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item>{uploaderFirstProofSection?.JSX}</Grid>
                  </Grid>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.strWorkGovPlace}
                    icon="accountBalance"
                    mode={"classic"}
                    iconColor={App_info_Color}
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strPositionTitle} icon="work" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            )}
          </Grid>

          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-8 pb-5>
              <Button py-0 px-10 label={dictionary?.shared?.buttons?.submitBtn?.[lang]} onClick={updateTraineeInfo} mode="outlined" className={classes?.btnBox} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
