export const prefixDefaultClassName = "uicore";
export const defaultBaseSize = 0.25;

// let xsScrSize = window.matchMedia("(max-width: 600px)");
// let smScrSize = window.matchMedia("screen and (min-width: 601px) and (max-width: 900px)");
// let mdScrSize = window.matchMedia("screen and (min-width: 901px) and (max-width: 1200px)");
// let lgScrSize = window.matchMedia("screen and (min-width: 1201px) and (max-width: 1536px)");
// let xlScrSize = window.matchMedia("(min-width: 1537px)");

export const xsScrSize = `@media screen and (max-width: 600px)`;
export const smScrSize = `@media screen and (max-width: 950px)`;
export const mdScrSize = `@media screen and (max-width: 1200px)`;
export const lgScrSize = `@media screen and (max-width: 1537px)`;
export const xlScrSize = `@media screen and (min-width: 1537px)`;

export const xsScr = `600px`;
export const smScr = `950px`;
export const mdScr = `1200px`;
export const lgScr = `1537px`;
export const xlScr = `1537px`;
