import { objCssProperties } from "packages/style/src/helper/cssProperties";
import { returnColor } from "../color/color";
import keys, { keysText } from "./keys";

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    if(arrClasses?.[0] === keysText?.text && keys?.[keysText?.size]?.[arrClasses?.[1]]){
        return { [objCssProperties?.fontSize] : keys?.[keysText?.size]?.[arrClasses?.[1]]}
    }else if(arrClasses?.[0] === keysText?.text && !isNaN(Number(arrClasses?.[1]))){
        return { [objCssProperties?.fontSize] : `${keys?.[keysText?.size]?.baseSize * Number(arrClasses?.[1])}rem`}
    }else if(arrClasses?.[0] === keysText?.break && arrClasses?.[1]){
        if(arrClasses?.[1] === keys?.[keysText?.break]?.normal){
            return {
                [objCssProperties?.wordBreak] : keys?.[keysText?.break]?.normal,
                [objCssProperties?.overflowWrap] : keys?.[keysText?.break]?.normal,
            }
        }else{
            return {[objCssProperties?.wordBreak] : keys?.[keysText?.break]?.[arrClasses?.[2]]}
        }
    }else if(arrClasses?.[1] === keysText?.truncate){
        return {
            [objCssProperties?.overflow] : "hidden",
            [objCssProperties?.textOverflow] : "ellipsis",
            [objCssProperties?.whiteSpace] : "nowrap"
        }
    }if(arrClasses?.[1] === keysText?.transform && arrClasses?.[2]){
        return { [objCssProperties?.textTransform] : keys?.[keysText?.transform]?.[arrClasses?.[2]]}
    }else{
        return {[objCssProperties?.color] : returnColor(arrClasses)}
    }
}

