import React, { forwardRef, useContext, useId, useRef} from 'react'
import { ContextThemeProvider, cssKeys, mainTheme, styleFunction} from "@cbmisorg/styles";
import { Icon } from '@cbmisorg/icons';
import TextStyle from './TextStyle';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import GroupField from '../GroupField/GroupField';

const Text = forwardRef((/**@type {any}*/props, ref) => {
  // const {
  //   id, label,placeholder,errorMessage=null, helperMessage, language="eng",
  //   icon,adornment,endIcon,endAdornment,
  //   mode,color,iconColor,
  //   type="text",value="",defaultValue="",
  //   inputDir,
  //   onFocus, onBlur,
  // objLang,currentLang,setCurrentLang, getCurrentValue, // FORM API
  //   sx,hidden,disabled,dir,className,MenuList=null,...rest} = props

  const {objLang,...rest} = props

  return (
    <GroupField 
      ref={ref}
      optionLang={objLang}
      {...rest}
      />
  )
});

export default Text