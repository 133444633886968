  import {
    StyleBuilder,
    styleVariables,
    cssKeys,
    mainTheme,
    styleFunction,
  } from "@cbmisorg/styles";

  export default(objThemeInfo)=>{
    const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
    const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

    const classesName = {
      modal_container: `${styleVariables?.prefixDefaultClassName}_modal_container`,
      modal_out: `${styleVariables?.prefixDefaultClassName}_modal_out`,
      modal: `${styleVariables?.prefixDefaultClassName}_modal`,
      modal_close_btn: `${styleVariables?.prefixDefaultClassName}_modal_close_btn`,
      modal_details: `${styleVariables?.prefixDefaultClassName}_modal_details`,
      modal_header: `${styleVariables?.prefixDefaultClassName}_modal_header`,
      modal_header_content: `${styleVariables?.prefixDefaultClassName}_modal_header_content`,
      modal_body: `${styleVariables?.prefixDefaultClassName}_modal_body`,
      modal_footer: `${styleVariables?.prefixDefaultClassName}_modal_footer`,
      sx:`${styleVariables?.prefixDefaultClassName}_modal_sx`
    }

    const getWidth = (width) => {
      switch (String(width).toLowerCase()) {
        case "xl":
          return {
            width: cssObject?.size?.[cssKeys?.xl]
          }
        case "lg":
          return {
            width: cssObject?.size?.[cssKeys?.lg]
          }
        case "md":
          return {
            width: cssObject?.size?.[cssKeys?.md]
          }
        case "sm":
          return {
            width: cssObject?.size?.[cssKeys?.sm]
          }
          case "sm":
          return {
            width: cssObject?.size?.[cssKeys?.xs]
          }
        case "fluid":
           return {
            width: cssObject?.size?.[cssKeys?.full]
          }
        case "half":
          return {
            width: cssObject?.size?.[cssKeys?.half]
          }
        default:
           return {
            width: cssObject?.size?.[cssKeys?.md],
            sm:{
              width: cssObject?.size?.[cssKeys?.["11/12"]],
            }
          }
      }
    };

    const style = StyleBuilder({
      blnRandomPrefix: false,
      blnIsDefaultStyle: true,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.modal_container]:{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: 1201,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
          overflow: "auto",
          background: "rgba(0,0,0,0.7)",
          padding: "15% auto",
        },
        [classesName?.modal_out]:{
          position: "fixed",
          top: "0",
          left: "0",
          width: "99%",
          height: "100%",
          background: "transparent",
          padding: "15% auto",
          margin: "auto",
        },
        [classesName?.modal]:{
          height: "auto",
          maxHeight: "90%",
          display: "block",
          margin: "auto",
          position: "static",
          overflow: "auto",
          zIndex: 1,
        },
        [classesName?.modal_close_btn]:{
          position: "absolute",
          right: "0px",
          border: "none",
          margin: "0",
          padding: "0px",
          background: "transparent",
          color: "currentColor",
          transition: ".2s",
          cursor: "pointer",
        },
        [classesName?.modal_details]:{
          position: "relative",
          textAlign: "center",
        },
        [classesName?.modal_header]:{
          position: "relative",
          padding: "0px",
          paddingBottom: cssObject?.spacing?.[cssKeys?.sm],
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle1],
          fontWeight: cssObject?.text?.fontWeight?.[cssKeys?.fontWeight_md],
          minHeight: cssObject?.spacing?.[cssKeys?.md],
          color: cssObject?.color?.gray,
          borderBottom: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.borderColor])}`,
          marginTop: "0px",
          width: "100%",
          display:"flex",
          alignItems:"center",
          justifyContent:"center"
        },
        [classesName?.modal_header_content]:{
          position: "relative",
          width: "max-content",
          display:"block"
        },
        [classesName?.modal_body]:{
          position: "relative",
          marginTop: cssObject?.spacing?.[cssKeys?.xs],
          padding: cssObject?.spacing?.[cssKeys?.sm],
          textAlign: "start",
        },
        [classesName?.modal_footer]:{
          position: "relative",
          top: cssObject?.spacing?.[cssKeys?.sm],
          marginTop: cssObject?.spacing?.[cssKeys?.md],
          marginBottom: cssObject?.spacing?.[cssKeys?.sm],
          color: cssObject?.color?.gray,
          fontSize: cssObject?.text?.size?.[cssKeys?.body2],
        },
      },
    });

    const styleModalSx = StyleBuilder({
      blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.modal]: (props) => {
          return{
            color: cssObject?.color?.bodyColor,
            background:cssObject?.color?.bodyColorBg,
            padding: `${cssObject?.spacing?.[cssKeys?.md]} ${cssObject?.spacing?.[cssKeys?.sm]}`,
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
            bottom: cssObject?.spacing?.[cssKeys?.lg],
            boxShadow: cssObject?.boxShadow?.[cssKeys?.lg],
            ...getWidth(props?.size)
          }
        },
        [classesName?.sx]: (props) => {
          return {
            ...props?.sx,
          };
        },
      },
    });

    const styleModalHeaderSx = StyleBuilder({
      blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.modal_close_btn]:{
          fontSize: cssObject?.text?.size?.[cssKeys?.subtitle1],
          "&hover":{
            background:cssObject?.color?.bodyColorBg,
            borderColor: cssObject?.color?.bodyColorDark,
            color: cssObject?.color?.error,
            transform:  "translateY(-3px)",

          }
        },
        [classesName?.sx]: (props) => {
          return props?.sx;
        },
      },
    });

    const styleModalBodySx = StyleBuilder({
      blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.sx]: (props) => {
          return props?.sx;
        },
      },
    });

    const styleModalFooterSx = StyleBuilder({
      blnRandomPrefix: true,
      blnIsDefaultStyle: false,
      blnCbmisStyle: true,
      objStyles: {
        [classesName?.sx]: (props) => {
          return props?.sx;
        },
      },
    });



    return{
      classesName,
      style,
      styleModalSx,
      styleModalHeaderSx,
      styleModalBodySx,
      styleModalFooterSx,
      getWidth
    }
  }

