import { objCssProperties } from "packages/style/src/helper/cssProperties";
import keys, { keysShadow } from "./keys";

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
   if(arrClasses?.length === 1){
    return {[objCssProperties?.boxShadow]: keys?.[keysShadow?.shadow]}
   }else if(arrClasses?.length === 2){
    return {[objCssProperties?.boxShadow]: keys?.[arrClasses?.[1]]}
   }
}