
import formatKeysValue from "./formatKeysValue";
import { keysPosition } from "./position/keys";
import { keysShadow } from "./shadow/keys";
import { keysSize } from "./sizing/keys";
import { keysSpacing } from "./spacing/keys";

import background from "./background/background";
import border from "./border/border";
import text from "./text/text";
import sizing from "./sizing/sizing";
import spacing from "./spacing/spacing";
import position from "./position/position";
import shadow from "./shadow/shadow";

export default (classesName)=>{
    "rule-value1-value2"
    const arrCssValue = String(classesName)?.toLowerCase()?.split("-")

    if(arrCssValue[0] === formatKeysValue?.background){
        return background(classesName)
    }else if(arrCssValue[0] === formatKeysValue?.border || arrCssValue[0] === formatKeysValue?.rounded){
        return border(classesName)
    }else if(arrCssValue[0] === formatKeysValue?.text || arrCssValue[0] === formatKeysValue?.font || arrCssValue[0] === formatKeysValue?.break){
        return text(classesName)
    }else if(arrCssValue[0] === keysSize?.width || arrCssValue[0] === keysSize?.height || arrCssValue[1] === keysSize?.width || arrCssValue[2] === keysSize?.height){
        return sizing(classesName)
    }else if(String(arrCssValue[0]).charAt(0) === keysSpacing?.mrg || String(arrCssValue[0]).charAt(0) === keysSpacing?.pdn){
        return spacing(classesName)
    }else if(keysPosition?.[arrCssValue?.[0]]){
        return position(classesName)
    }else if(arrCssValue?.[0] === keysShadow?.shadow){
        return shadow(classesName)
    }
}