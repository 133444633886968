import React, { forwardRef, useContext, useState } from 'react'
import { styleFunction, ContextThemeProvider } from '@cbmisorg/styles';
import ButtonStyle from './ButtonStyle';
import { Icon } from '@cbmisorg/icons';

const Button = forwardRef((/**@type {any}*/props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, style, styleSx, btnIconSize} = ButtonStyle(theTheme);
  const {label, color, size, icon, iconSize, endIcon, iconColor, mode, sx, hidden, disabled, dir, className, id, ...rest}= props
  const classes = style();
  const classesSx = styleSx({sx, color, size, icon, mode});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.button], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);


  return (
    <button className={itemClasses}
    {...styleFunction?.getRestProps(rest)}
    ref={ref}
    >
    {
      icon && (
        <span className={`${classes?.[classesName?.btn_icon]}`}>
          <Icon
              icon={icon}
              size={iconSize ? iconSize : ""}
              color={iconColor ? styleFunction.checkColorReturnValue(iconColor) : ""}
            />
        </span>
      )
    }
    {
      label && (<span className={`${classes?.[classesName?.btn_label]}`}>{label}</span>)
    }
    {
      endIcon && (
        <span className={`${classes?.[classesName?.btn_icon]}`}>
          <Icon
              icon={endIcon}
              size={btnIconSize(props?.size, props?.iconSize)}
              color={iconColor ? styleFunction.checkColorReturnValue(iconColor) :""}
            />
        </span>
      )
    }
    </button>
  )
})

export default Button