/* eslint-disable no-fallthrough */
import React, { forwardRef, useContext } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import LoaderStyle from "./LoaderStyle";
import Container from "../../layout/Container/Container";

const Loader = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = LoaderStyle(theTheme);
  const { mode, color, backdrop, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, color, backdrop });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.loader_container],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  return backdrop ? (
    <Container>
      <div className={itemClasses} {...styleFunction?.getRestProps(rest)}>
        <div ref={ref} className={`${classes?.[classesName?.loader]} ${classesSx?.[classesName?.loader]}`}></div>
      </div>
    </Container>
  ) : null;
});

export default Loader;
