import React, { useId, useRef } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { Grid } from "../../index";
export default function RadialProgressBar({ onChange = null, value = null, label = " deg", readOnly = false }) {
  let id = useId().replaceAll(":", "");
  const circleID = "radial-slider-circle-" + id;
  const dotCircleID = "radial-slider-dot-circle-" + id;
  const dotCircleClassName = "radial-slider-dot-" + id;
  const debugCircleID = "radial-slider-debug-circle-" + id;

  const style = ClassesBuilder({
    container: {
      border: "0",
      margin: "0",
      padding: "0",
      boxSizing: "border-box",
      outline: "0",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      height: "100%",
      width: "100%",
      "&>": {
        "&*": {
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          border: "0",
          margin: "0",
          padding: "0",
          boxSizing: "border-box",
          outline: 0,
        },
      },
    },
    debug: {
      color: "#2e78b2",
      fontFamily: "monospace",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate3d(-50%, -50%, 0)",
      zIndex: 100,
      fontSize: "18px",
      backgroundColor: "#ecf0f1",
    },
    circle: {
      position: "relative",
      top: "50%",
      left: "50%",
      backgroundColor: value && typeof value === "number" ? `conic-gradient(#FF3B60 0deg ${value}deg, #2e78b2 ${value + 1}deg ${360 - value}deg)` : "#2e78b2",
      borderRadius: "50%",
      width: "25%",
      height: "0",
      minWidth: "50px",
      minHeight: "50px",
      paddingBottom: "25%",
      transform: "translate3d(-50%, -50%, 0)",
      boxShadow: "0 0 10px rgba(#000, 0.5)",
      "&before": {
        content: "''",
        position: "absolute",
        width: "90%",
        height: "90%",
        backgroundColor: "#ecf0f1",
        borderRadius: "50%",
        top: "5%",
        left: "5%",
        boxShadow: "inset 0 0 10px rgba(#000, 0.5)",
      },
      ["." + dotCircleClassName]: {
        position: "absolute",
        width: "5%",
        height: "50%",
        left: "47.5%",
        top: "0",
        transform: value && typeof value === "number" ? `rotate(${value}deg)` : "rotate(0deg)",
        transformOrigin: "center bottom",
        "&before": {
          content: "''",
          position: "absolute",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px #000",
          width: "200%",
          transform: "translate3d(-25%, -25%, 0)",
          height: "0",
          paddingBottom: "200%",
          borderRadius: "50%",
          cursor: "pointer",
        },
      },
    },
  });

  const inputRef = useRef({ isDragging: false, value: value });

  const onDrag = (event) => {
    try {
      event.preventDefault();

      if (!inputRef?.current?.isDragging || readOnly) {
        return;
      }

      let circle = document.getElementById(circleID);

      let rect = circle.getBoundingClientRect();

      let center_x = rect?.width / 2 + rect?.left;
      let center_y = rect?.height / 2 + rect?.top;

      let touch = null;
      if (circle?.originalEvent && circle?.originalEvent.touches) {
        touch = circle?.originalEvent.touches[0];
      }

      let pos_x = event?.pageX || touch?.pageX;
      let pos_y = event?.pageY || touch?.pageY;

      let delta_y = center_y - pos_y;
      let delta_x = center_x - pos_x;
      let angle = Math.atan2(delta_y, delta_x) * (180 / Math.PI);

      angle = angle - 90;

      if (angle < 0) {
        angle = 360 + angle;
      }

      angle = Math.round(angle);
      inputRef.current.value = angle;
      if (onChange) {
        onChange({ ...event, value: angle });
      }

      circle.style.background = `conic-gradient(#FF3B60 0deg ${angle}deg, #2e78b2 ${angle + 1}deg ${360 - angle}deg)`;

      let dotEle = document.getElementById(dotCircleID);
      if (dotEle) {
        dotEle.style.transform = `rotate(${angle}deg)`;
      }
      let debugEle = document.getElementById(debugCircleID);
      if (debugEle) {
        debugEle.innerHTML = angle + label;
      }
    } catch {}
  };

  const onDragStart = (event) => {
    inputRef.current.isDragging = true;
  };

  const onDragEnd = (event) => {
    inputRef.current.isDragging = false;
  };

  return (
    <>
      <Grid container>
        <Grid item xs="12" p-0 justifyContent="center">
          <div className={style.container}>
            <div className={style.circle} onMouseDown={onDragStart} onTouchStart={onDragStart} onMouseMove={onDrag} onTouchMove={onDrag} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} id={circleID}>
              <div id={debugCircleID} className={style.debug}>
                {value || inputRef.current.value || 0}
                {label}
              </div>
              {readOnly ? null : <div className={dotCircleClassName} id={dotCircleID}></div>}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
