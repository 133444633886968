import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";

export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    container: `${styleVariables?.prefixDefaultClassName}_container`,

    range_container: `${styleVariables?.prefixDefaultClassName}_range_container`,
    range_slider: `${styleVariables?.prefixDefaultClassName}_range_slider`,
    range_label: `${styleVariables?.prefixDefaultClassName}_range_label`,
    range_slider_min_max: `${styleVariables?.prefixDefaultClassName}_range_slider_min_max`,
    range_lider_tooltip: `${styleVariables?.prefixDefaultClassName}_range_lider_tooltip`,
    range_lider_tooltip_text: `${styleVariables?.prefixDefaultClassName}_range_lider_tooltip_text`,
    range_slider_value_output: `${styleVariables?.prefixDefaultClassName}_range_slider_value_output`,
    sx: `${styleVariables?.prefixDefaultClassName}_range_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles:{
      [classesName?.range_container]:{
        position: "relative",
        width: "100%",
        display:"block",
        margin:0,
        padding:0,
      },
      [classesName?.range_slider]:{
        position: "relative",
        width: "100%",
        display:"flex",
        margin:0,
        padding:0,
        appearance:"none",
        WebkitAppearance:"none",
        MozAppearance:"none",
        background: "none",
          alignSelf: "center",
          cursor: "grab",

        "&focus":{
          boxShadow: "none",
          outline: "none",
        },
        "&WebkitSliderRunnableTrack":{
          content: '',
          pointerEvents: "none",
          width: "100%",
          overflow:"hidden",
          zIndex:0,
        },
        "&WebkitSliderThumb": {
          appearance:"none",
          WebkitAppearance:"none",
          MozAppearance:"none",
          // borderRadius: "50%",
          width:"100px",
          height:"100px",
          backgroundColor: "currentColor",
          zIndex:2,
          padding:0,
        },
        "&MozRangeTrack":{
          width: "100%",
        },
        "&MozRangeThumb":{
          cursor: "grab",
          borderRadius: "50%",
          position: "relative",
          padding:0,
        },
        "&MozRangeProgress": {
          border: 0,
          marginTop: 0,
        },
      },
      [classesName?.range_label]:{
        display: "flex",
      },
      [classesName?.range_slider_min_max]:{
        display: "flex",
        justifyContent:"space-between",
        margin: 0,
        padding: 0,
        "span":{
          display: "flex",
          margin: 0,
          padding: 0,
          height: 0,
          lineHeight: "1rem",
        }
      },
      [classesName?.range_lider_tooltip]:{
        zIndex: 2,
        position: "absolute",
        textAlign: "center",
        opacity: 1,
        borderRadius: "100%",
        background:cssObject?.color?.[cssKeys?.currentColor],
        "&after":{
          content: "",
          position: "absolute",
          left: 0,
          borderTopColor: cssObject?.color?.[cssKeys?.currentColor],

        }
      },
      [classesName?.range_slider_value_output]:{
        position: "absolute",
        textAlign: "center",
        width:"90%"
      },
    }
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.range_slider]:(props) => {
        return{
          height: cssObject?.spacing?.[cssKeys?.sm], /* Specified height */
          background: cssObject?.color?.[cssKeys?.light],
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.md,
          transition: "box-shadow 0.2s ease-in-out",
          transformOrigin: "75px 75px",
          progressShadow: "2px 2px 4px rgba(0, 0, 0, 0.2) inset",
          pointerEvents:  !props?.readOnly ? "none": "default",
          "&WebkitSliderThumb":{
            position:"relative",
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.md,
            padding:0,
            boxShadow: `-1000px 0 0 1000px ${ styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary])}`,//cssObject?.boxShadow?.[cssKeys?.md],
            ...(
              props?.thumb?{
              height: cssObject?.spacing?.[cssKeys?.md],
              width: cssObject?.spacing?.[cssKeys?.md],
              cursor: "grab",
              border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.light])}`,
              background: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary]),  
            }:{
              height: 0,
              width: 0,
              cursor: "none",
              pointerEvents:"none",
              border:"none",
              }
            ),
          },
          "&WebkitSliderRunnableTrack":{
            background: cssObject?.color?.[cssKeys?.light],
            height: cssObject?.spacing?.[cssKeys?.sm],
            border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary])}`,
          },
          "&MozRangeThumb":{
              position:"relative",
              borderRadius: cssObject?.border?.[cssKeys?.rounded]?.[cssKeys?.md],
              ...(
                props?.thumb?{
                height: cssObject?.spacing?.[cssKeys?.md],
                width: cssObject?.spacing?.[cssKeys?.md],
                cursor: "grab",
                border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.light])}`,
                background: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary]),
                }:{
                height: 0,
                width: 0,
                cursor: "none",
                border:"none",
                }
              ),
            },
          "&MozRangeTrack":{
            height: cssObject?.spacing?.[cssKeys?.sm],
            },
           "&MozRangeProgress": {
             height: cssObject?.spacing?.[cssKeys?.sm],
             background: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.[cssKeys?.primary]),
           },

           "&MsTrack": {
            height: cssObject?.spacing?.[cssKeys?.sm],
            border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.primary])}`,
            },
            "&MsFillLower":{
              background: styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.primary]),
            },
            "&MsFillUpper": {
              background:  styleFunction.checkColorReturnValue(cssObject?.color?.[cssKeys?.primary]),
            },
        }
      },
      [classesName?.range_lider_tooltip]:(props) =>{
        return{
          top: `-${cssObject?.size?.[cssKeys?.item_md]}`,
          width: cssObject?.size?.[cssKeys?.item_sm],
          height: cssObject?.size?.[cssKeys?.item_sm],
          color:props?.color? styleFunction?.checkColorReturnValue(props?.color) : cssObject?.color?.[cssKeys?.primary],
          "&after":{
            top: "19px",
            borderLeft: "14px solid transparent",
            borderRight: "14px solid transparent",
            borderTopWidth: "16px",
            borderTopStyle: "solid",
            borderRadius: "16px",
          }
        }
      },
      [classesName?.range_slider_value_output]:(props)=>{
        return{
        top: `-${cssObject?.size?.[cssKeys?.item_xs]}`,
        color:props?.color? styleFunction?.checkColorReturnValue(props?.color) : cssObject?.color?.[cssKeys?.primary],
        fontSize: cssObject?.text?.size?.[cssKeys?.subtitle2],
      }},
      [classesName?.sx]: (props) => {
        return {
          color:props?.color? styleFunction?.checkColorReturnValue(props?.color) : cssObject?.color?.[cssKeys?.primary],
          ...props?.sx,

        };
      },
    },
  });


  return{
    classesName,
    style,
    styleSx,
  }
}