import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import TableStyle from "./TableStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const TableBody = forwardRef((/**@type {any}*/props,ref) =>{
  const { sx, hidden, disabled, dir, id, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, styleTableBodySx} = TableStyle(theTheme);
  const classesSx = styleTableBodySx({sx});
  const comID = id ||  String(useId()).slice(1,-1)


  const itemClasses = styleFunction.getClassName(classesName?.table_body ,classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <tbody className={itemClasses} {...styleFunction?.getRestProps({...rest})} ref={ref} key={comID}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </tbody>
  );

});

export default TableBody;
