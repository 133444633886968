import { objCssProperties } from "packages/style/src/helper/cssProperties";
import keys, { keysPosition } from "./keys";

const getPosSize=(arrClasses, key)=>{
    const size = arrClasses?.[1]
    if(!isNaN(Number(size)) ){
        return `${keys?.size?.baseSize * Number(size)}rem`
    }else if(keysPosition?.[size]){
            return keys?.size?.[keysPosition?.[size]]
    }
}

export default (classesName)=>{
    const arrClasses = String(classesName)?.toLowerCase()?.split("-");
    const value = getPosSize(arrClasses)
    if(keys?.posVal?.[arrClasses?.[0]]){
        return {[objCssProperties?.position] : arrClasses?.[0]}
    }else if(arrClasses?.[0] === keys?.side?.y){
        return {
            [objCssProperties?.top] : value,
            [objCssProperties?.bottom] : value
        }
    }else if(arrClasses?.[0] === keys?.side?.x){
        return {
            [objCssProperties?.left] : value,
            [objCssProperties?.right] : value
        }
    }else if(arrClasses?.[0] === keys?.side?.inset){
        return {
            [objCssProperties?.left] : value,
            [objCssProperties?.right] : value,
            [objCssProperties?.top] : value,
            [objCssProperties?.bottom] : value,
        }
    }else if(keys?.side?.[arrClasses?.[0]]){
        return {
            [objCssProperties?.[keys?.side?.[arrClasses?.[0]]]] : value,
        }
    }
}