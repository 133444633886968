import React, { forwardRef, useContext, useId } from 'react'
import {ContextThemeProvider, styleFunction} from "@cbmisorg/styles";
import ErrorBoundary from '../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary';
import GroupBarStyle from './GroupBarStyle';

const GroupBar = forwardRef(function GroupBar(/**@type {any}*/props,ref) {
  const theTheme = useContext(ContextThemeProvider);
  const { sx, hidden, disabled, dir, className, id, position={xl:"",lg:"",md:"",sm:"",xs:""}, ...rest } = props;
  const {classesName, style, styleSx} = GroupBarStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({sx});
  const itemClasses = styleFunction.getClassName(classes?.[classesName?.groupBar], classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  const comID = id ||  String(useId()).slice(1,-1)

  return (
    <div className={itemClasses} ref={ref} {...styleFunction?.getRestProps(rest)} key={comID}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </div>
  )
})

export default GroupBar