import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const  classesName = {
    container: `${styleVariables?.prefixDefaultClassName}_container`,
    sx:`${styleVariables?.prefixDefaultClassName}_container_sx`
  }

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.container]: {
        display: cssObject?.display?.[cssKeys?.block],
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position:cssObject?.position?.[cssKeys?.relative],
        margin: "auto",
        height:"fit-content",
      }
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        return {
          padding: cssObject?.spacing?.[cssKeys?.xs],
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
          width: getWidth(props?.width)?.width,
          height:"fit-content",
          ...props?.sx,
          sm:{
            width: getWidth(props?.width)?.sm?.width,
            padding:  cssObject?.spacing?.[cssKeys?.sm],
            height:"fit-content",
            ...props?.sx?.sm,
          },
          xs:{
            width: cssObject?.size?.[cssKeys?.full],
            padding:  cssObject?.spacing?.[cssKeys?.xs],
           height:"fit-content",
            ...props?.sx?.sm,
          },
        };
      },
    },
  });

  const getWidth = (width) => {
    switch (String(width).toLowerCase()) {
      case "xl":
        return {
          width: cssObject?.size?.[cssKeys?.xl]
        }
      case "lg":
        return {
          width: cssObject?.size?.[cssKeys?.lg]
        }
      case "md":
        return {
          width: cssObject?.size?.[cssKeys?.md]
        }
      case "sm":
        return {
          width: cssObject?.size?.[cssKeys?.sm]
        }
        case "xm":
        return {
          width: cssObject?.size?.[cssKeys?.xs]
        }
      case "fluid":
         return {
          width: cssObject?.size?.[cssKeys?.full]
        }
      case "half":
        return {
          width: cssObject?.size?.[cssKeys?.half]
        }
      default:
         return {
          width: cssObject?.size?.[cssKeys?.["11/12"]],
          sm:{
            width: cssObject?.size?.[cssKeys?.["12/12"]],
            padding:cssObject?.spacing?.[cssKeys?.xs]
          }
        }
    }
  };

  return{
    classesName,
    style,
    styleSx,
  }
}