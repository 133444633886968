import * as appStyle from "../../../../../../util/appStyle";

export const objStyle = {
  mainPaper: {
    background: appStyle?.App_info_Opacity_Color,
    border: "1px solid",
    borderColor: appStyle?.App_info_Color,
    maxHeight: "200px",
  },
  title: {
    ...appStyle.fontsMada,
    color: appStyle?.App_Light_Text_Color,
    textAlign: "justify",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&hover": {
      textOverflow: "inherit",
      overflow: "inherit",
      whiteSpace: "wrap",
    },
  },
  labelModal:{
    ...appStyle.fontsChanga,
    textAlign: "justify",
  },
  modal: {
    backgroundColor: appStyle.App_Dark_Color,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    minHeight: "25%",
    color: appStyle.App_Light_Text_Color,
    padding: "20px",
    xs:{
      width:"88%"
    }
  },
  modalHeader: {
    border: "none",
    color: appStyle.App_info_Color,
    margin: "auto",
    maxHeight: "10px",
    ".uicore_icon": {
      width: "25px",
      height: "25px",
    },
  },
  icon: {
    zIndex: 12,
    opacity: 0.8,
    borderRadius: "5px",
    color: appStyle?.App_info_Color,
    "&hover": {
      background: appStyle?.App_info_Color,
      color: appStyle?.App_Dark_Color,
      opacity: 1,
    },
  },
};
