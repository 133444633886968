import { ClientFunction, ClientData } from "@cbmisorg/helper";
import * as appVariables from "../configuration/appVariables";

export async function fetchData(strPageInfo, objRequestQuery) {
  let bigFeatureID = window?.FID_CP
    ? appVariables.App_LocalStorage_Is_Encrypted
      ? ClientFunction.decryptText(window?.FID_CP, appVariables.App_LocalStorage_Encrypted_KEY)
      : window?.FID_CP
    : ClientFunction.getAppLocalStorage("FID", appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);

  let tokens = null;
  if (objRequestQuery?.appState?.clientInfo?.blnIsUserLoggedIn) {
    if (objRequestQuery?.appState?.userInfo?.clientTokens) {
      tokens = objRequestQuery?.appState?.userInfo?.clientTokens;
    }
  }

  return await ClientData.fetchData(
    appVariables.App_Server_Url_GraphQL,
    objRequestQuery,
    {
      bigUserID: objRequestQuery?.appState?.userInfo?.bigUserID,
      bigUserRoleID: objRequestQuery?.appState?.userInfo?.bigUserRoleID,
      bigFeatureID: bigFeatureID,
      token: tokens,
    },
    {
      blnIsTestingMode: appVariables.App_IsInTestingMode,
      strPageInfo: strPageInfo,
      blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
      strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
    }
  );
}

export async function fetchFile(strPageInfo, appState, lstFiles, fileType, subDirPath, blnIsMultiFile = false) {
  let bigFeatureID = window?.FID_CP
    ? appVariables.App_LocalStorage_Is_Encrypted
      ? ClientFunction.decryptText(window?.FID_CP, appVariables.App_LocalStorage_Encrypted_KEY)
      : window?.FID_CP
    : ClientFunction.getAppLocalStorage("FID", appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);

  let tokens = null;
  if (appState?.clientInfo?.blnIsUserLoggedIn) {
    if (appState?.userInfo?.clientTokens) {
      tokens = appState?.userInfo?.clientTokens;
    }
  }

  return await ClientData.uploadFile(
    appVariables.App_Server_Url_UploadFiles,
    lstFiles,
    fileType,
    subDirPath,
    {
      appName: appVariables.App_Name,
      queryName: fileType,
      bigUserID: appState?.userInfo?.bigUserID,
      bigUserRoleID: appState?.userInfo?.bigUserRoleID,
      bigFeatureID: bigFeatureID,
      token: tokens,
    },
    { blnReturnFullResponse: true, blnIsMultiFile: blnIsMultiFile ? true : false },
    {
      blnIsTestingMode: appVariables.App_IsInTestingMode,
      strPageInfo: strPageInfo,
      blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
      strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
    }
  );
}

export async function fetchSendEmailPost(strPageInfo, appState, objBody) {
  let bigFeatureID = window?.FID_CP
    ? appVariables.App_LocalStorage_Is_Encrypted
      ? ClientFunction.decryptText(window?.FID_CP, appVariables.App_LocalStorage_Encrypted_KEY)
      : window?.FID_CP
    : ClientFunction.getAppLocalStorage("FID", appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);

  let tokens = null;
  if (appState?.clientInfo?.blnIsUserLoggedIn) {
    if (appState?.userInfo?.clientTokens) {
      tokens = appState?.userInfo?.clientTokens;
    }
  }

  return await ClientData.fetchPostRestful(
    appVariables.App_Server_Url_SendEmail,
    objBody,
    {
      bigUserID: appState?.userInfo?.bigUserID,
      bigUserRoleID: appState?.userInfo?.bigUserRoleID,
      bigFeatureID: bigFeatureID,
      appName: appVariables.App_Name,
      routingPath: new URL(appVariables.App_Server_Url_SendEmail).pathname,
      token: tokens,
    },
    {
      blnIsTestingMode: appVariables.App_IsInTestingMode,
      strPageInfo: strPageInfo,
      blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
      strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
    }
  );
}
