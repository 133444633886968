import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
const userApi = generateQueries("tblUser");
const statisticApi = generateQueries("tblStatistic");

const strPageInfo = "@src/client/component/shared/account/components/traineeSetterModal/traineeSetterModal.controller.js";

export default function traineeSetterModalCtrl({ appState, appDispatch, isLoading, setIsLoading, blnIsStartUp }) {
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const handler = {
    openModal:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields.controller.resetForm();

          // const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstCountryOption = [];
          let lstDegreeOption = [];
          let lstEmploymentOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGPAOption = [];
          let lstGenderOption = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstEmploymentOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGPAOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption);
          fields.bigDegreeID.setOptions(lstDegreeOption);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigEmploymentID.setOptions(lstEmploymentOption);
          fields.bigCountryOfCitizenID.setOptions(lstCountryOption);
          fields.bigCountryOfResidenceID.setOptions(lstCountryOption);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption);
          fields.bigGpaID.setOptions(lstGPAOption);

          state.blnIsNew = true;
          if (appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID) {
            state.blnIsNew = false;
            fields.bigCountryOfCitizenID.setValue(appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID) {
            state.blnIsNew = false;
            fields.bigCountryOfResidenceID.setValue(appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID);
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigEmploymentID) {
            fields.bigEmploymentID.setValue(appState?.userInfo?.jsnUserInfo?.bigEmploymentID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigDegreeID) {
            fields.bigDegreeID.setValue(appState?.userInfo?.jsnUserInfo?.bigDegreeID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(appState?.userInfo?.jsnUserInfo?.bigAcademicYearID);
          }
          if (appState?.userInfo?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(appState?.userInfo?.jsnUserInfo?.strUniversityName);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGpaID) {
            fields.bigGpaID.setValue(appState?.userInfo?.jsnUserInfo?.bigGpaID);
          }

          state.blnOpenSetterModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },

    onChangeCountry:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        // const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.value });
          }
        });
        lstUniversityOption.push({ key: "other", value: dictionary?.shared?.prefix?.otherBtn });

        fields.strUniversityName.setOptions(lstUniversityOption);
      },

    updateTraineeInfo:
      ({ state, setState, fields, uploaderFirstProofSection }) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          setIsLoading(true);
          //TO-DO: bundle all queries in one quey on the serverApp
          //#region lstFirstProofSection
          if (objCat?.Root?.employment?.student?.id === fields?.bigEmploymentID?.getValue()?.key && fields?.blnIsFirstSections?.getValue()) {
            if (state.lstReceipt?.length) {
              const resultUploadReceipt = await uploaderFirstProofSection?.uploadHandler("lstFirstProofSection");
              if (!resultUploadReceipt) {
                setIsLoading(false);
                return;
              }
            }else{
              Alert.viewAlert(dictionary?.components?.stakeholders?.member?.account?.traineeSetter?.proofUpload?.[lang], "error")
            }
          }
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update user
          const resultUser = await userApi(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
            },
            objectInput: {
              jsnUserInfo: {
                ...appState?.userInfo?.jsnUserInfo,
                bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
                bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
                bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
                bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
                strUniversityName: fields?.strUniversityName?.getValue()?.key,
                bigGpaID: fields?.bigGpaID?.getValue()?.key,
                strWorkPlace: fields.strWorkGovPlace?.getValue(),
                strPositionTitle: fields?.strPositionTitle?.getValue(),
                blnIsFirstSections: fields?.blnIsFirstSections?.getValue(),
                lstFirstProofSection: state?.lstFirstProofSection,
              },
              // bigUserStatusID: appVariables?.objUserStatus?.Applicant,
              blnIsStudent: objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key || objCat?.Root?.employment?.unemployed?.id === fields.bigEmploymentID?.getValue()?.key ? false : true,
              blnIsStartUp: !(objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key || objCat?.Root?.employment?.unemployed?.id === fields.bigEmploymentID?.getValue()?.key) ? true : false,
              dtmUpdatedDate: new Date(),
            },
          });

          if (!resultUser?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          appState.userInfo.blnIsStudent = blnIsStartUp ? false : true;
          appState.userInfo.blnIsStartUp = blnIsStartUp ? true : false;
          appState.userInfo.jsnUserInfo = {
            ...appState?.userInfo?.jsnUserInfo,
            bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
            bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
            strMajor: fields?.bigMajorID?.getValue()?.key,
            bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
            bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
            bigGender: fields?.bigGender?.getValue()?.key,
            bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
            strUniversityName: fields?.strUniversityName?.getValue()?.key,
            bigGpaID: fields?.bigGpaID?.getValue()?.key,
            strWorkPlace: fields.strWorkGovPlace?.getValue(),
            strPositionTitle: fields?.strPositionTitle?.getValue(),
            blnIsFirstSections: fields?.blnIsFirstSections?.getValue(),
            lstFirstProofSection: state?.lstFirstProofSection,
          };
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region statistics update
          let arrAttributes = [];
          if (blnIsStartUp) {
            arrAttributes.push("intCountJoinEntrepreneurAll");
            if (state?.blnIsNew) {
              arrAttributes.push("intCountJoinEntrepreneur");
            }
          } else {
            arrAttributes.push("intCountJoinStudentAll");
            if (state?.blnIsNew) {
              arrAttributes.push("intCountJoinStudent");
            }
          }
          await statisticApi(strPageInfo, appState, "aaiotAppIncDecInt", {
            objectCondition: {
              bigSystemID: appVariables.aaiot_System_ID,
            },
            arrAttributes: arrAttributes,
          });
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          state.blnOpenSetterModal = false;
          appDispatch();
          setState({ ...state });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_Successfully2?.[lang], "success", appLangDirection?.[lang]);
        } catch (error) {
          appFunctions.logError(strPageInfo, "updateTraineeInfo-ERROR", error);
          setIsLoading(false);
        }
      },

    handelChangeEmployment:
      ({ fields }) =>
      () => {
        try {
          const employmentValue = fields.bigEmploymentID?.getValue()?.key;
          
          if (objCat?.Root?.employment?.student?.id === employmentValue || objCat?.Root?.employment?.unemployed?.id === employmentValue) {
            fields?.strUniversityName?.setValidation("required", true);
            fields?.bigAcademicYearID?.setValidation("required", true);
            fields?.bigGpaID?.setValidation("required", true);
          } else {
            fields?.strWorkGovPlace?.setValidation("required", true);
            fields?.strPositionTitle?.setValidation("required", true);
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "handelChangeEmployment-ERROR", error);
        }
      },
    objCat: objCat,
  };
  return handler;
}
