import { getCacheStoreTrack, getCacheStyleSheetIndex } from "../cache/MainCache";
import { pkgConfigStyle } from "..";
const splitMedia = "-|-";

export async function cleanRule(idComp, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, _styleRef, _props, lstStyleClasses) {
  try {
    if (_styleRef?.blnInsertRun || !lstStyleClasses?.length) {
      return;
    }

    let styleRef = _styleRef || {};

    const objCache = getCacheStoreTrack(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });

    for (let i = 0; i < lstStyleClasses.length; i++) {
      const mainCssClassName = styleRef?.objClassesNames?.[lstStyleClasses?.[i]?.[0]];
      if (!objCache.has(mainCssClassName)) {
        continue;
      }

      const objGroupClassNameInfo = objCache.get(mainCssClassName);

      if (objGroupClassNameInfo?.usage?.has(idComp)) {
        objGroupClassNameInfo?.usage?.delete(idComp);
      }

      if (objGroupClassNameInfo?.usage?.size) {
        continue;
      }

      if (!objGroupClassNameInfo?.objCss?.size && !objGroupClassNameInfo?.usage?.size) {
        objCache.delete(mainCssClassName);
        continue;
      }

      let from = 0;
      const entries = objGroupClassNameInfo.objCss.entries();
      for (const entry of entries) {
        if (_styleRef?.blnInsertRun || objGroupClassNameInfo?.usage?.size) {
          break;
        }
        if (!entry) {
          continue;
        }

        const [cssSelectors, { idx }] = entry;
        const indexStyle = getCacheStyleSheetIndex(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });

        const cssRule = document?.styleSheets?.[indexStyle];
        for (let k = from; k < cssRule?.cssRules?.length; k++) {
          if (_styleRef?.blnInsertRun || objGroupClassNameInfo?.usage?.size) {
            break;
          }
          const objCurrentCssRule = cssRule.cssRules.item(k);

          if (objCurrentCssRule instanceof CSSMediaRule && cssSelectors.includes("@media") && cssSelectors.includes(splitMedia)) {
            const [mediaType, selectorText] = cssSelectors.split(splitMedia);
            let objCSSStyleRule = objCurrentCssRule.cssRules?.length ? objCurrentCssRule?.cssRules?.item(0) : null;
            let strMediaListItem = objCurrentCssRule.media?.length ? objCurrentCssRule?.media?.item(0) : null;
            if (objCSSStyleRule && strMediaListItem && objCSSStyleRule instanceof CSSStyleRule && mediaType.includes(strMediaListItem) && objCSSStyleRule?.selectorText === selectorText) {
              cssRule.deleteRule(k);
              objGroupClassNameInfo.objCss.delete(cssSelectors);
              from = k;
              break;
            }
          }

          if (objCurrentCssRule instanceof CSSStyleRule && objCurrentCssRule?.selectorText === cssSelectors) {
            cssRule.deleteRule(k);
            objGroupClassNameInfo.objCss.delete(cssSelectors);
            from = k;
            break;
          }

          if (objCurrentCssRule instanceof CSSKeyframesRule && cssSelectors.includes("@keyframes") && objCurrentCssRule?.cssText?.includes(cssSelectors)) {
            const [keyframes, animateName] = cssSelectors.split(" ");
            if (animateName && objCurrentCssRule?.name === animateName) {
              cssRule.deleteRule(k);
              objGroupClassNameInfo.objCss.delete(cssSelectors);
              from = k;
              break;
            }
          }

          from = 0;
        }
      }

      if (!objGroupClassNameInfo?.objCss?.size && !objGroupClassNameInfo?.usage?.size) {
        objCache.delete(mainCssClassName);
      }
    }

    _styleRef.blnInsertRun = false;
    _styleRef.blnPatchRun = false;
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.warn(error);
    }
  }
}

export async function cleanOne(idComp, blnIsDefaultStyle, blnCbmisStyle, appStyleConfig, strRuleClassName, strClientClassName) {
  try {
    const objCache = getCacheStoreTrack(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });

    let [clientClassName, mainCssClassName] = [strClientClassName, strRuleClassName];
    if (!objCache.has(mainCssClassName)) {
      return;
    }
    const objGroupClassNameInfo = objCache.get(mainCssClassName);

    if (objGroupClassNameInfo?.usage?.has(idComp)) {
      objGroupClassNameInfo?.usage?.delete(idComp);
    }

    if (objGroupClassNameInfo?.usage?.size) {
      return;
    }

    if (!objGroupClassNameInfo?.objCss?.size && !objGroupClassNameInfo?.usage?.size) {
      objCache.delete(mainCssClassName);
      return;
    }

    let prevIdx = 0;
    let offset = 0;
    let from = 0;
    const entries = objGroupClassNameInfo.objCss.entries();
    for (const entry of entries) {
      if (objGroupClassNameInfo?.usage?.size) {
        break;
      }
      if (!entry) {
        continue;
      }

      const [cssSelectors, { idx }] = entry;
      const indexStyle = getCacheStyleSheetIndex(appStyleConfig, { blnCbmisStyle, blnIsDefaultStyle });
      const cssRule = document?.styleSheets?.[indexStyle];

      prevIdx = idx;
      if (prevIdx > 0 && offset > 0) {
        from = idx - (prevIdx - offset);
        from = from < 0 ? 0 : from >= cssRule?.cssRules?.length ? 0 : from;
      }
      for (let k = from; k < cssRule?.cssRules?.length; k++) {
        if (objGroupClassNameInfo?.usage?.size) {
          break;
        }
        const objCurrentCssRule = cssRule.cssRules.item(k);

        if (objCurrentCssRule instanceof CSSMediaRule && cssSelectors.includes("@media") && cssSelectors.includes(splitMedia)) {
          const [mediaType, selectorText] = cssSelectors.split(splitMedia);
          let objCSSStyleRule = objCurrentCssRule.cssRules?.length ? objCurrentCssRule?.cssRules?.item(0) : null;
          let strMediaListItem = objCurrentCssRule.media?.length ? objCurrentCssRule?.media?.item(0) : null;
          if (objCSSStyleRule && strMediaListItem && objCSSStyleRule instanceof CSSStyleRule && mediaType.includes(strMediaListItem) && objCSSStyleRule?.selectorText === selectorText) {
            cssRule.deleteRule(k);
            objGroupClassNameInfo.objCss.delete(cssSelectors);
            offset = k;
            break;
          }
        }

        if (objCurrentCssRule instanceof CSSStyleRule && objCurrentCssRule?.selectorText === cssSelectors) {
          cssRule.deleteRule(k);
          objGroupClassNameInfo.objCss.delete(cssSelectors);
          offset = k;
          break;
        }

        if (objCurrentCssRule instanceof CSSKeyframesRule && cssSelectors.includes("@keyframes") && objCurrentCssRule?.cssText?.includes(cssSelectors)) {
          const [keyframes, animateName] = cssSelectors.split(" ");
          if (animateName && objCurrentCssRule?.name === animateName) {
            cssRule.deleteRule(k);
            objGroupClassNameInfo.objCss.delete(cssSelectors);
            offset = k;
            break;
          }
        }

        prevIdx = 0;
        offset = 0;
        from = 0;
      }
    }

    if (!objGroupClassNameInfo?.objCss?.size && !objGroupClassNameInfo?.usage?.size) {
      objCache.delete(mainCssClassName);
    }
  } catch (error) {
    if (pkgConfigStyle?.blnIsTestingMode) {
      console.warn(error);
    }
  }
}
