import { Grid, Typography } from '@cbmisorg/material';
import React from 'react'
import { dictionary } from '../../../../../../../util/appLanguage/dictionary';
import { App_Light_Text_Color } from '../../../../../../../util/appStyle';

const WorkSectorOption = ({ type, lang }) => {
    return (
      <Grid container px-1 py-2 spacing={0} m-0>
        <Grid item xs="12">
          <Typography as="body3" color={App_Light_Text_Color}>
            {dictionary?.shared?.app?.[type]?.[lang] || dictionary?.shared?.app?.[type]?.title?.[lang]}
          </Typography>
        </Grid>
      </Grid>
    );
  };

export default WorkSectorOption