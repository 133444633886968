import React, { forwardRef, useContext, useId } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import TableStyle from "./TableStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const TableCell = forwardRef((/**@type {any}*/props,ref) =>{
  const { headLabel, rowSpan, colSpan, sx, id, hidden, disabled, dir, className, ...rest } = props;
  const theTheme = useContext(ContextThemeProvider);
  const {classesName, styleTableCellSx} = TableStyle(theTheme);
  const classesSx = styleTableCellSx({sx});
  const comID = id ||  String(useId()).slice(1,-1)

  const itemClasses = styleFunction.getClassName(classesName?.table_cell, classesSx?.[classesName?.sx], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest), className);

  return (
    <td
      data-label={headLabel}
      rowSpan={rowSpan}
      colSpan={colSpan}
      className={itemClasses}
      {...styleFunction?.getRestProps({...rest})}
      ref={ref}
      key={comID}
    >
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </td>
  );
});

export default TableCell;
