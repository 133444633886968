"use client";

export default function AppStyleConfig() {
  const objAppStyleConfig = {
    intStyleSheetDefaultIndex: null,
    intStyleSheetSxIndex: null,
    intStyleSheetClientIndex: null,
    objCssRuleCacheSX: new Map(),
    objCssRuleCacheDefault: new Map(),
    objCssRuleCacheClient: new Map(),
  };
  
  let blnFindDefault = false;
  let blnFindSx = false;
  let blnFindClient = false;

  let lstSheet = [];
  if (typeof document !== "undefined") {
    lstSheet = document?.styleSheets;
  }

  for (let i = lstSheet?.length - 1; i >= 0; i--) {
    const element = document.styleSheets[i];
    if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"default-css";}`) {
      objAppStyleConfig.intStyleSheetDefaultIndex = i;
      blnFindDefault = true;
    } else if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"sx-css";}`) {
      objAppStyleConfig.intStyleSheetSxIndex = i;
      blnFindSx = true;
    } else if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"client-css";}`) {
      objAppStyleConfig.intStyleSheetClientIndex = i;
      blnFindClient = true;
    }

    if (blnFindClient && blnFindDefault && blnFindSx) {
      break;
    }
  }

  return objAppStyleConfig;
}

export const checkStyleSheetIndex = (appStyleConfig, blnIsDefaultStyle, blnCbmisStyle, indexStyle) => {
  const element = document.styleSheets[indexStyle];

  let blnIsExist = false;
  if (blnIsDefaultStyle && blnCbmisStyle && String(element?.cssRules?.[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"default-css";}`) {
    blnIsExist = true;
  } else if (!blnIsDefaultStyle && blnCbmisStyle && String(element?.cssRules?.[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"sx-css";}`) {
    blnIsExist = true;
  } else if (!blnCbmisStyle && String(element?.cssRules?.[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"client-css";}`) {
    blnIsExist = true;
  }

  if (!blnIsExist) {
    let blnFindDefault = false;
    let blnFindSx = false;
    let blnFindClient = false;
    for (let i = document.styleSheets.length - 1; i >= 0; i--) {
      const element = document.styleSheets[i];
      if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"default-css";}`) {
        appStyleConfig.intStyleSheetDefaultIndex = i;
        blnFindDefault = true;
      } else if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"sx-css";}`) {
        appStyleConfig.intStyleSheetSxIndex = i;
        blnFindSx = true;
      } else if (String(element?.cssRules[0]?.cssText).toLowerCase().replaceAll(" ", "") === `:root{--data-cbmis:"client-css";}`) {
        appStyleConfig.intStyleSheetClientIndex = i;
        blnFindClient = true;
      }

      if (!blnCbmisStyle && blnFindClient) {
        break;
      }
      if (blnCbmisStyle && blnFindDefault && blnFindSx) {
        break;
      }
    }
  }
};

export function getCacheStyleSheetIndex(appStyleConfig, { blnIsDefaultStyle, blnCbmisStyle }) {
  const indexStyle =
    blnIsDefaultStyle && blnCbmisStyle
      ? appStyleConfig.intStyleSheetDefaultIndex
      : blnCbmisStyle
      ? appStyleConfig.intStyleSheetSxIndex
      : appStyleConfig.intStyleSheetClientIndex;
  checkStyleSheetIndex(appStyleConfig, blnIsDefaultStyle, blnCbmisStyle, indexStyle);

  return blnIsDefaultStyle && blnCbmisStyle
    ? appStyleConfig.intStyleSheetDefaultIndex
    : blnCbmisStyle
    ? appStyleConfig.intStyleSheetSxIndex
    : appStyleConfig.intStyleSheetClientIndex;
}

export function getCacheStoreTrack(appStyleConfig, { blnIsDefaultStyle, blnCbmisStyle }) {
  return blnIsDefaultStyle && blnCbmisStyle
    ? appStyleConfig.objCssRuleCacheDefault
    : blnCbmisStyle
    ? appStyleConfig.objCssRuleCacheSX
    : appStyleConfig.objCssRuleCacheClient;
}
