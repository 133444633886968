import React, { useEffect, useState } from "react";
import { Container, Loader, Grid } from "@cbmisorg/material";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import { useApp } from "../../../../configuration/contextapi/context";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./Account.style";
import { App_info_Color } from "../../../../util/appStyle";

import accountCtrl from "./Account.controller";
import SponsorSetterModalView from "./components/sponsorSetterModal/sponsorSetterModal.view";
import TraineeSetterModalView from "./components/traineeSetterModal/traineeSetterModal.view";
// import ManagerSetterModalView from "./components/managerSetterModal/managerSetterModal.view";
import AnnouncementView from "./components/announcement/Announcement.view";
import RunningActivityView from "./components/runningActivity/RunningActivity.view";

import lineImage from "../../../../util/assets/images/Mediamodifier-Design.svg"
import ServicesView from "./components/services/Services.view";
export default function AccountView() {
  useCheckHooksGlb()
  const { appState, appDispatch } = useApp();

  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle(lang));

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    lstRunningActivity: [], //contain only the activities that is ongoing + sorted by the date // structure : {strCatName//from cate, objActivity//from cate, lstActiveSubjectOrdered, dtmLastDate}
    lstUserRegActivity: [], //contain only the activities that is ongoing (main like aiweek not its subjects)
    objPartnerInfo: null, //set only when open SponsorSetterModal, you can also use getPartnerInfo to set it.
    lstServices: [],
  });

  

  const handlers = accountCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state });
  // const getPartnerInfo = handlers.getPartnerInfo({ isLoading, setIsLoading, setState, state });
  // const flipUserType = handlers.flipUserType({ isLoading, setIsLoading, setState, state });
  const jsxSponsorSetter = SponsorSetterModalView({ isLoading, setIsLoading, parentState: state, setParentState: setState, classes });
  const jsxTraineeSetter = TraineeSetterModalView({ isLoading, setIsLoading, classes });
  // const jsxStartUpSetter = TraineeSetterModalView({ isLoading, setIsLoading, blnIsStartUp: true, classes });
  // const jsxManagerSetter = ManagerSetterModalView({ isLoading, setIsLoading });
  const jsxRunningActivity = RunningActivityView({ isLoading, setIsLoading, parentState: state, setParentState: setState, classes  });

  useEffect(() => {
    if (!state.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <AnnouncementView appState={appState} />
            </Grid>
            {
              !state?.lstRunningActivity?.length?null:(
                <Grid item xs="12">
                  {jsxRunningActivity?.lstElm}
                </Grid>
              )
            }
            <Grid item xs="12" sm="12" md="6">
              {jsxSponsorSetter.openEle}
            </Grid>
            <Grid item xs="12" sm="12" md="6">
              {jsxTraineeSetter.openEle}
            </Grid>
          </Grid>
            <img src={lineImage} alt="" className={classes?.imgLine}/>
            <ServicesView isLoading={isLoading} setIsLoading={setIsLoading} parentState={state} classes={classes} lang={lang}/>
            

        </Container>
        <div className={classes?.anmition}>
          <div className={classes?.anmitiongif} />
        </div>
      </section>

      {jsxSponsorSetter.modal}
      {jsxSponsorSetter?.modalTranstion}
      {jsxSponsorSetter?.modalImage}
      {jsxTraineeSetter.modal}
      {jsxRunningActivity?.modal}

      {/* {jsxStartUpSetter.openEle}
      {jsxStartUpSetter.modal}

      {jsxManagerSetter.openEle}
      {jsxManagerSetter.modal} */}
    </React.Fragment>
  );
}
