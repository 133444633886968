"use client";
function getPkgConfigMaterial() {
    const initConfig = {
      blnIsTestingMode: false,

    };
    return initConfig;
}

export var pkgConfigMaterial = getPkgConfigMaterial();

export function setPkgConfigMaterial({ blnIsTestingMode = true}) {
  pkgConfigMaterial.blnIsTestingMode = blnIsTestingMode;
}

//#region cbmis dataDisplay
export { default as Avatar } from "./dataDisplay/Avatar/Avatar";
export { default as Badge } from "./dataDisplay/Badge/Badge";
export { default as Chip } from "./dataDisplay/Chip/Chip";
export { default as Counter } from "./dataDisplay/Counter/Counter";
export { default as Modal } from "./dataDisplay/Modal/Modal";
export { default as ModalHeader } from "./dataDisplay/Modal/ModalHeader";
export { default as ModalBody } from "./dataDisplay/Modal/ModalBody";
export { default as ModalFooter } from "./dataDisplay/Modal/ModalFooter";
// export { default as Tooltip } from "./dataDisplay/Tooltip/Tooltip";
export { default as Typography } from "./dataDisplay/Typography/Typography";
export { default as Table } from "./dataDisplay/Table/Table";
export { default as TableHead } from "./dataDisplay/Table/TableHead";
export { default as TableBody } from "./dataDisplay/Table/TableBody";
export { default as TableFooter } from "./dataDisplay/Table/TableFooter";
export { default as TableRow } from "./dataDisplay/Table/TableRow";
export { default as TableCell } from "./dataDisplay/Table/TableCell";
// export { default as VideoPlayer } from "./dataDisplay/videoPlayer/VideoPlayer";
//#endregion

//#region cbmis feedback
export * as Alert from "./feedback/Alert/Alert";
export * as Confirmation from "./feedback/Confirmation/Confirmation";
export * as InlineTextDialog from "./feedback/inlineTextDialog/InlineTextDialog";

export { default as Note } from "./feedback/Note/Note";
//#endregion

//#region cbmisInput
export { default as Button } from "./inputs/Button/Button";
export { default as CheckboxGroup } from "./inputs/CheckboxGroup/CheckboxGroup";
export { default as DatePicker } from "./inputs/DatePicker/DatePicker";
export { default as ErrorMessage } from "./inputs/ErrorMessage/ErrorMessage";
export { default as List } from "./inputs/List/List";
export { default as MultipleList } from "./inputs/MultipleList/MultipleList";
export { default as RadioButtons } from "./inputs/RadioButtons/RadioButtons";
export { default as Text } from "./inputs/Text/Text";
export { default as TextArea } from "./inputs/TextArea/TextArea";
export { default as Password } from "./inputs/Password/Password";
export { default as Number } from "./inputs/Number/Number";
export { default as Rating } from "./inputs/Rating/Rating";
export { default as RangeSlider } from "./inputs/RangeSlider/RangeSlider";
export { default as Switch } from "./inputs/Switch/Switch";
// export { default as Color } from "./inputs/Color/Color";
export { default as Option } from "./inputs/option/Option";
export { default as GroupField } from "./inputs/GroupField/GroupField";
export { default as RadialProgressBar } from "./inputs/RadialProgressBar/RadialProgressBar";

//#endregion

//#region cbmis layout
export { default as Box } from "./layout/Box/Box";
export { default as Container } from "./layout/Container/Container";
export { default as Line } from "./layout/Line/Line";
export { default as Grid } from "./layout/Grid/Grid";

//cbmis navigation
// export { default as BottomBar } from "./navigation/BottomBar/BottomBar";
export { default as Breadcrumbs } from "./navigation/Breadcrumbs/Breadcrumbs";
export { default as Drawer } from "./navigation/Drawer/Drawer";
export { default as Menu } from "./navigation/Menu/Menu";
export { default as MenuItem } from "./navigation/Menu/MenuItem";
// export { default as Pagination } from "./navigation/Pagination/Pagination";
// export { default as SpeedDial } from "./navigation/SpeedDial/SpeedDial";
// export { default as SpeedDialAction } from "./navigation/SpeedDial/SpeedDialAction";
export { default as Stepper } from "./navigation/Stepper/Stepper";
export { default as Slideshow } from "./surfaces/Slideshow/Slideshow";
export { default as Slide } from "./surfaces/Slideshow/Slide";
export { default as Tabs } from "./navigation/Tabs/Tabs";
// export { default as ParallaxScrolling } from "./navigation/ParallaxScrolling/ParallaxScrolling";
export { default as AppBar } from "./navigation/AppBar/AppBar";
export { default as GroupBar } from "./navigation/groupBar/GroupBar";
export { default as Nav } from "./navigation/nav/Nav";
// export { default as BtnGoToTop } from "./navigation/btnGoToTop/BtnGoToTop";
//#endregion

//#region cbmis surfaces
export { default as Accordion } from "./surfaces/Accordion/Accordion";
export { default as AccordionTab } from "./surfaces/Accordion/AccordionTab";
export { default as AccordionSummary } from "./surfaces/Accordion/AccordionSummary";
export { default as AccordionDetails } from "./surfaces/Accordion/AccordionDetails";
export { default as Card } from "./surfaces/Card/Card";
export { default as CardFront } from "./surfaces/Card/CardFront";
export { default as CardBack } from "./surfaces/Card/CardBack";
export { default as CardImage } from "./surfaces/Card/CardImage";
export { default as CardDetails } from "./surfaces/Card/CardDetails";
// export { default as Glass } from "./surfaces/Glass/Glass";
export { default as Loader } from "./surfaces/Loader/Loader";
export { default as Paper } from "./surfaces/Paper/Paper";
export { default as AnimationBox } from "./surfaces/AnimationBox/AnimationBox";

//#endregion

