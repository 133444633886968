import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../../graphql/schema/GenerateQuery";
import SponsorTrainingTypeOptionsView from "./component/sponsorTrainingTypeOptions.view";
import { objTrainingToSupport } from "../../../../../../../old/appHelper/appVariables";
const partnerApi = generateQueries("tblPartner");

const strSponsorTrainingType = "fullstack";

const strPageInfo = "@src/client/component/shared/account/components/sponsorSetterModal/sponsorSetterModal.controller.js";

export default function sponsorSetterModalCtrl({ appState, appDispatch, parentState, setParentState, isLoading, setIsLoading }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields.controller.resetForm();

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          setIsLoading(true);

          const objCat = appFunctions.getCategoryCache();
          let lstSponsorTrainingTypeOption = [];

          Object.entries(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
                lang,
                value,
                getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(key, objCat),
              });
              lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
            }
          });
          fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption);

          const result = await partnerApi(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes.tblPartner.full,
            objectCondition: {
              bigPartnerID: appState?.userInfo?.bigUserID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          if (result?.response && result?.response?.bigPartnerID && !Number.isNaN(Number(result?.response?.bigPartnerID))) {
            state.blnIsRecordExist = true;
            parentState.objPartnerInfo = result?.response;

            fields?.bigWorkSector?.setValue(result?.response?.jsnPartnerInfo?.bigWorkSector);
            fields?.strPlaceName?.setValue(result?.response?.jsnPartnerInfo?.strPlaceName);
            fields?.strURL?.setValue(result?.response?.jsnPartnerInfo?.strURL);
            state.lstLogo = Array.isArray(result?.response?.strLogoImg) ? result?.response?.strLogoImg : [];
          } else {
            state.blnIsRecordExist = false;
            parentState.objPartnerInfo = null;
            state.lstLogo = [];
          }

          state.blnIsInitDone = true;
          setState({ ...state });
          setParentState({ ...parentState });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },
    getPartnerTransPlanTotalPrice: (strSponsorType, objCat) => {
      try {
        const intCountStudentNumSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
        const priceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng);
        return ((priceSystem || 0) * (intCountStudentNumSystem || 0)).toLocaleString();
      } catch {
        return 0;
      }
    },
    openModal:
      ({ state, setState, fields }) =>
      async () => {
        try {
          state.blnOpenSetterModal = true;
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },

    updatePartnerInfo:
      ({ state, setState, fields, uploaderLogo, uploaderReceipt }) =>
      (intSupportTransStatus = appVariables.objSupportTransStatus.Send_New) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }
          setIsLoading(true);
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add or update partner
          const resultUpload = await uploaderLogo?.uploadHandler();
          if (!resultUpload) {
            setIsLoading(false);
            return;
          }

          let bigPartnerStatusID = state?.blnIsRecordExist ? parentState?.objPartnerInfo?.bigPartnerStatusID : null;
          if (!bigPartnerStatusID) {
            bigPartnerStatusID = !appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer
              ? appVariables.objPartnerStatus.Approved
              : appVariables.objPartnerStatus.New;
          }
          const objCat = appFunctions.getCategoryCache();

          // //#region
          let jsnSupportTrans = Array.isArray(parentState?.objPartnerInfo?.jsnSupportTrans)
            ? JSON.parse(JSON?.stringify(parentState?.objPartnerInfo?.jsnSupportTrans))?.reverse()
            : [];

          if (state.lstReceipt?.length) {
            const resultUploadReceipt = await uploaderReceipt?.uploadHandler("supportTransReceipt");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }
          const intCountStudentNum = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType].sponser?.[fields?.strSponsorTrainingType?.getValue()?.key]?.studentNumber?.value?.eng) || 0;
          const intPriceSystem = Number(objCat?.Root?.trainingType?.[strSponsorTrainingType]?.price?.value?.eng) || 0;

          if (!state.selectedTrans) {
            jsnSupportTrans.push({
              id: appFunctions.generateID(6),
              dtmCreatedDate: new Date().toUTCString(),
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              lstReceipt: state.lstReceipt,
              lstReceiptCatch: [],
              intReceivedAmount: fields?.intReceivedAmount?.getValue() || null,
            });
          } else {
            const transIndex = jsnSupportTrans?.findIndex((item) => item.id === state?.selectedTrans?.id);
            jsnSupportTrans[transIndex] = {
              ...state?.selectedTrans,
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              lstReceipt: state.lstReceipt,
              lstReceiptCatch: [],
            };
          }

          let intTraineesCount_Old = parentState?.objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount_Old = parentState?.objPartnerInfo?.intManagerTrainCount;

          let intTraineesCount = parentState?.objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount = parentState?.objPartnerInfo?.intManagerTrainCount;

          for (let i = 0; i < jsnSupportTrans.length; i++) {
            if (appFunctions.getDifferenceBetweenTwoDate(jsnSupportTrans?.[i]?.dtmCreatedDate, new Date(), "month") <= 4) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          if (intTraineesCount === intTraineesCount_Old && intManagerTrainCount === intManagerTrainCount_Old) {
            for (let i = 0; i < jsnSupportTrans.length; i++) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (String(jsnSupportTrans?.[i]?.strTrainingType) === String(objTrainingToSupport.manager?.key)) {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intManagerTrainCount) {
                    intManagerTrainCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                } else {
                  if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                    intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                  }
                }
              }
            }
          }

          // //#endregion
          const resultPartner = await partnerApi(strPageInfo, appState, "aaiotAccountPartnerUpdate", {
            objectInput: {
              bigUserID: appState?.userInfo?.bigUserID,
              jsnUserInfo: {
                bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              },

              jsnPartnerInfo: {},
              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              strPlaceName: fields?.strPlaceName?.getValue(),
              strFullName: appState?.userInfo?.strFullName,
              strUserEmail: appState?.userInfo?.strUserEmail,
              strUserPhone: appState?.userInfo?.strUserPhone,
              strURL: fields?.strURL?.getValue(),

              bigPartnerStatusID: bigPartnerStatusID,

              jsnSupportTrans: jsnSupportTrans,

              blnIsFullstackSponsor: true,
              blnIsManagerSponsor: false,

              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intTraineesCount,//intTraineesCount_Old !== intTraineesCount ? intTraineesCount : undefined,
              intManagerTrainCount: intManagerTrainCount,//intManagerTrainCount_Old !== intManagerTrainCount ? intManagerTrainCount : undefined,

              blnIsConfirmed: bigPartnerStatusID === appVariables.objPartnerStatus.Approved,
              //blnVewLogo: false,
              //strLogoImg: state?.lstLogo,
              lstStatisticsOnAdd: ["intCountJoinSponsorAll", "intCountJoinPartner"],
              lstStatisticsOnUpdate: ["intCountJoinSponsorAll"],

              dtmUpdatedDate: new Date().toUTCString(),
            },
          });

          if (!resultPartner?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }
          
          parentState.objPartnerInfo = { ...parentState.objPartnerInfo, ...resultPartner?.response?.partnerInfo };
          appState.userInfo.blnIsSponsor = resultPartner?.response?.userInfo?.blnIsSponsor;
          appState.userInfo.jsnUserInfo = resultPartner?.response?.userInfo?.jsnUserInfo;
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          state.blnOpenSetterModal = false;
          setState({ ...state });
          setIsLoading(false);
          setParentState({ ...parentState });
          appDispatch();
          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_Successfully2?.[lang], "success", appLangDirection?.[lang]);
        } catch (error) {
          appFunctions.logError(strPageInfo, "updatePartnerInfo-ERROR", error);
          setIsLoading(false);
        }
      },

    dilogeImgModal:
      ({ state, setState }) =>
      (lstImg = null, typeImg = null) =>
      () => {
        try {
          state.blnOpenImgeModal = !state.blnOpenImgeModal;
          state.selectedLstImg = lstImg;
          state.selectedImgType = typeImg;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "dilogeImgModal-Error");
        }
      },
  };

  return handler;
}
