import { ClientTest } from "@cbmisorg/client-app";
import { ClientFunction } from "@cbmisorg/helper";
import * as appVariables from "./appVariables";
import objAppRouting from "./routing/appRouting";

export const getNextStrURL = () => {
  const localStorage = getAppLocalStorage();
  const blnIsUserLoggedIn = localStorage?.clientInfo?.blnIsUserLoggedIn || false;
  const lstUserFeature = localStorage?.userInfo?.lstUserFeature || [];
  
  if (blnIsUserLoggedIn && lstUserFeature?.includes(objAppRouting?.Account?.featureID)) {
    return objAppRouting?.Account?.url;
  } else {
    return objAppRouting?.Public_Login?.url;
  }
};
export const logMessage = (strPageInfo, msg, title) => {
  ClientTest.logMessage(strPageInfo, title, msg, appVariables.App_IsInTestingMode);
};

export const { ...clientFunction } = ClientFunction;

export const detectBrowser = () => {
  let userAgent = navigator.userAgent;
  if (userAgent.match(/edg/i)) {
    return "edge";
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    return "chrome";
  } else if (userAgent.match(/safari/i)) {
    return "safari";
  }
  return "chrome";
};

export const scroll = (id) => () => {
  const section = document.querySelector(`#${id}`);
  section.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const sortbyDate = (array, key) => {
  return array
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a?.[key]).getTime() - new Date(b?.[key]).getTime();
    })
    .reverse();
};

export function setAppLocalStorage(objAppState) {
  ClientFunction.setAppLocalStorage(objAppState, appVariables.App_LocalStorage_Name, appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);
}

export function getAppLocalStorage() {
  return ClientFunction.getAppLocalStorage(appVariables.App_LocalStorage_Name, appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);
}

export function clearAppLocalStorage(objAppState) {
  objAppState.clientInfo = {
    ...objAppState.clientInfo,
    blnIsUserLoggedIn: false,
    objSystemInfo: {
      ...objAppState?.clientInfo?.objSystemInfo,
      bigSystemID: appVariables?.aaiot_System_ID,
    },

    bigSystemID: appVariables?.aaiot_System_ID,
    bigAppID: appVariables?.App_ID,

    dtmLastLoggingIn: "",
    dtmLastCheckedSystemInfo: "",
  };
  objAppState.userInfo = {
    bigUserID: 0,
    bigAppID: appVariables?.App_ID,
    bigSystemID: appVariables?.aaiot_System_ID,
    strFullName: "",
    strUserEmail: "",
    strUserPhone: "",
    strUsername: "",
    bigUserRoleID: appVariables?.Role_Public,
    bigCountryID: 0,
    lstUserFeature: [],
    txtLoginKeys: [],
    txtLoginIP: [],
    strLastLoginUserAgent: "",
    dtmLastLogin: "",
  };
  setAppLocalStorage(objAppState);
}

export const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const {
  addDaysToDate,
  blnIsTodayBiggerThanTheDate,
  camelize,
  checkFileName,
  checkIsObjectFine,
  checkIsValidDate,
  checkNumber,
  checkParseObject,
  clearSessionStorageObject,
  compareTwoArraysIsEqual,
  convertDateToInputForm,
  copyTextToClipboard,
  decryptText,
  downloadFile,
  encryptText,
  excelDateToJSDate,
  fetchFileFromUrlAndDownload,
  generateID,
  //generateRandomString,
  generateRandomStringSecure,
  getAppSessionStorage,
  getCurrentDate,
  getDateUSFormat,
  getDifferenceBetweenTwoDate,
  getISODate,
  getKeyByValue,
  getRandomNumber,
  getRandomNumberSecure,
  getSpecificDateFromNow,
  isDate,
  normalizeText,
  parseArabicNumber,
  processFile,
  recursionResetObject,
  resizeImg,
  safeInvoke,
  scrollToElement,
  setAppSessionStorage,
  trimDash,
  compressText,
  decompressText,
} = ClientFunction;

export function calculateAge(DofBirth) {
  const dob = new Date(DofBirth);
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const getDeactivateDate = (months) => {
  const date = getCurrentDate();
  const addedYear = months / 12;
  const addMonth = months % 12;

  date.setFullYear(date.getFullYear() + addedYear);
  date.setMonth(date.getUTCMonth() + addMonth);

  return date.toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" });
};

//#region category function
export function getCategoryCache() {
  try {
    let result = decompressText(getAppLocalStorage()?.clientInfo?.objCatInfo);
    return result;
  } catch {
    return {};
  }
}

export function appCategoryGetValue(objCategory, id_or_name, strMiddleKey = null, blnIsParentKey = false) {
  try {
    let result = {};
    let lstCat = Object.entries(objCategory);

    for (let i = 0; i < lstCat.length; i++) {
      let [key, value] = lstCat[i];
      if (key === "id" || key === "value") {
        continue;
      }

      if (strMiddleKey) {
        if (blnIsParentKey) {
          if (!Number.isNaN(Number(id_or_name))) {
            if (String(id_or_name) !== String(value?.id)) {
              continue;
            } else {
              result = value?.[strMiddleKey]?.value;
              break;
            }
          }
        }

        value = value?.[strMiddleKey];
      }

      if (!Number.isNaN(Number(id_or_name))) {
        if (String(value?.id) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      } else {
        if (String(key) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      }
    }

    return result;
  } catch {
    return {};
  }
}

export function appCategoryGetKey(objCategory, id_or_name) {
  try {
    let result = null;
    let lstCat = Object.entries(objCategory);

    for (let i = 0; i < lstCat.length; i++) {
      let [key, value] = lstCat[i];

      if (key === "id" || key === "value") {
        continue;
      }

      if (!Number.isNaN(Number(id_or_name))) {
        if (String(value?.id) === String(id_or_name)) {
          result = key;
          break;
        }
      } else {
        if (String(key) === String(id_or_name)) {
          result = value?.id;
          break;
        }
      }
    }

    return result;
  } catch {
    return null;
  }
}
//#endregion

export function getSponsorTypeAccordingToTraineeNum(intNumTrainee, fullstack_OR_manager = appVariables.objTrainingToSupport.fullstack.key) {
  const objCat = getCategoryCache();

  if (!fullstack_OR_manager) {
    fullstack_OR_manager = appVariables.objTrainingToSupport.fullstack.key;
  }

  let objTrainingSponsor = objCat?.Root?.trainingType?.[fullstack_OR_manager];
  const objSafeBackupValue = {
    sponser: {
      main: { studentNumber: { value: { eng: 100, arb: 100 } } },
      golden: { studentNumber: { value: { eng: 50, arb: 50 } } },
      silver: { studentNumber: { value: { eng: 25, arb: 25 } } },
      bronze: { studentNumber: { value: { eng: 10, arb: 10 } } },
    },
  };
  if (!objTrainingSponsor) {
    objTrainingSponsor = objSafeBackupValue;
  }

  if (Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.main?.studentNumber?.value?.eng)) {
    return appVariables.objSponserCat.main.key;
  }

  if (Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.golden?.studentNumber?.value?.eng) && Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.main?.studentNumber?.value?.eng)) {
    return appVariables.objSponserCat.golden.key;
  }

  if (
    Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.silver?.studentNumber?.value?.eng) &&
    Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.golden?.studentNumber?.value?.eng)
  ) {
    return appVariables.objSponserCat.silver.key;
  }

  if (
    (Number(intNumTrainee) > 0 || Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.bronze?.studentNumber?.value?.eng)) &&
    Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.silver?.studentNumber?.value?.eng)
  ) {
    return appVariables.objSponserCat.bronze.key;
  }

  return null;
}

export function getSponsorTypeAccordingToAllTraineeNum(lstSupport = []) {
  const objCoutStudent = {};
  for (let index = 0; index < lstSupport.length; index++) {
    const element = lstSupport[index];
    if (!objCoutStudent?.[element.strTrainingType]) {
      objCoutStudent[element.strTrainingType] = 0;
    }

    if (element?.intSupportTransStatus === appVariables.objSupportTransStatus?.Approved) {
      objCoutStudent[element.strTrainingType] += element?.intTraineesCount || 0;
    }
  }
  const lstValueSort = Object.values(objCoutStudent).sort();
  
  return getSponsorTypeAccordingToTraineeNum(lstValueSort?.[lstValueSort?.length - 1]);
}

export const isValidUrl = (urlString) => {
  try {
     new URL(urlString)
     return true;
  } catch (error) {
    return false;
  }
};
