export const keysSpacing={
    start: "start",
    end:"end",

    px:"px",
    auto:"auto",

    mrg:"m",
    mrgTop:"mt",
    mrgBottom:"mb",
    mrgLeft:"ml",
    mrgRight:"mr",
    mrgY:"my",
    mrgX:"mx",

    xs:"xs",
    sm:"sm",
    md:"md",
    lg:"lg",
    xl:"xl",

    pdn:"p",
    pdnTop:"pt",
    pdnBottom:"pb",
    pdnLeft:"pl",
    pdnRight:"pr",
    pdnY:"py",
    pdnX:"px",
}

export default{
    spacing:{
        [keysSpacing?.px]:"1px",
        [keysSpacing?.auto]:"auto",

        [keysSpacing?.xs]:".5rem",
        [keysSpacing?.sm]:"1rem",
        [keysSpacing?.md]:"1.3rem",
        [keysSpacing?.lg]:"2.6rem",
        [keysSpacing?.xl]:"5rem",

        baseSize: 0.25,

    }
}