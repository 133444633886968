import React, { forwardRef, useContext, useId, useState } from "react";
import { ContextThemeProvider, styleFunction } from "@cbmisorg/styles";
import RatingStyle from "./RatingStyle";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";
import RatingRadioInput from "./RatingRadioInput";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const Rating = forwardRef((/**@type {any}*/ props, ref) => {
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = RatingStyle(theTheme);
  const { id, label, errorMessage, helperMessage, currentLang, color, icon, iconSize, value, defaultValue, onChange, readOnly = false, sx, hidden, disabled, dir, className, ...rest } = props;
  const classes = style();
  const classesSx = styleSx({ sx, color, readOnly });
  const comID = id || String(useId()).slice(1, -1) + "rating";
  const [inputValue, setInputValue] = useState(Number(value) || Number(defaultValue) || 0);;
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.rating_container],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  //#region change
  const handleChange = (event) => {
    const id = String(event.target.id).split("-");
    const value = parseInt(id[0].charAt(id[0].length - 1));
    if (onChange) {
      onChange(event);
    }
    setInputValue(value);
  };
  //#endregion

  return (
    <div ref={ref} className={itemClasses} {...styleFunction?.getRestProps(rest)}>
      <ErrorBoundary>
        <div className={classes?.[classesName?.rating]} id={classesSx?.[classesName?.sx]}>
          {label ? (
            <label className={`${classes?.[classesName?.label]} ${classesSx?.[classesName?.label]}`} htmlFor={`${comID}`}>
              {typeof label === "object" ? label?.[currentLang || "default"] : label}
            </label>
          ) : (
            ""
          )}
          <RatingRadioInput
            name={comID}
            id={`star1-${comID}`}
            value={1}
            ref={ref}
            readOnly={readOnly}
            icon={icon}
            iconSize={iconSize}
            onChange={handleChange}
            classes={classes}
            classesSx={classesSx}
            classesName={classesName}
            inputValue={inputValue}
            color={color}
          />
          <RatingRadioInput
            name={comID}
            id={`star2-${comID}`}
            value={2}
            ref={ref}
            readOnly={readOnly}
            icon={icon}
            iconSize={iconSize}
            onChange={handleChange}
            classes={classes}
            classesSx={classesSx}
            classesName={classesName}
            inputValue={inputValue}
            color={color}
          />
          <RatingRadioInput
            name={comID}
            id={`star3-${comID}`}
            value={3}
            ref={ref}
            readOnly={readOnly}
            icon={icon}
            iconSize={iconSize}
            onChange={handleChange}
            classes={classes}
            classesSx={classesSx}
            classesName={classesName}
            inputValue={inputValue}
            color={color}
          />
          <RatingRadioInput
            name={comID}
            id={`star4-${comID}`}
            value={4}
            ref={ref}
            readOnly={readOnly}
            icon={icon}
            iconSize={iconSize}
            onChange={handleChange}
            classes={classes}
            classesSx={classesSx}
            classesName={classesName}
            inputValue={inputValue}
            color={color}
          />
          <RatingRadioInput
            name={comID}
            id={`star5-${comID}`}
            value={5}
            ref={ref}
            readOnly={readOnly}
            icon={icon}
            iconSize={iconSize}
            onChange={handleChange}
            classes={classes}
            classesSx={classesSx}
            classesName={classesName}
            inputValue={inputValue}
            color={color}
          />
        </div>
      </ErrorBoundary>
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
});

export default Rating;
