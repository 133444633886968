export const keysText={
    text: "text",
    font:"font",
    break:"break",
    truncate:"truncate",
    transform:"transform",
    size:"size",



}

export default{
    [keysText?.break]:{
        normal:"normal",
        words:"break-word",
        all:"break-all"
    },
    [keysText?.transform]:{
        "uppercase":"uppercase",
        "lowercase":"lowercase",
        "capitalize":"capitalize",
        "normalcase":"none",
    },
    [keysText?.size]:{
        h1:"4rem",
        h2:"3rem",
        h3:"1.875rem",
        h4:"2.25rem",
        h5:"1.875rem",
        h6:"1.25rem",
        subtitle1:"1.125rem",
        subtitle2:"1.15rem",
        body1:"1rem",
        body2:".875rem",
        caption:".75rem",
        overline:".5rem",

        baseSize: 0.25,

    }
}