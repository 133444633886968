import {
  StyleBuilder,
  styleVariables,
  cssKeys,
  mainTheme,
  styleFunction,
} from "@cbmisorg/styles";


export default(objThemeInfo)=>{
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;

  const classesName = {
    box: `${styleVariables?.prefixDefaultClassName}_box`,
    sx: `${styleVariables?.prefixDefaultClassName}_box_sx`,
  };

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.box]: {
        display: "block",
        position: "relative",
        overflow: "auto",
        width: cssObject?.size?.[cssKeys?.full],
        maxWidth: cssObject?.size?.[cssKeys?.full],
        textAlign: "center",
        color: cssObject?.color?.bodyColor,
      }
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.sx]: (props) => {
        return {
          fontSize:cssObject?.text?.size?.body1,
          padding: cssObject?.spacing?.[cssKeys?.md],
          margin: `${cssObject?.spacing?.[cssKeys?.sm]} auto`,
          borderRadius: cssObject?.border?.[cssKeys?.rounded]?.sm,
          ...getBorder(props),
          boxShadow:getShadow(props?.elevation),
          ...props?.sx,
          "&hover":{
            cursor:parseInt(props?.hoverElevation)?"pointer":"default",
            ...props?.sx?.["&hover"],
            boxShadow:getShadow(props?.hoverElevation)
          }
        };
      },
    },
  });

  const getBorder = (props) => {
    switch (parseInt(props?.outline)) {
      case 1:
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
        };
      case 2:
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_sm]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color ||cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
        };
      case 3:
        return {
          color: props?.color || cssObject?.color?.bodyColor,
          border: `${cssObject?.size?.[cssKeys?.border_md]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${props?.color ||cssObject?.color?.bodyColor}`,
          background: cssObject?.color?.[cssKeys?.transparent],
        };
      case 4:
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_lg]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color ||cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
        };
      case 5:
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_xl]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color ||cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],
        };
      case 0:
        return {
          color: styleFunction.checkColorReturnValue(cssObject?.color?.bodyColor),
          background: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.primary),
        };
      default:
        return {
          color: styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor),
          border: `${cssObject?.size?.[cssKeys?.border_xs]} ${cssObject?.border?.[cssKeys?.border]?.solid} ${styleFunction.checkColorReturnValue(props?.color || cssObject?.color?.bodyColor)}`,
          background: cssObject?.color?.[cssKeys?.transparent],

        };
    }
  };

  const getShadow = (elevation) => {
    switch (parseInt(elevation || 0)) {
      case 1:
        return cssObject?.boxShadow?.[cssKeys?.xs];
      case 2:
        return cssObject?.boxShadow?.[cssKeys?.sm];
      case 3:
        return cssObject?.boxShadow?.[cssKeys?.md];
      case 4:
        return cssObject?.boxShadow?.[cssKeys?.lg];
      case 5:
        return  cssObject?.boxShadow?.[cssKeys?.xl];
      default:
        return cssObject?.boxShadow?.[cssKeys?.xs];
    }
  };

  return{
    classesName,
    style,
    styleSx,
  }
}